import EditIcon from "../../assets/icon/edit.svg";
import CloseIcon from "../../assets/icon/x.svg";
import { Button } from "@mui/material";
import { Fragment } from "react";
import { capitalizeFirstLetter } from "../../utils/util";

export const PBFEEditableList = ({
  label,
  arr,
  mode,
  showFeeAccount = false,
  action = () => {},
  closeAction = () => {},
}) => {
  return (
    <div className="">
      <div className="text-textlabel text-12px">{label}</div>
      <div>
        {arr.length <= 0 && (
          <div>
            <div className="inline-block rounded-full font-medium text-textprimary py-6px px-12px bg-purpleLight mt-10px ">
              {showFeeAccount ? 
                <div> {"All " + label} </div> 
                :
                <div> {"All " + capitalizeFirstLetter(mode)} </div> 
              }
            </div>
          </div>
        )}
        {arr.map((item) => {
          return (
            <div>
              <div className="inline-block rounded-full font-medium text-textprimary py-6px px-12px bg-purpleLight mt-10px ">
                <div className="flex flex-row">
                  <div>{item.id + " - " + item.name}</div>
                  <img
                    alt=""
                    onClick={() => closeAction(mode, item)}
                    className="ml-2 cursor-pointer"
                    src={CloseIcon}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Button
        variant="text"
        onClick={action}
        sx={{ fontSize: "14px", marginTop: "20px" }}
        startIcon={<img alt="" src={EditIcon}></img>}
      >
        EDIT
      </Button>
    </div>
  );
};

export const PBFEEList = ({ arr, action, selectAll, selectAllLabel }) => {
  const Horizon = ({ item, index }) => {
    return (
      <div className="inline-block mr-2">
        <div className=" rounded-full font-medium text-textprimary py-6px px-12px bg-purpleLight mt-10px ">
          {/* <div className=" flex-shrink-0 rounded-full font-medium text-textprimary py-6px px-12px bg-purpleLight mt-10px ">*/}
          <div className="flex flex-row">
            <div>{item.id + " - " + item.name}</div>
            <img
              alt=""
              onClick={() => action(item, index)}
              className="ml-2 cursor-pointer"
              src={CloseIcon}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {selectAll && (
        <div
          onClick={selectAll}
          className="inline-block mr-2 hover:bg-textscb hover:text-white cursor-pointer flex-shrink-0 rounded-full font-medium text-textprimary py-6px px-12px bg-purpleLight mt-10px "
        >
          <div>{selectAllLabel}</div>
        </div>
      )}
      {arr?.map((item, index) => {
        return <Horizon item={item} index={index} />;
      })}
    </Fragment>
  );
};
