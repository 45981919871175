// require === this filed is required
// number === this filed must be number

export const validateError = {
  from: [
    "required",
    "word:MIN",
    "minus",
    "lower_than:to",
    "max:999999999.99",
    "decimal:2",
  ],
  to: ["required", "word:MAX", "minus", "max:999999999.99", "decimal:2"],
  tier: ["required"],
  rate: ["minus", "sync:rate_type"],
  priority: ["minus"],
  min: ["minus", "max:999999999.99", "lower_than:max"],
  max: ["minus", "max:999999999.99"],
  fix_amount: ["minus", "max:999999999.99"],
  min_tier: ["minus", "max:999999999.99", "lower_than:max_tier"],
  max_tier: ["minus", "max:999999999.99"],
};
