import api from "./index";
import { apiConfig } from "../config";

const getHistoryById = async (params, query) => {
  return api.instanceCorp.get(`${apiConfig.getHistory}${query}`);
};

const getTaxSchemeHistory = async (params, query)=>{
  return api.instanceCorp.get(`${apiConfig.getTaxSchemeHistory}${query}`);
}

const exportedObject = {
  getHistoryById,
  getTaxSchemeHistory
};

export default exportedObject;
