import { format } from "date-fns";
import * as React from "react";
import PBFESearchbar from "../../components/Elements/Searchbar/searchbar";
import { PBFECommonTable } from "../../components/Table/commonTable";
import {
  MINIMUM_SEARCH_ACTIVE,
  UPLOAD_CHARGE_MAKER_CHECKER_HEADER,
  UPLOAD_MAKER_CHECKER_HEADER,
} from "../../constants/constant";
import { formatDateApi, formateDate, formateDateUplaode, formateDateWithOutNullValue, formatEmpty } from "../../utils/util";
import {
  PBFEGeneralInfoChecker,
  PBFESectionReason,
} from "../WaitingForApproval/approvalDetail";

const FormatDatRows = (array_input, isChargeCode) => {
  const res = array_input?.map((item) => {

    if (isChargeCode) {
      return {
        txn_id: item?.transaction_id,
        tnx_date: item?.transaction_date,
        cust_id: item?.customer_id,
        acct_no: item?.account_id,
        charge_code: item?.charge_code,
        txn_volume: item?.transaction_volume,
      };
    }
    return {
      txn_id: item?.transaction_id,
      tnx_date: item?.transaction_date,
      cust_id: item?.customer_id,
      acct_no: item?.account_id,
      txn_code: item?.transaction_code,
      txn_volume: item?.transaction_volume,
    };
  });

  return res;
};

const UploadMakerChecker = ({ data }) => {
  const [searched, setSearched] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [originalRows, setOriginalRows] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    if (data) {
      setRows(data?.data?.display_obj);
      setOriginalRows(data?.data?.display_obj);
    } else {
      return;
    }
  }, []);
  const isChargeCode = data.transaction_type === "CHARGECODETRANSACTION";

  const requestSearch = (searchedVal) => {
    const value = searchedVal.target.value;
    setSearched(value);
    setPage(0);
    if (value?.length < MINIMUM_SEARCH_ACTIVE) {
      setRows(originalRows);
      return;
    } else {
      const filteredRows = rows.filter((row) => {

        if (isChargeCode) {

          return (
            row.account_id
              .toLowerCase()
              .includes(searchedVal.target.value.toLowerCase()) ||
            row.charge_code
              .toLowerCase()
              .includes(searchedVal.target.value.toLowerCase()) ||
            row.customer_id
              .toLowerCase()
              .includes(searchedVal.target.value.toLowerCase()) ||
            formateDateUplaode(row.transaction_date)
              .toLowerCase()
              .includes(searchedVal.target.value.toLowerCase()) ||
            row.transaction_id
              .toLowerCase()
              .includes(searchedVal.target.value.toLowerCase()) ||
            row.transaction_volume
              .toLowerCase()
              .includes(searchedVal.target.value.toLowerCase())
          );
        }

        return (
          row.account_id
            .toLowerCase()
            .includes(searchedVal.target.value.toLowerCase()) ||
          row.customer_id
            .toLowerCase()
            .includes(searchedVal.target.value.toLowerCase()) ||
          row.charge_code
            .toLowerCase()
            .includes(searchedVal.target.value.toLowerCase()) ||
          row.transaction_date
            .toLowerCase()
            .includes(searchedVal.target.value.toLowerCase()) ||
          row.transaction_id
            .toLowerCase()
            .includes(searchedVal.target.value.toLowerCase())
        );
      });
      setRows(filteredRows);
    }
  };


  // var today = format(new Date(), "yyyy-MM-dd") + "T00:00:00";

  return (
    <div className="w-full overflow-auto pt-xxl">
      <div className="h-10px bg-gray-100"></div>
      <PBFEGeneralInfoChecker data={data} />
      <div className="h-10px bg-gray-100"></div>
      {data.reason && (
        <React.Fragment>
          <PBFESectionReason data={data} />
          <div className="h-10px bg-gray-100"></div>
        </React.Fragment>
      )}
      <div className="w-full px-xl">
        <div className={isChargeCode ? "flex justify-end w-full  pt-md" : "hidden"}>
          <PBFESearchbar
            placeholder={"Search"}
            onChange={requestSearch}
            value={searched}
          />
        </div>
        <PBFECommonTable
          searched={searched}
          page={page}
          setPage={setPage}
          headCells={isChargeCode ? UPLOAD_CHARGE_MAKER_CHECKER_HEADER : UPLOAD_MAKER_CHECKER_HEADER}
          rows={FormatDatRows(rows, isChargeCode)}
          formatControl={[null, formateDateUplaode, null, null, null, null]}
          showFooter={true}
        />
      </div>
    </div>
  );
};

export default UploadMakerChecker;
