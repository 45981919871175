import { PBFEContent } from "../../../components/Elements/Text/content";
import { formatEmpty } from "../../../utils/util";

const CustomerRenderInformation = ({ data }) => {
  return (
    <div className="grid grid-cols-4 gap-y-6 gap-x-2">
      <PBFEContent
        label="Tax Id"
        value={formatEmpty(
          data?.ext_info?.customer_tax_id || data?.customer_tax_id
        )}
      />
      <div className="col-span-3">
        <PBFEContent
          label="Tax Branch No"
          value={formatEmpty(
            data?.ext_info?.customer_branch_no || data?.customer_branch_no
          )}
        />
      </div>

      <div className="col-span-2">
        <PBFEContent
          label="Email"
          value={formatEmpty(data?.ext_info?.email || data?.email)}
        />
      </div>
      <div className="col-span-2">
        <PBFEContent
          label="Customer Name"
          value={formatEmpty(
            data?.ext_info?.full_name_thai || data?.full_name_thai
          )}
        />
      </div>

      <div className="col-span-2">
        <PBFEContent
          label="Register Address"
          value={
            formatEmpty(
              data?.ext_info?.reg_addr_line1 || data?.reg_addr_line1,
              ""
            ) +
            " " +
            formatEmpty(
              data?.ext_info?.reg_addr_line2 || data?.reg_addr_line2,
              ""
            ) +
            " " +
            formatEmpty(
              data?.ext_info?.reg_addr_line3 || data?.reg_addr_line3,
              ""
            ) +
            " " +
            formatEmpty(data?.ext_info?.reg_zip_code || data?.reg_zip_code, "")
          }
        />
      </div>
      <div className="col-span-2">
        <PBFEContent
          label="Contact Address"
          value={
            formatEmpty(
              data?.ext_info?.reg_addr_line1 || data?.reg_addr_line1,
              ""
            ) +
            " " +
            formatEmpty(
              data?.ext_info?.reg_addr_line2 || data?.reg_addr_line2,
              ""
            ) +
            " " +
            formatEmpty(
              data?.ext_info?.reg_addr_line3 || data?.reg_addr_line3,
              ""
            ) +
            " " +
            formatEmpty(data?.ext_info?.reg_zip_code || data?.reg_zip_code, "")
          }
        />
      </div>
    </div>
  );
};

export default CustomerRenderInformation;
