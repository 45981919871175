import * as React from "react";

import { TYPE_NONE } from "../type";
import CoreContainer from "./CoreContainer";

const CoreMain = ({
  mode,
  fields,
  index,
  FieldReference,
  loadData,
  language,
  parentKey,
  parentIndex,
  fieldIndex,
  grandParentKey,
  grandParentIndex,
  showError,
  reset,
  FunctionReset,
  initalValue,
  FunctionRemoveStep,
  FunctionAddStep,
  originalData,
  isLastStep,
  isFirstStep,
  showSymbol,
  currentData,
}) => {
  return (
    <CoreContainer
      showSymbol={showSymbol}
      FunctionRemoveStep={FunctionRemoveStep}
      FunctionAddStep={FunctionAddStep}
      mode={mode}
      initalValue={initalValue}
      label={fields[index].label}
      name={fields[index].name}
      FieldReference={FieldReference}
      index={index}
      disabled={fields[index].disabled}
      loadData={loadData}
      language={language}
      style={fields[index].style}
      required={fields[index].required}
      type={fields[index].type}
      unit={fields[index].unit}
      placeholder={fields[index].placeholder}
      emitter={fields[index].emitter}
      render={fields[index].render}
      renderError={fields[index].renderError}
      classNameInput={fields[index].classNameInput}
      classNameLabel={fields[index].classNameLabel}
      classNameWrapper={fields[index].classNameWrapper}
      classNameUnit={fields[index].classNameUnit}
      isEdit={fields[index].type === TYPE_NONE ? false : fields[index].isEdit}
      subLabel={fields[index].subLabel}
      classNameSubLabel={fields[index].classNameSubLabel}
      classNameValue={fields[index].classNameValue}
      options={fields[index].options}
      isMultiple={fields[index].isMultiple}
      recipient={
        fields[index].type === TYPE_NONE ? true : fields[index].recipient
      }
      classNameOptions={fields[index].classNameOptions}
      classNameOption={fields[index].classNameOption}
      classNameIcon={fields[index].classNameIcon}
      classNameValues={fields[index].classNameValues}
      fieldCustoms={fields[index].fieldCustoms}
      getPathUrl={fields[index].getPathUrl}
      accept={fields[index].accept}
      classNameError={fields[index].classNameError}
      parentKey={parentKey}
      parentIndex={parentIndex}
      fieldIndex={fieldIndex}
      grandParentKey={grandParentKey}
      grandParentIndex={grandParentIndex}
      direction={fields[index].direction}
      limit={fields[index].limit}
      showError={showError}
      renderFooter={fields[index].renderFooter}
      minimum={fields[index].minimum}
      international={fields[index].international}
      select={fields[index].select}
      defualtValue={fields[index].defualtValue}
      count={fields[index].count}
      trigger={fields[index].trigger}
      labelUpload={fields[index].labelUpload}
      dataType={fields[index].dataType}
      reset={reset}
      FunctionReset={FunctionReset}
      originalData={originalData}
      isLastStep={isLastStep}
      isFirstStep={isFirstStep}
      errorCustoms={fields[index].errorCustoms}
      no_space={fields[index].no_space}
      currentData={currentData}
    />
  );
};

export default CoreMain;
