import React from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import { DebounceInput } from "react-debounce-input";

export default function PBFESearchbar({
  placeholder = "",
  onChange,
  value,
  border = "",
  height = 42,
  fontSize = 14,
}) {
  return (
    <div className={"TextField-with-border-radius " + border}>
      <DebounceInput
        debounceTimeout={300}
        element={TextField}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        InputProps={{
          style: {
            height: height,
            fontSize: fontSize,
            margin: 10,
            width: "290px",
          },
          type: "search",
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {/* <TextField
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        InputProps={{
          style: {
            height: height,
            fontSize: fontSize,
            margin: 10,
            width: "290px",
          },
          type: "search",
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      /> */}
    </div>
  );
}
