import { format } from "date-fns";
import React, { useContext } from "react";
import { checkDateInvalid } from "../../../functions";
import { formatDateApi } from "../../../utils/util";
import { FooterButton } from "../../Elements/Button/footerButton";
import { PBFEEditFeeTable } from "../../Table/editFeeTable";
import { PBFEAccordionMain } from "../Accordion/accordionMain";
import _ from "lodash";
import {
  PRICING_WITHOUT_DEBIT,
  TYPE_ONE_TIME,
} from "../../../constants/constant";

export const ServiceContext = React.createContext();

export const ServiceDetail = ({
  origin,
  data,
  setData,
  account_table,
  isEdit,
  primaryIndex,
  primaryField,
  // disabledDebitProfile = false,
  charge_name,
  charge_code,
  isNew,
  isGroup,
  isOffer = false,
  originBigData,
  setErrorState,
  group = {},
  // lengthGroup,
}) => {
  const infoCtx = useContext(ServiceContext);
  const [hasChange, setHasChange] = React.useState(false);
  const [lastCharge, setLastCharge] = React.useState("");
  const [checkAdd, setCheckAdd] = React.useState(false);
  const [errorLocalFee, setErrorLocalFee] = React.useState(false);
  const [futureRateIndex, setFutureRateIndex] = React.useState(-1);

  const checkMaxTodate = (to_date) => {
    if (
      to_date === "9999-12-31T00:00:00" ||
      to_date === "" ||
      to_date === null
    ) {
      return new Date();
    }

    return new Date(to_date);
  };

  const getFormateDate = (date) => {
    if (checkDateInvalid(new Date(date))) {
      return date;
    }
    return formatDateApi(date);
  };

  const getFromDateFutureRate = (from_date, to_date) => {
    const today = `${format(new Date(), "yyyy-MM-dd")}T00:00:00`;

    let currentFromDate = from_date;
    let currentToDate = to_date;

    if (checkDateInvalid(new Date(to_date))) {
      currentToDate = "";
    }
    if (checkDateInvalid(new Date(from_date))) {
      currentFromDate = "";
    } else if (
      from_date < today &&
      (to_date === "9999-12-31T00:00:00" || to_date === "")
    ) {
      currentToDate = new Date();
      currentToDate = getFormateDate(currentToDate);
    } else if (from_date >= today) {
      if (to_date && !(to_date === "9999-12-31T00:00:00" || to_date === "")) {
        const newToDate = checkMaxTodate(to_date);
        currentToDate = new Date(newToDate);
        currentToDate.setDate(new Date(newToDate).getDate());
        currentToDate = getFormateDate(currentToDate);
      } else {
        currentToDate = new Date(from_date);
        currentToDate.setDate(new Date(from_date).getDate() + 1);
        currentToDate = getFormateDate(currentToDate);
      }
    }

    return { currentFromDate, currentToDate };
  };

  React.useEffect(() => {
    if (lastCharge === "addFutureRate") {
      const last_charge_obj = JSON.parse(
        sessionStorage.getItem("last_charge_obj")
      );

      console.log("last_charge_obj", last_charge_obj);

      setData((prev) => {
        const temp = [...prev];

        // currentFromDate, currentToDate
        const { currentFromDate, currentToDate } = getFromDateFutureRate(
          last_charge_obj.from_date,
          last_charge_obj.to_date
        );

        let currentAction =
          temp[primaryIndex][primaryField][futureRateIndex]?.action;

        if (currentAction === undefined) {
          currentAction = "EDIT";
        }

        // current
        if (last_charge_obj?.pricing_method === TYPE_ONE_TIME) {
          temp[primaryIndex][primaryField][futureRateIndex] = {
            ...temp[primaryIndex][primaryField][futureRateIndex],
            from_date: getFormateDate(currentFromDate),
            to_date: getFormateDate(currentFromDate),
            action: currentAction,
          };
        }

        if (last_charge_obj?.pricing_method !== TYPE_ONE_TIME) {
          temp[primaryIndex][primaryField][futureRateIndex] = {
            ...temp[primaryIndex][primaryField][futureRateIndex],
            from_date: getFormateDate(currentFromDate),
            to_date: getFormateDate(currentToDate),
            action: currentAction,
          };
        }

        // current before not equal null
        let newFutureFromDate = currentToDate;
        let newFutureToDate = "";

        if (last_charge_obj?.pricing_method === TYPE_ONE_TIME) {
          if (currentToDate !== "") {
            newFutureFromDate = new Date(newFutureFromDate);
            newFutureFromDate.setDate(newFutureFromDate.getDate() + 1);
          }
        }

        if (last_charge_obj?.pricing_method !== TYPE_ONE_TIME) {
          if (currentToDate !== "") {
            newFutureFromDate = new Date(currentToDate);
            newFutureFromDate.setDate(newFutureFromDate.getDate() + 1);
          }
        }

        // future
        temp[primaryIndex][primaryField] = [
          ...temp[primaryIndex][primaryField],
          {
            ...last_charge_obj,
            checkCurrentDate: false,
            action: "NEW",
            customer_fee_charge_id: "",
            from_date: getFormateDate(newFutureFromDate),
            to_date:
              last_charge_obj?.pricing_method === TYPE_ONE_TIME
                ? getFormateDate(newFutureFromDate)
                : newFutureToDate !== ""
                ? getFormateDate(newFutureToDate)
                : "",
          },
        ];

        // console.log("temp out", temp);

        return [...temp];
      });
      infoCtx?.setHasChange(!infoCtx?.hasChange);
      setHasChange(!hasChange);
    }
  }, [lastCharge, checkAdd]);

  const onChangeField = (index) => (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      prev[primaryIndex][primaryField][index][name] = value;
      prev[primaryIndex][primaryField][index].action = "EDIT";
      return [...prev];
    });
  };

  const onChangeFieldForQualifers =
    (conditionIndex, feeValueIndex, hasValue = false, index) =>
    (e) => {
      const { name, value } = e.target;
      setData((prev) => {
        if (hasValue) {
          prev[primaryIndex][primaryField][feeValueIndex].fee_value.conditions[
            conditionIndex
          ].value[index] = {
            ...prev[primaryIndex][primaryField][feeValueIndex].fee_value
              .conditions[conditionIndex].value[index],
            [name]: value,
          };
          prev[primaryIndex][primaryField][feeValueIndex].action = "EDIT";
          return [...prev];
        } else {
          prev[primaryIndex][primaryField][feeValueIndex].fee_value.conditions[
            conditionIndex
          ] = {
            ...prev[primaryIndex][primaryField][feeValueIndex].fee_value
              .conditions[conditionIndex],
            [name]: value,
          };
          prev[primaryIndex][primaryField][feeValueIndex].action = "EDIT";
          return [...prev];
        }
      });
      setHasChange(!hasChange);
    };

  const onChangeFieldDropDownSingle = (index) => (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      if (name === "tier" && value === "NO") {
        prev[primaryIndex][primaryField][index].fee_value.value[0] = {
          ...prev[primaryIndex][primaryField][index].fee_value.value[0],
          from: "",
          to: "",
        };
      }

      if (
        prev[primaryIndex][primaryField][index].fee_value.value.length === 1
      ) {
        prev[primaryIndex][primaryField][index].fee_value.value = [
          {
            fix_amount: "",
            from: "",
            to: "",
            rate_type: "",
            rate: "",
          },
        ];
      }

      prev[primaryIndex][primaryField][index].fee_value = {
        ...prev[primaryIndex][primaryField][index].fee_value,
        [name]: value,
      };
      prev[primaryIndex][primaryField][index].action = "EDIT";
      return [...prev];
    });
    setHasChange(!hasChange);
  };

  const onChangeFieldDropDownValueSingle = (valueIndex, index) => (e) => {
    const { name, value } = e.target;

    setData((prev) => {
      prev[primaryIndex][primaryField][valueIndex].fee_value.value[index][
        name
      ] = value;
      prev[primaryIndex][primaryField][valueIndex].action = "EDIT";
      return [...prev];
    });
    setHasChange(!hasChange);
  };

  const onChangeFieldDropDownSingleForQualifiers =
    (conditionIndex, feeValueIndex) => (e) => {
      const { name, value } = e.target;
      setData((prev) => {
        if (name === "tier" && value === "NO") {
          prev[primaryIndex][primaryField][feeValueIndex].fee_value.conditions[
            conditionIndex
          ].value = [
            {
              fix_amount: "",
              // from: "",
              // to: "",
              rate_type: "",
              rate: "",
            },
          ];
          prev[primaryIndex][primaryField][feeValueIndex].fee_value.conditions[
            conditionIndex
          ] = {
            ...prev[primaryIndex][primaryField][feeValueIndex].fee_value
              .conditions[conditionIndex],
            [name]: value,
          };

          prev[primaryIndex][primaryField][feeValueIndex].action = "EDIT";
          return [...prev];
        } else {
          prev[primaryIndex][primaryField][feeValueIndex].fee_value.conditions[
            conditionIndex
          ] = {
            ...prev[primaryIndex][primaryField][feeValueIndex].fee_value
              .conditions[conditionIndex],
            [name]: value,
          };

          if (
            prev[primaryIndex][primaryField][feeValueIndex].fee_value
              .conditions[conditionIndex].value.length === 1
          ) {
            prev[primaryIndex][primaryField][
              feeValueIndex
            ].fee_value.conditions[conditionIndex].value = [
              {
                fix_amount: "",
                // from: "",
                // to: "",
                rate_type: "",
                rate: "",
              },
            ];
          }
          prev[primaryIndex][primaryField][feeValueIndex].action = "EDIT";
          return [...prev];
        }
      });
      setHasChange(!hasChange);
    };

  const onChangeFieldDropDownValueSingleForQualifiers =
    (conditionIndex, feeValueIndex, index) => (e) => {
      const { name, value } = e.target;

      setData((prev) => {
        prev[primaryIndex][primaryField][feeValueIndex].fee_value.conditions[
          conditionIndex
        ].value[index][name] = value;
        prev[primaryIndex][primaryField][feeValueIndex].action = "EDIT";
        return [...prev];
      });
      setHasChange(!hasChange);
    };

  const addTier = (index) => (e) => {
    e.stopPropagation();
    setData((prev) => {
      const intialValue = {
        fix_amount: "",
        from: "",
        to: "",
        rate_type: "",
        rate: "",
      };
      prev[primaryIndex][primaryField][index].fee_value.value.push(intialValue);
      prev[primaryIndex].isEdit = true;
      prev[primaryIndex][primaryField][index].action = "EDIT";
      return [...prev];
    });
    setHasChange(!hasChange);
  };

  const removeRow = (feeValueIndex, index) => (e) => {
    e.stopPropagation();
    setData((prev) => {
      if (
        ["SLAB", "STEP"].includes(
          prev[primaryIndex][primaryField][index].fee_value.tier
        )
      ) {
        prev[primaryIndex][primaryField][feeValueIndex].fee_value = {};
        return [...prev];
      }
      prev[primaryIndex][primaryField][feeValueIndex].fee_value.value = prev[
        primaryIndex
      ][primaryField][feeValueIndex].fee_value.value.filter(
        (_, currentIndex) => currentIndex !== index
      );
      prev[primaryIndex][primaryField][feeValueIndex].action = "EDIT";
      return [...prev];
    });
    setHasChange(!hasChange);
  };

  const removeRowForQualifiers = (conditionIndex, feeValueIndex) => (e) => {
    e.stopPropagation();
    setData((prev) => {
      prev[primaryIndex][primaryField][feeValueIndex].fee_value.conditions =
        prev[primaryIndex][primaryField][
          feeValueIndex
        ].fee_value.conditions.filter(
          (_, currentIndex) => currentIndex !== conditionIndex
        );
      prev[primaryIndex][primaryField][feeValueIndex].action = "EDIT";
      return [...prev];
    });
    setHasChange(!hasChange);
  };

  const addTierForQualifiers = (index, jedex) => (e) => {
    e.stopPropagation();
    setData((prev) => {
      const intialValue = {
        fix_amount: "",
        from: "",
        to: "",
        rate_type: "",
        rate: "",
      };
      prev[primaryIndex][primaryField][index].fee_value.conditions[
        jedex
      ].value.push(intialValue);
      prev[primaryIndex][primaryField][index].action = "EDIT";
      return [...prev];
    });
    setHasChange(!hasChange);
  };

  const onClickCheckBoxPricing = (feeChargeIndex) => (e) => {
    e.stopPropagation();

    setData((prev) => {
      for (
        let feeChargeIndex = 0;
        feeChargeIndex < prev[primaryIndex][primaryField].length;
        feeChargeIndex++
      ) {
        if (
          prev[primaryIndex][primaryField][feeChargeIndex]
            .price_without_post === "Y"
        ) {
          prev[primaryIndex][primaryField][feeChargeIndex].price_without_post =
            "N";
          prev[primaryIndex][primaryField][
            feeChargeIndex
          ].price_without_post_checked = e.target.checked;
        } else {
          prev[primaryIndex][primaryField][feeChargeIndex].price_without_post =
            "Y";
          prev[primaryIndex][primaryField][
            feeChargeIndex
          ].price_without_post_checked = e.target.checked;
        }

        prev[primaryIndex][primaryField][feeChargeIndex].action = "EDIT";
      }
      return [...prev];
    });

    // OLD
    // setData((prev) => {
    //   if (
    //     prev[primaryIndex][primaryField][feeChargeIndex].price_without_post ===
    //     "Y"
    //   ) {
    //     prev[primaryIndex][primaryField][feeChargeIndex].price_without_post =
    //       "N";
    //     prev[primaryIndex][primaryField][
    //       feeChargeIndex
    //     ].price_without_post_checked = e.target.checked;
    //   } else {
    //     prev[primaryIndex][primaryField][feeChargeIndex].price_without_post =
    //       "Y";
    //     prev[primaryIndex][primaryField][
    //       feeChargeIndex
    //     ].price_without_post_checked = e.target.checked;
    //   }

    //   prev[primaryIndex][primaryField][feeChargeIndex].action = "EDIT";
    //   return [...prev];
    // });
    setHasChange(!hasChange);
  };

  const onClickRemove = (e) => {
    // e.stopPropagation();
    setData((prev) => {
      const checkAllEmpty = prev[primaryIndex][primaryField]?.every(
        (item) => !item.customer_fee_charge_id
      );

      if (
        prev[primaryIndex][primaryField][currentLast().index]
          .customer_fee_charge_id > 0 ||
        checkAllEmpty
      ) {
        prev[primaryIndex][primaryField][currentLast().index].action = "DELETE";
        prev[primaryIndex][primaryField][currentLast().index].isRemove = true;
      } else {
        prev[primaryIndex][primaryField] = prev[primaryIndex][
          primaryField
        ]?.filter((item, index) => index !== currentLast().index);
      }
      // prev[primaryIndex][primaryField][currentLast().index].first = false;
      return [...prev];
    });
    setHasChange(!hasChange);
  };

  const onClickAdd = (e) => {
    // e.stopPropagation();
    setData((prev) => {
      const clone_first = _.cloneDeep(prev[primaryIndex][primaryField][0]);
      prev[primaryIndex][primaryField] = prev[primaryIndex][
        primaryField
      ].filter((item) => {
        return item?.customer_fee_charge_id > 0;
      });

      prev[primaryIndex][primaryField] = [
        ...prev[primaryIndex][primaryField],
        {
          ...clone_first,
          customer_fee_charge_id: "",
          action: "NEW",
          isRemove: false,
        },
      ];
      // const clone_first = prev[primaryIndex][primaryField]
      // prev[primaryIndex][primaryField]
      // prev[primaryIndex][primaryField][
      //   currentLast().index
      // ].customer_fee_charge_id = "";
      // prev[primaryIndex][primaryField][currentLast().index].action = "NEW";
      // prev[primaryIndex][primaryField][currentLast().index].isRemove = false;
      // prev[primaryIndex][primaryField][currentLast().index].first = true;
      return [...prev];
    });
    setHasChange(!hasChange);
  };

  const currentLast = () => {
    let current = data[0];

    let found_current = false;
    let index = 0;

    if (data.length > 1) {
      data?.forEach((item, itemIndex) => {
        if (!item.isRemove && !found_current) {
          current = item;
          index = itemIndex;
          found_current = true;
        }
      });
    }

    return { current, index };
  };

  const RenderFooter = ({}) => {
    if ((isEdit || isNew) && !currentLast().current.isRemove) {
      return <FooterButton text="Add Future Rate" action={addFutureRate} />;
    }

    return null;
  };

  const addFutureRate = () => {
    setData((prev) => {
      let futureIndex = 0;

      for (
        let index = prev[primaryIndex][primaryField].length - 1;
        index >= 0;
        index--
      ) {
        if (prev[primaryIndex][primaryField][index].isRemove === false) {
          futureIndex = index;
          break;
        }
      }
      const last_charge = prev[primaryIndex][primaryField][futureIndex];

      sessionStorage.setItem("last_charge_obj", JSON.stringify(last_charge));
      setLastCharge("addFutureRate");
      setCheckAdd(!checkAdd);
      setFutureRateIndex(futureIndex);
      return prev;
    });
    infoCtx?.setHasChange(!infoCtx?.hasChange);
  };

  const filterDataLength = () => {
    const filter_data = data.filter((item) => {
      return !item.isRemove;
    });

    if (filter_data.length === 0) {
      return 1;
    }

    return filter_data.length;
  };

  if (isGroup) {
    return (
      <div className="">
        <ServiceContext.Provider value={{ hasChange, setHasChange }}>
          <PBFEAccordionMain
            pl=""
            label={`${charge_code} - ${charge_name}`}
            onClick={onClickCheckBoxPricing(0)}
            // disabled={currentLast().current.checkCurrentDate}
            isEdit={isEdit}
            isRemove={
              (isEdit || isNew) &&
              filterDataLength() === 1 &&
              !currentLast().current.isRemove &&
              !currentLast().current.checkCurrentDate
            }
            isAdd={
              (isEdit || isNew) &&
              filterDataLength() === 1 &&
              currentLast().current.isRemove &&
              !currentLast().current.checkCurrentDate
            }
            onClickRemove={onClickRemove}
            onClickAdd={onClickAdd}
            detail={
              !data ? null : (
                <div>
                  {" "}
                  {data?.map((fee, index) => {
                    if (fee.isRemove) return null;

                    console.log("orgiin" , origin);

                    return (
                      <div
                        key={`fee-table${index}`}
                        className={index > 0 && "mt-6"}
                      >
                        <PBFEEditFeeTable
                          key={`service-detail-fee-${index}`}
                          checkAdd={checkAdd}
                          hasChange={hasChange}
                          lastCharge={lastCharge}
                          currentLast={currentLast()}
                          group={group}
                          isOffer={isOffer}
                          isGroup={isGroup}
                          isEdit={isEdit}
                          originData={data}
                          // origin={origin?.fee_charge ?  origin?.fee_charge[index]: null}
                          data={fee}
                          setData={setData}
                          index={index}
                          primaryField={primaryField}
                          primaryIndex={primaryIndex}
                          account_table={account_table}
                          isNew={isNew}
                          onChangeField={onChangeField}
                          addTier={addTier}
                          addTierForQualifiers={addTierForQualifiers}
                          onChangeFieldDropDownSingle={
                            onChangeFieldDropDownSingle
                          }
                          onChangeFieldDropDownSingleForQualifiers={
                            onChangeFieldDropDownSingleForQualifiers
                          }
                          onChangeFieldForQualifers={onChangeFieldForQualifers}
                          removeRow={removeRow}
                          removeRowForQualifiers={removeRowForQualifiers}
                          onChangeFieldDropDownValueSingle={
                            onChangeFieldDropDownValueSingle
                          }
                          onChangeFieldDropDownValueSingleForQualifiers={
                            onChangeFieldDropDownValueSingleForQualifiers
                          }
                          lengthGroup={filterDataLength()}
                          onClickCheckBoxPricing={onClickCheckBoxPricing}
                          setErrorState={setErrorState}
                          errorLocalFee={errorLocalFee}
                          setErrorLocalFee={setErrorLocalFee}
                        />
                      </div>
                    );
                  })}{" "}
                  <RenderFooter />
                </div>
              )
            }
            defaultExpanded={!currentLast()?.current?.isTemplate}
            toogleStatus={
              // data?.price_without_post === "Y"
              (isEdit || isNew) &&
              currentLast().current?.price_without_post === "Y"
            }
            showLabel={
              (!isEdit || !isNew) &&
              currentLast().current?.price_without_post === "Y"
            }
            hasToogle={!currentLast().current.isRemove}
            toogleLabel={PRICING_WITHOUT_DEBIT}
            // fullScreen={true}
          />
        </ServiceContext.Provider>
      </div>
    );
  }

  return (
    <div className="">
      <ServiceContext.Provider value={{ hasChange, setHasChange }}>
        {data && (
          <div>
            {data?.map((fee, index) => {
              return (
                <PBFEAccordionMain
                  pl=""
                  label={`${charge_code ? charge_code : fee.charge_code} - ${
                    charge_name ? charge_name : fee.charge_name
                  }`}
                  isEdit={isEdit}
                  detail={
                    <PBFEEditFeeTable
                      key={`service-detail-fee-${index}`}
                      currentLast={currentLast()}
                      checkAdd={checkAdd}
                      lastCharge={lastCharge}
                      isEdit={isEdit}
                      data={fee}
                      setData={setData}
                      index={index}
                      primaryField={primaryField}
                      primaryIndex={primaryIndex}
                      account_table={account_table}
                      isNew={isNew}
                      origin={origin?.fee_charge[index]}
                      onChangeField={onChangeField}
                      addTier={addTier}
                      addTierForQualifiers={addTierForQualifiers}
                      onChangeFieldDropDownSingle={onChangeFieldDropDownSingle}
                      onChangeFieldDropDownSingleForQualifiers={
                        onChangeFieldDropDownSingleForQualifiers
                      }
                      onChangeFieldForQualifers={onChangeFieldForQualifers}
                      removeRow={removeRow}
                      removeRowForQualifiers={removeRowForQualifiers}
                      onChangeFieldDropDownValueSingle={
                        onChangeFieldDropDownValueSingle
                      }
                      onChangeFieldDropDownValueSingleForQualifiers={
                        onChangeFieldDropDownValueSingleForQualifiers
                      }
                      isOffer={isOffer}
                      setErrorState={setErrorState}
                      errorLocalFee={errorLocalFee}
                      setErrorLocalFee={setErrorLocalFee}
                    />
                  }
                  hasToogle
                  showLabel={
                    (!isEdit || !isNew) && data[0]?.price_without_post === "Y"
                  }
                  toogleLabel={PRICING_WITHOUT_DEBIT}
                  // fullScreen={true}
                />
              );
            })}
            <RenderFooter />
          </div>
        )}
      </ServiceContext.Provider>
    </div>
  );
};
