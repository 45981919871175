import React from "react";
import { PBFELoading } from "../../../components/Elements/Loading/loading";
import { PBFEContent } from "../../../components/Elements/Text/content";
import PBFEError from "../../../components/Error/alertError";
import PBFEInvalid from "../../../components/Error/invalidData";
import { PBFEAccordionMain } from "../../../components/Panel/Accordion/accordionMain";
import useQuery from "../../../hooks/useQuery";
import { formatDateApi } from "../../../utils/util";
import offersBundleService from "../../../api/offersBundleService";
import AccountRenderBundleOffer from "./Account.RenderBundleOffer";
import { TextField } from "@mui/material";

const AccountAddNewOfferBundle = ({
  account,
  offer_code,
  accountTable,
  isAddActionOffer,
  debitProfile,
  setDebitProfile,
  originalData,
  errorState,
  setErrorState,
  is_maker,
  checkConfirmError,
  setCheckConfirmError,
  setRadioOffer,
}) => {
  const [remark, setRemark] = React.useState("");
  const prePareData = (data) => {
    return {
      ...data,
      from_date: formatDateApi(),
      to_date: "",
    };
  };

  const { data, error, loading } = useQuery(
    offersBundleService.getOfferBundlesInfo,
    {
      offer_code: offer_code,
    },
    {},
    [],
    { prePareData, checkFirstLoad: true }
  );

  React.useEffect(() => {
    setRadioOffer(null);
  }, []);

  if (loading)
    return (
      <div className="h-screen m-auto">
        <PBFELoading />
      </div>
    );

  if (error) return <PBFEError error={error} />;
  return (
    <div className="w-full">
      <PBFEInvalid
        isShow={checkConfirmError && errorState.length > 0}
        message=""
        setOpen={setCheckConfirmError}
        open={checkConfirmError}
        errorState={errorState}
      />
      <div className="w-full overflow-auto">
        <div className="grid grid-cols-2 w-full px-xl my-20px">
          <PBFEContent label="Account Name" value={account?.account_name} />
          <PBFEContent label="Account No." value={account?.account_id} />
          <PBFEContent label="Customer Name" value={account?.customer_name} />
          <PBFEContent label="Customer ID" value={account?.customer_id} />
        </div>
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionMain
          label="Bundled Offer"
          detail={
            isAddActionOffer ? (
              <div></div>
            ) : (
              <AccountRenderBundleOffer
                remark={remark}
                errorState={errorState}
                account={account}
                checkConfirmError={checkConfirmError}
                setCheckConfirmError={setCheckConfirmError}
                is_maker={is_maker}
                customer={account}
                originalData={originalData}
                debitProfile={debitProfile}
                setDebitProfile={setDebitProfile}
                setErrorState={setErrorState}
                data={{
                  bundle_offer: [
                    {
                      ...data,
                      posting_account: [],
                    },
                  ],
                }}
                isEdit={true}
                isNew={true}
                accountTable={accountTable}
              />
            )
          }
        />
        <div className="ml-40px mr-25px">
          <TextField
            id="remark"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            label="REMARK"
            variant="filled"
            helperText={"Limited " + remark?.length + "/300"}
            fullWidth
            inputProps={{ maxLength: 300 }}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountAddNewOfferBundle;
