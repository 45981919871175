import api from "./index";

// config
import { apiConfig } from "../config";

const getCustomer = async (params, queryString) => {
  return api.instanceCorp.get(`${apiConfig.getCustomerAPI}${queryString}`);
};

const getCustomerById = async (params, queryString) => {
  return api.instanceCorp.get(
    `${apiConfig.getCustomerAPI}/${params.customer_id}/info`
  );
};

const getServiceCustomerById = async (params, queryString) => {
  return api.instanceCorp.get(
    `${apiConfig.getCustomerAPI}/${params.customer_id}/unassigned-services`
  );
};

const getOfferCustomerById = async (params, queryString) => {
  return api.instanceCorp.get(
    `${apiConfig.getCustomerAPI}/${params.customer_id}/unassigned-offers`
  );
};

const getBundleOfferCustomerById = async (params, queryString) => {
  return api.instanceCorp.get(
    `${apiConfig.getCustomerAPI}/${params.customer_id}/unassigned-bundle-offers`)
}
const refreshDIH = async (customer_id) => {
  return api.instanceCorp.get(
    `${apiConfig.getCustomerAPI}/${customer_id}/refresh-address`
  );
};

export default {
  getCustomer,
  getCustomerById,
  getServiceCustomerById,
  getOfferCustomerById,
  getBundleOfferCustomerById,
  refreshDIH
};
