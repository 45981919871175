import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import { getComparator, stableSort } from "../../functions";
import { TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER } from "../../constants/style";
import NoRecordFound from "../Error/noRecordFound";
import { Checkbox } from "@mui/material";

export const PBFECommonTableCheckBox = ({
  headCells,
  rows,
  selectable = false,
  orderByDefault = "",
  searched,
  open,
  setRows,
  setData,
}) => {
  const [order, setOrder] = React.useState();
  const [orderBy, setOrderBy] = React.useState();
  const [sortBy, setSortBy] = React.useState(true);

  React.useEffect(() => {
    setOrderBy(orderByDefault);
    setOrder("asc");
  }, [orderByDefault, open]);

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow hover={false}>
          {headCells?.map((headCell) => {
            return (
              <TableCell
                // rowSpan={2}
                // colSpan={}
                key={headCell.id}
                align={"left"}
                padding={"normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  // aria-rowspan={2}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortBy(!sortBy);
  };

  const DataArrayTable = stableSort(
    rows,
    getComparator(order, orderBy),
    orderBy,
    sortBy
  );

  const onClickCheckBox = (rows, row) => {
    const findIndex = rows.findIndex(
      (item) => item.service_code === row.service_code
    );
    setRows((privious) => {
      privious[findIndex].checked = !privious[findIndex].checked;
      return [...privious];
    });
    // const row_that_checked = rows?.filter((items) => {
    //   return items.checked === true;
    // });
    // setData();
  };

  return (
    <div>
      {/* <TableContainer sx={{ maxHeight: "455px" }}> */}

      <TableContainer sx={{ height: "420px" }}>
        {searched?.length >= 3 && rows?.length <= 0 ? (
          <NoRecordFound />
        ) : (
          <Table
            stickyHeader={true}
            sx={{ width: "100%" }}
            aria-label="simple table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              rowCount={rows?.length}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {DataArrayTable?.map((row, index) => {
                return (
                  <TableRow
                    id="table-row"
                    key={index}
                    hover={selectable}
                    // onClick={() => onClickRow && onClickRow(row)}
                    sx={TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER}
                  >
                    <TableCell>
                      <Checkbox
                        onClick={() => onClickCheckBox(rows, row)}
                        checked={row?.checked}
                      />
                    </TableCell>

                    <TableCell>{row?.service_name}</TableCell>
                    <TableCell> {row?.service_code}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};
