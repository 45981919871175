export const refemock = {
  customer_id: "001400000000000000000003577667",
  title_thai: "บริษัท จำกัด",
  full_name_thai: "บริษัท เถกิงเปลี่ยนชื่อนะจ๊ะ จำกัด",
  customer_tax_id: "9043000021446",
  customer_branch_no: "00000",
  reg_addr_line1: "11 ALAND เลขที่ 9",
  reg_addr_line2: "ต.หลักห้า อ.ไทรน้อย",
  reg_addr_line3: "จ.นนทบุรี",
  reg_zip_code: "11150",
  email: "",
  service_info: [
    {
      customer_service_info_id: 1348,
      service_code: "EMAILALERT",
      account_id: "",
      customer_tax_id: "9043000021446",
      customer_branch_no: "00000",
      reg_addr_line1: "พหลโยธิน เพลส 111 เลขที่ 3",
      reg_addr_line2: "ซอยจตุจักร ต.หลักห้า อ.ไทรน้อย",
      reg_addr_line3: "จ.นนทบุรี",
      reg_zip_code: "11150",
      contact_addr_line1: "",
      contact_addr_line2: "",
      contact_addr_line3: "",
      contact_zip_code: "",
      email: "",
      different_contact_addr_flag: "N",
      email_alert_flag: "Y",
      customer_full_name_thai: "บริษัท เถกิงเปลี่ยนชื่อนะจ๊ะ จำกัด",
    },
    {
      customer_service_info_id: 1349,
      service_code: "LCLCT",
      account_id: "147640013104893",
      customer_tax_id: "9043000021446",
      customer_branch_no: "00000",
      reg_addr_line1: "พหลโยธิน เพลส 111 เลขที่ 3",
      reg_addr_line2: "ซอยจตุจักร ต.หลักห้า อ.ไทรน้อย",
      reg_addr_line3: "จ.นนทบุรี",
      reg_zip_code: "11150",
      contact_addr_line1: "",
      contact_addr_line2: "",
      contact_addr_line3: "",
      contact_zip_code: "",
      email: "",
      different_contact_addr_flag: "N",
      email_alert_flag: "Y",
      customer_full_name_thai: "บริษัท เถกิงเปลี่ยนชื่อนะจ๊ะ จำกัด",
    },
    {
      customer_service_info_id: 1350,
      service_code: "BIZNETPA",
      account_id: "",
      customer_tax_id: "9043000021446",
      customer_branch_no: "00000",
      reg_addr_line1: "พหลโยธิน เพลส 111 เลขที่ 3",
      reg_addr_line2: "ซอยจตุจักร ต.หลักห้า อ.ไทรน้อย",
      reg_addr_line3: "จ.นนทบุรี",
      reg_zip_code: "11150",
      contact_addr_line1: "",
      contact_addr_line2: "",
      contact_addr_line3: "",
      contact_zip_code: "",
      email: "",
      different_contact_addr_flag: "N",
      email_alert_flag: "Y",
      customer_full_name_thai: "บริษัท เถกิงเปลี่ยนชื่อนะจ๊ะ จำกัด",
    },
    {
      customer_service_info_id: 1352,
      service_code: "BLPLACEMENT",
      account_id: "",
      customer_tax_id: "9043000021446",
      customer_branch_no: "00000",
      reg_addr_line1: "พหลโยธิน เพลส 111 เลขที่ 3",
      reg_addr_line2: "ซอยจตุจักร ต.หลักห้า อ.ไทรน้อย",
      reg_addr_line3: "จ.นนทบุรี",
      reg_zip_code: "11150",
      contact_addr_line1: "",
      contact_addr_line2: "",
      contact_addr_line3: "",
      contact_zip_code: "",
      email: "",
      different_contact_addr_flag: "N",
      email_alert_flag: "Y",
      customer_full_name_thai: "บริษัท เถกิงเปลี่ยนชื่อนะจ๊ะ จำกัด",
    },
    {
      customer_service_info_id: 1353,
      service_code: "API_INQUIRY",
      account_id: "",
      customer_tax_id: "9043000021446",
      customer_branch_no: "00000",
      reg_addr_line1: "พหลโยธิน เพลส 111 เลขที่ 3",
      reg_addr_line2: "ซอยจตุจักร ต.หลักห้า อ.ไทรน้อย",
      reg_addr_line3: "จ.นนทบุรี",
      reg_zip_code: "11150",
      contact_addr_line1: "",
      contact_addr_line2: "",
      contact_addr_line3: "",
      contact_zip_code: "",
      email: "",
      different_contact_addr_flag: "N",
      email_alert_flag: "Y",
      customer_full_name_thai: "บริษัท เถกิงเปลี่ยนชื่อนะจ๊ะ จำกัด",
    },
    {
      customer_service_info_id: 1354,
      service_code: "API_INQUIRY",
      account_id: "",
      customer_tax_id: "9043000021446",
      customer_branch_no: "00000",
      reg_addr_line1: "พหลโยธิน เพลส 111 เลขที่ 3",
      reg_addr_line2: "ซอยจตุจักร ต.หลักห้า อ.ไทรน้อย- เทส",
      reg_addr_line3: "จ.นนทบุรี",
      reg_zip_code: "11155",
      contact_addr_line1: "",
      contact_addr_line2: "",
      contact_addr_line3: "",
      contact_zip_code: "",
      email: "",
      different_contact_addr_flag: "N",
      email_alert_flag: "Y",
      customer_full_name_thai: "บริษัท เถกิงเปลี่ยนชื่อนะจ๊ะ จำกัด",
    },
    {
      customer_service_info_id: 1355,
      service_code: "API_INQUIRY",
      account_id: "147640013104893",
      customer_tax_id: "9043000021446",
      customer_branch_no: "00000",
      reg_addr_line1: "11 ALAND เลขที่ 9",
      reg_addr_line2: "ต.หลักห้า อ.ไทรน้อย",
      reg_addr_line3: "จ.นนทบุรี",
      reg_zip_code: "11150",
      contact_addr_line1: "",
      contact_addr_line2: "",
      contact_addr_line3: "",
      contact_zip_code: "",
      email: "",
      different_contact_addr_flag: "N",
      email_alert_flag: "Y",
      customer_full_name_thai: "บริษัท เถกิงเปลี่ยนชื่อนะจ๊ะ จำกัด",
    },
    {
      customer_service_info_id: 1356,
      service_code: "ETAX",
      account_id: "",
      customer_tax_id: "9043000021446",
      customer_branch_no: "00000",
      reg_addr_line1: "11 ALAND เลขที่ 9",
      reg_addr_line2: "ต.หลักห้า อ.ไทรน้อย",
      reg_addr_line3: "จ.นนทบุรี",
      reg_zip_code: "11150",
      contact_addr_line1: "",
      contact_addr_line2: "",
      contact_addr_line3: "",
      contact_zip_code: "",
      email: "",
      different_contact_addr_flag: "N",
      email_alert_flag: "Y",
      customer_full_name_thai: "บริษัท เถกิงเปลี่ยนชื่อนะจ๊ะ จำกัด",
    },
    {
      customer_service_info_id: 783,
      service_code: "ETAX",
      account_id: "147640013104893",
      customer_tax_id: "9043000021446",
      customer_branch_no: "00000",
      reg_addr_line1: "พหลโยธิน เพลส 111 เลขที่ 3",
      reg_addr_line2: "ซอยจตุจักร ต.หลักห้า อ.ไทรน้อย",
      reg_addr_line3: "จ.นนทบุรี",
      reg_zip_code: "11150",
      contact_addr_line1: "",
      contact_addr_line2: "",
      contact_addr_line3: "",
      contact_zip_code: "",
      email: "",
      different_contact_addr_flag: "N",
      email_alert_flag: "N",
      customer_full_name_thai: "บริษัท เถกิงเปลี่ยนชื่อนะจ๊ะ จำกัด",
    },
    {
      customer_service_info_id: 784,
      service_code: "SMSALERT",
      account_id: "147640013104893",
      customer_tax_id: "9043000021446",
      customer_branch_no: "00000",
      reg_addr_line1: "พหลโยธิน เพลส 111 เลขที่ 3",
      reg_addr_line2: "ซอยจตุจักร ต.หลักห้า อ.ไทรน้อย",
      reg_addr_line3: "จ.นนทบุรี",
      reg_zip_code: "11150",
      contact_addr_line1: "",
      contact_addr_line2: "",
      contact_addr_line3: "",
      contact_zip_code: "",
      email: "",
      different_contact_addr_flag: "N",
      email_alert_flag: "N",
      customer_full_name_thai: "บริษัท เถกิงเปลี่ยนชื่อนะจ๊ะ จำกัด",
    },
  ],
};
export const data_dih = {
  status: {
    code: "1000",
    description: "Success",
  },
  data: {
    customer_id: "001400000000000000000000002182",
    title_thai: "ห้างหุ้นส่วนจำกัด",
    full_name_thai: "ห้างหุ้นส่วนจำกัด พักผ่อนนอนสบาย",
    customer_tax_id: "",
    customer_branch_no: "00000",
    reg_addr_line1: "ใบหยก2 110 เลขที่ 2",
    reg_addr_line2: "ซอยราชดำริ ต.หลักสี่ อ.หลักสี่",
    reg_addr_line3: "จ.นครปฐม",
    reg_zip_code: "11130",
    email: "",
    service_info: [
      {
        customer_service_info_id: 624,
        service_code: "MT940IMP",
        account_id: "147640013249261",
        customer_tax_id: "",
        customer_branch_no: "00000",
        reg_addr_line1: "",
        reg_addr_line2: "",
        reg_addr_line3: "",
        reg_zip_code: "11130",
        contact_addr_line1: "",
        contact_addr_line2: "",
        contact_addr_line3: "",
        contact_zip_code: "11130",
        email: "",
        different_contact_addr_flag: "N",
        email_alert_flag: "N",
        customer_full_name_thai: "ห้างหุ้นส่วนจำกัด พักผ่อนนอนสบายย",
      },
    ],
  },
};
export const data_tax_schemes = {
  status: { code: 1000, description: "Success" },
  data: [
    {
      tax_scheme_id: "VATETAX",
      tax_scheme_name: "vat For E-Tax",
      current_tax_rate: 7.0,
    },
    {
      tax_scheme_id: "WHTBLPMT",
      tax_scheme_name: "WHT For Bill Payment",
      current_tax_rate: 3.0,
    },
    {
      tax_scheme_id: "VATBLPMT",
      tax_scheme_name: "Vat For Bill Payment",
      current_tax_rate: 7.0,
    },
    {
      tax_scheme_id: "TEST1",
      tax_scheme_name: "Vat For Bill Payment 1",
      current_tax_rate: 7.01,
    },
    {
      tax_scheme_id: "TEST2",
      tax_scheme_name: "Vat For Bill Payment 2",
      current_tax_rate: 7.1,
    },
    {
      tax_scheme_id: "TEST3",
      tax_scheme_name: "Vat For Bill Payment 3",
      current_tax_rate: 0.1,
    },
    {
      tax_scheme_id: "TEST4",
      tax_scheme_name: "Vat For Bill Payment 4",
      current_tax_rate: 0.3,
    },
    {
      tax_scheme_id: "TEST5",
      tax_scheme_name: "Vat For Bill Payment 5",
      current_tax_rate: 7.0,
    },
    {
      tax_scheme_id: "TEST6",
      tax_scheme_name: "Vat For Bill Payment 6",
      current_tax_rate: 7.0,
    },
    {
      tax_scheme_id: "TEST7",
      tax_scheme_name: "Vat For Bill Payment 7",
      current_tax_rate: 7.0,
    },
    {
      tax_scheme_id: "TEST8",
      tax_scheme_name: "Vat For Bill Payment 8",
      current_tax_rate: 7.0,
    },
    {
      tax_scheme_id: "TEST9",
      tax_scheme_name: "Vat For Bill Payment 9",
      current_tax_rate: 7.0,
    },
    {
      tax_scheme_id: "TEST10",
      tax_scheme_name: "Vat For Bill Payment 10",
      current_tax_rate: 7.0,
    },
    {
      tax_scheme_id: "TEST11",
      tax_scheme_name: "Vat For Bill Payment 11",
      current_tax_rate: 7.0,
    },
    {
      tax_scheme_id: "TEST12",
      tax_scheme_name: "Vat For Bill Payment 12",
      current_tax_rate: 7.0,
    },
    {
      tax_scheme_id: "TEST13",
      tax_scheme_name: "Vat For Bill Payment 13",
      current_tax_rate: 7.0,
    },
    {
      tax_scheme_id: "TEST14",
      tax_scheme_name: "Vat For Bill Payment 14",
      current_tax_rate: 7.0,
    },
    {
      tax_scheme_id: "TEST15",
      tax_scheme_name: "Vat For Bill Payment 15",
      current_tax_rate: 7.0,
    },
    {
      tax_scheme_id: "TEST16",
      tax_scheme_name: "Vat For Bill Payment 16",
      current_tax_rate: 7.0,
    },
  ],
};

export const data_tax_select = {
  status: { code: 1000, description: "Success" },
  data: {
    tax_scheme_id: "VATETAX",
    tax_scheme_name: "vat For E-Tax",
    tax_type: "VAT",
    gl_account_code: "44322",
    gl_product_code: "4402",
    gl_tax_code: "1",
    gl_description: "Payment Fee",
    gl_rc_code: "6001",
    gl_oc_code: "6002",
    current_tax_rate: 7.0,
    tax_rate: [
      {
        tax_rate: 7.0,
        effective_date: "2020-01-01T00:00:00",
        end_date: "9999-12-31T00:00:00",
      },
    ],
    assign_service: [],
  },
};

export const data_tax_row = {
  current_tax_rate: 7,
  tax_scheme_id: "VATETAX",
  tax_scheme_name: "vat For E-Tax",
};

export const data_service = {
  status: { code: 1000, description: "Success" },
  data: [
    {
      service_code: "DBKAGENT",
      service_name: "Deposit Banking Agent Revenue Share",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "WBKAGENT",
      service_name: "Withdraw Banking Agent Revenue Share",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "ETAX",
      service_name: "E-Tax Invoice & E-Receipt",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "BLPLACEMENT",
      service_name: "Bill placement",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "BLBKAGENT",
      service_name: "BLPMT Banking Agent Revenue Share",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "LCLCT",
      service_name: "Local Collect",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "CTACTAONUS",
      service_name: "Credit Transfer API",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "MT940",
      service_name: "MT940",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "LMS",
      service_name: "LMS Monthly Fee",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "INTB",
      service_name: "Interbank Statement",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "BIZNET",
      service_name: "CCB Subscription",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "TOKEN",
      service_name: "Token Fee",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "API_INQUIRY",
      service_name: "API Enquiry",
      from_date: "2021-01-01T00:00:00",
      to_date: "9999-12-31T00:00:00",
    },
    {
      service_code: "ETAXDCF",
      service_name: "E-Tax Invoice & E-Receipt Customization",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "BLPMT",
      service_name: "Bill Payment",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "CTACTAOFFUS",
      service_name: "Credit Transfer API",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "CTANYIDONUS",
      service_name: "Credit Transfer API",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "CTANYIDOFFUS",
      service_name: "Credit Transfer API",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "CPTLMTDDON",
      service_name: "Capital Market (Online)",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "PGWDIRECTDEBIT",
      service_name: "Lego Direct Debit",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "BIZNETPA",
      service_name: "Startbiz PA Fee",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "SMSALERT",
      service_name: "Startbiz PA Fee",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "EMAILALERT",
      service_name: "Startbiz PA Fee",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "SCBBIZNET",
      service_name: "Startbiz PA Fee",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "QRT30OTF",
      service_name: "QR Tag30 (RFS)",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "QRT30SMS",
      service_name: "QR Tag30 (RFS)",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "QRT30MRCHNT",
      service_name: "QR Tag30 (RFS)",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "QRT30BLPMT",
      service_name: "QR Tag30 (RFS)",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "QRT30TXN",
      service_name: "QR Tag30 (RFS)",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "SPSBACTAONUS",
      service_name: "Dee money",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "SPSBACTAOFFUS",
      service_name: "Dee money",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
    {
      service_code: "BCM",
      service_name: "Business Cash Management Services Monthly Fee",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-12-31T00:00:00",
    },
  ],
};

export const data_service_ibfo_blbkagent = {
  status: { code: 1000, description: "Success" },
  data: {
    service_code: "BLBKAGENT",
    service_name: "BLPMT Banking Agent Revenue Share",
    statement_description: "Banking Agent - Revenue Sharing",
    gl_account_code: "4002",
    gl_product_code: "4001",
    gl_tax_code: "1",
    gl_description: "GL DESC",
    gl_rc_code: "5001",
    gl_oc_code: "5002",
    vat_tax_scheme_id: "VATBLPMT",
    wht_tax_scheme_id: "WHTBLPMT",
    remark: "-",
    effective_from: "2021-01-01T00:00:00",
    effective_to: "2022-12-31T00:00:00",
    transaction_code: "0000",
    monthly_posting_day: 1,
    vat_gl: {
      gl_name: "Vat For Bill Payment",
      gl_type: "VAT",
      gl_rate: 7.0,
      gl_description: "Payment Fee",
      gl_product_code: "4401",
      gl_account_code: "44321",
      gl_tax_code: "1",
      gl_rc_code: "5001",
      gl_oc_code: "5002",
    },
    wht_gl: {
      gl_name: "WHT For Bill Payment",
      gl_type: "WHT",
      gl_rate: 3.0,
      gl_description: "Payment Fee",
      gl_product_code: "5501",
      gl_account_code: "55321",
      gl_tax_code: "1",
      gl_rc_code: "7001",
      gl_oc_code: "7002",
    },
    fee_charge: [
      {
        charge_code: "UT10015",
        charge_name: "Banking Agent - Revenue Sharing",
        pricing_frequency: "MONTHLY",
        pricing_method: "TRAN_VOLUME",
        transaction_consolidation: "MONTHLY",
        posting_type: "DEBIT",
        posting_frequency: "MONTHLY",
        posting_consolidation: "SERVICE",
        vat_type: "INCLUDE",
        wht_type: "Y",
        price_without_post: "N",
        qualifiers: null,
        fee_value: {
          tier: "STEP",
          value: [
            { to: 50000, from: 0, rate: 3, rate_type: "THB" },
            { to: 300000, from: 50000.01, rate: 2, rate_type: "THB" },
            { to: 999999999.99, from: 300000.01, rate: 1, rate_type: "THB" },
          ],
        },
      },
    ],
  },
};

export const data_retail = {
  status: { code: 1000, description: "Success" },
  data: [
    {
      offer_code: "OFFER001",
      offer_name: "Spend more get more1",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER003",
      offer_name: "Spend more get more3",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER005",
      offer_name: "Spend more get more5",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER008",
      offer_name: "Spend more get more8",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER009",
      offer_name: "Spend more get more9",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER010",
      offer_name: "Spend more get more10",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER011",
      offer_name: "Spend more get more11",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER012",
      offer_name: "Spend more get more12",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER013",
      offer_name: "Spend more get more13",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER014",
      offer_name: "Spend more get more14",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER015",
      offer_name: "Spend more get more15",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER016",
      offer_name: "Spend more get more16",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER017",
      offer_name: "Spend more get more17",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER018",
      offer_name: "Spend more get more18",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER019",
      offer_name: "Spend more get more19",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER020",
      offer_name: "Spend more get more20",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER002",
      offer_name: "Spend more get more2",
      effective_from: "2021-01-01T00:00:00",
      effective_to: "9999-12-31T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER004",
      offer_name: "Spend more get more4",
      effective_from: "2021-12-20T00:00:00",
      effective_to: "2022-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER006",
      offer_name: "Spend more get more6",
      effective_from: "2022-02-01T00:00:00",
      effective_to: "2023-01-01T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
    {
      offer_code: "OFFER007",
      offer_name: "Spend more get more7",
      effective_from: "2022-01-01T00:00:00",
      effective_to: "9999-12-31T00:00:00",
      enrollment_condition: {
        accountType: "SA",
        payrollFlag: ["A2", "B2", "C2"],
      },
      gl_product_code: "4402",
      gl_account_code: "44322",
      gl_tax_code: "1",
      gl_description: "Payment Fee",
      gl_rc_code: "6001",
      gl_oc_code: "6001",
      vat_tax_scheme_id: "VATBLPMT",
      wht_tax_scheme_id: "WHTBLPMT",
    },
  ],
};

export const data_retail_selected = {
  status: { code: 1000, description: "Success" },
  data: {
    offer_code: "OFFER001",
    offer_name: "Spend more get more1",
    effective_from: null,
    effective_to: null,
    enrollment_condition: {
      accountType: "SA",
      payrollFlag: ["A2", "B2", "C2"],
    },
    gl_product_code: "4402",
    gl_account_code: "44322",
    gl_tax_code: "1",
    gl_description: "Payment Fee",
    gl_rc_code: "6001",
    gl_oc_code: "6001",
    vat_tax_scheme_id: "VATBLPMT",
    wht_tax_scheme_id: "WHTBLPMT",
    vat_gl: {
      gl_name: "Vat For Bill Payment",
      gl_type: "VAT",
      gl_rate: 7.0,
      gl_description: "Payment Fee",
      gl_product_code: "4401",
      gl_account_code: "44321",
      gl_tax_code: "1",
      gl_rc_code: "5001",
      gl_oc_code: "5002",
    },
    wht_gl: {
      gl_name: "WHT For Bill Payment",
      gl_type: "WHT",
      gl_rate: 3.0,
      gl_description: "Payment Fee",
      gl_product_code: "5501",
      gl_account_code: "55321",
      gl_tax_code: "1",
      gl_rc_code: "7001",
      gl_oc_code: "7002",
    },
    offer_fee_charge: [
      {
        charge_code: "PF10001",
        charge_name: "PF10001",
        pricing_frequency: "MONTHLY",
        pricing_method: "TRAN_VALUE",
        transaction_consolidation: "MONTHLY",
        posting_type: "DEBIT",
        posting_frequency: "MONTHLY",
        posting_consolidation: "SERVICE",
        vat_type: "NO",
        wht_type: "N",
        qualifiers: {
          tranCode: ["RDEBITC", "RCREDITC"],
          payrollFlag: ["A2", "B2", "C2"],
        },
        fee_value: {
          cap: 20,
          max: 84.94,
          min: 1,
          tier: "STEP",
          value: [
            { to: 5000, from: 0, rate: 0, rate_type: "PERCENT", fix_amount: 0 },
            {
              to: 999999999.99,
              from: 5000.01,
              rate: 1,
              interest: 1,
              rate_type: "PERCENT",
              fix_amount: 0,
            },
          ],
        },
      },
    ],
  },
};

export const data_offer = {
  status: { code: 1000, description: "Success" },
  data: [
    {
      offer_code: "OFFER001",
      offer_name: "Offer Name 001",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER002",
      offer_name: "Offer Name 002",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER009",
      offer_name: "Offer Name 009",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER010",
      offer_name: "Offer Name 010",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER011",
      offer_name: "Offer Name 011",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER012",
      offer_name: "Offer Name 012",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER013",
      offer_name: "Offer Name 013",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER014",
      offer_name: "Offer Name 014",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER015",
      offer_name: "Offer Name 015",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER016",
      offer_name: "Offer Name 016",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER008",
      offer_name: "Offer Name 008",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER017",
      offer_name: "Offer Name 017",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER018",
      offer_name: "Offer Name 018",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER019",
      offer_name: "Offer Name 019",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER020",
      offer_name: "Offer Name 020",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER021",
      offer_name: "Offer Name 021",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER022",
      offer_name: "Offer Name 022",
      from_date: "2021-01-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER003",
      offer_name: "Offer Name 003",
      from_date: "2021-12-01T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER004",
      offer_name: "Offer Name 004",
      from_date: "2021-12-30T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER005",
      offer_name: "Offer Name 005",
      from_date: "2021-12-31T00:00:00",
      to_date: "2022-01-01T00:00:00",
    },
    {
      offer_code: "OFFER006",
      offer_name: "Offer Name 006",
      from_date: "2022-01-01T00:00:00",
      to_date: "2023-01-01T00:00:00",
    },
    {
      offer_code: "OFFER007",
      offer_name: "Offer Name 007",
      from_date: "2021-01-01T00:00:00",
      to_date: "9999-12-31T00:00:00",
    },
  ],
};

export const data_offer_selected = {
  status: { code: 1000, description: "Success" },
  data: {
    offer_code: "OFFER001",
    offer_name: "Offer Name 001",
    from_date: "2021-01-01T00:00:00",
    to_date: "2022-01-01T00:00:00",
    offer_fee_service: [
      {
        service_code: "BLPMT",
        service_name: "Bill Payment",
        offer_fee_charge: [
          {
            charge_code: "PF10001",
            charge_name: "Bill Payment Processing Fee",
            pricing_frequency: "MONTHLY",
            pricing_method: "TRAN_VALUE",
            transaction_consolidation: "MONTHLY",
            posting_type: "DEBIT",
            posting_frequency: "MONTHLY",
            posting_consolidation: "SERVICE",
            vat_type: "NO",
            wht_type: "N",
            price_without_post: "N",
            qualifiers: { charge_type: ["V", "P"] },
            fee_value: {
              conditions: [
                {
                  tier: "STEP",
                  value: [
                    { to: 50000, from: 0, rate: 5, rate_type: "THB" },
                    {
                      to: 999999999.99,
                      from: 50000.01,
                      rate: 5.5,
                      rate_type: "THB",
                    },
                  ],
                  prioity: 1,
                  qualifiers: { charge_type: ["V"] },
                },
              ],
            },
          },
          {
            charge_code: "RT10002",
            charge_name: "Bill Payment Report Fee",
            pricing_frequency: "MONTHLY",
            pricing_method: "TRAN_VOLUME",
            transaction_consolidation: "MONTHLY",
            posting_type: "DEBIT",
            posting_frequency: "MONTHLY",
            posting_consolidation: "SERVICE",
            vat_type: "NO",
            wht_type: "N",
            price_without_post: "Y",
            qualifiers: null,
            fee_value: {
              max: 999999999.99,
              min: 0,
              tier: "SLAP",
              value: [
                {
                  to: 0,
                  from: 0,
                  rate: 0,
                  max_tier: 0,
                  min_tier: 0,
                  rate_type: "THB",
                  fix_amount: 3000,
                },
                {
                  to: 999999999.99,
                  from: 1,
                  rate: 0,
                  max_tier: 10000,
                  min_tier: 0,
                  rate_type: "THB",
                  fix_amount: 3000,
                },
              ],
            },
          },
          {
            charge_code: "UT10012",
            charge_name: "Bill Payment Fee",
            pricing_frequency: "DAILY",
            pricing_method: "TRAN_VALUE",
            transaction_consolidation: "TRANSACTION",
            posting_type: "DEBIT",
            posting_frequency: "MONTHLY",
            posting_consolidation: "SERVICE",
            vat_type: "NO",
            wht_type: "N",
            price_without_post: "N",
            qualifiers: {
              zone: ["IN", "OUT"],
              channels: [
                "TELL",
                "LEAD",
                "PSS",
                "ATM",
                "CDM",
                "MOBI",
                "MPAY",
                "PHON",
                "TELE",
                "CCC",
                "ENET",
                "EPT",
                "BCMS",
                "BNET",
                "BPOS",
                "PTNR",
                "EDC",
              ],
              charge_type: ["V", "P"],
            },
            fee_value: {
              conditions: [
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "NO",
                  value: [
                    {
                      to: 999999999.99,
                      from: 0,
                      rate: 0,
                      max_tier: 99999,
                      min_tier: 0,
                      rate_type: "THB",
                      fix_amount: 2,
                    },
                  ],
                  priority: 1,
                  qualifiers: {
                    zone: ["IN"],
                    channel: ["TELL", "ENET"],
                    charge_type: ["V"],
                  },
                },
                {
                  max: 1000,
                  min: 0,
                  tier: "STEP",
                  value: [
                    {
                      to: 50000,
                      from: 0,
                      rate: 0,
                      max_tier: 99999,
                      min_tier: 0,
                      rate_type: "THB",
                      fix_amount: 15,
                    },
                    {
                      to: 999999999.99,
                      from: 50000.01,
                      rate: 0.1,
                      max_tier: 99999,
                      min_tier: 0,
                      rate_type: "PERCENT",
                      fix_amount: 30,
                    },
                  ],
                  priority: 2,
                  qualifiers: {
                    zone: ["OUT"],
                    channel: ["TELL", "ENET"],
                    charge_type: ["V"],
                  },
                },
                {
                  max: 1000,
                  min: 100,
                  tier: "SLAP",
                  value: [
                    {
                      to: 50000,
                      from: 0,
                      rate: 0,
                      max_tier: 99999,
                      min_tier: 0,
                      rate_type: "THB",
                      fix_amount: 15,
                    },
                    {
                      to: 999999999.99,
                      from: 50000.01,
                      rate: 0.1,
                      max_tier: 40,
                      min_tier: 0,
                      rate_type: "PERCENT",
                      fix_amount: 30,
                    },
                  ],
                  priority: 3,
                  qualifiers: {
                    zone: ["OUT"],
                    channel: ["TELL", "ENET"],
                    charge_type: ["B"],
                  },
                },
              ],
            },
          },
        ],
      },
      {
        service_code: "LCLCT",
        service_name: "Local Collect",
        offer_fee_charge: [
          {
            charge_code: "UT10011",
            charge_name: "Local Collect Monthly Fee",
            pricing_frequency: "DAILY",
            pricing_method: "TRAN_VALUE",
            transaction_consolidation: "TRANSACTION",
            posting_type: "DEBIT",
            posting_frequency: "MONTHLY",
            posting_consolidation: "SERVICE",
            vat_type: "NO",
            wht_type: "N",
            price_without_post: "N",
            qualifiers: { zone: ["IN", "OUT"], tran_type: ["ABC", "CSH"] },
            fee_value: {
              conditions: [
                {
                  tier: "NO",
                  value: [{ rate: 0.1, rate_type: "PERCENT" }],
                  prioity: 1,
                  qualifiers: { zone: ["OUT"], tran_type: "ABC" },
                },
                {
                  tier: "NO",
                  value: [{ rate: 0.2, rate_type: "PERCENT" }],
                  prioity: 2,
                  qualifiers: { zone: ["OUT"], tran_type: "CSH" },
                },
              ],
            },
          },
          {
            charge_code: "RT10001",
            charge_name: "Local Collect Monthly Report  Fee",
            pricing_frequency: "MONTHLY",
            pricing_method: "SUBSCRIPTION",
            transaction_consolidation: "NO",
            posting_type: "DEBIT",
            posting_frequency: "MONTHLY",
            posting_consolidation: "SERVICE",
            vat_type: "NO",
            wht_type: "N",
            price_without_post: "N",
            qualifiers: null,
            fee_value: { tier: "NO", value: [{ fix_amount: 500 }] },
          },
        ],
      },
      {
        service_code: "LMS",
        service_name: "LMS Monthly Fee",
        offer_fee_charge: [
          {
            charge_code: "RT10024",
            charge_name: "LMS Monthly Fee",
            pricing_frequency: "MONTHLY",
            pricing_method: "SUBSCRIPTION",
            transaction_consolidation: "NO",
            posting_type: "DEBIT",
            posting_frequency: "MONTHLY",
            posting_consolidation: "SERVICE",
            vat_type: "NO",
            wht_type: "N",
            price_without_post: "N",
            qualifiers: null,
            fee_value: { tier: "NO", value: [{ fix_amount: 100 }] },
          },
        ],
      },
      {
        service_code: "SCBBIZNET",
        service_name: "Startbiz PA Fee",
        offer_fee_charge: [
          {
            charge_code: "RT10023",
            charge_name: "SCB Business Net Annual Fee",
            pricing_frequency: "YEARLY",
            pricing_method: "SUBSCRIPTION",
            transaction_consolidation: "NO",
            posting_type: "DEBIT",
            posting_frequency: "YEARLY",
            posting_consolidation: "SERVICE",
            vat_type: "NO",
            wht_type: "N",
            price_without_post: "N",
            qualifiers: null,
            fee_value: { tier: "NO", value: [{ fix_amount: 200 }] },
          },
        ],
      },
    ],
  },
};

export const add_edit_delete_charge_data = {
  status: {
    code: 1000,
    description: "Success",
  },
  data: {
    transaction_id: 4001,
    transaction_reference: "20220119180137471",
    reference_key: null,
    description: "Update Customer / Account Pricelist",
    transaction_type: "UPDATESERVICE",
    reference1: "BLPMT",
    reference2: null,
    maker: "testuser",
    create_datetime: "2022-01-19T18:01:37",
    approval: null,
    approval_datetime: null,
    status: "Pending",
    customer_id: "001400000000000000000023511007",
    account_id: null,
    refer_transaction_id: null,
    data: {
      update_obj: {
        fee_charge: [
          {
            action: "EDIT",
            to_date: "2022-12-30T00:00:00",
            vat_type: "INCLUDE",
            wht_type: "Y",
            fee_value: {
              conditions: [
                {
                  tier: "NO",
                  value: [
                    {
                      to: null,
                      from: null,
                      rate: 0,
                      rate_type: "THB",
                    },
                  ],
                  priority: 1,
                  qualifiers: {
                    interbank: ["ONUS"],
                  },
                },
                {
                  tier: "STEP",
                  value: [
                    {
                      to: 50000,
                      from: 0,
                    },
                    {
                      to: 999999999.99,
                      from: 50001,
                      rate: 0,
                      rate_type: "PERCENT",
                    },
                  ],
                  priority: 2,
                  qualifiers: {
                    interbank: ["OFFUS"],
                  },
                },
              ],
            },
            from_date: "2021-01-01T00:00:00",
            qualifiers: {
              INTERBANK: ["ONUS", "OFFUS"],
            },
            pricing_method: "TRAN_VOLUME",
            pricing_frequency: "DAILY",
            price_without_post: "N",
            customer_fee_charge_id: 1005,
            customer_fee_service_id: 801,
            transaction_consolidation: "MONTHLY",
          },
        ],
        customer_id: "001400000000000000000023511007",
        service_code: "BLPMT",
        customer_name: "Start Up Thailand",
        posting_account: [],
      },
      display_obj: {
        after_debit: [
          {
            customer_offer_posting_account_id: 501,
            from_date: "2021-01-10",
            posting_account_id: "A0101",
            posting_account_name: "TEST CREATE",
            status: "Dormant",
            to_date: "2022-12-31T00:00:00",
          },
        ],
        after_charge: [
          {
            action: "EDIT",
            to_date: "2022-12-30T00:00:00",
            vat_type: "INCLUDE",
            charge_code: "UT10077",
            charge_name: "QRT30-Transaction Fee",
            wht_type: "Y",
            fee_value: {
              conditions: [
                {
                  tier: "NO",
                  value: [
                    {
                      to: null,
                      from: null,
                      rate: 0,
                      rate_type: "THB",
                    },
                  ],
                  priority: 1,
                  qualifiers: {
                    interbank: ["ONUS"],
                  },
                },
                {
                  tier: "STEP",
                  value: [
                    {
                      to: 50000,
                      from: 0,
                    },
                    {
                      to: 999999999.99,
                      from: 50001,
                      rate: 0,
                      rate_type: "PERCENT",
                    },
                  ],
                  priority: 2,
                  qualifiers: {
                    interbank: ["OFFUS"],
                  },
                },
              ],
            },
            from_date: "2021-01-01T00:00:00",
            qualifiers: {
              INTERBANK: ["ONUS", "OFFUS"],
            },
            pricing_method: "TRAN_VOLUME",
            pricing_frequency: "DAILY",
            price_without_post: "N",
            customer_fee_charge_id: 1005,
            customer_fee_service_id: 801,
            transaction_consolidation: "MONTHLY",
          },
          {
            action: "DELETE",
            to_date: "2022-12-30T00:00:00",
            vat_type: "INCLUDE",
            wht_type: "Y",
            fee_value: {
              conditions: [
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "NO",
                  value: [
                    {
                      to: null,
                      from: null,
                      rate: 0.2,
                      rate_type: "THB",
                      fix_amount: 15,
                    },
                  ],
                  priority: 1,
                  qualifiers: {
                    interbank: ["ONUS"],
                  },
                },
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "STEP",
                  value: [
                    {
                      to: 50000,
                      from: 0,
                      fix_amount: 30,
                    },
                    {
                      to: 999999999.99,
                      from: 50001,
                      rate: 0.1,
                      tier_max: 2000,
                      tier_min: 20,
                      rate_type: "PERCENT",
                      fix_amount: 15,
                    },
                  ],
                  priority: 2,
                  qualifiers: {
                    interbank: ["OFFUS"],
                  },
                },
              ],
            },
            from_date: "2021-01-01T00:00:00",
            qualifiers: {
              INTERBANK: ["ONUS", "OFFUS"],
            },
            charge_code: "UT10076",
            charge_name: "QRT30-Transaction Fee",
            pricing_method: "TRAN_VOLUME",
            pricing_frequency: "DAILY",
            price_without_post: "N",
            customer_fee_charge_id: 1001,
            customer_fee_service_id: 801,
            transaction_consolidation: "MONTHLY",
          },
          {
            action: "NEW",
            to_date: "2022-12-30T00:00:00",
            vat_type: "INCLUDE",
            wht_type: "Y",
            fee_value: {
              conditions: [
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "NO",
                  value: [
                    {
                      to: null,
                      from: null,
                      rate: 0.2,
                      rate_type: "THB",
                      fix_amount: 15,
                    },
                  ],
                  priority: 1,
                  qualifiers: {
                    interbank: ["ONUS"],
                  },
                },
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "STEP",
                  value: [
                    {
                      to: 50000,
                      from: 0,
                      fix_amount: 30,
                    },
                    {
                      to: 999999999.99,
                      from: 50001,
                      rate: 0.1,
                      tier_max: 2000,
                      tier_min: 20,
                      rate_type: "PERCENT",
                      fix_amount: 15,
                    },
                  ],
                  priority: 2,
                  qualifiers: {
                    interbank: ["OFFUS"],
                  },
                },
              ],
            },
            from_date: "2021-01-01T00:00:00",
            qualifiers: {
              INTERBANK: ["ONUS", "OFFUS"],
            },
            pricing_method: "TRAN_VOLUME",
            pricing_frequency: "DAILY",
            price_without_post: "N",
            customer_fee_charge_id: "",
            customer_fee_service_id: 801,
            transaction_consolidation: "MONTHLY",
            charge_code: "UT10077",
            charge_name: "QRT30-Transaction Fee",
          },
        ],
        before_debit: [
          {
            customer_offer_posting_account_id: 501,
            from_date: "2021-01-10",
            posting_account_id: "A0101",
            posting_account_name: "TEST CREATE",
            status: "Dormant",
            to_date: "2022-11-20T00:00:00",
          },
        ],
        service_code: "BLPMT",
        service_name: "Bill Payment",
        before_charge: [
          {
            action: "EDIT",
            to_date: "2022-12-30T00:00:00",
            vat_type: "INCLUDE",
            wht_type: "Y",
            fee_value: {
              conditions: [
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "NO",
                  value: [
                    {
                      to: null,
                      from: null,
                      rate: 0.2,
                      rate_type: "THB",
                      fix_amount: 15,
                    },
                  ],
                  priority: 1,
                  qualifiers: {
                    interbank: ["ONUS"],
                  },
                },
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "STEP",
                  value: [
                    {
                      to: 50000,
                      from: 0,
                      fix_amount: 30,
                    },
                    {
                      to: 999999999.99,
                      from: 50001,
                      rate: 0.1,
                      tier_max: 2000,
                      tier_min: 20,
                      rate_type: "PERCENT",
                      fix_amount: 15,
                    },
                  ],
                  priority: 2,
                  qualifiers: {
                    interbank: ["OFFUS"],
                  },
                },
              ],
            },
            from_date: "2021-01-01T00:00:00",
            qualifiers: {
              INTERBANK: ["ONUS", "OFFUS"],
            },
            charge_code: "UT10076",
            charge_name: "QRT30-Transaction Fee",
            pricing_method: "TRAN_VOLUME",
            pricing_frequency: "DAILY",
            price_without_post: "N",
            customer_fee_charge_id: 1005,
            customer_fee_service_id: 801,
            transaction_consolidation: "MONTHLY",
          },
          {
            action: "DELETE",
            to_date: "2022-12-30T00:00:00",
            vat_type: "INCLUDE",
            wht_type: "Y",
            fee_value: {
              conditions: [
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "NO",
                  value: [
                    {
                      to: null,
                      from: null,
                      rate: 0.2,
                      rate_type: "THB",
                      fix_amount: 15,
                    },
                  ],
                  priority: 1,
                  qualifiers: {
                    interbank: ["ONUS"],
                  },
                },
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "STEP",
                  value: [
                    {
                      to: 50000,
                      from: 0,
                      fix_amount: 30,
                    },
                    {
                      to: 999999999.99,
                      from: 50001,
                      rate: 0.1,
                      tier_max: 2000,
                      tier_min: 20,
                      rate_type: "PERCENT",
                      fix_amount: 15,
                    },
                  ],
                  priority: 2,
                  qualifiers: {
                    interbank: ["OFFUS"],
                  },
                },
              ],
            },
            from_date: "2021-01-01T00:00:00",
            qualifiers: {
              INTERBANK: ["ONUS", "OFFUS"],
            },
            charge_code: "UT10076",
            charge_name: "QRT30-Transaction Fee",
            pricing_method: "TRAN_VOLUME",
            pricing_frequency: "DAILY",
            price_without_post: "N",
            customer_fee_charge_id: 1001,
            customer_fee_service_id: 801,
            transaction_consolidation: "MONTHLY",
          },
        ],
      },
    },
  },
};
export const edit_offer_maker_checker = {
  status: {
    code: 1000,
    description: "Success",
  },
  data: {
    transaction_id: 4001,
    transaction_reference: "20220119180137471",
    reference_key: null,
    description: "Update Customer / Account Pricelist",
    transaction_type: "UPDATESERVICE",
    reference1: "BLPMT",
    reference2: null,
    maker: "testuser",
    create_datetime: "2022-01-19T18:01:37",
    approval: null,
    approval_datetime: null,
    status: "Pending",
    customer_id: "001400000000000000000023511007",
    account_id: null,
    refer_transaction_id: null,
    data: {
      update_obj: {
        fee_charge: [
          {
            action: "EDIT",
            to_date: "2022-12-30T00:00:00",
            vat_type: "INCLUDE",
            wht_type: "Y",
            fee_value: {
              conditions: [
                {
                  tier: "NO",
                  value: [
                    {
                      to: null,
                      from: null,
                      rate: 0,
                      rate_type: "THB",
                    },
                  ],
                  priority: 1,
                  qualifiers: {
                    interbank: ["ONUS"],
                  },
                },
                {
                  tier: "STEP",
                  value: [
                    {
                      to: 50000,
                      from: 0,
                    },
                    {
                      to: 999999999.99,
                      from: 50001,
                      rate: 0,
                      rate_type: "PERCENT",
                    },
                  ],
                  priority: 2,
                  qualifiers: {
                    interbank: ["OFFUS"],
                  },
                },
              ],
            },
            from_date: "2021-01-01T00:00:00",
            qualifiers: {
              INTERBANK: ["ONUS", "OFFUS"],
            },
            pricing_method: "TRAN_VOLUME",
            pricing_frequency: "DAILY",
            price_without_post: "N",
            customer_fee_charge_id: 1005,
            customer_fee_service_id: 801,
            transaction_consolidation: "MONTHLY",
          },
        ],
        customer_id: "001400000000000000000023511007",
        service_code: "BLPMT",
        customer_name: "Start Up Thailand",
        posting_account: [],
      },
      display_obj: {
        after_debit: [
          {
            posting_account_id: "A0105",
            posting_account_name: "BP Acct 1",
            status: "Active",
            from_date: "2022-01-21T07:09:31",
            to_date: null,
            action: "NEW",
            account: "NEW",
          },
        ],
        after_charge: [
          {
            action: "EDIT",
            to_date: "2022-12-30T00:00:00",
            vat_type: "INCLUDE",
            charge_code: "UT10077",
            charge_name: "QRT30-Transaction Fee",
            wht_type: "Y",
            fee_value: {
              conditions: [
                {
                  tier: "NO",
                  value: [
                    {
                      to: null,
                      from: null,
                      rate: 0,
                      rate_type: "THB",
                    },
                  ],
                  priority: 1,
                  qualifiers: {
                    interbank: ["ONUS"],
                  },
                },
                {
                  tier: "STEP",
                  value: [
                    {
                      to: 50000,
                      from: 0,
                    },
                    {
                      to: 999999999.99,
                      from: 50001,
                      rate: 0,
                      rate_type: "PERCENT",
                    },
                  ],
                  priority: 2,
                  qualifiers: {
                    interbank: ["OFFUS"],
                  },
                },
              ],
            },
            from_date: "2021-01-01T00:00:00",
            qualifiers: {
              INTERBANK: ["ONUS", "OFFUS"],
            },
            pricing_method: "TRAN_VOLUME",
            pricing_frequency: "DAILY",
            price_without_post: "N",
            customer_fee_charge_id: 1005,
            customer_fee_service_id: 801,
            transaction_consolidation: "MONTHLY",
          },
          {
            action: "DELETE",
            to_date: "2022-12-30T00:00:00",
            vat_type: "INCLUDE",
            wht_type: "Y",
            fee_value: {
              conditions: [
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "NO",
                  value: [
                    {
                      to: null,
                      from: null,
                      rate: 0.2,
                      rate_type: "THB",
                      fix_amount: 15,
                    },
                  ],
                  priority: 1,
                  qualifiers: {
                    interbank: ["ONUS"],
                  },
                },
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "STEP",
                  value: [
                    {
                      to: 50000,
                      from: 0,
                      fix_amount: 30,
                    },
                    {
                      to: 999999999.99,
                      from: 50001,
                      rate: 0.1,
                      tier_max: 2000,
                      tier_min: 20,
                      rate_type: "PERCENT",
                      fix_amount: 15,
                    },
                  ],
                  priority: 2,
                  qualifiers: {
                    interbank: ["OFFUS"],
                  },
                },
              ],
            },
            from_date: "2021-01-01T00:00:00",
            qualifiers: {
              INTERBANK: ["ONUS", "OFFUS"],
            },
            charge_code: "UT10076",
            charge_name: "QRT30-Transaction Fee",
            pricing_method: "TRAN_VOLUME",
            pricing_frequency: "DAILY",
            price_without_post: "N",
            customer_fee_charge_id: 1001,
            customer_fee_service_id: 801,
            transaction_consolidation: "MONTHLY",
          },
          {
            action: "NEW",
            to_date: "2022-12-30T00:00:00",
            vat_type: "INCLUDE",
            wht_type: "Y",
            fee_value: {
              conditions: [
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "NO",
                  value: [
                    {
                      to: null,
                      from: null,
                      rate: 0.2,
                      rate_type: "THB",
                      fix_amount: 15,
                    },
                  ],
                  priority: 1,
                  qualifiers: {
                    interbank: ["ONUS"],
                  },
                },
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "STEP",
                  value: [
                    {
                      to: 50000,
                      from: 0,
                      fix_amount: 30,
                    },
                    {
                      to: 999999999.99,
                      from: 50001,
                      rate: 0.1,
                      tier_max: 2000,
                      tier_min: 20,
                      rate_type: "PERCENT",
                      fix_amount: 15,
                    },
                  ],
                  priority: 2,
                  qualifiers: {
                    interbank: ["OFFUS"],
                  },
                },
              ],
            },
            from_date: "2021-01-01T00:00:00",
            qualifiers: {
              INTERBANK: ["ONUS", "OFFUS"],
            },
            pricing_method: "TRAN_VOLUME",
            pricing_frequency: "DAILY",
            price_without_post: "N",
            customer_fee_charge_id: "",
            customer_fee_service_id: 801,
            transaction_consolidation: "MONTHLY",
            charge_code: "UT10077",
            charge_name: "QRT30-Transaction Fee",
          },
        ],
        before_debit: [],
        service_code: "BLPMT",
        service_name: "Bill Payment",
        before_charge: [
          {
            action: "EDIT",
            to_date: "2022-12-30T00:00:00",
            vat_type: "INCLUDE",
            wht_type: "Y",
            fee_value: {
              conditions: [
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "NO",
                  value: [
                    {
                      to: null,
                      from: null,
                      rate: 0.2,
                      rate_type: "THB",
                      fix_amount: 15,
                    },
                  ],
                  priority: 1,
                  qualifiers: {
                    interbank: ["ONUS"],
                  },
                },
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "STEP",
                  value: [
                    {
                      to: 50000,
                      from: 0,
                      fix_amount: 30,
                    },
                    {
                      to: 999999999.99,
                      from: 50001,
                      rate: 0.1,
                      tier_max: 2000,
                      tier_min: 20,
                      rate_type: "PERCENT",
                      fix_amount: 15,
                    },
                  ],
                  priority: 2,
                  qualifiers: {
                    interbank: ["OFFUS"],
                  },
                },
              ],
            },
            from_date: "2021-01-01T00:00:00",
            qualifiers: {
              INTERBANK: ["ONUS", "OFFUS"],
            },
            charge_code: "UT10076",
            charge_name: "QRT30-Transaction Fee",
            pricing_method: "TRAN_VOLUME",
            pricing_frequency: "DAILY",
            price_without_post: "N",
            customer_fee_charge_id: 1005,
            customer_fee_service_id: 801,
            transaction_consolidation: "MONTHLY",
          },
          {
            action: "DELETE",
            to_date: "2022-12-30T00:00:00",
            vat_type: "INCLUDE",
            wht_type: "Y",
            fee_value: {
              conditions: [
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "NO",
                  value: [
                    {
                      to: null,
                      from: null,
                      rate: 0.2,
                      rate_type: "THB",
                      fix_amount: 15,
                    },
                  ],
                  priority: 1,
                  qualifiers: {
                    interbank: ["ONUS"],
                  },
                },
                {
                  max: 999999999.99,
                  min: 0,
                  tier: "STEP",
                  value: [
                    {
                      to: 50000,
                      from: 0,
                      fix_amount: 30,
                    },
                    {
                      to: 999999999.99,
                      from: 50001,
                      rate: 0.1,
                      tier_max: 2000,
                      tier_min: 20,
                      rate_type: "PERCENT",
                      fix_amount: 15,
                    },
                  ],
                  priority: 2,
                  qualifiers: {
                    interbank: ["OFFUS"],
                  },
                },
              ],
            },
            from_date: "2021-01-01T00:00:00",
            qualifiers: {
              INTERBANK: ["ONUS", "OFFUS"],
            },
            charge_code: "UT10076",
            charge_name: "QRT30-Transaction Fee",
            pricing_method: "TRAN_VOLUME",
            pricing_frequency: "DAILY",
            price_without_post: "N",
            customer_fee_charge_id: 1001,
            customer_fee_service_id: 801,
            transaction_consolidation: "MONTHLY",
          },
        ],
      },
    },
  },
};

export const maker_checker_add_new_service = {
  status: {
    code: 1000,
    description: "Success",
  },
  data: {
    transaction_id: 4002,
    transaction_reference: "20220119181632270",
    reference_key: null,
    description: "New Customer / Account Pricelist",
    transaction_type: "NEWSERVICE",
    reference1: null,
    reference2: null,
    maker: "testuser",
    create_datetime: "2022-01-19T18:16:32",
    approval: null,
    approval_datetime: null,
    status: "Pending",
    customer_id: null,
    account_id: null,
    refer_transaction_id: null,
    data: {
      update_obj: {
        fee_charge: [
          {
            to_date: "9999-12-31T00:00:00",
            vat_type: "NO",
            wht_type: "N",
            fee_value: {
              tier: "NO",
              value: [
                {
                  to: null,
                  from: null,
                  rate: 7,
                  rate_type: "THB",
                },
              ],
            },
            from_date: "2021-01-01T00:00:00",
            qualifiers: null,
            charge_code: "UT10085",
            charge_name: "API Enquiry Fee",
            pricing_method: "TRAN_VOLUME",
            pricing_frequency: "MONTHLY",
            price_without_post: "N",
            posting_consolidation: "SERVICE",
            customer_fee_charge_id: "",
            transaction_consolidation: "MONTHLY",
          },
          {
            to_date: "9999-12-31T00:00:00",
            vat_type: "NO",
            wht_type: "N",
            fee_value: {
              tier: "NO",
              value: [
                {
                  to: null,
                  from: null,
                },
              ],
            },
            from_date: "2021-01-01T00:00:00",
            qualifiers: null,
            charge_code: "RT10027",
            charge_name: "API Enquiry  Monthly Fee",
            pricing_method: "SUBSCRIPTION",
            pricing_frequency: "MONTHLY",
            price_without_post: "N",
            posting_consolidation: "SERVICE",
            customer_fee_charge_id: "",
            transaction_consolidation: "NO",
          },
        ],
        customer_id: "001400000000000000000023511007",
        service_code: "API_INQUIRY",
        service_name: "API Enquiry",
        customer_name: "Start Up Thailand",
        posting_account: [],
        customer_fee_service_id: "",
      },
      display_obj: {
        gender: null,
        status: "AC",
        account: [
          {
            status: "Normal",
            account_id: "A0105",
            account_name: "BP Acct 1",
          },
        ],
        id_type: "Passport",
        service: [
          {
            charge_group: [
              {
                fee_charge: [
                  {
                    to_date: "9999-12-31T00:00:00",
                    vat_type: "NO",
                    wht_type: "N",
                    fee_value: {
                      tier: "NO",
                      value: [
                        {
                          to: null,
                          from: null,
                          rate: 7,
                          rate_type: "THB",
                        },
                      ],
                    },
                    from_date: "2021-01-01T00:00:00",
                    qualifiers: null,
                    charge_code: "UT10085",
                    charge_name: "API Enquiry Fee",
                    pricing_method: "TRAN_VOLUME",
                    pricing_frequency: "MONTHLY",
                    price_without_post: "N",
                    posting_consolidation: "SERVICE",
                    customer_fee_charge_id: "",
                    transaction_consolidation: "MONTHLY",
                  },
                ],
                charge_code: "UT10085",
                charge_name: "API Enquiry Fee",
              },
              {
                fee_charge: [
                  {
                    to_date: "9999-12-31T00:00:00",
                    vat_type: "NO",
                    wht_type: "N",
                    fee_value: {
                      tier: "NO",
                      value: [
                        {
                          to: null,
                          from: null,
                        },
                      ],
                    },
                    from_date: "2021-01-01T00:00:00",
                    qualifiers: null,
                    charge_code: "RT10027",
                    charge_name: "API Enquiry  Monthly Fee",
                    pricing_method: "SUBSCRIPTION",
                    pricing_frequency: "MONTHLY",
                    price_without_post: "N",
                    posting_consolidation: "SERVICE",
                    customer_fee_charge_id: "",
                    transaction_consolidation: "NO",
                  },
                ],
                charge_code: "RT10027",
                charge_name: "API Enquiry  Monthly Fee",
              },
            ],
            service_code: "API_INQUIRY",
            service_name: "API Enquiry",
            posting_account: [],
          },
        ],
        open_date: "2019-11-22T00:00:00",
        title_thai: "บริษัท",
        customer_id: "001400000000000000000023511007",
        service_code: "API_INQUIRY",
        service_name: "API Enquiry",
        contact_name1: "คุณ ภมร",
        contact_name2: "คุณ อรุณรักษ์",
        customer_name: "Start Up Thailand",
        date_of_birth: "2019-11-22T00:00:00",
        full_name_eng: "Start Up Thailand",
        full_name_thai: "สตาร์ทอัพ ไทยแลนด์",
        contact_number1: "029986531",
        contact_number2: null,
        application_date: "2014-05-05T00:00:00",
        customer_segment: "3",
        sub_segment_code: "65",
        payroll_indicator: "P",
        customer_fee_service_id: "",
      },
    },
  },
};

export const add_debit_future_profile = {
  after_debit: [
    {
      posting_account_id: "A0105",
      posting_account_name: "BP Acct 1",
      status: "Active",
      from_date: "2022-01-21T07:09:31",
      to_date: null,
      action: "NEW",
      account: "NEW",
    },
  ],
  before_debit: [],
};
