import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Table, TableContainer, TextField } from "@mui/material";
import { cloneDeep } from "lodash";
import * as React from "react";
import { FooterButton } from "../../../components/Elements/Button/footerButton";
import { Controllers } from "../../../components/radium-omnibus/src"; // V.1.9.6 for only scb
import DropdownNew, {
  DropdownNewWithPlaceholder,
  DropdownObjectWithPlaceholder,
} from "../../../components/radium-omnibus/src/fields/dropdownNew";
import { TABLE_STYLE } from "../../../constants/style";
import { formatDateCheckValid, yesNoMapping } from "../../../utils/util";
import _ from "lodash";
import { PBFEContent } from "../../../components/Elements/Text/content";
import { PBFEContentDom } from "../../../components/Elements/Text/contentDom";
import RemoveIcon from "../../../assets/icon/remove.svg";
import {
  checkFromToDate,
  FunctionCheckDateLength,
  FunctionCheckDatIsPast,
} from "../../../functions";
import { ERROR_MESSAGE_REQUIRED } from "../../../utils/errorMessage";
import { TableNewRenderQualifiers } from "../../../components/Table/tableCellQualifiers";
import { NewVersionRenderQualifiersEditMode } from "../../../components/Table/feeTable";
import { RenderTable } from "..";
import SDOnSubmit from "../functions/SD.OnSubmit";
import Plus from "../../../assets/icon/plus.svg";
import Emitter from "../../../utils/useEmitter";

const ListenerComponent = ({ action, name }) => {
  React.useEffect(() => {
    Emitter.on(name, (data) => {
      action(data);
    });
  }, []);
  return <></>;
};
const transactionCodeString = (value) => {
  if (Array.isArray(value)) {
    let temp = "";
    for (let i = 0; i < value?.length; i++) {
      temp += value[i].transaction_code;
      if (i !== value?.length - 1) {
        temp += ",";
      }
    }
    return temp;
  }
  return value;
};

const RenderControllerMemo = React.memo(
  ({
    isCreate,
    data,
    setError,
    setErrorOpen,
    setSubmitData,
    setShowConfirm,
    taxscheme,
  }) => {
    return (
      <div className="grid grid-cols-8  gap-x-10 gap-y-2 pt-20px">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controllers
            language="EN"
            showError={false}
            showSymbol={false}
            loadData={
              isCreate
                ? {
                    // service_name: "New Service Name",
                    // service_code: "NEWSVC",
                    // effective_from: new Date(),
                    // effective_to: new Date("9999-12-31T00:00:00"),
                    monthly_posting_day: 5,
                    posting_consolidation: "SERVICE",
                    monthly_price_day: 1,
                    // vat_gl: "NO",
                    // wht_gl: "NO",
                  }
                : cloneDeep({
                    ...data,
                    remark: "",
                    from_date_to_date: {
                      from_date: formatDateCheckValid(
                        new Date(data?.effective_from)
                      ),
                      to_date: formatDateCheckValid(
                        new Date(data?.effective_to)
                      ),
                    },
                    fee_charge: data?.fee_charge?.map((row) => {
                      return {
                        ...row,
                        pricing_frequency_transaction_consolidation: {
                          pricing_frequency: row?.pricing_frequency,
                          transaction_consolidation:
                            row?.transaction_consolidation,
                          pricing_method: row?.pricing_method,
                        },
                      };
                    }),
                    vat_gl: data?.vat_gl?.gl_name
                      ? data?.vat_gl?.gl_name
                      : "NO",
                    wht_gl: data?.wht_gl?.gl_name
                      ? data?.wht_gl?.gl_name
                      : "NO",
                  })
            }
            fields={[
              {
                classNameCore: "col-span-4 px-10",
                name: "service_name",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Service Name"}
                      variant="standard"
                      fullWidth
                      sx={{ input: { color: "#7A58BF" } }}
                      inputProps={{ maxLength: 100 }}
                      // error={thisValue?.error}
                      // helperText={
                      //   thisValue?.error
                      //     ? thisValue?.message
                      //     : "Limited " + value?.length + "/100"
                      // }
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/100"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-2",
                type: "none",
                render: () => {
                  return <></>;
                },
              },
              {
                classNameCore: "col-span-2 pr-10",
                name: "service_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  // if (isCreate) {
                  return (
                    <TextField
                      id={name}
                      disabled={isCreate ? false : true}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Service Code"}
                      variant="standard"
                      fullWidth
                      sx={{ input: { color: "#7A58BF" } }}
                      inputProps={{ maxLength: 40 }}
                      // error={thisValue?.error}
                      // helperText={
                      //   thisValue?.error
                      //     ? thisValue?.message
                      //     : "Limited " + value?.length + "/40"
                      // }
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/40"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/40"
                      }
                    />
                  );
                  // } else {
                  //   return (
                  //     <PBFEContent
                  //       isSCBColor={true}
                  //       label="Service Code"
                  //       value={value}
                  //     />
                  //   );
                  // }
                },
                required: true,
                no_space: true,
              },
              {
                classNameCore: "col-span-4 px-10",
                name: "from_date_to_date",
                initalValue: {
                  from_date: formatDateCheckValid(new Date()),
                  to_date: formatDateCheckValid(
                    new Date("9999-12-31T00:00:00")
                  ),
                },
                errorCustoms: ({ value }) => {
                  const checkFromDate =
                    checkFromToDate(
                      value?.from_date,
                      value?.to_date,
                      null,
                      "from_date"
                    ) || false;
                  const checkToDate =
                    checkFromToDate(
                      value?.from_date,
                      value?.to_date,
                      null,
                      "to_date"
                    ) || false;

                  const checkFromDateLength = FunctionCheckDateLength(
                    value?.to_date,
                    "from_date"
                  );

                  const checkToDateLength = FunctionCheckDateLength(
                    value?.to_date,
                    "to_date"
                  );
                  const isPast = FunctionCheckDatIsPast(data?.effective_from);

                  if (value?.from_date === "") {
                    return ERROR_MESSAGE_REQUIRED;
                  } else if (checkFromDateLength.error) {
                    return checkFromDateLength.message;
                  } else if (checkToDateLength.error) {
                    return checkToDateLength.message;
                  } else if (checkToDate) {
                    return checkToDate;
                  } else if (
                    FunctionCheckDatIsPast(value?.from_date) &&
                    !isPast
                  ) {
                    return "From date must not be in the past";
                  } else if (checkFromDate) {
                    return checkFromDate;
                  } else {
                    return "";
                  }
                },
                fieldCustoms: ({ value, setValue }) => {
                  return (
                    <div className="grid grid-cols-2 gap-10">
                      <DesktopDatePicker
                        label="Date desktop"
                        inputFormat="dd/MM/yyyy"
                        disablePast
                        disabled={
                          new Date(data?.effective_from).getTime() <=
                          new Date().getTime()
                        }
                        // disabled={!isCreate && index === 0}
                        value={value?.from_date}
                        onChange={(e) =>
                          setValue({
                            ...value,
                            from_date: formatDateCheckValid(e),
                          })
                        }
                        renderInput={(params) => {
                          if (params?.inputProps?.placeholder) {
                            params.inputProps.placeholder = "DD/MM/YYYY";
                          }
                          return (
                            <TextField
                              {...params}
                              placeholder="DD/MM/YYYY"
                              InputProps={{
                                ...params.InputProps,
                                style: { color: "#7A58BF", fontSize: 16 },
                                placeholder: "DD/MM/YYYY",
                              }}
                              variant="standard"
                              label={"From"}
                              error={
                                checkFromToDate(
                                  value?.from_date,
                                  value?.to_date,
                                  null,
                                  "from_date"
                                ) ||
                                FunctionCheckDateLength(
                                  value?.from_date,
                                  "from_date"
                                ).error
                              }
                              helperText={
                                FunctionCheckDateLength(
                                  value?.from_date,
                                  "from_date"
                                ).error
                                  ? FunctionCheckDateLength(
                                      value?.from_date,
                                      "from_date"
                                    ).message
                                  : checkFromToDate(
                                      value?.from_date,
                                      value?.to_date,
                                      null,
                                      "from_date"
                                    )
                              }
                            />
                          );
                        }}
                      />
                      <DesktopDatePicker
                        label="Date desktop"
                        inputFormat="dd/MM/yyyy"
                        disablePast
                        // disabled={!isCreate && index === 0}
                        value={
                          value?.to_date === "9999-12-31"
                            ? null
                            : value?.to_date
                        }
                        onChange={(e) =>
                          setValue({
                            ...value,
                            to_date: formatDateCheckValid(e),
                          })
                        }
                        renderInput={(params) => {
                          if (params?.inputProps?.placeholder) {
                            params.inputProps.placeholder = "DD/MM/YYYY";
                          }
                          return (
                            <TextField
                              {...params}
                              placeholder="DD/MM/YYYY"
                              InputProps={{
                                ...params.InputProps,
                                style: { color: "#7A58BF", fontSize: 16 },
                                placeholder: "DD/MM/YYYY",
                              }}
                              variant="standard"
                              label={"To"}
                              error={
                                checkFromToDate(
                                  value?.from_date,
                                  value?.to_date,
                                  null,
                                  "to_date"
                                ) ||
                                FunctionCheckDateLength(
                                  value?.to_date,
                                  "to_date"
                                ).error
                              }
                              helperText={
                                FunctionCheckDateLength(
                                  value?.to_date,
                                  "to_date"
                                ).error
                                  ? FunctionCheckDateLength(
                                      value?.to_date,
                                      "to_date"
                                    ).message
                                  : checkFromToDate(
                                      value?.from_date,
                                      value?.to_date,
                                      null,
                                      "to_date"
                                    )
                              }
                            />
                          );
                        }}
                      />
                    </div>
                  );
                },
              },
              {
                classNameCore: "col-span-4 pr-10",
                name: "transaction_mapping",
                // required: true,
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={transactionCodeString(value)}
                      label={"Transaction Code"}
                      variant="standard"
                      fullWidth
                      sx={{ input: { color: "#7A58BF" } }}
                      inputProps={{ maxLength: 100 }}
                      // error={thisValue?.error}
                      // helperText={
                      //   thisValue?.error
                      //     ? thisValue?.message
                      // : "Limited " +
                      //   transactionCodeString(value).length +
                      //   "/100"
                      // }
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " +
                            transactionCodeString(value).length +
                            "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " +
                            transactionCodeString(value).length +
                            "/100"
                      }
                    />
                  );
                },
              },
              {
                classNameCore: "col-span-4 px-10",
                name: "statement_description",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Statement Description"}
                      sx={{ input: { color: "#7A58BF" } }}
                      variant="standard"
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/100"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-2",
                name: "monthly_posting_day",
                disabled: true,
                fieldCustoms: ({ value, name }) => {
                  return (
                    // <PBFEContent
                    //   label="Monthly Posting Day"
                    //   isSCBColor={true}
                    //   value={value ? value : 5}
                    // />
                    <TextField
                      disabled={true}
                      id={name}
                      // onChange={(e) => setValue(e.target.value)}
                      value={value ? value : "-"}
                      label={"Monthly Posting Day"}
                      variant="standard"
                      sx={{ input: { color: "#7A58BF" } }}
                      fullWidth
                      inputProps={{ maxLength: 1 }}
                    />
                  );
                },
              },
              {
                classNameCore: "col-span-2 pr-10",
                name: "fcy_flag",
                initalValue: "N",
                fieldCustoms: ({ value, setValue, thisValue }) => {
                  Emitter.emit("fcy_flag", value);
                  return (
                    <DropdownObjectWithPlaceholder
                      // value={yesNoMapping(value)}
                      value={value}
                      label={"FCY Service"}
                      initalValue={"N"}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      options={[
                        {
                          label: "NO",
                          value: "N",
                        },
                        {
                          label: "YES",
                          value: "Y",
                        },
                      ]}
                      error={thisValue?.error}
                      helperText={thisValue?.error ? thisValue?.message : ""}
                    />
                  );
                },
                required: false,
              },
              // {
              //   classNameCore: "col-span-2",
              //   type: "none",
              //   render: () => {
              //     return <></>;
              //   },
              // },
              {
                classNameCore: "col-span-2 pl-10",
                name: "gl_description",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"GL Description"}
                      variant="standard"
                      fullWidth
                      sx={{ input: { color: "#7A58BF" } }}
                      inputProps={{ maxLength: 64 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/64"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/64"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-2 pr-10",
                name: "gl_product_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      sx={{ input: { color: "#7A58BF" } }}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Product Code"}
                      variant="standard"
                      fullWidth
                      inputProps={{ maxLength: 20 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/20"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/20"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-2",
                name: "gl_account_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Account Code"}
                      variant="standard"
                      sx={{ input: { color: "#7A58BF" } }}
                      fullWidth
                      inputProps={{ maxLength: 20 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/20"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/20"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-2 pr-10",
                name: "gl_tax_code",
                errorCustoms: ({ value }) => {
                  if (value === "0") {
                    return "";
                  } else if (value === "1") {
                    return "";
                  } else {
                    return "Tax Code must be 0 or 1";
                  }
                },
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Tax Code"}
                      variant="standard"
                      sx={{ input: { color: "#7A58BF" } }}
                      fullWidth
                      inputProps={{ maxLength: 1 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/1"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/1"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-2 pl-10",
                name: "gl_rc_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"GL RC"}
                      variant="standard"
                      sx={{ input: { color: "#7A58BF" } }}
                      fullWidth
                      inputProps={{ maxLength: 20 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/20"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/20"
                      }
                    />
                  );
                },
                required: false,
              },
              {
                classNameCore: "col-span-2 pr-10",
                name: "gl_oc_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"GL OC"}
                      variant="standard"
                      sx={{ input: { color: "#7A58BF" } }}
                      fullWidth
                      inputProps={{ maxLength: 20 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/20"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/20"
                      }
                    />
                  );
                },
                required: false,
              },
              {
                classNameCore: "col-span-2",
                // initalValue: "NO",
                name: "vat_gl",
                fieldCustoms: ({ value, setValue, thisValue }) => {
                  return (
                    <DropdownNewWithPlaceholder
                      value={yesNoMapping(value)}
                      label={`VAT Tax Scheme`}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      options={
                        taxscheme?.length > 0
                          ? [
                              "NO",
                              ...taxscheme
                                ?.filter((row) => {
                                  return row?.tax_type === "VAT";
                                })
                                ?.map((row) => {
                                  return row?.tax_scheme_name;
                                }),
                            ]
                          : ["NO"]
                      }
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? ""
                          : thisValue?.error
                          ? thisValue?.message
                          : ""
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-2 pr-10",
                // initalValue: "NO",
                name: "wht_gl",
                fieldCustoms: ({ value, setValue, thisValue }) => {
                  return (
                    <DropdownNewWithPlaceholder
                      value={yesNoMapping(value)}
                      label={`WHT Tax Scheme`}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      options={
                        taxscheme?.length > 0
                          ? [
                              "NO",
                              ...taxscheme
                                ?.filter((row) => {
                                  return row?.tax_type === "WHT";
                                })
                                ?.map((row) => {
                                  return row?.tax_scheme_name;
                                }),
                            ]
                          : ["NO"]
                      }
                      error={thisValue?.error}
                      helperText={thisValue?.error ? thisValue?.message : ""}
                    />
                  );
                },
                required: false,
              },
              {
                classNameCore: "col-span-4 px-10",
                name: "debit_advice_name",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Debit Advice Description"}
                      variant="standard"
                      sx={{ input: { color: "#7A58BF" } }}
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/100"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-2",
                name: "pmh_instr_type",
                required: ({ listener }) => {
                  if (listener === "N") {
                    return false;
                  } else {
                    return true;
                  }
                },
                fieldCustoms: (props) => {
                  const {
                    value,
                    setValue,
                    name,
                    thisValue,
                    setListener,
                    listener,
                  } = props;
                  if (listener === "N") {
                    return <React.Fragment></React.Fragment>;
                  }
                  return (
                    <React.Fragment>
                      <ListenerComponent
                        action={(listener) => {
                          if (listener === "N") {
                            setValue("");
                          }
                          setListener(listener);
                        }}
                        name="fcy_flag"
                      />
                      <TextField
                        id={name}
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        label={`PMH Instruction Type`}
                        variant="standard"
                        fullWidth
                        sx={{ input: { color: "#7A58BF" } }}
                        inputProps={{ maxLength: 50 }}
                        error={
                          thisValue?.value === "" ? false : thisValue?.error
                        }
                        helperText={
                          thisValue?.value === ""
                            ? "Limited " + value?.length + "/50"
                            : thisValue?.error
                            ? thisValue?.message
                            : "Limited " + value?.length + "/50"
                        }
                      />
                    </React.Fragment>
                  );
                },
              },
              {
                classNameCore: "col-span-2",
                type: "none",
                render: () => {
                  return <div></div>;
                },
              },
              {
                classNameCore: "col-span-4 px-10",
                name: "alert_name_th",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Thai Description in Email Content"}
                      variant="standard"
                      sx={{ input: { color: "#7A58BF" } }}
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/100"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-4 pr-10",
                name: "alert_name_eng",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"English Description in Email Content"}
                      variant="standard"
                      sx={{ input: { color: "#7A58BF" } }}
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/100"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-4 px-10",
                name: "alt_statement_desc",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Alt Statement Description"}
                      variant="standard"
                      fullWidth
                      sx={{ input: { color: "#7A58BF" } }}
                      inputProps={{ maxLength: 100 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/100"
                      }
                    />
                  );
                },
              },
              {
                classNameCore: "col-span-2",
                name: "alt_gl_account_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Alt Account Code"}
                      sx={{ input: { color: "#7A58BF" } }}
                      variant="standard"
                      fullWidth
                      inputProps={{ maxLength: 20 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/20"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/20"
                      }
                    />
                  );
                },
                required: false,
              },
              {
                errorCustoms: ({ value }) => {
                  if (value === "0") {
                    return "";
                  } else if (value === "1") {
                    return "";
                  } else if (value === "") {
                    return "";
                  } else {
                    return "Alt Tax Code must be 0 or 1";
                  }
                },
                classNameCore: "col-span-2 pr-10",
                name: "alt_gl_tax_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Alt Tax Code"}
                      variant="standard"
                      sx={{ input: { color: "#7A58BF" } }}
                      fullWidth
                      inputProps={{ maxLength: 1 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/1"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/1"
                      }
                    />
                  );
                },
                required: false,
              },
              {
                classNameCore: "col-span-2 pl-10",
                name: "alt_gl_description",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Alt GL Description"}
                      variant="standard"
                      fullWidth
                      sx={{ input: { color: "#7A58BF" } }}
                      inputProps={{ maxLength: 100 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/100"
                      }
                    />
                  );
                },
                required: false,
              },
              {
                classNameCore: "col-span-2 pr-10",
                name: "alt_gl_product_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Alt Product Code"}
                      variant="standard"
                      sx={{ input: { color: "#7A58BF" } }}
                      fullWidth
                      inputProps={{ maxLength: 20 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/20"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/20"
                      }
                    />
                  );
                },
                required: false,
              },
              {
                classNameCore: "col-span-2 ",
                name: "alt_gl_rc_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Alt GL RC"}
                      variant="standard"
                      fullWidth
                      sx={{ input: { color: "#7A58BF" } }}
                      inputProps={{ maxLength: 20 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/20"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/20"
                      }
                    />
                  );
                },
                required: false,
              },
              {
                classNameCore: "col-span-2 pr-10",
                name: "alt_gl_oc_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Alt GL OC"}
                      variant="standard"
                      sx={{ input: { color: "#7A58BF" } }}
                      fullWidth
                      inputProps={{ maxLength: 20 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/20"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/20"
                      }
                    />
                  );
                },
                required: false,
              },
              {
                classNameCore: "col-span-4",
                type: "none",
                render: () => {
                  return <div></div>;
                },
              },
              // {
              //   classNameCore: "col-span-8",
              //   type: "none",
              //   render: () => {
              //     const isQualifer =
              //       Object.keys(data?.qualifiers || {}).length > 0;
              //     console.log("isQualifer", { isQualifer, data });
              //     if (isQualifer) {
              //       return (
              //         <TableContainer>
              //           <Table sx={TABLE_STYLE}>
              //             <TableNewRenderQualifiers />
              //             <NewVersionRenderQualifiers
              //               qualifiers={data?.qualifiers}
              //             />
              //           </Table>
              //         </TableContainer>
              //       );
              //     } else {
              //       return <React.Fragment />;
              //     }
              //   },
              // },
              {
                classNameCore: "col-span-8",
                type: "none",
                render: () => {
                  return (
                    <div className="border broder-b-gray-100 w-full my-4"></div>
                  );
                },
              },
              {
                classNameCore: "col-span-8 grid grid-cols-8 gap-10 px-10",
                key: "fee_charge",
                showButtonAdd: false,
                showButtonRemove: false,
                initalValue: {
                  //   charge_name: "New Charge Code",
                  //   charge_code: "RT00000",
                  price_without_post: "N",
                  show_volume_flag: "N",
                  show_value_flag: "N",
                  posting_consolidation: "SERVICE",
                  monthly_pricing_day: 1,
                  // posting_frequency: "",
                  fee_value: {
                    tier: "NO",
                    value: [
                      {
                        fixed_amount: "",
                        rate: "",
                        min: "",
                        max: "",
                      },
                    ],
                  },
                },
                showIndexZero: false,
                renderIndexZero: (props) => {
                  return (
                    <div className="col-span-8 pt-4 ">
                      <FooterButton
                        text={
                          <div className="flex space-x-1">
                            <img
                              alt=""
                              className="ml-10px h-15px w-15px cursor-pointer"
                              src={Plus}
                            />
                            <div className="mx-1">Add Charge Code</div>
                          </div>
                        }
                        action={(e) => {
                          e.stopPropagation();
                          props.add();
                        }}
                      />
                    </div>
                  );
                },
                fields: [
                  {
                    classNameCore: "col-span-4 pr-10",
                    name: "charge_name",
                    fieldCustoms: ({ value, setValue, name, thisValue }) => {
                      return (
                        <TextField
                          id={name}
                          onChange={(e) => setValue(e.target.value)}
                          value={value}
                          label={"Charge Name"}
                          variant="standard"
                          sx={{ input: { color: "#7A58BF" } }}
                          fullWidth
                          inputProps={{ maxLength: 100 }}
                          error={
                            thisValue?.value === "" ? false : thisValue?.error
                          }
                          helperText={
                            thisValue?.value === ""
                              ? "Limited " + value?.length + "/100"
                              : thisValue?.error
                              ? thisValue?.message
                              : "Limited " + value?.length + "/100"
                          }
                        />
                      );
                    },
                    required: true,
                  },
                  {
                    classNameCore: "col-span-2",
                    name: "charge_code",
                    fieldCustoms: (props) => {
                      return (
                        <TextField
                          disabled={
                            isCreate ||
                            props?.fieldIndex + 1 > data?.fee_charge?.length
                              ? false
                              : true
                          }
                          id={props.name}
                          onChange={(e) => props.setValue(e.target.value)}
                          value={props.value}
                          label={"Charge Code"}
                          variant="standard"
                          sx={{ input: { color: "#7A58BF" } }}
                          fullWidth
                          inputProps={{ maxLength: 10 }}
                          error={
                            props?.thisValue?.value === ""
                              ? false
                              : props?.thisValue?.error
                          }
                          helperText={
                            props?.thisValue?.value === ""
                              ? "Limited " + props.value?.length + "/10"
                              : props?.thisValue?.error
                              ? props?.thisValue?.message
                              : "Limited " + props.value?.length + "/10"
                          }
                        />
                      );
                    },
                    required: true,
                    no_space: true,
                  },
                  {
                    classNameCore: "col-span-1",
                    name: "price_without_post",
                    fieldCustoms: ({ value, setValue, thisValue }) => {
                      return (
                        <DropdownNew
                          value={yesNoMapping(value)}
                          label={`Price Without Debit`}
                          onChange={(e) => {
                            setValue(e.target.value === "YES" ? "Y" : "N");
                          }}
                          options={["YES", "NO"]}
                          error={
                            thisValue?.value === "" ? false : thisValue?.error
                          }
                          helperText={
                            thisValue?.value === ""
                              ? ""
                              : thisValue?.error
                              ? thisValue?.message
                              : ""
                          }
                        />
                      );
                    },
                    required: true,
                  },
                  {
                    classNameCore: "col-span-1",
                    type: "button",

                    render: (props) => {
                      // if (props?.isFirst) {
                      //   return <React.Fragment />;
                      // } else {
                      return (
                        <div
                          className="flex text-value items-center cursor-pointer text-textscb"
                          onClick={() => props.remove()}
                        >
                          <img
                            src={RemoveIcon}
                            className="mr-10px"
                            alt="header_image"
                          />
                          Remove
                        </div>
                      );
                      // }
                    },
                  },

                  {
                    classNameCore: "col-span-6",
                    name: "pricing_frequency_transaction_consolidation",

                    fieldCustoms: ({
                      value,
                      setValue,
                      name,
                      thisValue,
                      fieldIndex,
                      props,
                    }) => {
                      const isQualifer =
                        Object.keys(props?.originalData?.qualifiers || {})
                          ?.length > 0 ||
                        props?.originalData?.qualifiers?.length > 0;

                      return (
                        <div className="  grid grid-cols-3  ">
                          <div className="pr-5">
                            <DropdownObjectWithPlaceholder
                              value={value?.pricing_method}
                              label={`Pricing Method`}
                              onChange={(e) => {
                                const etv = e.target.value;

                                const findDom = document?.getElementById(
                                  `dom_posting_frequency_${fieldIndex}`
                                );

                                if (etv === "SUBSCRIPTION") {
                                  setValue({
                                    ...value,
                                    pricing_method: etv,
                                    pricing_frequency: "MONTHLY",
                                    transaction_consolidation: "NO",
                                  });
                                  findDom.innerHTML = "MONTHLY";
                                } else if (etv === "TRAN_VOLUME") {
                                  setValue({
                                    ...value,
                                    pricing_method: etv,
                                    pricing_frequency: "MONTHLY",
                                    transaction_consolidation: "MONTHLY",
                                  });
                                  findDom.innerHTML = "MONTHLY";
                                } else if (etv === "TRAN_VALUE") {
                                  setValue({
                                    ...value,
                                    pricing_method: etv,
                                    pricing_frequency: "MONTHLY",
                                    transaction_consolidation: "MONTHLY",
                                  });
                                  findDom.innerHTML = "MONTHLY";
                                } else if (etv === "ONE_TIME") {
                                  setValue({
                                    ...value,
                                    pricing_method: etv,
                                    pricing_frequency: "ONE_TIME",
                                    transaction_consolidation: "NO",
                                  });
                                  findDom.innerHTML = "DAILY";
                                }
                                Emitter.emit(`pricing_method_${fieldIndex}`, {
                                  value: etv,
                                });
                              }}
                              options={
                                isQualifer
                                  ? [
                                      {
                                        value: "TRAN_VOLUME",
                                        label: "Transaction Volume",
                                      },
                                      {
                                        value: "TRAN_VALUE",
                                        label: "Transaction Value",
                                      },
                                    ]
                                  : [
                                      {
                                        value: "SUBSCRIPTION",
                                        label: "Subscription",
                                      },
                                      {
                                        value: "TRAN_VOLUME",
                                        label: "Transaction Volume",
                                      },
                                      {
                                        value: "TRAN_VALUE",
                                        label: "Transaction Value",
                                      },
                                      {
                                        value: "ONE_TIME",
                                        label: "One-Time",
                                      },
                                    ]
                              }
                            />
                          </div>
                          <div className="pr-14">
                            <DropdownObjectWithPlaceholder
                              value={value?.pricing_frequency}
                              label={`Pricing Frequency`}
                              onChange={(e) => {
                                const etv = e.target.value;
                                const findDom = document?.getElementById(
                                  `dom_posting_frequency_${fieldIndex}`
                                );
                                if (etv === "DAILY") {
                                  setValue((prev) => {
                                    prev.pricing_frequency = etv;
                                    prev.transaction_consolidation =
                                      "TRANSACTION";
                                    return { ...prev };
                                  });
                                  if (value?.pricing_method === "ONE_TIME") {
                                    findDom.innerHTML = "ONE_TIME";
                                  }
                                  if (value?.pricing_method !== "ONE_TIME") {
                                    findDom.innerHTML = "Monthly";
                                  }
                                } else if (
                                  etv === "MONTHLY" &&
                                  value?.pricing_method === "SUBSCRIPTION"
                                ) {
                                  setValue((prev) => {
                                    prev.pricing_frequency = etv;
                                    prev.transaction_consolidation = "NO";
                                    return { ...prev };
                                  });
                                  findDom.innerHTML = "Monthly";
                                } else if (etv === "MONTHLY") {
                                  setValue((prev) => {
                                    prev.pricing_frequency = etv;
                                    prev.transaction_consolidation = "MONTHLY";
                                    return { ...prev };
                                  });
                                  findDom.innerHTML = "Monthly";
                                } else if (etv === "YEARLY") {
                                  setValue((prev) => {
                                    prev.pricing_frequency = etv;
                                    prev.transaction_consolidation = "NO";
                                    return { ...prev };
                                  });
                                  findDom.innerHTML = "Yearly";
                                }
                              }}
                              options={
                                value?.pricing_method === "SUBSCRIPTION"
                                  ? [
                                      {
                                        label: "Monthly",
                                        value: "MONTHLY",
                                      },
                                      {
                                        label: "Yearly",
                                        value: "YEARLY",
                                      },
                                      // {
                                      //   label: "No",
                                      //   value: "NO",
                                      // },
                                    ]
                                  : value?.pricing_method === "TRAN_VALUE"
                                  ? [
                                      {
                                        label: "Monthly",
                                        value: "MONTHLY",
                                      },
                                      {
                                        label: "Daily",
                                        value: "DAILY",
                                      },
                                      // {
                                      //   label: "No",
                                      //   value: "NO",
                                      // },
                                    ]
                                  : value?.pricing_method === "TRAN_VOLUME"
                                  ? [
                                      {
                                        label: "Monthly",
                                        value: "MONTHLY",
                                      },
                                      {
                                        label: "Daily",
                                        value: "DAILY",
                                      },
                                      // {
                                      //   label: "No",
                                      //   value: "NO",
                                      // },
                                    ]
                                  : value?.pricing_method === "ONE_TIME"
                                  ? [
                                      {
                                        label: "One-Time",
                                        value: "ONE_TIME",
                                      },
                                      // {
                                      //   label: "No",
                                      //   value: "NO",
                                      // },
                                    ]
                                  : []
                              }
                            />
                          </div>
                          <div className="pl-5">
                            <DropdownObjectWithPlaceholder
                              value={value?.transaction_consolidation}
                              label={`Transaction Consolidation`}
                              onChange={(e) => {
                                setValue({
                                  ...value,
                                  transaction_consolidation: e.target.value,
                                });
                              }}
                              options={
                                value?.pricing_method === "SUBSCRIPTION"
                                  ? [
                                      {
                                        label: "No",
                                        value: "NO",
                                      },
                                    ]
                                  : value?.pricing_method === "TRAN_VALUE"
                                  ? [
                                      {
                                        label: "Monthly",
                                        value: "MONTHLY",
                                      },
                                      {
                                        value: "TRANSACTION",
                                        label: "Transaction",
                                      },
                                    ]
                                  : value?.pricing_method === "TRAN_VOLUME"
                                  ? [
                                      {
                                        label: "Monthly",
                                        value: "MONTHLY",
                                      },
                                      {
                                        value: "TRANSACTION",
                                        label: "Transaction",
                                      },
                                    ]
                                  : value?.pricing_method === "ONE_TIME"
                                  ? [
                                      {
                                        label: "No",
                                        value: "NO",
                                      },
                                    ]
                                  : []
                              }
                            />
                          </div>
                        </div>
                      );
                    },
                  },
                  {
                    classNameCore: "col-span-1",
                    name: "vat_type",
                    fieldCustoms: ({ value, setValue, thisValue }) => {
                      return (
                        <DropdownNewWithPlaceholder
                          value={value}
                          label={`VAT`}
                          onChange={(e) => {
                            setValue(e.target.value);
                          }}
                          options={["NO", "INCLUDE", "EXCLUDE"]}
                          error={
                            thisValue?.value === "" ? false : thisValue?.error
                          }
                          helperText={
                            thisValue?.value === ""
                              ? ""
                              : thisValue?.error
                              ? thisValue?.message
                              : ""
                          }
                        />
                      );
                    },
                    required: true,
                  },
                  {
                    classNameCore: "col-span-1",
                    name: "wht_type",
                    fieldCustoms: ({ value, setValue, thisValue }) => {
                      return (
                        <DropdownNewWithPlaceholder
                          value={yesNoMapping(value)}
                          label={`WHT`}
                          onChange={(e) => {
                            setValue(e.target.value === "YES" ? "Y" : "N");
                          }}
                          options={["YES", "NO"]}
                          error={
                            thisValue?.value === "" ? false : thisValue?.error
                          }
                          helperText={
                            thisValue?.value === ""
                              ? ""
                              : thisValue?.error
                              ? thisValue?.message
                              : ""
                          }
                        />
                      );
                    },
                    required: true,
                  },
                  {
                    classNameCore: "col-span-2",
                    name: "posting_type",
                    fieldCustoms: ({ value, setValue, thisValue }) => {
                      return (
                        <DropdownNewWithPlaceholder
                          // disabled={isCreate ? false : true}
                          value={value}
                          label={`Posting Type`}
                          onChange={(e) => {
                            setValue(e.target.value);
                          }}
                          options={["DEBIT", "CREDIT"]}
                          error={
                            thisValue?.value === "" ? false : thisValue?.error
                          }
                          helperText={
                            thisValue?.value === ""
                              ? ""
                              : thisValue?.error
                              ? thisValue?.message
                              : ""
                          }
                        />
                      );
                    },
                    required: true,
                  },
                  {
                    classNameCore: "col-span-2",
                    name: "posting_frequency",
                    fieldCustoms: ({ value, name, fieldIndex }) => {
                      return (
                        <PBFEContentDom
                          isSCBColor={true}
                          label="Posting Frequency"
                          value={value ? value : "-"}
                          name={name}
                          fieldIndex={fieldIndex}
                        />
                      );
                    },
                  },
                  {
                    classNameCore: "col-span-2",
                    name: "posting_consolidation",
                    fieldCustoms: ({ value }) => {
                      return (
                        <PBFEContent
                          isSCBColor={true}
                          label="Posting Consolidation"
                          value={value ? value : "-"}
                        />
                      );
                    },
                  },
                  // {
                  //   classNameCore: "col-span-1",
                  //   name: "monthly_pricing_day",
                  //   fieldCustoms: ({ value }) => {
                  //     return (
                  //       <PBFEContent
                  //         isSCBColor={true}
                  //         label="Monthly Pricing Day"
                  //         value={value ? value : "-"}
                  //       />
                  //     );
                  //   },
                  // },
                  {
                    classNameCore: "col-span-1",
                    name: "monthly_pricing_day",
                    fieldCustoms: ({ value, name }) => {
                      return (
                        <TextField
                          disabled={true}
                          id={name}
                          // onChange={(e) => setValue(e.target.value)}
                          value={value ? value : "-"}
                          label={"Monthly Pricing Day"}
                          variant="standard"
                          sx={{ input: { color: "#7A58BF" } }}
                          fullWidth
                          inputProps={{ maxLength: 1 }}
                        />
                      );
                    },
                  },
                  {
                    classNameCore: "col-span-1",
                    name: "qualifiers",
                    fieldCustoms: ({ value }) => {
                      const hasValue = Object.keys(value || {})?.length > 0;
                      return (
                        <PBFEContent
                          isSCBColor={true}
                          label="Qualifiers"
                          value={hasValue ? "YES" : "NO"}
                        />
                      );
                    },
                  },
                  {
                    classNameCore: "col-span-8",
                    name: "qualifiers",
                    fieldCustoms: ({ value, setValue }) => {
                      if (value) {
                        return (
                          <TableContainer sx={TABLE_STYLE}>
                            <Table sx={TABLE_STYLE}>
                              {value?.length > 0 && (
                                <TableNewRenderQualifiers />
                              )}
                              <NewVersionRenderQualifiersEditMode
                                value={value}
                                setValue={setValue}
                              />
                            </Table>
                          </TableContainer>
                        );
                      } else {
                        return <React.Fragment />;
                      }
                    },
                  },
                  {
                    classNameCore: "col-span-8",
                    name: "fee_value",
                    fieldCustoms: (props) => {
                      const isDisabledCharge =
                        props?.originalData?.pricing_method ===
                          "SUBSCRIPTION" || false;

                      let posibleValueOfQualifires = {};

                      if (data) {
                        const findSelfQualifiers = data?.fee_charge?.find(
                          (item) =>
                            item?.charge_code ===
                            props?.originalData?.charge_code
                        );
                        if (findSelfQualifiers) {
                          posibleValueOfQualifires =
                            findSelfQualifiers?.qualifiers;
                        }
                      }

                      return (
                        <RenderTable
                          isDisabledCharge={isDisabledCharge}
                          value={props?.value}
                          setValue={props?.setValue}
                          fieldIndex={props?.fieldIndex}
                          posibleValueOfQualifires={posibleValueOfQualifires}
                        />
                      );
                    },
                  },
                  {
                    classNameCore: "col-span-4",
                    name: "dr_adv_item_desc",
                    fieldCustoms: ({ value, setValue, name, thisValue }) => {
                      return (
                        <TextField
                          id={name}
                          onChange={(e) => setValue(e.target.value)}
                          value={value}
                          label={`Debit Advice Item Description`}
                          variant="standard"
                          fullWidth
                          sx={{ input: { color: "#7A58BF" } }}
                          inputProps={{ maxLength: 256 }}
                          error={
                            thisValue?.value === "" ? false : thisValue?.error
                          }
                          helperText={
                            thisValue?.value === ""
                              ? "Limited " + value?.length + "/256"
                              : thisValue?.error
                              ? thisValue?.message
                              : "Limited " + value?.length + "/256"
                          }
                        />
                      );
                    },
                    required: true,
                  },

                  {
                    classNameCore: "col-span-2",
                    type: "none",
                    render: () => {
                      return <div></div>;
                    },
                  },
                  {
                    classNameCore: "col-span-1",
                    name: "show_volume_flag",

                    fieldCustoms: ({ value, setValue, thisValue }) => {
                      return (
                        <DropdownNew
                          value={yesNoMapping(value)}
                          label={`Display Volume`}
                          onChange={(e) => {
                            setValue(e.target.value === "YES" ? "Y" : "N");
                          }}
                          options={["YES", "NO"]}
                          error={
                            thisValue?.value === "" ? false : thisValue?.error
                          }
                          helperText={
                            thisValue?.value === ""
                              ? ""
                              : thisValue?.error
                              ? thisValue?.message
                              : ""
                          }
                        />
                      );
                    },
                  },
                  {
                    classNameCore: "col-span-1",
                    name: "show_value_flag",
                    initalValue: "NO",
                    fieldCustoms: ({ value, setValue, thisValue }) => {
                      return (
                        <DropdownNew
                          value={yesNoMapping(value)}
                          label={`Display Value`}
                          onChange={(e) => {
                            setValue(e.target.value === "YES" ? "Y" : "N");
                          }}
                          options={["YES", "NO"]}
                          error={
                            thisValue?.value === "" ? false : thisValue?.error
                          }
                          helperText={
                            thisValue?.value === ""
                              ? ""
                              : thisValue?.error
                              ? thisValue?.message
                              : ""
                          }
                        />
                      );
                    },
                  },
                  {
                    classNameCore:
                      "col-span-8  border-t-2 pt-4 border-gray-200",
                    type: "button",
                    render: (props) => {
                      if (props?.isLast) {
                        return (
                          <FooterButton
                            text={
                              <div className="flex space-x-1">
                                <img
                                  alt=""
                                  className="ml-10px h-15px w-15px cursor-pointer"
                                  src={Plus}
                                />
                                <div className="mx-1">Add Charge Code</div>
                              </div>
                            }
                            action={(e) => {
                              e.stopPropagation();
                              props.add();
                            }}
                          />
                        );
                      } else {
                        return <React.Fragment />;
                      }
                    },
                  },
                ],
              },
              {
                classNameCore: "col-span-8 px-10",
                name: "remark",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Remark"}
                      variant="standard"
                      fullWidth
                      sx={{ input: { color: "#7A58BF" } }}
                      inputProps={{ maxLength: 300 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/300"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/300"
                      }
                    />
                  );
                },
              },
            ]}
            onSubmit={(thisNewData) => {
              SDOnSubmit({
                thisNewData,
                setError,
                setErrorOpen,
                setSubmitData,
                setShowConfirm,
                data,
                taxscheme,
                isCreate,
              });
            }}
            classNameSubmit="hidden"
          />
        </LocalizationProvider>
      </div>
    );
  }
);

export default RenderControllerMemo;
