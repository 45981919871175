import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { PBFEContent } from "../Elements/Text/content";
import { renderDropdownValue } from "../../functions";
import { TYPE_ONE_TIME, TYPE_SUBSCRIPTION } from "../../constants/constant";
import _ from "lodash";

export const PBFEDropdown = ({
  label,
  keyName,
  rows,
  setData,
  index,
  primaryIndex,
  primaryField,
  isEdit = false,
  isNew,
  options = [],
  error = false,
  disabled = false,
  defaultValue
}) => {
  const setValue = (event) => {
    const { value } = event.target;

    setData((prev) => {
      console.log("prev", {
        prev,
        rows,
        index,
        primaryIndex,
        primaryField,
      });
      if (value === "SUBSCRIPTION") {
        let newValue = _.cloneDeep(prev[primaryIndex][primaryField]);
        newValue = newValue?.map((item) => {
          return {
            ...item,
            [keyName]: value,
            pricing_frequency: "",
          };
        });

        // prev[primaryIndex][primaryField][index][keyName] = value;
        prev[primaryIndex][primaryField] = newValue;
        // prev[primaryIndex][primaryField][index]["pricing_frequency"] = "";
        return [...prev];
      }
      if (value === "DAILY") {
        // prev[primaryIndex][primaryField][index][keyName] = value;

        let newValue = _.cloneDeep(prev[primaryIndex][primaryField]);
        newValue = newValue?.map((item) => {
          return {
            ...item,
            [keyName]: value,
            transaction_consolidation: "TRANSACTION",
          };
        });

        // prev[primaryIndex][primaryField][index][keyName] = value;
        prev[primaryIndex][primaryField] = newValue;

        // prev[primaryIndex][primaryField][index]["transaction_consolidation"] =
        //   "TRANSACTION";

        return [...prev];
      } else if (value === "MONTHLY") {
        // prev[primaryIndex][primaryField][index][keyName] = value;

        let newValue = _.cloneDeep(prev[primaryIndex][primaryField]);
        newValue = newValue?.map((item) => {
          return {
            ...item,
            [keyName]: value,
            transaction_consolidation: "MONTHLY",
          };
        });

        // prev[primaryIndex][primaryField][index][keyName] = value;
        prev[primaryIndex][primaryField] = newValue;

        // prev[primaryIndex][primaryField][index]["transaction_consolidation"] =
        //   "MONTHLY";

        return [...prev];
      } else {
        // prev[primaryIndex][primaryField][index][keyName] = value;

        let newValue = _.cloneDeep(prev[primaryIndex][primaryField]);
        newValue = newValue?.map((item) => {
          return {
            ...item,
            [keyName]: value,
          };
        });

        // prev[primaryIndex][primaryField][index][keyName] = value;
        prev[primaryIndex][primaryField] = newValue;

        return [...prev];
      }
    });
  };

  if (!isEdit) {
    return (
      <PBFEContent
        w=" w-20 "
        mode="flex w-full"
        label={label}
        value={rows[keyName] ? renderDropdownValue(keyName, rows[keyName]) : ""}
      />
    );
  }
  if (isNew && rows[keyName] === TYPE_SUBSCRIPTION) {
    return (
      <PBFEContent
        w=" w-20 "
        mode="flex w-full"
        label={label}
        value={rows[keyName] ? renderDropdownValue(keyName, rows[keyName]) : ""}
      />
    );
  }

  if (isNew && rows[keyName] === TYPE_ONE_TIME) {
    return (
      <PBFEContent
        w=" w-20 "
        mode="flex w-full"
        label={label}
        value={rows[keyName] ? renderDropdownValue(keyName, rows[keyName]) : ""}
      />
    );
  }


  return (
    <FormControl fullWidth variant="standard">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        error={error}
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={defaultValue ? defaultValue : rows[keyName]}
        defaultValue={defaultValue ? defaultValue : rows[keyName]}
        sx={{ color: "#7A58BF", fontSize: 16 }}
        label={label}
        onChange={setValue}
      >
        {options?.map((option, index) => {
          return (
            <MenuItem key={index} value={option.id}>
              {renderDropdownValue(keyName, option.id)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
