import { cloneDeep } from "lodash";

export const FieldAddStep = (props, setThisStep) => {
  setThisStep((prev) => {
    let temp = [...prev];
    temp.push(props.fields);
    return [...temp];
  });
};

export const FieldDataToKeepThisStep = (
  props,
  setKeepData,
  data,
  index,
  setAddIndex
) => {
  if (props.grandParentKey === props.parentKey) {
    if (props.FieldReference.current[props.parentIndex][props.parentKey]) {
      let cloneData = cloneDeep(
        props.FieldReference.current[props.parentIndex][props.parentKey]
      );

      cloneData.splice(index + 1, 0, data);

      setKeepData({ [props.parentKey]: cloneDeep(cloneData) });
      setAddIndex(null);
    }
  } else {
    if (
      props.FieldReference.current[props.grandParentIndex][
        props.grandParentKey
      ][props.parentIndex][props.parentKey]
    ) {
      let cloneData = cloneDeep(
        props.FieldReference.current[props.grandParentIndex][
          props.grandParentKey
        ][props.parentIndex][props.parentKey]
      );

      cloneData.splice(index + 1, 0, data);

      const newData = {
        ...props.FieldReference.current[props.grandParentIndex],
        [props.grandParentKey]: {
          ...props.FieldReference.current[props.grandParentIndex][
            props.grandParentKey
          ],
          [props.parentIndex]: {
            ...props.FieldReference.current[props.grandParentIndex][
              props.grandParentKey
            ][props.parentIndex],
            [props.parentKey]: cloneData,
          },
        },
      };
      setKeepData(newData);
      setAddIndex(null);
    }
  }
};
