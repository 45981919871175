import React from "react";

export const PBFEContentDom = ({
  label = "label",
  valueStyle = "",
  mode = "",
  overwriteText = "text-label",
  w = "w-full",
  disable = false,
  isSCBColor = false,
  name = "",
  value,
  fieldIndex,
}) => {
  return (
    <div className={mode} id="PBFEContent">
      <div
        id="PBFEContentLabel"
        className={`${overwriteText} text-textlabel ${w} `}
      >
        {label}
      </div>
      <div
        id={`dom_${name}_${fieldIndex}`}
        className={
          "text-value " +
          (isSCBColor ? "" : " font-bold ") +
          "break-words " +
          valueStyle +
          (disable
            ? " text-disable "
            : isSCBColor
            ? " text-textscb"
            : " text-textprimary")
        }
      >
        {value}
      </div>
    </div>
  );
};
