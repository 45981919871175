import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { PBFECommonTable } from "../../Table/commonTable";
import {
  HISTORY_HEAD_CELLS,
  TAXSCHME_HISTORY_HEAD_CELLS,
} from "../../../constants/constant";
import {
  formateDateWithTimeHourMintues,
  formateDateWithTimeHourMintuesOneline,
} from "../../../utils/util";
import useQuery from "../../../hooks/useQuery";
import PBFEError from "../../Error/alertError";
import Skeleton from "react-loading-skeleton";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const HistoryModal = ({
  open = false,
  action,
  onClose,
  //   data = temp,
  serviceApi,
  params = {},
  query = {},
  callbackNavigate = () => {},
  isTaxScheme = false,
  header = [],
  headCell = [],
  isAppbar = false,
}) => {
  const [page, setPage] = React.useState(0);
  const WrapText = (text) => {
    return <div className="w-64 break-words">{text}</div>;
  };

  const {
    data: rows,
    loading,
    error,
  } = useQuery(
    serviceApi,
    { ...params },
    {
      ...query,
      limit: 100,
    },
    [open]
  );

  if (error) return <PBFEError error={error} />;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      fullWidth={true}
      maxWidth={"xl"}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent sx={{ padding: 0 }}>
        <div className="text-value w-full text-center font-semibold text-textprimary py-10px border-b border-gray-300">
          History
        </div>
        {loading ? (
          <div className="p-fourteen">
            <Skeleton
              containerTestId="react-loading-skeleton"
              height={30}
              width={`100%`}
              count={9}
            />
          </div>
        ) : (
          <div className="px-xxxl py-large grid">
            <PBFECommonTable
              disabledSort={true}
              page={page}
              setPage={setPage}
              showFooter={true}
              headCells={
                headCell?.length > 0
                  ? headCell
                  : isTaxScheme
                  ? TAXSCHME_HISTORY_HEAD_CELLS
                  : HISTORY_HEAD_CELLS
              }
              rows={rows}
              showFields={
                header?.length > 0
                  ? header
                  : isTaxScheme
                  ? ["create_datetime", "create_user", "action", "remark"]
                  : [
                      "approve_date",
                      "checker",
                      "request_type",
                      "maker",
                      "request_date",
                      "remark",
                    ]
              }
              onClickRow={(row) => callbackNavigate(row)}
              formatControl={
                isTaxScheme || isAppbar
                  ? [
                      formateDateWithTimeHourMintuesOneline,
                      null,
                      null,
                      WrapText,
                    ]
                  : [
                      formateDateWithTimeHourMintuesOneline,
                      null,
                      null,
                      null,
                      formateDateWithTimeHourMintuesOneline,
                      WrapText,
                    ]
              }
              rowsPerPage={15}
              showNoData={true}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 2, fontSize: 14 }}>
        <Button variant="contained" onClick={onClose}>
          DONE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
