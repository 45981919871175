import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@mui/material";
import React from "react";
import { OPTIONS_TEMPLATE } from "../../../constants/constant";
import { FilterDropdown } from "../../Input/filterDropdown";
import { ErrorPopUp } from "./accountModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const UploadModal = ({
  action,
  open = false,
  title = "Upload Data",
  onClose,
  error = false,
  loadingUpload = false,
  setTemplateUpload,
  templateUpload,
  validateUpload,
  setValidateUpload,
  file,
  setFile,
  errorMessage = "",
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent sx={{ width: "600px", padding: 0 }}>
        <div className="text-value w-full text-center font-semibold text-textprimary py-10px border-b border-gray-300">
          {title}
        </div>
        {error && <ErrorPopUp error={errorMessage} />}
        <div className="px-xxxl py-large grid">
          <div className="text-textgray text-12px mb-10px">Template</div>
          <FilterDropdown
            label="Choose Template"
            value={templateUpload}
            options={OPTIONS_TEMPLATE.slice(1, OPTIONS_TEMPLATE.length)}
            onChange={(e) => {
              e.stopPropagation();
              setTemplateUpload(e.target.value);
              setValidateUpload((prev) => {
                return {
                  ...prev,
                  template: null,
                };
              });
            }}
            width={"150px"}
            validateError={validateUpload?.template}
          />
          <div className="text-textgray text-12px mt-25px mb-10px">File</div>
          <div className={``}>
            <label
              className=" bg-purpleLight inline-block rounded-full py-6px px-12px text-14px cursor-pointer	"
              onChange={(e) => {
                if (e.target.files[0]) {
                  setFile(e.target.files[0]);
                  setValidateUpload((prev) => {
                    return {
                      ...prev,
                      file: null,
                    };
                  });
                }
              }}
              htmlFor="formId"
            >
              <input
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                name=""
                type="file"
                id="formId"
                hidden
              />

              <div
                className={` inline-block cursor-pointer ${
                  file ? " w-auto " : " w-20 "
                } `}
              >
                {file === "" ? "Choose File" : file?.name}
              </div>
            </label>
          </div>
          <div className="text-textgray text-12px mt-10px mb-10px">
            .xls,.xlsx only
          </div>
          <div className="text-red-400 text-sm ml-2">{validateUpload.file}</div>
        </div>
      </DialogContent>
      <DialogActions sx={{ padding: 2, fontSize: 14 }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          disabled={loadingUpload}
          // loadingPosition="start"
          loading={loadingUpload}
          variant="contained"
          onClick={action}
        >
          UPLOAD
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
