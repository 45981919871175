import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  MILD_PURPLE,
  NORMAL_FONT_SIZE,
  SMALL_FONT_SIZE,
  TEXT_PRIMARY,
  TABLE_STYLE,
} from "../../constants/style";
import _ from "lodash";
import {
  POSTING_CONSOLIDATION,
  POSTING_FREQUENCY,
  POSTING_TYPE,
  PRICING_METHOD,
  PRICING_FREQUENCY,
  TRANSACTION_CONSOLIDATION,
  VAT_TYPE,
  QUALIFIER_TYPE,
  WHT_TYPE,
} from "../../constants/constant";
import { formatEmpty } from "../../utils/util";
import { onClickScroll } from "../../functions";

export const PBFEChargesTable = ({
  data,
  type,
  displayQualifier = true,
  displayPricingDate = false,
}) => {
  // const data_charge =
  //  type === "Service" ? data?.fee_charge : data?.offer_fee_charge;

  return (
    <div className="border-t ">
      <TableContainer id="TableContainer">
        <Table sx={TABLE_STYLE}>
          <TableHead>
            <TableRow>
              <TableCell id="Charges_Code">Charges Code</TableCell>
              <TableCell id="Charges_Name">Charges Name</TableCell>
              <TableCell id="Pricing_Method">Pricing Method</TableCell>
              <TableCell id="Pricing_Frequency">Pricing Frequency</TableCell>
              <TableCell id="Transaction_Consolidation">
                Transaction Consolidation
              </TableCell>
              <TableCell id="Posting_Type">Posting Type</TableCell>
              <TableCell id="Posting_Frequency">Posting Frequency</TableCell>
              <TableCell id="Posting_Consolidation">
                Posting Consolidation
              </TableCell>
              {displayPricingDate && (
                <TableCell id="Pricing_Date">Pricing Date</TableCell>
              )}
              <TableCell id="VAT">VAT</TableCell>
              <TableCell id="WHT">WHT</TableCell>
              {displayQualifier && (
                <TableCell id="Qualifier">Qualifier</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody id="TableBody">
            {data?.map((charge, index) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    id="Value_Charges_Code"
                    sx={{
                      minWidth: "70px",
                      color: MILD_PURPLE,
                      fontWeight: 600,
                      fontSize: NORMAL_FONT_SIZE,
                    }}
                  >
                    <div
                      className="cursor-pointer"
                      onClick={() => onClickScroll(charge.charge_code)}
                    >
                      {charge?.charge_code}
                    </div>
                  </TableCell>
                  <TableCell
                    id="Value_Charges_Name"
                    sx={{
                      //  minWidth: "199px",
                      color: TEXT_PRIMARY,
                      fontWeight: 600,
                      fontSize: NORMAL_FONT_SIZE,
                    }}
                  >
                    {type === "Service"
                      ? charge?.charge_name
                      : charge?.charge_name}
                  </TableCell>
                  <TableCell
                    id="Value_Pricing_Method"
                    sx={{
                      //   minWidth: "110px",
                      color: TEXT_PRIMARY,
                      fontWeight: 400,
                      fontSize: NORMAL_FONT_SIZE,
                    }}
                  >
                    {PRICING_METHOD[charge?.pricing_method]}
                  </TableCell>
                  <TableCell
                    id="Value_Pricing_Frequency"
                    sx={{
                      minWidth: "75px",
                      color: TEXT_PRIMARY,
                      fontWeight: 400,
                      fontSize: NORMAL_FONT_SIZE,
                    }}
                  >
                    {PRICING_FREQUENCY[charge?.pricing_frequency]}
                  </TableCell>
                  <TableCell
                    id="Value_Transaction_Consolidation"
                    sx={{
                      minWidth: "95px",
                      color: TEXT_PRIMARY,
                      fontWeight: 400,
                      fontSize: NORMAL_FONT_SIZE,
                    }}
                  >
                    {
                      TRANSACTION_CONSOLIDATION[
                        charge?.transaction_consolidation
                      ]
                    }
                  </TableCell>
                  <TableCell
                    id="Value_Posting_Type"
                    sx={{
                      minWidth: "75px",
                      color: TEXT_PRIMARY,
                      fontWeight: 400,
                      fontSize: NORMAL_FONT_SIZE,
                    }}
                  >
                    {POSTING_TYPE[charge?.posting_type]}
                  </TableCell>
                  <TableCell
                    id="Value_Posting_Frequency"
                    sx={{
                      minWidth: "80px",
                      color: TEXT_PRIMARY,
                      fontWeight: 400,
                      fontSize: NORMAL_FONT_SIZE,
                    }}
                  >
                    {POSTING_FREQUENCY[charge?.posting_frequency]}
                  </TableCell>
                  <TableCell
                    id="Value_Posting_Consolidation"
                    sx={{
                      minWidth: "95px",
                      color: TEXT_PRIMARY,
                      fontWeight: 400,
                      fontSize: NORMAL_FONT_SIZE,
                    }}
                  >
                    {POSTING_CONSOLIDATION[charge?.posting_consolidation]}
                  </TableCell>
                  {displayPricingDate && (
                    <TableCell
                      id="Value_Pricing_Date"
                      sx={{
                        minWidth: "95px",
                        color: TEXT_PRIMARY,
                        fontWeight: 400,
                        fontSize: NORMAL_FONT_SIZE,
                      }}
                    >
                      {formatEmpty(charge?.pricing_date)}
                    </TableCell>
                  )}
                  <TableCell
                    id="Value_VAT_Type"
                    sx={{
                      minWidth: "50px",
                      color: TEXT_PRIMARY,
                      fontWeight: 400,
                      fontSize: NORMAL_FONT_SIZE,
                    }}
                  >
                    {VAT_TYPE[charge?.vat_type]}
                  </TableCell>
                  <TableCell
                    id="Value_WHT_Type"
                    sx={{
                      minWidth: "50px",
                      color: TEXT_PRIMARY,
                      fontWeight: 400,
                      fontSize: NORMAL_FONT_SIZE,
                    }}
                  >
                    {WHT_TYPE[charge?.wht_type]}
                  </TableCell>
                  {displayQualifier && (
                    <TableCell
                      id="Value_Qualifier"
                      sx={{
                        color: TEXT_PRIMARY,
                        fontWeight: 600,
                        fontSize: SMALL_FONT_SIZE,
                      }}
                    >
                      {charge?.qualifiers && (
                        <div className="flex flex-row">
                          {Object.keys(charge?.qualifiers).map(
                            (charge_type, index) => (
                              <div
                                key={index}
                                className="p-xs  bg-gray-200 rounded-full mr-4px text-center flex-shrink-0"
                              >
                                {QUALIFIER_TYPE[charge_type]
                                  ? QUALIFIER_TYPE[charge_type]
                                  : _.capitalize(charge_type)}
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
