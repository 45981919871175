import * as React from "react";
import { FunctionCore } from "./core";
import { TEXT_AUTHOR } from "./utils/Field.Constant";
import { FunctionSubmit } from "./utils/Field.Submit";

export const Controllers = ({
  mode = "normal" || "object",
  fields = [],
  onCancel,
  onSubmit,
  loading = false,
  labelSubmit = "Submit",
  labelCancel = "Cancel",
  classNameCancel = "",
  classNameSubmit = "",
  classNameWrapper = "",
  loadData = {},
  language = "TH",
  firstButton = "cancel",
  renderAlertError = false,
  showError = true,
  error = "Error",
  resetAfterSubmit = false,
  showSymbol = true,
}) => {
  console.warn = function filterWarnings() {
    return;
  };
  console.error = function filterErrors() {
    return;
  };

  const [reset, setReset] = React.useState(false);

  const ARRAY = new Array("");
  const FieldReference = React.useRef(ARRAY);

  const FunctionReset = () => {
    setReset(true);
    setTimeout(() => {
      setReset(false);
    }, 100);
  };

  return (
    <React.Fragment>
      <FunctionCore
        reset={reset}
        FunctionReset={FunctionReset}
        mode={mode}
        fields={fields}
        key={`${TEXT_AUTHOR}-core-${mode}`}
        FieldReference={FieldReference}
        loadData={loadData}
        language={language}
        fieldIndex={0}
        grandParentIndex={0}
        grandParentKey={""}
        parentIndex={0}
        parentKey={""}
        showError={showError}
        showSymbol={showSymbol}
      />
      <div
        style={{
          marginTop: "10px",
        }}
        className={`${TEXT_AUTHOR}-wrapper ${classNameWrapper}`}
      >
        {firstButton === "cancel" ? (
          <React.Fragment>
            {onCancel && (
              <button
                id={`${TEXT_AUTHOR}-button-cancel`}
                className={`${TEXT_AUTHOR}-cancel ${classNameCancel}`}
                onClick={(e) => {
                  if (onCancel) {
                    onCancel({ FunctionReset });
                  } else {
                    return;
                  }
                }}
              >
                {labelCancel}
              </button>
            )}
            {onSubmit && (
              <button
                id={`${TEXT_AUTHOR}-button-submit`}
                className={`${TEXT_AUTHOR}-submit ${classNameSubmit}`}
                onClick={() => {
                  if (loading) {
                    return;
                  } else {
                    FunctionSubmit(
                      fields,
                      FieldReference,
                      onSubmit,
                      renderAlertError,
                      error,
                      FunctionReset,
                      resetAfterSubmit
                    );
                  }
                }}
              >
                {labelSubmit}
              </button>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {onSubmit && (
              <button
                id={`${TEXT_AUTHOR}-button-submit`}
                className={`${TEXT_AUTHOR}-submit ${classNameSubmit}`}
                onClick={() => {
                  if (loading) {
                    return;
                  } else {
                    FunctionSubmit(
                      fields,
                      FieldReference,
                      onSubmit,
                      renderAlertError,
                      error,
                      FunctionReset,
                      resetAfterSubmit
                    );
                  }
                }}
              >
                {labelSubmit}
              </button>
            )}
            {onCancel && (
              <button
                id={`${TEXT_AUTHOR}-button-cancel`}
                className={`${TEXT_AUTHOR}-cancel ${classNameCancel}`}
                onClick={() => {
                  if (onCancel) {
                    onCancel({ FunctionReset });
                  } else {
                    return;
                  }
                }}
              >
                {labelCancel}
              </button>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
