import NotFoundIcon from "../../assets/icon/not_found.svg";

const NoRecordFound = () => {
  return (
    <div id="NoRecordFound" className="m-auto pt-xl">
      <img
        className="w-28px h-28px m-auto mb-5px"
        alt="error"
        src={NotFoundIcon}
      />
      <div className="text-value  w-full text-center text-textsecoundary">
        No Record Found.
      </div>
    </div>
  );
};

export default NoRecordFound;
