import React from "react";
import { PBFELoading } from "../../../components/Elements/Loading/loading";
import { PBFEContent } from "../../../components/Elements/Text/content";
import PBFEError from "../../../components/Error/alertError";
import PBFEInvalid from "../../../components/Error/invalidData";
import { PBFEAccordionMain } from "../../../components/Panel/Accordion/accordionMain";
import useQuery from "../../../hooks/useQuery";
import { formatDateApi } from "../../../utils/util";
import offersService from "../../../api/offersService";
import CustomerRenderOffer from "./Customer.RenderOffer";
import { TextField } from "@mui/material";

const CustomerRenderAddNewOffer = ({
  customer,
  offer_code,
  accountTable,
  isAddActionOffer,
  debitProfile,
  setDebitProfile,
  originalData,
  errorState,
  setErrorState,
  setRadioOffer,
  is_maker,
  checkConfirmError,
  setCheckConfirmError,
}) => {
  const [remark, setRemark] = React.useState("");
  const prePareData = (data) => {
    return {
      ...data,
      from_date: formatDateApi(),
      to_date: "",
      offer_fee_service: data?.offer_fee_service?.map((service) => {
        return {
          ...service,
          fee_charge: service.offer_fee_charge,
        };
      }),
    };
  };

  const { data, error, loading } = useQuery(
    offersService.getOffersInfo,
    {
      offer_code: offer_code,
    },
    {},
    [],
    { prePareData, checkFirstLoad: true }
  );

  React.useEffect(() => {
    setRadioOffer(null);
  }, []);

  if (loading)
    return (
      <div className="h-screen m-auto">
        <PBFELoading />
      </div>
    );

  if (error) return <PBFEError error={error} />;
  return (
    <div className="w-full">
      <PBFEInvalid
        isShow={checkConfirmError && errorState.length > 0}
        message=""
        setOpen={setCheckConfirmError}
        open={checkConfirmError}
        errorState={errorState}
      />
      <div className="w-full overflow-auto">
        <div className="grid grid-cols-2 w-full px-xl my-20px">
          <PBFEContent label="Customer Name" value={customer?.customer_name} />
          <PBFEContent label="Customer ID" value={customer?.customer_id} />
        </div>
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionMain
          label="Offer"
          detail={
            isAddActionOffer ? (
              <div></div>
            ) : (
              <CustomerRenderOffer
                errorState={errorState}
                checkConfirmError={checkConfirmError}
                setCheckConfirmError={setCheckConfirmError}
                is_maker={is_maker}
                customer={customer}
                originalData={originalData}
                debitProfile={debitProfile}
                setDebitProfile={setDebitProfile}
                setErrorState={setErrorState}
                remark={remark}
                data={{
                  offer: [
                    {
                      ...data,
                      posting_account: [],
                      service: data.offer_fee_service,
                    },
                  ],
                }}
                isEdit={true}
                isNew={true}
                accountTable={accountTable}
              />
            )
          }
        />
        <div className="ml-40px mr-25px">
          <TextField
            id="remark"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            label="REMARK"
            variant="filled"
            helperText={"Limited " + remark?.length + "/300"}
            fullWidth
            inputProps={{ maxLength: 300 }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerRenderAddNewOffer;
