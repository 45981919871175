import * as React from "react";

export const COLOR = {
  RED: "#FA3232",
  RED_LIGHT: "#f0817f",
  WHITE: "#FFFFFF",
  GRAY: "#F5F5F5",
  GREEN: "#00B300",
  ORANGE: "#FF9100",
};

export const STYLE_REQUIRED_RED_STAR = {
  color: COLOR.RED,
};

export const STYLE_REQUIRED_FIELD_FLEX = {
  display: "flex",
};

export const STYLE_FIELD_ERROR = {
  color: COLOR.WHITE,
  display: "flex",
  backgroundColor: COLOR.RED,
  borderRadius: "5px",
  padding: "7px",
};

export const STYLE_FIELD_WARNING = {
  color: COLOR.WHITE,
  display: "flex",
  backgroundColor: COLOR.RED,
  borderRadius: "5px",
  padding: "7px",
};

export const STYLE_FIELD_SUCCESS = {
  color: COLOR.WHITE,
  display: "flex",
  backgroundColor: COLOR.RED,
  borderRadius: "5px",
  padding: "7px",
};
