import { PBFEContent } from "../../../components/Elements/Text/content";
import {
  MAPPING_CUSTOMER_TYPE,
  MAPPING_GENDER,
  MAPPING_PAYROLL_INDICATOR,
} from "../../../constants/constant";
import { formateDate, formatEmpty } from "../../../utils/util";

const CustomerRenderGeneralInfomation = ({ data }) => {
  return (
    <div className="grid grid-cols-4 gap-y-6 gap-x-2">
      <PBFEContent
        label="Customer Title - Thai"
        value={formatEmpty(data.title_thai)}
      />
      <PBFEContent
        label="Customer Full Name (Eng)"
        value={formatEmpty(data.full_name_eng)}
      />
      <div className="col-span-2">
        <PBFEContent
          label="Customer Full Name (Thai)"
          value={formatEmpty(data.full_name_thai)}
        />
      </div>
      <div className="col-span-2">
        <PBFEContent
          label="Date of Incorporation/Date of Birth"
          value={formateDate(data?.date_of_birth)}
        />
      </div>
      <div className="col-span-2">
        <PBFEContent
          label="Gender"
          value={formatEmpty(MAPPING_GENDER[data.gender])}
        />
      </div>
      <PBFEContent
        label="Entry Date"
        value={formateDate(data.entry_date, "-")}
      />
      <PBFEContent
        label="Application Date"
        value={formateDate(data.application_date)}
      />
      <PBFEContent label="Open Date" value={formateDate(data.open_date)} />
      <PBFEContent label="ID Type" value={formatEmpty(data.id_type)} />
      <div className="col-span-2">
        <PBFEContent
          label="Payroll Indicator"
          value={formatEmpty(MAPPING_PAYROLL_INDICATOR[data.payroll_indicator])}
        />
      </div>
      <div className="col-span-2">
        <PBFEContent
          label="Customer Type"
          value={formatEmpty(MAPPING_CUSTOMER_TYPE[data.customer_type])}
        />
      </div>

      <PBFEContent
        label="Contact Name1"
        value={formatEmpty(data.contact_name1)}
      />
      <PBFEContent
        label="Contact Name2"
        value={formatEmpty(data.contact_name2)}
      />
      <PBFEContent
        label="Contact Number1"
        value={formatEmpty(data.contact_number1)}
      />
      <PBFEContent
        label="Contact Number2"
        value={formatEmpty(data.contact_number2)}
      />
      <div className="col-span-2">
        <PBFEContent
          label="User Defined Status"
          value={formatEmpty(data.status)}
        />
      </div>
      <div className="col-span-2">
        <PBFEContent
          label="Sub Segment"
          value={formatEmpty(data.sub_segment_code)}
        />
      </div>
    </div>
  );
};

export default CustomerRenderGeneralInfomation;
