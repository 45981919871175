import { TextField } from "@mui/material";
import { useState, useEffect, memo } from "react";
import { PBFELoading } from "../../../components/Elements/Loading/loading";
import { PBFEContent } from "../../../components/Elements/Text/content";
import PBFEError from "../../../components/Error/alertError";
import { PBFEAccordionSearch } from "../../../components/Panel/Accordion/accordionMain";
import { PBFECommonTable } from "../../../components/Table/commonTable";
import PBFESideTable from "../../../components/Table/sideTable";
import RemoveIcon from "../../../assets/icon/remove.svg";
import Plus from "../../../assets/icon/plus.svg";
import userGroupService from "../../../api/userGroupService";
import serviceService from "../../../api/serviceService";

import {
  MINIMUM_SEARCH_ACTIVE,
  PATH_USER_GROUP_CREATE,
  PROFILE_PATH_USER_GROUP_NAME,
  SERVICE_HEAD_CELL,
  USER_HEAD_CELL,
  USER_ROLE_MAPPING,
} from "../../../constants/constant";
import useQuery from "../../../hooks/useQuery";
import { AsyncModalService } from "../../../components/Panel/Modal/asyncModalService";
import { checkValidateNameOfUserGroupIsDupicate } from "../../../functions";
import PBFEInvalid from "../../../components/Error/invalidData";
import { useLocation } from "react-router-dom";

const FormatUserGroupData = (array_input) => {
  if (!array_input) {
    return [];
  } else {
    const res = array_input.map((item) => {
      return {
        name: item.user_group_name,
        id: item.user_group_id,
      };
    });
    return res;
  }
};

const FormatHookUserDuplicate = (array_user) => {
  let find_object_user_to_be_array = array_user.reduce(function (
    previous,
    current
  ) {
    let occurs = previous.reduce(function (
      previous_item,
      current_item,
      current_index
    ) {
      return current_item.user_id === current.user_id
        ? current_index
        : previous_item;
    },
    -1);

    if (occurs >= 0) {
      previous[occurs].user_role = previous[occurs].user_role.concat(
        current.user_role
      );
    } else {
      let obj = {
        user_name: current.user_name,
        user_id: current.user_id,
        user_role: [current.user_role],
        status: current.status || "Active Mock",
      };
      previous = previous.concat([obj]);
    }

    return previous;
  },
  []);

  return find_object_user_to_be_array;
};

const FormatFilterActionDuplicate = (array_data) => {
  if (array_data) {
    // const res = array_data?.filter((o) => {
    //   return o.Action !== "DELETE";
    // });
    return array_data;
    // return res;
  } else {
    return [];
  }
};

const FormatHookRoles = (data_input) => {
  if (!data_input) return <div></div>;
  return (
    <div className="flex">
      {data_input?.map((row, index) => {
        return (
          <div
            className=" bg-gray-200 p-2 rounded-3xl text-14px mr-2"
            key={`user-group-${index}`}
          >
            {USER_ROLE_MAPPING[row]}
          </div>
        );
      })}
    </div>
  );
};

const FormatHookStatus = (data_input) => {
  return (
    <div className="flex">
      <div
        className={`
    ${
      data_input === "ACTIVE" || data_input === "Active"
        ? "bg-green-500"
        : "bg-gray-400"
    }  rounded-full w-3 h-3 mr-2 my-auto`}
      ></div>
      <div className="my-auto">{data_input}</div>
    </div>
  );
};

const FormatDataBodyUserGroup = (id, name, array_input, error) => {
  if (id && array_input) {
    const res = array_input?.map((row) => {
      return row.service_code;
    });

    return { id, name, service: res, error: error };
  } else {
    const res = array_input?.map((row) => {
      return row.service_code;
    });

    return { user_group_name: name, service: res, error: error };
  }
};

const Info = ({
  selected,
  actionAppbar,
  isEdit,
  setDataUserGroup,
  allDataName = [],
  isCreate,
  setErrorDesc,
  errorDesc,
}) => {
  const [servicesData, setServicesData] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [openService, setOpenService] = useState(false);
  const [prepireDataWhenSearch, setPrePairDataWhenSearch] = useState([]);
  const [isSetBack, setIsSetBack] = useState(false);
  const [pageService, setPageService] = useState(0);
  const [pageGroup, setPageGroup] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [accordion, setAccordion] = useState(true);
  const [defualtName, setDefualtName] = useState("");
  const [searched, setSearched] = useState("");

  const getErrorName = checkValidateNameOfUserGroupIsDupicate(
    allDataName,
    groupName,
    defualtName
  );

  const {
    data: dataInfo,
    loading: loadingInfo,
    error: errorInfo,
  } = useQuery(
    userGroupService.getUserGroupInfo,
    {
      id: selected?.id,
    },
    {},
    [selected],
    { checkFirstLoad: JSON.stringify(selected?.id)?.length > 0 }
  );

  useEffect(() => {
    setErrorDesc(false);
  }, [selected]);

  useEffect(() => {
    if (isSetBack) {
      setDataUserGroup(
        FormatDataBodyUserGroup(
          dataInfo?.user_group_id,
          groupName,
          prepireDataWhenSearch,
          getErrorName
        )
      );
    } else if (servicesData) {
      setDataUserGroup(
        FormatDataBodyUserGroup(
          dataInfo?.user_group_id,
          groupName,
          servicesData,
          getErrorName
        )
      );
    } else {
      return;
    }
  }, [servicesData, groupName]);

  useEffect(() => {
    if (dataInfo) {
      setServicesData(dataInfo?.service);
      setGroupsData(dataInfo?.user_role);
      setGroupName(dataInfo?.user_group_name);
      setDefualtName(dataInfo?.user_group_name);
    }
  }, [dataInfo, isEdit, isCreate]);

  const onClickDeleteService = (value) => {
    const findIndexToAddDelete = servicesData.findIndex((d) => {
      return d.service_code === value;
    });

    setServicesData((previous) => {
      // const infoFieldName = "service";
      // previous[infoFieldName][findIndexToAddDelete].Action = "DELETE";
      // previous[infoFieldName].splice(findIndexToAddDelete, 1);
      previous.splice(findIndexToAddDelete, 1);
      return [...previous];
    });
  };

  const requestSearchUser = (event) => {
    setPageGroup(0);
    const value = event.target.value;
    if (value?.length < MINIMUM_SEARCH_ACTIVE) {
      setGroupsData(dataInfo?.user_role);
    } else {
      const filteredRowsUser = dataInfo?.user_role?.filter((row) => {
        return (
          row.user_id.toLowerCase().includes(value.toLowerCase()) ||
          row.user_name.toLowerCase().includes(value.toLowerCase())
        );
      });
      setGroupsData(filteredRowsUser);
    }
  };

  const requestSearchService = (
    isEdit = false,
    event,
    prepireDataWhenSearch = [],
    isSetBack = false,
    setIsSetBack = () => {},
    setServicesData = () => {},
    setPrePairDataWhenSearch = () => {}
  ) => {
    setPageService(0);
    const value = event.target.value;
    setSearched(value);
    if (value?.length < MINIMUM_SEARCH_ACTIVE) {
      if (isEdit) {
        if (prepireDataWhenSearch?.length > 0 && isSetBack) {
          setServicesData(prepireDataWhenSearch);
          setPrePairDataWhenSearch([]);
          setIsSetBack(false);
        } else {
          return;
        }
      } else {
        setServicesData(dataInfo?.service);
      }
    } else {
      if (isEdit) {
        if (isSetBack) {
          const filteredRowsService = prepireDataWhenSearch?.filter((row) => {
            return (
              row.service_code.toLowerCase().includes(value.toLowerCase()) ||
              String(row.service_name)
                .toLowerCase()
                .includes(value.toLowerCase())
            );
          });

          setServicesData(filteredRowsService);
        } else {
          const filteredRowsService = servicesData?.filter((row) => {
            return (
              row.service_code.toLowerCase().includes(value.toLowerCase()) ||
              String(row.service_name)
                .toLowerCase()
                .includes(value.toLowerCase())
            );
          });
          setIsSetBack(true);
          setPrePairDataWhenSearch(servicesData);
          setServicesData(filteredRowsService);
        }
      } else {
        const filteredRowsService = dataInfo?.service?.filter((row) => {
          return (
            row.service_code.toLowerCase().includes(value.toLowerCase()) ||
            String(row.service_name).toLowerCase().includes(value.toLowerCase())
          );
        });
        setServicesData(filteredRowsService);
      }
    }
  };

  const deleteService = (value) => {
    return (
      <div className="flex flex-row justify-between w-full">
        <div>{value}</div>
        <img
          onClick={(e) => {
            e.stopPropagation();
            onClickDeleteService(value);
          }}
          src={RemoveIcon}
        />
      </div>
    );
  };

  const addService = () => {
    setOpenService(true);
  };

  if (loadingInfo) return <PBFELoading />;
  if (errorInfo && actionAppbar !== PATH_USER_GROUP_CREATE) {
    return <PBFEError error={errorInfo} />;
  }
  if (isEdit || isCreate)
    return (
      <div>
        <PBFEInvalid
          isShow={errorDesc}
          message=""
          setOpen={setErrorDesc}
          open={errorDesc}
          errorState={[]}
          pt="0"
          mt="0"
        />
        <div className="pl-xl pr-xl">
          <TextField
            sx={{
              input: { color: "#7A58BF" },
              marginTop: "20px",
              marginBottom: "20px",
              width: "100%",
            }}
            variant="standard"
            label="Group Name"
            error={getErrorName.error}
            helperText={getErrorName.message}
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>
        <PBFEAccordionSearch
          placeholderSearch="Search Service Name / Code"
          expanded={accordion}
          onchangeAccordion={() => setAccordion(!accordion)}
          requestSearch={(event) =>
            requestSearchService(
              true,
              event,
              prepireDataWhenSearch,
              isSetBack,
              setIsSetBack,
              setServicesData,
              setPrePairDataWhenSearch
            )
          }
          label="Service"
          button={{ img: Plus, name: "ADD SERVICE", action: addService }}
          detail={
            <PBFECommonTable
              showFooter={true}
              orderByDefault="service_code"
              headCells={SERVICE_HEAD_CELL}
              formatControl={[null, deleteService]}
              rows={FormatFilterActionDuplicate(servicesData)}
              page={pageService}
              setPage={setPageService}
              showNoData={true}
              showFields={["service_name", "service_code"]}
            />
          }
        />
        {openService && (
          <AsyncModalService
            title="Add Service"
            open={openService}
            setOpen={setOpenService}
            onClose={() => setOpenService(false)}
            dataFromApi={servicesData}
            placeholder="Search Service Name / Code"
            serviceApi={serviceService.getService}
            setData={setServicesData}
            setServicesData={setServicesData}
            setIsSetBack={setIsSetBack}
            isSetBack={isSetBack}
            prepireDataWhenSearch={prepireDataWhenSearch}
            setPrePairDataWhenSearch={setPrePairDataWhenSearch}
            requestSearch={requestSearchService}
            setAccordion={setAccordion}
          />
        )}
      </div>
    );

  return (
    <div className="w-full overflow-auto">
      <div className="w-full px-xl my-20px">
        <PBFEContent label="Group Name" value={dataInfo?.user_group_name} />
      </div>
      <div className="h-10px bg-gray-100"></div>
      <PBFEAccordionSearch
        placeholderSearch="Search Staff Name / ID"
        requestSearch={requestSearchUser}
        label="User"
        detail={
          <PBFECommonTable
            disabledSortList={["user_role"]}
            showFooter={true}
            orderByDefault="user_name"
            formatControl={[null, null, FormatHookRoles, FormatHookStatus]}
            headCells={USER_HEAD_CELL}
            rows={FormatHookUserDuplicate(groupsData)}
            page={pageGroup}
            setPage={setPageGroup}
            showNoData={true}
          />
        }
      />
      <div className="h-10px bg-gray-100"></div>
      <PBFEAccordionSearch
        placeholderSearch="Search Service Name / Code"
        requestSearch={(event) =>
          requestSearchService(
            false,
            event,
            prepireDataWhenSearch,
            isSetBack,
            setIsSetBack,
            setServicesData,
            setPrePairDataWhenSearch
          )
        }
        label="Service"
        detail={
          <PBFECommonTable
            showFooter={true}
            orderByDefault="service_name"
            headCells={SERVICE_HEAD_CELL}
            rows={servicesData}
            page={pageService}
            setPage={setPageService}
            showFields={["service_name", "service_code"]}
            showNoData={true}
          />
        }
      />
    </div>
  );
};

const UserGroup = ({
  setRouteName,
  setActionAppbar,
  setGroupSetting: setIsEdit,
  groupSetting: isEdit,
  setDataUserGroup,
  dataUserGroup,
  actionAppbar,
  isCreate = false,
  setIsCreate = () => {},
  setErrorDesc,
  errorDesc,
}) => {
  const location = useLocation();

  const [selected, setSelected] = useState("");
  const [searched, setSearched] = useState("");
  const [page, setPage] = useState(0);

  const { data, loading, error } = useQuery(
    userGroupService.getUserGroup,
    {},
    {}
  );

  const originalData = FormatUserGroupData(data);
  const [rows, setRows] = useState(originalData);

  useEffect(() => {
    if (data) {
      setRows(FormatUserGroupData(data));
    }
  }, [data]);

  const setInfo = (row) => {
    console.log("set info", row);
    setIsEdit(false);
    setIsCreate(false);
    setRouteName([
      {
        title: PROFILE_PATH_USER_GROUP_NAME,
        path: "/usergroup",
        setSelected: setSelected,
        action: () => {
          setSearched("");
          setRows(originalData);
        },
        check: true,
      },
      {
        title: row.name,
      },
    ]);
    setSelected(row);
    setActionAppbar(PROFILE_PATH_USER_GROUP_NAME);
  };

  useEffect(() => {
    if (selected) {
      setActionAppbar(PROFILE_PATH_USER_GROUP_NAME);
    } else {
      setActionAppbar(PATH_USER_GROUP_CREATE);
    }
  }, [selected]);

  useEffect(() => {
    if (!selected && isCreate) {
      setRouteName([
        {
          title: PROFILE_PATH_USER_GROUP_NAME,
          path: "/usergroup",
          setSelected: () => {
            window.location.reload();
          },
          check: true,
          // action: () => {
          //   setIsCreate(false);
          //   setSearched("");
          //   setRows(originalData);
          // },
        },
        { title: "NEW USER GROUP" },
      ]);
    } else {
      if (!selected) {
        setRouteName([
          {
            title: PROFILE_PATH_USER_GROUP_NAME,
            path: "/usergroup",
            setSelected: () => {
              window.location.reload();
            },
            check: true,
          },
        ]);
      }
      if (isEdit) {
        console.log("is edit");
        setRouteName([
          {
            title: PROFILE_PATH_USER_GROUP_NAME,
            path: "/usergroup",
            setSelected: () => {
              window.location.reload();
            },
            check: true,
            action: () => {
              window.location.reload();
            },
          },
          {
            title: `${selected.name}`,
            path: `/usergroup/${selected.name}`,
            setSelected: setIsEdit,
            check: true,
          },
          {
            title: "EDIT USER GROUP",
          },
        ]);
      }
    }
  }, [setRouteName, isEdit, isCreate]);

  useEffect(() => {
    setIsEdit(false);
    setIsCreate(false);
  }, [location.pathname]);

  const requestSearch = (searchedVal) => {
    const value = searchedVal.target.value;
    setSearched(value);
    setPage(0);
    if (value?.length < MINIMUM_SEARCH_ACTIVE) {
      setRows(originalData);
      return;
    }

    const filteredRows = originalData?.filter((row) => {
      return row.name.toLowerCase().includes(value.toLowerCase());
    });
    setRows(filteredRows);
  };

  const rowsDataSorted = rows?.sort((a, b) => {
    if (a.name) {
      return a.name.toString().localeCompare(b.name);
    } else {
      return 0;
    }
  });

  useEffect(() => {
    if (actionAppbar === PATH_USER_GROUP_CREATE) {
      setSelected(true);
    } else {
      return;
    }
  }, [isEdit]);

  if (error) return <PBFEError error={error} />;

  return (
    <div className="h-screen w-full">
      <div className=" h-full flex">
        <div className=" pt-xxl min-w-max  h-screen overflow-y-scroll">
          <PBFESideTable
            id="PBFESideTable"
            labelKey={"name"}
            searchPlacholder="Search User Group"
            //  valueKey={"current_tax_rate"}
            searched={searched}
            setInfo={setInfo}
            requestSearch={requestSearch}
            rows={rowsDataSorted}
            loading={loading}
            page={page}
            setPage={setPage}
          />
        </div>
        <div className="border-r"></div>
        <div className="pt-xxl w-full overflow-y-scroll h-screen">
          {(selected || isCreate) && (
            <Info
              selected={selected}
              actionAppbar={actionAppbar}
              isEdit={isEdit}
              setDataUserGroup={setDataUserGroup}
              dataUserGroup={dataUserGroup}
              allDataName={data}
              isCreate={isCreate}
              setErrorDesc={setErrorDesc}
              errorDesc={errorDesc}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserGroup;
