import React from "react";
import { PBFEContent } from "../../../components/Elements/Text/content";
import PBFEInvalid from "../../../components/Error/invalidData";
import { PBFEAccordionMain } from "../../../components/Panel/Accordion/accordionMain";
import { ACCOUNT_TITLE } from "../../../constants/constant";
import AccountRenderService from "./Account.RenderService";
import { TextField } from "@mui/material";

const AccountAddNewServiceDuplicate = ({
  dataRequest,
  data,
  customer,
  accountTable,
  debitProfile,
  setDebitProfile,
  isAddActionService,
  originalData,
  setErrorState,
  is_maker,
  errorState,
  setCheckConfirmError,
  checkConfirmError,
  setRouteName,
  setIsNewService,
  account,
}) => {
  const [remark, setRemark] = React.useState("");

  React.useEffect(() => {
    setRouteName([
      { title: ACCOUNT_TITLE, path: "/account" },
      {
        title: `${dataRequest.account_name}`,
        path: `/account/${dataRequest.account_id}`,
        setSelected: setIsNewService,
        check: true,
      },
      {
        title: "ADD SERVICE",
      },
    ]);
  }, [dataRequest]);

  // console.log("dataRequest", dataRequest);

  return (
    <div className="w-full">
      <PBFEInvalid
        isShow={checkConfirmError && errorState.length > 0}
        message=""
        setOpen={setCheckConfirmError}
        open={checkConfirmError}
        errorState={errorState}
      />
      <div className="w-full overflow-auto">
        <div className="grid grid-cols-2 w-full px-xl my-20px">
          <PBFEContent label="Account Name" value={dataRequest?.account_name} />
          <PBFEContent label="Account No." value={dataRequest?.account_id} />
          <PBFEContent
            label="Customer Name"
            value={dataRequest?.customer_name}
          />
          <PBFEContent label="Customer ID" value={dataRequest?.customer_id} />
        </div>
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionMain
          label="Service"
          detail={
            isAddActionService ? (
              <div></div>
            ) : (
              <AccountRenderService
                setErrorState={setErrorState}
                dataRequest={dataRequest}
                remark={remark}
                checkConfirmError={checkConfirmError}
                setCheckConfirmError={setCheckConfirmError}
                errorState={errorState}
                is_maker={is_maker}
                originalData={originalData}
                customer={customer}
                debitProfile={debitProfile}
                setDebitProfile={setDebitProfile}
                data={{
                  service: [
                    {
                      ...data,
                    },
                  ],
                }}
                isEdit={true}
                isNew={true}
                accountTable={accountTable}
                account={account}
              />
            )
          }
        />
        <div className="ml-40px mr-25px">
          <TextField
            id="remark"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            label="REMARK"
            variant="filled"
            helperText={"Limited " + remark?.length + "/300"}
            fullWidth
            inputProps={{ maxLength: 300 }}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountAddNewServiceDuplicate;
