import { TextField } from "@mui/material";

const FieldText = ({
  name,
  value,
  setValue,
  limit,
  thisValue,
  lable,
  fullWidth = false,
}) => {
  return (
    <TextField
      id={name}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      label={lable}
      variant="standard"
      fullWidth={fullWidth}
      sx={{ input: { color: "#7A58BF" } }}
      inputProps={{ maxLength: limit }}
      error={thisValue?.value === "" ? false : thisValue?.error}
      helperText={
        thisValue?.value === ""
          ? "Limited " + value?.length + `/${limit}`
          : thisValue?.error
          ? thisValue?.message
          : "Limited " + value?.length + `/${limit}`
      }
    />
  );
};

export default FieldText;
