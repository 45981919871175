import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { IconButton } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDisabledAction } from "../../../redux/actionAppbarSlice";

const PBFEBreadcrumb = ({
  titles,
  setDataRequest,
  setRouteName,
  setAction,
  setActionAppbar,
  ...props
}) => {
  const navigate = useNavigate();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    let temp = [];
    const onClickNavigate = async (
      path,
      setSelected,
      check,
      action = () => {}
    ) => {
      setAction("");

      // if (location?.pathname === "/taxScheme") {
      //   setActionAppbar("TAX SCHEME SELECT");
      // }

      console.log("CLICK NAVIGATE", {
        path,
        setSelected,
        check,
        action,
        location,
      });

      if (check && setSelected) {
        if (action) {
          await action();
        }

        setRouteName((prev) => {
          prev = prev.slice(0, prev.length - 1);
          dispatch(setDisabledAction({ disabledAction: true }));
          return [...prev];
        });
        return setSelected(false);
      }

      const isHash = location.pathname.split("/").length >= 3;

      // if (setSelected && isHash && path === "/taxScheme") {
      //   setTimeout(() => {
      //     setRouteName([{ title: "TAX SCHEME", path: "/taxScheme" }]);
      //     window.history.pushState(null, null, "/taxScheme");
      //     return setSelected("");
      //   }, 500);
      // }

      // if (setSelected && path === "/taxScheme") {
      //   setRouteName([{ title: "TAX SCHEME", path: "/taxScheme" }]);
      //   return setSelected("");
      // }

      setDataRequest({});
      setRouteName([]);
      navigate(path);
    };
    temp.push(
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => onClickNavigate("/")}
      >
        <IconButton>
          <HomeIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </Link>
    );
    if (titles !== undefined) {
      for (let i = 0; i < titles.length - 1; i++) {
        temp.push(
          <Link
            underline="hover"
            key={i + 2}
            color="inherit"
            onClick={() =>
              onClickNavigate(
                titles[i]?.path,
                titles[i]?.setSelected,
                titles[i]?.check,
                titles[i]?.action
              )
            }
          >
            <div className="text-medium-14px text-textgray cursor-pointer">
              {titles[i].title}
            </div>
          </Link>
        );
      }

      temp.push(
        //   <Typography key={titles.length + 1} color="text.primary">
        <div className=" text-textprimary " key={titles.length + 1}>
          {titles[titles.length - 1]?.title}
        </div>
        //    </Typography>
      );
    }
    setBreadcrumbs(temp);
  }, [titles, navigate, setRouteName]);

  return (
    <Breadcrumbs
      {...props}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default PBFEBreadcrumb;
