import api from "./index";
import { apiConfig } from "../config";

const getMyRequest = async (params, query) => {
  return api.instanceCorp.get(`${apiConfig.getMyRequestAPI}${query}`);
};

// const postMakerChecker = async (body) => {
//   return api.instanceCorp.post(`${apiConfig.postMakerCheckerAPI}`, {
//     ...body,
//   });
// // };

// const getMyRequestInfoById = async (params, query) => {
//   return api.instanceCorp.get(
//     `${apiConfig.getMyRequestAPI}/${params.request_id}`
//   );
// };

const exportedObject = {
  getMyRequest,
  //   postMakerChecker,
  //   getMyRequestInfoById,
};

export default exportedObject;
