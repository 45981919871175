export const TYPE_TEXT = "text";
export const TYPE_TEXT_AREA = "textarea";
export const TYPE_NUMBER = "number";
export const TYPE_EMAIL = "email";
export const TYPE_RADIO = "radio";
export const TYPE_PHONE_NUMBER = "phone_number";
export const TYPE_DATE = "date";
export const TYPE_TIME = "time";
export const TYPE_SELECT = "select";
export const TYPE_CALCULATOR = "calculate";
export const TYPE_NONE = "none";
export const TYPE_CHECK_BOX = "checkbox";
export const TYPE_CUSTOMS = "customs";
export const TYPE_FILE = "file";
export const TYPE_PASSWORD = "password";
export const TYPE_SUBMIT = "submit";
export const TYPE_OTP = "otp";
export const TYPE_BUTTON = "button";

export const TYPE_MODEL_ERROR_LIMIT = "limit";
export const TYPE_MODEL_ERROR_REQURIED = "required";
export const TYPE_MODEL_ERROR_NUMBER = "number";
export const TYPE_MODEL_ERROR_EMAIL = "email";
export const TYPE_MODEL_ERROR_MINIMUM = "minimum";
export const TYPE_MODEL_ERROR_NO_SPACE = "no_space";

export const TYPE_MODE_NORMAL = "normal";
export const TYPE_ERROR_NORMAL = "normal";
export const TYPE_ERROR_ARRAY = "array";

export const TYPE_FUNCTION = "function";
