import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { FormHelperText } from "@mui/material";

const DropdownNew = ({
  label,
  onChange,
  options = [],
  error = false,
  disabled = false,
  value,
  helperText,
}) => {
  return (
    <FormControl fullWidth variant="standard">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        placeholder="Please Select"
        error={error}
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        sx={{ color: "#7A58BF", fontSize: 16 }}
        label={label}
        onChange={onChange}
      >
        {options?.map((option, index) => {
          return (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
      {error && (
        <FormHelperText
          style={{
            color: "#d32f2f",
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const DropdownObject = ({
  label,
  onChange,
  options = [],
  error = false,
  disabled = false,
  value,
  helperText,
}) => {

  return (
    <FormControl fullWidth variant="standard">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        placeholder="Please Select"
        error={error}
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        sx={{ color: "#7A58BF", fontSize: 16 }}
        label={label}
        onChange={onChange}
      >
        {options?.map((option, index) => {
          return (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      {error && (
        <FormHelperText
          style={{
            color: "#d32f2f",
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const DropdownNewWithPlaceholder = ({
  label,
  onChange,
  options = [],
  error = false,
  disabled = false,
  value,
  helperText,
}) => {
  return (
    <FormControl fullWidth variant="standard">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        placeholder="Please Select"
        error={error}
        displayEmpty
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value ? value : "Please Select"}
        sx={{ color: "#7A58BF", fontSize: 16 }}
        label={label}
        onChange={onChange}
      >
        <MenuItem disabled value="Please Select">
          Please Select
        </MenuItem>
        {options?.map((option, index) => {
          return (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
      {error && (
        <FormHelperText
          style={{
            color: "#d32f2f",
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const DropdownObjectWithPlaceholder = ({
  label,
  onChange,
  options = [],
  error = false,
  disabled = false,
  value,
  helperText,
}) => {
  return (
    <FormControl fullWidth variant="standard">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        placeholder="Please Select"
        error={error}
        displayEmpty
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value ? value : "Please Select"}
        sx={{ color: "#7A58BF", fontSize: 16 }}
        label={label}
        onChange={onChange}
      >
        <MenuItem disabled value="Please Select">
          Please Select
        </MenuItem>
        {options?.map((option, index) => {
          return (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      {error && (
        <FormHelperText
          style={{
            color: "#d32f2f",
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default DropdownNew;
