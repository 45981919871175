import React, { useEffect, useState } from "react";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import { PBFEContent } from "../../components/Elements/Text/content";
import PBFEError from "../../components/Error/alertError";
import {
  PBFEAccordionMain,
  PBFEAccordionWithButton,
} from "../../components/Panel/Accordion/accordionMain";
import { AsyncModal } from "../../components/Panel/Modal/asyncModal";
import {
  ACCOUNT_TITLE,
  ADD_OFFER_TABLE_HEADER,
  ADD_SERVICE_TABLE_HEADER,
} from "../../constants/constant";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Plus from "../../assets/icon/plus.svg";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import {
  BundleOfferInformation,
  OfferInformation,
  ServiceInformation,
} from "../../components/Panel/Information/information";
import useQuery from "../../hooks/useQuery";
import {
  checkCurrentDate,
  checkDuplicatePriority,
  checkDuplicateQualifer,
  checkErrorDate,
  getFeeChargeError,
  getValidateOfferError,
  onScrollToTop,
  prePareBundleOffer,
  prePareOffer,
  prePareService,
} from "../../utils/util";
import { useSelector } from "react-redux";
import { HistoryModal } from "../../components/Panel/Modal/historyModal";
import {
  postSendApproverCustomerService,
  setConfirmText,
  setDisabledAction,
  setStateComponent,
} from "../../redux/actionAppbarSlice";
import { PBFEHeader } from "../../components/Elements/Text/header";
import accountService from "../../api/accountService";
import { modalConfig } from "../../config";
import {
  mappingBundleOfferEditApprover,
  mappingOfferEditApprover,
  mappingServiceEditApprover,
} from "../../utils/mappingData";
import PBFEInvalid from "../../components/Error/invalidData";
import makerCheckerService from "../../api/makerCheckerService";
import _ from "lodash";
import historyService from "../../api/historyService";
import HISTORY_IMAGE from "../../assets/icon/history.svg";
import { TextField } from "@mui/material";
import AccountRenderService from "./render/Account.RenderService";
import AccountAddNewServiceDuplicate from "./render/Account.AddNewServiceDuplicate";
import AccountGeneralInfomation from "./render/Account.GeneralInfomation";
import AccountRenderOffer from "./render/Account.RenderOffer";
import AccountAddNewOfferDuplicate from "./render/Account.AddNewOfferDuplicate";
import AccountAddNewOffer from "./render/Account.AddNewOffer";
import AccountAddNewService from "./render/Account.AddNewService";
import AccountAddNewOfferBundleDuplicate from "./render/Account.AddNewOfferBundleDuplicate";
import AccountAddNewOfferBundle from "./render/Account.AddNewOfferBundle";
import AccountRenderBundleOffer from "./render/Account.RenderBundleOffer";

export const PBFEAccountInfo = ({
  setRouteName,
  dataRequest,
  setDataRequest,
  setActionAppbar,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const { user } = useSelector((state) => state.auth);

  const prePareData = (data) => {
    const service = data?.service?.map((service) => {
      return {
        ...service,
        charge_group: service.charge_group.map((group) => {
          return {
            ...group,
            fee_charge: group.fee_charge.map((charge) => {
              const posibleValue = Object.keys(charge?.qualifiers || {});
              if (charge?.fee_value?.conditions?.length > 0) {
                charge.fee_value.conditions = charge.fee_value.conditions.map(
                  (third) => {
                    let thisQualifiers = third?.qualifiers;
                    posibleValue?.forEach((thisKey) => {
                      if (
                        !thisQualifiers?.[String(thisKey).toLocaleLowerCase()]
                      ) {
                        thisQualifiers = {
                          ...thisQualifiers,
                          [thisKey]: [],
                        };
                      }
                    });
                    return {
                      ...third,
                      qualifiers: thisQualifiers,
                    };
                  }
                );
              }
              return {
                ...charge,
                isError: false,
                isRemove: false,
                checkCurrentDate: checkCurrentDate(
                  new Date(charge.from_date),
                  new Date(charge.to_date)
                ),
              };
            }),
          };
        }),
      };
    });

    const offer = data?.offer?.map((offer) => {
      return {
        ...offer,
        checkCurrentDate: checkCurrentDate(
          new Date(offer.from_date),
          new Date(offer.to_date)
        ),
      };
    });

    return {
      ...data,
      service,
      offer,
    };
  };

  const {
    data,
    setData,
    loading,
    error,
    originalData: originDataQuery,
    refetch,
  } = useQuery(
    accountService.getAccountById,
    {
      account_id: params.account_id,
    },
    {},
    [],
    { prePareData, checkFirstLoad: true }
  );

  const [accountTable, setAccountTable] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchedPage, setSearchedPage] = useState("");
  const [accountPage, setAccountPage] = useState(0);

  const [radioService, setRadioService] = useState(null);
  const [radioOffer, setRadioOffer] = useState(null);
  const [radioBundleOffer, setRadioBundleOffer] = useState(null);

  const [isAddActionService, setIsAddActionService] = useState(false);
  const [isAddActionOffer, setIsAddActionOffer] = useState(false);
  const [isAddActionBundleOffer, setIsAddActionBundleOffer] = useState(false);

  const [isNewService, setIsNewService] = useState(false);
  const [isNewOffer, setIsNewOffer] = useState(false);
  const [isNewBundleOffer, setIsNewBundleOffer] = useState(false);

  const [showHistory, setShowHistory] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditWhereData, setIsEditWhereData] = useState("");
  const [debitProfile, setDebitProfile] = useState([]);
  const [errorState, setErrorState] = useState([]);
  const [checkConfirmError, setCheckConfirmError] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [remark, setRemark] = useState("");
  const [errorModalMessage, setErrorModalMessage] = useState("");
  const isDuplicate = location?.search?.slice(0, 1) === "?";

  const isService =
    Object?.keys(isEditWhereData)?.findIndex((i) => {
      return i === "service_code";
    }) !== -1;

  const isBundleOffer =
    Object?.keys(isEditWhereData)?.findIndex((i) => {
      return i === "cust_bndl_offer_id" || i === "tran_conditions";
    }) !== -1;

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [isEdit, isNewOffer, isNewService, isNewBundleOffer]);

  const onBackButtonEvent = async () => {
    if (isEdit || isNewService || isNewOffer || isNewBundleOffer) {
      setIsEdit(false);
      setIsNewService(false);
      setIsNewOffer(false);
      setIsNewBundleOffer(false);
      setRadioService(null);
      setRadioOffer(null);
      setRadioBundleOffer(null);

      dispatch(setDisabledAction({ disabledAction: true }));
      await refetch({ account_id: params.account_id }, {});
    } else {
      navigate("/account");
    }
  };

  useEffect(() => {
    if (isNewService || isNewOffer || isNewBundleOffer) {
      if (data?.account_name && data?.account_id && !isDuplicate) {
        setDebitProfile([
          {
            isAdded: true,
            posting_account_id: data?.account_id,
            posting_account_name: data?.account_name,
            status: data?.status,
            from_date: new Date().toJSON()?.slice(0, 19),
            to_date: null,
          },
        ]);
      }
    }
  }, [data, isEdit, isNewService, isNewOffer, isNewBundleOffer]);

  useEffect(() => {
    setActionAppbar("CUSTOMER_SERVICE");
    setErrorModalMessage("");
    if (!data || isNewService || isNewOffer || isNewBundleOffer) return;
    dispatch(
      setStateComponent({
        stateComponent: "customerService",
        onClickAction: onClickSendToApprover,
        onClickBeforeAction: onClickBeforeAction,
      })
    );
    dispatch(setConfirmText(modalConfig.sendApproverConfirm.description));
    if (!isEditWhereData) {
      dispatch(setDisabledAction({ disabledAction: true }));
    }

    if (isEditWhereData) {
      dispatch(setDisabledAction({ disabledAction: false }));
    }
  }, [data, isEditWhereData, debitProfile, errorState, dataRequest]);

  useEffect(() => {
    if (isNewService || isNewOffer || isEdit || isNewBundleOffer) {
      setCheckConfirmError(false);
      setErrorState([]);
      setRemark("");
    }
  }, [isNewOffer, isNewService, isEdit, isNewBundleOffer]);

  useEffect(() => {
    const queryStringUrl = queryString.parse(location.search);
    if (Object.keys(dataRequest).length > 0 && queryStringUrl?.transaction_id) {
      sessionStorage.setItem("duplicate-ccy", dataRequest?.raw_data?.ccy);
      setDuplicate(true);
      dispatch(setDisabledAction({ disabledAction: false }));
      if (dataRequest.transaction_type === "NEWSERVICE") {
        setIsNewService(true);
      } else if (dataRequest.transaction_type === "UPDATESERVICE") {
        setIsEdit(true);
        setIsEditWhereData(prePareService(_.cloneDeep(dataRequest.raw_data)));
        setRouteName([
          { title: ACCOUNT_TITLE, path: `/${dataRequest.account_name}` },
          {
            title: `${dataRequest.account_name}`,
            path: `/account/${dataRequest.account_id}`,
            setSelected: setIsEdit,
            check: true,
            action: () => {
              setErrorState([]);
            },
          },
          {
            title: "EDIT SERVICE",
          },
        ]);
      } else if (dataRequest.transaction_type === "NEWOFFER") {
        setIsNewOffer(true);
      } else if (dataRequest.transaction_type === "UPDATEOFFER") {
        setIsEdit(true);
        setIsEditWhereData(prePareOffer(_.cloneDeep(dataRequest.raw_data)));
        setRouteName([
          { title: ACCOUNT_TITLE, path: `/${dataRequest.account_name}` },
          {
            title: `${dataRequest.account_name}`,
            path: `/account/${dataRequest.account_id}`,
            setSelected: setIsEdit,
            check: true,
            action: () => {
              setErrorState([]);
            },
          },
          {
            title: "EDIT OFFER",
          },
        ]);
      } else if (dataRequest.transaction_type === "NEWBUNDLEOFFER") {
        setIsNewBundleOffer(true);
      } else if (dataRequest.transaction_type === "UPDATEBUNDLEOFFER") {
        setIsEdit(true);
        setIsEditWhereData(
          prePareBundleOffer(_.cloneDeep(dataRequest.raw_data))
        );
        setRouteName([
          { title: ACCOUNT_TITLE, path: `/${dataRequest.account_name}` },
          {
            title: `${dataRequest.account_name}`,
            path: `/account/${dataRequest.account_id}`,
            setSelected: setIsEdit,
            check: true,
            action: () => {
              setErrorState([]);
            },
          },
          {
            title: "EDIT BUNDLED OFFER",
          },
        ]);
      }
    }
  }, [dataRequest]);

  useEffect(() => {
    if (data) {
      setAccountTable(data.account || []);
      setOriginalData(data.account || []);
      if (isEdit || isNewService || isNewOffer || isNewBundleOffer) return;
      setRouteName([
        { title: ACCOUNT_TITLE, path: "/account" },
        {
          title: `${
            data?.account_name ? data?.account_name : data?.customer_name
          }`,
          path: `/account/${params.account_id}`,
        },
      ]);
    }
  }, [setRouteName, data]);

  const onClickBeforeAction = (setShowConfirm) => {
    let checkError = false;

    if (isService) {
      const service = _.cloneDeep(isEditWhereData);
      const checkErroDateList = checkErrorDate(service);
      if (checkDuplicatePriority(service, true)) {
        checkError = true;
        setErrorState((prev) => [
          {
            field_name: "priority_empty",
            show: true,
            message: "Priority cannot be empty ,  - or 0",
          },
        ]);
      } else {
        checkError = false;
        setErrorState((prev) => [
          ...prev.filter((item) => item.field_name !== "priority_empty"),
        ]);
      }
      const checkErrorDuplicate = checkDuplicatePriority(service);
      const checkListChargeError = getFeeChargeError(service);
      const checkErrorQualifer = checkDuplicateQualifer(service);

      if (checkErrorQualifer.length > 0) {
        const unqiue_error_state = _.unionBy(checkErrorQualifer, "field_name");
        setErrorState((prev) =>
          _.unionBy([...unqiue_error_state, ...prev], "field_name")
        );
        checkError = true;
      } else {
        setErrorState((prev) => [
          ...prev.filter((item) => item.field_name !== "qualifer"),
        ]);
      }

      if (checkListChargeError.length > 0) {
        checkError = true;
        setErrorState((prev) => [
          ...prev,
          { field_name: "validate_error", show: false },
        ]);
      } else {
        setErrorState((prev) => [
          ...prev.filter((item) => item.field_name !== "validate_error"),
        ]);
      }

      if (checkErroDateList.length > 0) {
        const unqiue_error_state = _.unionBy(checkErroDateList, "field_name");
        setErrorState((prev) =>
          _.unionBy([...unqiue_error_state, ...prev], "field_name")
        );
        checkError = true;
      } else {
        setErrorState((prev) => {
          return prev.filter((item) => {
            return item.field_name !== "overlap_date";
          });
        });
      }

      if (checkErrorDuplicate.length > 0) {
        const unqiue_error_state = _.unionBy(checkErrorDuplicate, "field_name");
        setErrorState((prev) =>
          _.unionBy([...unqiue_error_state, ...prev], "field_name")
        );
        checkError = true;
      } else {
        setErrorState((prev) => {
          return prev.filter((item) => {
            return item.field_name !== "priority";
          });
        });
      }
    } else {
      const listError = getValidateOfferError(_.cloneDeep(isEditWhereData));
      console.log("listError", listError);

      if (listError.length > 0) {
        checkError = true;
        setErrorState((prev) => {
          return [...prev, { field_name: "validate_error", show: false }];
        });
      } else {
        setErrorState((prev) =>
          prev.filter((item) => item.field_name !== "validate_error")
        );
      }
    }

    if (
      !debitProfile.filter((profile) => profile?.action !== "DELETE").length
    ) {
      setErrorState((prev) => {
        const find_exists = prev.find(
          (item) => item.field_name === "posting_account"
        );
        if (!find_exists) {
          prev.push({
            error: true,
            message: "Please select debit profile",
            field_name: "posting_account",
            field_value: [],
            show: true,
          });
        }
        return [...prev];
      });
      checkError = true;
    } else {
      if (isService) {
        const originalData = JSON.parse(sessionStorage.getItem("originalData"));

        const findServiceOriginal = _.cloneDeep(
          originalData.service.find(
            (service) => service.service_code === isEditWhereData.service_code
          )
        );

        const cloneFindServiceOriginal = _.cloneDeep(findServiceOriginal);
        const cloneIsEditWhereData = _.cloneDeep(isEditWhereData);

        const cloneDebitProfile = _.cloneDeep(debitProfile);
        const clonePositingAccount = _.cloneDeep(
          findServiceOriginal.posting_account
        );

        delete cloneFindServiceOriginal.posting_account;
        delete cloneIsEditWhereData.posting_account;

        const compareServiceOriginal = JSON.stringify(cloneFindServiceOriginal);
        const compareService = JSON.stringify(cloneIsEditWhereData);
        // const compareServiceValue = FunctionGetNumberFromString(compareService)

        // console.log("compareServiceValue", compareServiceValue)
        // console.log("cloneFindServiceOriginal", cloneFindServiceOriginal)
        // console.log("cloneIsEditWhereData", cloneIsEditWhereData)
        // console.log("compareServiceOriginal", compareServiceOriginal.length)
        // console.log("compareService", compareService.length)
        // console.log("check", compareService === compareServiceOriginal)

        const compareDebitProfile = JSON.stringify(cloneDebitProfile);
        const comparePostingAccount = JSON.stringify(clonePositingAccount);

        if (
          compareServiceOriginal.length === compareService.length &&
          comparePostingAccount === compareDebitProfile
        ) {
          setErrorState((prev) => {
            const find_exists = prev.find(
              (item) => item.field_name === "not_update"
            );
            if (!find_exists) {
              prev.push({
                error: true,
                message: "No data changed",
                field_name: "not_update",
                field_value: [],
                show: true,
              });
            }
            return [...prev];
          });
          checkError = true;
        } else {
          setErrorState((prev) => {
            const filterExist = prev.filter(
              (item) => item.field_name !== "not_update"
            );
            return [...filterExist];
          });
        }
      } else if (isBundleOffer) {
        const originalData = dataRequest?.hasOwnProperty("raw_data")
          ? { offer: [_.omit(dataRequest?.raw_data, ["action"])] }
          : JSON.parse(sessionStorage.getItem("originalData"));

        let findOfferOriginal = null;

        if (originalData?.bundle_offer) {
          findOfferOriginal = originalData?.bundle_offer?.find(
            (d) => d?.offer_code === isEditWhereData?.offer_code
          );
        }

        if (originalData?.offer && !originalData?.bundle_offer) {
          findOfferOriginal = originalData?.offer?.find(
            (d) => d?.offer_code === isEditWhereData?.offer_code
          );
        }

        console.log("originalData", {
          originalData,
          findOfferOriginal,
          isEditWhereData,
        });

        const cloneFindOfferOriginal = _.cloneDeep(findOfferOriginal);
        const cloneIsEditWhereData = _.cloneDeep(
          _.omit(isEditWhereData, ["action"])
        );

        const cloneDebitProfile = _.cloneDeep(debitProfile);
        const clonePositingAccount = _.cloneDeep(
          findOfferOriginal.posting_account
        );

        delete cloneFindOfferOriginal.posting_account;
        delete cloneIsEditWhereData.posting_account;

        const compareOfferOriginal = JSON.stringify(cloneFindOfferOriginal);
        const compareOffer = JSON.stringify(cloneIsEditWhereData);

        const compareDebitProfile = JSON.stringify(cloneDebitProfile);
        const comparePostingAccount = JSON.stringify(clonePositingAccount);

        if (
          compareOfferOriginal === compareOffer &&
          comparePostingAccount === compareDebitProfile
        ) {
          setErrorState((prev) => {
            const find_exists = prev.find(
              (item) => item.field_name === "not_update"
            );
            if (!find_exists) {
              prev.push({
                error: true,
                message: "No data changed",
                field_name: "not_update",
                field_value: [],
                show: true,
              });
            }
            return [...prev];
          });
          checkError = true;
        } else {
          setErrorState((prev) => {
            const filterExist = prev.filter(
              (item) => item.field_name !== "not_update"
            );
            return [...filterExist];
          });
        }
      } else {
        const originalData = dataRequest?.hasOwnProperty("raw_data")
          ? { offer: [_.omit(dataRequest?.raw_data, ["action"])] }
          : JSON.parse(sessionStorage.getItem("originalData"));

        const findOfferOriginal = originalData?.offer?.find(
          (offer) => offer?.offer_code === isEditWhereData?.offer_code
        );

        const cloneDebitProfile = _.cloneDeep(debitProfile);
        const clonePositingAccount = _.cloneDeep(
          findOfferOriginal?.posting_account
        );

        const cloneFindOfferOriginal = _.cloneDeep(findOfferOriginal);
        const cloneIsEditWhereData = _.cloneDeep(
          _.omit(isEditWhereData, ["action"])
        );

        delete cloneFindOfferOriginal.posting_account;
        delete cloneIsEditWhereData.posting_account;

        const compareOfferOriginal = JSON.stringify(cloneFindOfferOriginal);
        const compareOffer = JSON.stringify(cloneIsEditWhereData);

        const compareDebitProfile = JSON.stringify(cloneDebitProfile);
        const comparePostingAccount = JSON.stringify(clonePositingAccount);

        if (
          compareOfferOriginal === compareOffer &&
          compareDebitProfile === comparePostingAccount
        ) {
          setErrorState((prev) => {
            const find_exists = prev.find(
              (item) => item.field_name === "not_update"
            );
            if (!find_exists) {
              prev.push({
                error: true,
                message: "No data changed",
                field_name: "not_update",
                field_value: [],
                show: true,
              });
            }
            return [...prev];
          });
          checkError = true;
        } else {
          setErrorState((prev) => {
            const filterExist = prev.filter(
              (item) => item.field_name !== "not_update"
            );
            return [...filterExist];
          });
        }
      }
    }

    if (
      errorState.filter((item) => {
        return ![
          "not_update",
          "overlap_date",
          "validate_error",
          "priority",
          "qualifer",
        ].includes(item.field_name);
      }).length > 0
    ) {
      checkError = true;
    }

    if (checkError || errorState.length > 0) {
      setCheckConfirmError(true);
      onScrollToTop("invalid");
    } else {
      setShowConfirm(true);
    }
  };

  const onClickSendToApprover = () => {
    if (isService) {
      return dispatch(
        postSendApproverCustomerService({
          data: mappingServiceEditApprover(
            {
              ...isEditWhereData,
              posting_account: debitProfile,
            },
            dataRequest,
            remark
          ),
        })
      );
    } else if (isBundleOffer) {
      return dispatch(
        postSendApproverCustomerService({
          data: mappingBundleOfferEditApprover(
            {
              ...isEditWhereData,
              posting_account: debitProfile,
            },
            dataRequest,
            remark
          ),
        })
      );
    } else {
      return dispatch(
        postSendApproverCustomerService({
          data: mappingOfferEditApprover(
            {
              ...isEditWhereData,
              posting_account: debitProfile,
            },
            dataRequest,
            remark
          ),
        })
      );
    }
  };

  const addService = (e) => {
    e.stopPropagation();
    setIsAddActionService(true);
  };

  const addOffer = (e) => {
    e.stopPropagation();
    setIsAddActionOffer(true);
  };

  const addBundleOffer = (e) => {
    e.stopPropagation();
    setIsAddActionBundleOffer(true);
  };

  const actionModalService = async () => {
    try {
      setLoadingAction(true);
      const { data: responseData } =
        await makerCheckerService.getMakerCheckerValidatePending(
          {},
          `?customer_id=${data.customer_id}&account_id=${data.account_id}&code=${radioService}&tran_type=NEWSERVICE`
        );

      if (responseData.data.pending === "N") {
        setIsNewService(true);
        setIsAddActionService(false);
        dispatch(setDisabledAction({ disabledAction: false }));
        setRouteName([
          { title: ACCOUNT_TITLE, path: "/account" },
          {
            title: `${data.account_name}`,
            path: `/account/${params.account_id}`,
            setSelected: setIsNewService,
            check: true,
            action: () => {
              setErrorState([]);
            },
          },
          {
            title: "ADD SERVICE",
          },
        ]);
        return;
      }

      setErrorModalMessage(responseData.data);
    } catch (error) {
    } finally {
      setLoadingAction(false);
    }
  };

  const actionModalOffer = async () => {
    try {
      setLoadingAction(true);
      const { data: responseData } =
        await makerCheckerService.getMakerCheckerValidatePending(
          {},
          `?customer_id=${data.customer_id}&account_id=${data.account_id}&code=${radioOffer}&tran_type=NEWOFFER`
        );

      if (responseData.data.pending === "N") {
        setIsNewOffer(true);
        setIsAddActionOffer(false);
        dispatch(setDisabledAction({ disabledAction: false }));
        setRouteName([
          { title: ACCOUNT_TITLE, path: "/account" },
          {
            title: `${data.account_name}`,
            path: `/account/${params.account_id}`,
            setSelected: setIsNewOffer,
            check: true,
            action: () => {
              setErrorState([]);
            },
          },
          {
            title: "ADD OFFER",
          },
        ]);
        return;
      }

      setErrorModalMessage(responseData.data);
    } catch (error) {
    } finally {
      setLoadingAction(false);
    }
  };

  const actionModalBundleOffer = async () => {
    try {
      setLoadingAction(true);
      const { data: responseData } =
        await makerCheckerService.getMakerCheckerValidatePending(
          {},
          `?customer_id=${data.customer_id}&account_id=${data.account_id}&code=${radioBundleOffer}&tran_type=NEWBUNDLEOFFER`
        );

      console.log(responseData);

      if (responseData.data.pending === "N") {
        setIsNewBundleOffer(true);
        setIsAddActionBundleOffer(false);
        dispatch(setDisabledAction({ disabledAction: false }));
        setRouteName([
          { title: ACCOUNT_TITLE, path: "/account" },
          {
            title: `${data.account_name}`,
            path: `/account/${params.account_id}`,
            setSelected: setIsNewBundleOffer,
            check: true,
            action: () => {
              setErrorState([]);
            },
          },
          {
            title: "ADD BUNDLED OFFER",
          },
        ]);
        return;
      }

      setErrorModalMessage(responseData.data);
    } catch (error) {
    } finally {
      setLoadingAction(false);
    }
  };

  const callbackNavigate = (row) => {
    navigate(`/account/${params.account_id}/history/${row.request_id}`);
  };

  const callbackFilterService = (value) => (row) => {
    return (
      row.service_name.toLowerCase().includes(value.toLowerCase()) ||
      row.service_code.toLowerCase().includes(value.toLowerCase())
    );
  };

  const callbackFilterOffer = (value) => (row) => {
    return (
      row.offer_name.toLowerCase().includes(value.toLowerCase()) ||
      row.offer_code.toLowerCase().includes(value.toLowerCase())
    );
  };

  if (isNewService) {
    return (
      <React.Fragment>
        {duplicate ? (
          <AccountAddNewServiceDuplicate
            dataRequest={dataRequest}
            setRouteName={setRouteName}
            setIsNewService={setIsNewService}
            data={dataRequest?.raw_data}
            is_maker={data?.is_maker === "Y"}
            originalData={data}
            customer={{
              customer_name: dataRequest.customer_name,
              customer_id: dataRequest.customer_id,
            }}
            account={{
              account_name: dataRequest.account_name,
              account_id: dataRequest.account_id,
            }}
            service_code={radioService}
            accountTable={accountTable}
            isAddActionService={isAddActionService}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            setErrorState={setErrorState}
            errorState={errorState}
            checkConfirmError={checkConfirmError}
            setCheckConfirmError={setCheckConfirmError}
          />
        ) : (
          <AccountAddNewService
            is_maker={data?.is_maker === "Y"}
            originalData={data}
            customer={{
              customer_name: data.customer_name,
              customer_id: data.customer_id,
            }}
            account={{
              account_name: data.account_name,
              account_id: data.account_id,
            }}
            setRadioService={setRadioService}
            service_code={radioService}
            accountTable={accountTable}
            isAddActionService={isAddActionService}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            setErrorState={setErrorState}
            errorState={errorState}
            checkConfirmError={checkConfirmError}
            setCheckConfirmError={setCheckConfirmError}
          />
        )}

        {isAddActionService && (
          <AsyncModal
            errorModalMessage={errorModalMessage}
            setErrorModalMessage={setErrorModalMessage}
            customer_id={params.customer_id}
            title="Add Service"
            open={isAddActionService}
            onClose={() => setIsAddActionService(false)}
            radioValue={radioService}
            setRadio={setRadioService}
            serviceApi={accountService.getServiceAccountById}
            params={{
              account_id: data?.account_id,
            }}
            query={{
              account_id: data?.account_id,
              user_id: user?.info?.preferred_username,
            }}
            option_field_label="service_name"
            option_field_value="service_code"
            action={actionModalService}
            loadingAction={loadingAction}
            placeholder={"Search Service Name / Code"}
          />
        )}
      </React.Fragment>
    );
  }

  if (isNewOffer) {
    return (
      <React.Fragment>
        {duplicate ? (
          <AccountAddNewOfferDuplicate
            dataRequest={dataRequest}
            setRouteName={setRouteName}
            setIsNewService={setIsNewService}
            data={dataRequest?.raw_data}
            is_maker={data?.is_maker === "Y"}
            originalData={data}
            account={{
              customer_id: dataRequest.customer_id,
              customer_name: dataRequest.customer_name,
              account_name: dataRequest.account_name,
              account_id: dataRequest.account_id,
            }}
            offer_code={radioOffer}
            accountTable={accountTable}
            isAddActionOffer={isAddActionOffer}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            setErrorState={setErrorState}
            errorState={errorState}
            checkConfirmError={checkConfirmError}
            setCheckConfirmError={setCheckConfirmError}
          />
        ) : (
          <AccountAddNewOffer
            is_maker={data?.is_maker === "Y"}
            originalData={data}
            account={{
              customer_id: data.customer_id,
              customer_name: data.customer_name,
              account_name: data.account_name,
              account_id: data.account_id,
            }}
            setRadioOffer={setRadioOffer}
            offer_code={radioOffer}
            accountTable={accountTable}
            isAddActionOffer={isAddActionOffer}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            setErrorState={setErrorState}
            errorState={errorState}
            checkConfirmError={checkConfirmError}
            setCheckConfirmError={setCheckConfirmError}
          />
        )}

        {isAddActionOffer && (
          <AsyncModal
            errorModalMessage={errorModalMessage}
            setErrorModalMessage={setErrorModalMessage}
            account_id={params.account_id}
            title="Add Offer"
            open={isAddActionOffer}
            onClose={() => setIsAddActionOffer(false)}
            radioValue={radioOffer}
            setRadio={setRadioOffer}
            serviceApi={accountService.getOfferAccountById}
            params={{
              account_id: params.account_id,
            }}
            query={{
              account_id: params.account_id,
              user_id: user?.info?.preferred_username,
            }}
            option_field_label="offer_name"
            option_field_value="offer_code"
            action={actionModalOffer}
            loadingAction={loadingAction}
            placeholder={"Search Offer Name / Code"}
            orderByDefault="offer_name"
            head_cells={ADD_OFFER_TABLE_HEADER}
            showFields={["offer_name", "offer_code"]}
            callbackFilter={callbackFilterOffer}
          />
        )}
      </React.Fragment>
    );
  }

  if (isNewBundleOffer) {
    return (
      <React.Fragment>
        {duplicate ? (
          <AccountAddNewOfferBundleDuplicate
            dataRequest={dataRequest}
            setRouteName={setRouteName}
            setIsNewService={setIsNewBundleOffer}
            data={dataRequest?.raw_data}
            is_maker={data?.is_maker === "Y"}
            originalData={data}
            account={{
              customer_id: dataRequest.customer_id,
              customer_name: dataRequest.customer_name,
              account_name: dataRequest.account_name,
              account_id: dataRequest.account_id,
            }}
            offer_code={radioOffer}
            accountTable={accountTable}
            isAddActionOffer={isAddActionOffer}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            setErrorState={setErrorState}
            errorState={errorState}
            checkConfirmError={checkConfirmError}
            setCheckConfirmError={setCheckConfirmError}
          />
        ) : (
          <AccountAddNewOfferBundle
            is_maker={data?.is_maker === "Y"}
            originalData={data}
            account={{
              customer_id: data.customer_id,
              customer_name: data.customer_name,
              account_name: data.account_name,
              account_id: data.account_id,
            }}
            setRadioOffer={setRadioBundleOffer}
            offer_code={radioBundleOffer}
            accountTable={accountTable}
            isAddActionOffer={isAddActionBundleOffer}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            setErrorState={setErrorState}
            errorState={errorState}
            checkConfirmError={checkConfirmError}
            setCheckConfirmError={setCheckConfirmError}
          />
        )}

        {isAddActionBundleOffer && (
          <AsyncModal
            errorModalMessage={errorModalMessage}
            setErrorModalMessage={setErrorModalMessage}
            account_id={params.account_id}
            title="Add Bundled Offer"
            open={isAddActionBundleOffer}
            onClose={() => setIsAddActionBundleOffer(false)}
            radioValue={radioBundleOffer}
            setRadio={setRadioBundleOffer}
            serviceApi={accountService.getBundleOfferAccountById}
            params={{
              account_id: params.account_id,
            }}
            query={{
              account_id: params.account_id,
              user_id: user?.info?.preferred_username,
            }}
            option_field_label="offer_name"
            option_field_value="offer_code"
            action={actionModalBundleOffer}
            loadingAction={loadingAction}
            placeholder={"Search Offer Name / Code"}
            orderByDefault="offer_name"
            head_cells={ADD_OFFER_TABLE_HEADER}
            showFields={["offer_name", "offer_code"]}
            callbackFilter={callbackFilterOffer}
          />
        )}
      </React.Fragment>
    );
  }

  if (isEdit) {
    let foundIndexOriginal = 0;

    if (originDataQuery) {
      foundIndexOriginal = isBundleOffer
        ? originDataQuery?.bundle_offer?.findIndex(
            (offer) => offer?.offer_code === isEditWhereData?.offer_code
          )
        : isService
        ? originDataQuery?.service?.findIndex(
            (service) => service?.service_code === isEditWhereData?.service_code
          )
        : originDataQuery?.offer?.findIndex(
            (offer) => offer?.offer_code === isEditWhereData?.offer_code
          );
    }

    return (
      <div id="edit" className="w-full">
        <PBFEInvalid
          isShow={checkConfirmError && errorState.length > 0}
          message=""
          setOpen={setCheckConfirmError}
          open={checkConfirmError}
          errorState={errorState}
        />
        <div className="w-full overflow-auto">
          <div className="grid grid-cols-2 w-full px-xl my-20px">
            <PBFEContent label="Account Name" value={data?.account_name} />
            <PBFEContent label="Account No." value={data?.account_id} />
            <PBFEContent label="Customer Name" value={data?.customer_name} />
            <PBFEContent label="Customer ID" value={data?.customer_id} />
          </div>
          <div className="h-10px bg-gray-100"></div>
          <React.Fragment>
            <PBFEAccordionMain
              label={isService ? "Service" : "Offer"}
              detail={
                <PBFEAccordionMain
                  pl="ml-20px"
                  customHeader={
                    <PBFEHeader
                      p=""
                      isOffer={!isService}
                      label={
                        isService
                          ? isEditWhereData?.service_name +
                            " - " +
                            isEditWhereData?.service_code
                          : isEditWhereData?.offer_name +
                            " - " +
                            isEditWhereData?.offer_code
                      }
                      purple={true}
                      hideButton={true}
                    />
                  }
                  detail={[
                    isBundleOffer ? (
                      <BundleOfferInformation
                        data={isEditWhereData}
                        index={foundIndexOriginal}
                        origin={origin}
                        setData={setData}
                        account_table={isEditWhereData?.posting_account}
                        accountTable={accountTable}
                        isEdit={isEdit}
                        setDebitProfile={setDebitProfile}
                        debitProfile={debitProfile}
                        setErrorState={setErrorState}
                        setDataDuplicate={setIsEditWhereData}
                      />
                    ) : isService ? (
                      <ServiceInformation
                        data={isEditWhereData}
                        index={foundIndexOriginal}
                        origin={origin}
                        setData={setData}
                        account_table={isEditWhereData?.posting_account}
                        isEdit={isEdit}
                        accountTable={accountTable}
                        setDebitProfile={setDebitProfile}
                        debitProfile={debitProfile}
                        setErrorState={setErrorState}
                        infoData={data}
                        // hideButton={hideButton}
                        // isNew={isNew}
                      />
                    ) : (
                      <OfferInformation
                        data={isEditWhereData}
                        index={foundIndexOriginal}
                        origin={origin}
                        setData={setData}
                        account_table={isEditWhereData?.posting_account}
                        accountTable={accountTable}
                        isEdit={isEdit}
                        setDebitProfile={setDebitProfile}
                        debitProfile={debitProfile}
                        setErrorState={setErrorState}
                        setDataDuplicate={setIsEditWhereData}
                      />
                    ),
                  ]}
                />
              }
            />
          </React.Fragment>
          <div className="ml-40px mr-25px">
            <TextField
              id="remark"
              onChange={(e) => setRemark(e.target.value)}
              value={remark}
              label="REMARK"
              helperText={"Limited " + remark?.length + "/300"}
              variant="filled"
              fullWidth
              inputProps={{ maxLength: 300 }}
            />
          </div>
        </div>
      </div>
    );
  }

  if (loading)
    return (
      <div className="h-screen m-auto">
        <PBFELoading />
      </div>
    );

  if (error) return <PBFEError error={error} />;

  return (
    <div className="w-full">
      <PBFEInvalid
        isShow={checkConfirmError && errorState.length > 0}
        message=""
        setOpen={setCheckConfirmError}
        open={checkConfirmError}
        errorState={errorState}
      />
      <div className="w-full overflow-auto">
        {!isNewService && isAddActionService && (
          <AsyncModal
            errorModalMessage={errorModalMessage}
            setErrorModalMessage={setErrorModalMessage}
            title="Add Service"
            open={isAddActionService}
            onClose={() => setIsAddActionService(false)}
            radioValue={radioService}
            setRadio={setRadioService}
            serviceApi={accountService.getServiceAccountById}
            query={{
              account_id: params.account_id,
              user_id: user?.info?.preferred_username,
            }}
            params={{
              account_id: params.account_id,
            }}
            option_field_label="service_name"
            option_field_value="service_code"
            action={actionModalService}
            loadingAction={loadingAction}
            orderByDefault="service_name"
            head_cells={ADD_SERVICE_TABLE_HEADER}
            showFields={["service_name", "service_code"]}
            callbackFilter={callbackFilterService}
            placeholder={"Search Service Name / Code"}
          />
        )}
        {!isNewOffer && isAddActionOffer && (
          <AsyncModal
            errorModalMessage={errorModalMessage}
            setErrorModalMessage={setErrorModalMessage}
            title="Add Offer"
            open={isAddActionOffer}
            onClose={() => setIsAddActionOffer(false)}
            radioValue={radioOffer}
            setRadio={setRadioOffer}
            serviceApi={accountService.getOfferAccountById}
            query={{
              account_id: params.account_id,
              user_id: user?.info?.preferred_username,
            }}
            params={{
              account_id: params.account_id,
            }}
            option_field_label="offer_name"
            option_field_value="offer_code"
            action={actionModalOffer}
            loadingAction={loadingAction}
            orderByDefault="offer_name"
            head_cells={ADD_OFFER_TABLE_HEADER}
            showFields={["offer_name", "offer_code"]}
            callbackFilter={callbackFilterOffer}
            placeholder="Search Offer Name / Code"
          />
        )}
        {!isNewBundleOffer && isAddActionBundleOffer && (
          <AsyncModal
            errorModalMessage={errorModalMessage}
            setErrorModalMessage={setErrorModalMessage}
            title="Add Bundled Offer"
            open={isAddActionBundleOffer}
            onClose={() => setIsAddActionBundleOffer(false)}
            radioValue={radioBundleOffer}
            setRadio={setRadioBundleOffer}
            serviceApi={accountService.getBundleOfferAccountById}
            query={{
              account_id: params.account_id,
              user_id: user?.info?.preferred_username,
            }}
            params={{
              account_id: params.account_id,
            }}
            option_field_label="offer_name"
            option_field_value="offer_code"
            action={actionModalBundleOffer}
            loadingAction={loadingAction}
            orderByDefault="offer_name"
            head_cells={ADD_OFFER_TABLE_HEADER}
            showFields={["offer_name", "offer_code"]}
            callbackFilter={callbackFilterOffer}
            placeholder="Search Offer Name / Code"
          />
        )}
        {showHistory && (
          <HistoryModal
            callbackNavigate={callbackNavigate}
            open={showHistory}
            onClose={() => setShowHistory(false)}
            serviceApi={historyService.getHistoryById}
            query={{
              customer_id: data.customer_id,
              account_id: data?.account_id,
              offset: 0,
              limit: 100,
            }}
          />
        )}
        <div className="grid grid-cols-2 w-full px-xl my-20px">
          <PBFEContent label="Account Name" value={data?.account_name} />
          <PBFEContent label="Account No." value={data?.account_id} />
          <PBFEContent label="Customer Name" value={data?.customer_name} />
          <PBFEContent label="Customer ID" value={data?.customer_id} />
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setShowHistory(true);
          }}
          className="flex  items-center ml-auto justify-end space-x-2  px-xl mb-8 mr-4px  text-textscb text-sm"
        >
          <img className="cursor-pointer" src={HISTORY_IMAGE} />
          <div className="cursor-pointer">HISTORY</div>
        </button>
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionMain
          label="General Info"
          detail={<AccountGeneralInfomation data={data} />}
        />
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionWithButton
          label="Service"
          buttons={
            (data.is_maker === "Y" && [
              { img: Plus, name: "ADD SERVICE", action: addService },
            ]) ||
            []
          }
          detail={
            <AccountRenderService
              setDataRequest={setDataRequest}
              setErrorState={setErrorState}
              account={{
                account_id: data.account_id,
                account_name: data.account_name,
              }}
              customer={{
                customer_name: data.customer_name,
                customer_id: data.customer_id,
              }}
              is_maker={data?.is_maker === "Y"}
              data={data}
              accountTable={accountTable}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              setRouteName={setRouteName}
              setIsEditWhereData={setIsEditWhereData}
              debitProfile={debitProfile}
              setDebitProfile={setDebitProfile}
              errorState={errorState}
              setCheckConfirmError={setCheckConfirmError}
              refetch={refetch}
            />
          }
        />
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionWithButton
          buttons={
            (data.is_maker === "Y" && [
              // { img: History, name: "HISTORY", action: () => setShowHistory(true) },
              { img: Plus, name: "ADD OFFER", action: addOffer },
            ]) ||
            []
          }
          label="Offer"
          detail={
            <AccountRenderOffer
              setDataRequest={setDataRequest}
              refetch={refetch}
              account={{
                customer_id: data.customer_id,
                customer_name: data.customer_name,
                account_name: data.account_name,
                account_id: data.account_id,
              }}
              customer={{
                customer_id: data.customer_id,
                customer_name: data.customer_name,
              }}
              is_maker={data?.is_maker === "Y"}
              data={data}
              accountTable={accountTable}
              isEdit={isEdit}
              setRouteName={setRouteName}
              setIsEdit={setIsEdit}
              setIsEditWhereData={setIsEditWhereData}
              debitProfile={debitProfile}
              setDebitProfile={setDebitProfile}
              setCheckConfirmError={setCheckConfirmError}
              setErrorState={setErrorState}
            />
          }
        />
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionWithButton
          buttons={
            (data.is_maker === "Y" && [
              // { img: History, name: "HISTORY", action: () => setShowHistory(true) },
              { img: Plus, name: "ADD BUNDLED OFFER", action: addBundleOffer },
            ]) ||
            []
          }
          label="Bundled Offer"
          detail={
            <AccountRenderBundleOffer
              setDataRequest={setDataRequest}
              refetch={refetch}
              account={{
                customer_id: data.customer_id,
                customer_name: data.customer_name,
                account_name: data.account_name,
                account_id: data.account_id,
              }}
              customer={{
                customer_id: data.customer_id,
                customer_name: data.customer_name,
              }}
              is_maker={data?.is_maker === "Y"}
              data={data}
              accountTable={accountTable}
              isEdit={isEdit}
              setRouteName={setRouteName}
              setIsEdit={setIsEdit}
              setIsEditWhereData={setIsEditWhereData}
              debitProfile={debitProfile}
              setDebitProfile={setDebitProfile}
              setCheckConfirmError={setCheckConfirmError}
              setErrorState={setErrorState}
            />
          }
        />
      </div>
    </div>
  );
};
