import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import React from "react";
import IconCalendar from "../../assets/icon/calendar.svg";
import { Box, TextField } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import {
  checkValidDate,
  getCheckValidateThreeMonth,
  validateFormatDate,
} from "../../utils/util";
import _, { isError } from "lodash";
import {
  TEXT_FIELD_ERROR_DATE,
  TEXT_FIELD_ERROR_DATE_FROM_TO,
} from "../../functions";
import { ERROR_MESSAGE_REQUIRED } from "../../utils/errorMessage";

export const DateRangePicker = ({
  refCalendar,
  isOpenCalendar,
  calendarValue,
  setCalendarValue,
  toggleCalendar,
  onChange,
  isErrorDate = [false, false],
  checkValidateThreeMonth = false,
  setIsErrorDate,
  marginX = "auto",
}) => {
  const getFormatDate = (currFrom, currTo) => {
    let from = currFrom;
    if (from === undefined || from === null) from = "";
    else {
      from =
        _.padStart(String(from.getDate()), 2, "0") +
        "/" +
        _.padStart(String(from.getMonth() + 1), 2, "0") +
        "/" +
        from.getFullYear();
    }
    let to = currTo;
    if (to === undefined || to === null) to = "";
    else {
      to =
        _.padStart(String(to.getDate()), 2, "0") +
        "/" +
        _.padStart(String(to.getMonth() + 1), 2, "0") +
        "/" +
        to.getFullYear();
    }

    return from + " - " + to;
  };

  const displayDate = () => {
    if (isErrorDate.some((item) => item === true)) {
      return getFormatDate(new Date(), new Date());
    }
    return getFormatDate(calendarValue[0], calendarValue[1]);
  };

  const onChangeFrom = (val) => {
    if (val === null) {
      setIsErrorDate([true, isErrorDate[1]]);
    } else if (validateFormatDate(val)) {
      setIsErrorDate([true, isErrorDate[1]]);
    } else if (new Date(val).getTime() > new Date(calendarValue[1]).getTime()) {
      setIsErrorDate([true, isErrorDate[1]]);
    } else if (
      checkValidateThreeMonth &&
      getCheckValidateThreeMonth(val, calendarValue[1])
    ) {
      setIsErrorDate([true, true]);
    } else if (String(val) === "Invalid Date") {
      setIsErrorDate([true, true]);
    } else {
      setIsErrorDate([false, false]);
    }

    if (!checkValidDate(val) && val !== null) return;
    onChange([val, calendarValue[1]]);
  };

  const onChangeTo = (val) => {
    if (val === null) {
      setIsErrorDate([isErrorDate[0], true]);
    } else if (validateFormatDate(val)) {
      setIsErrorDate([isErrorDate[0], true]);
    } else if (new Date(val).getTime() < new Date(calendarValue[0]).getTime()) {
      setIsErrorDate([isErrorDate[0], true]);
    } else if (
      checkValidateThreeMonth &&
      getCheckValidateThreeMonth(calendarValue[0], val)
    ) {
      setIsErrorDate([true, true]);
    } else if (String(val) === "Invalid Date") {
      setIsErrorDate([true, true]);
    } else {
      setIsErrorDate([false, false]);
    }

    if (!checkValidDate(val) && val !== null) return;
    onChange([calendarValue[0], val]);
  };

  const onCalendarChange = (val) => {
    let from_date = val[0];
    let to_date = val[1];

    let error_from = false;
    let error_to = false;

    if (from_date === null) {
      from_date = new Date();
    }
    if (to_date === null) {
      to_date = new Date();
    }

    if (
      new Date(from_date).getTime() > new Date(to_date).getTime() ||
      validateFormatDate(from_date)
    ) {
      error_from = true;
    }

    if (
      new Date(to_date).getTime() < new Date(from_date).getTime() ||
      validateFormatDate(to_date)
    ) {
      error_to = true;
    }

    if (
      checkValidateThreeMonth &&
      getCheckValidateThreeMonth(from_date, to_date)
    ) {
      error_from = true;
      error_to = true;
    }

    setIsErrorDate([error_from, error_to]);
    onChange([from_date, to_date]);
  };

  const selectToday = () => {
    onChange([new Date(), new Date()], true);
    setIsErrorDate([false, false]);
    toggleCalendar(null);
  };

  const selectMonth = (offset = 0) => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth() - offset, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1 - offset, 0);

    setIsErrorDate([false, false]);
    onChange([firstDay, lastDay], true);
    toggleCalendar(null);
  };

  const getHelperText = (type = "") => {
    if (type === "from") {
      if (calendarValue[0] === null) return ERROR_MESSAGE_REQUIRED;
      else if (validateFormatDate(calendarValue[0])) {
        return "Invalid date";
      } else if (
        new Date(calendarValue[0]).getTime() >
        new Date(calendarValue[1]).getTime()
      ) {
        return TEXT_FIELD_ERROR_DATE;
      } else if (
        checkValidateThreeMonth &&
        getCheckValidateThreeMonth(calendarValue[0], calendarValue[1])
      ) {
        return "The date range must be within 3 months";
      } else if (String(calendarValue[0]) === "Invalid Date") {
        return "Invalid date";
      }
    } else if (type === "to") {
      if (calendarValue[1] === null) return ERROR_MESSAGE_REQUIRED;
      else if (validateFormatDate(calendarValue[1])) {
        return "Invalid date";
      } else if (
        new Date(calendarValue[1]).getTime() <
        new Date(calendarValue[0]).getTime()
      ) {
        return TEXT_FIELD_ERROR_DATE_FROM_TO;
      } else if (
        checkValidateThreeMonth &&
        getCheckValidateThreeMonth(calendarValue[0], calendarValue[1])
      ) {
        return "The date range must be within 3 months";
      } else if (String(calendarValue[1]) === "Invalid Date") {
        return "Invalid date";
      }
    }
  };

  return (
    <Box position="relative">
      <Box
        onClick={isErrorDate.every((item) => item === false) && toggleCalendar}
        sx={{
          width: "240px",
          height: "30px",
          paddingX: 2,
          paddingTop: "2px",
          backgroundColor: "#7A58BF26",
          fontSize: 14,
          borderRadius: "15px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginX: marginX,
        }}
      >
        <div>{displayDate()}</div>
        <img alt="" src={IconCalendar} className="my-auto ml-2" />
      </Box>

      {isOpenCalendar && (
        <div
          ref={refCalendar}
          // className="absolute top-8 z-50 border border-gray-300 py-4 px-2 bg-white"
          style={{
            position: "absolute",
            top: "2rem",
            zIndex: "50",
            borderWidth: "1px",
            borderColor: "rgba(212, 212, 216, 1)",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            backgroundColor: "white",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <React.Fragment>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className="grid grid-cols-2 w-full gap-8 px-large">
                <DatePicker
                  label="From"
                  inputFormat="dd/MM/yyyy"
                  open={false}
                  value={calendarValue[0]}
                  onChange={onChangeFrom}
                  disableOpenPicker={true}
                  renderInput={(params) => {
                    if (params?.inputProps?.placeholder) {
                      params.inputProps.placeholder = "DD/MM/YYYY";
                    }

                    return (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { color: "#7A58BF", fontSize: 16 },
                        }}
                        variant="standard"
                        error={
                          isErrorDate[0]
                            ? true
                            : new Date(calendarValue[0]).getTime() >
                                new Date(calendarValue[1]).getTime() ||
                              validateFormatDate(calendarValue[0]) ||
                              calendarValue[0] === null ||
                              (checkValidateThreeMonth &&
                                getCheckValidateThreeMonth(
                                  calendarValue[0],
                                  calendarValue[1]
                                ))
                        }
                        helperText={
                          getHelperText("from")
                            ? getHelperText("from")
                            : isErrorDate[0]
                            ? "Invalid date"
                            : ""
                        }
                      />
                    );
                  }}
                />
                <DatePicker
                  label="To"
                  inputFormat="dd/MM/yyyy"
                  value={calendarValue[1]}
                  onChange={onChangeTo}
                  disableOpenPicker={true}
                  open={false}
                  renderInput={(params) => {
                    if (params?.inputProps?.placeholder) {
                      params.inputProps.placeholder = "DD/MM/YYYY";
                    }

                    return (
                      <TextField
                        variant="standard"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { color: "#7A58BF", fontSize: 16 },
                        }}
                        error={
                          isErrorDate[1]
                            ? true
                            : new Date(calendarValue[1]).getTime() <
                                new Date(calendarValue[0]).getTime() ||
                              validateFormatDate(calendarValue[1]) ||
                              calendarValue[1] === null ||
                              (checkValidateThreeMonth &&
                                getCheckValidateThreeMonth(
                                  calendarValue[0],
                                  calendarValue[1]
                                ))
                        }
                        helperText={
                          getHelperText("to")
                            ? getHelperText("to")
                            : isErrorDate[1]
                            ? "Invalid date"
                            : ""
                        }
                      />
                    );
                  }}
                />
              </div>
              <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                value={calendarValue}
                // sx={{ position: "absolute", zIndex: "100" }}
                onChange={onCalendarChange}
              />
            </LocalizationProvider>
            <div className="flex justify-center pt-3 space-x-5 border-t border-gray-300">
              <button
                onClick={() => selectToday()}
                className="bg-hover hover:bg-textscb font-bold p-10px text-14px rounded-full"
              >
                Today
              </button>
              <button
                onClick={() => selectMonth()}
                className="bg-hover hover:bg-textscb font-bold p-10px text-14px rounded-full"
              >
                This Month
              </button>
              <button
                onClick={() => selectMonth(1)}
                className="bg-hover hover:bg-textscb font-bold p-10px text-14px rounded-full"
              >
                Last Month
              </button>
            </div>
          </React.Fragment>
        </div>
      )}
    </Box>
  );
};
