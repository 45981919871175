import React from "react";
import { CUSTOMER_TITLE } from "../../constants/constant";
import { PBFEContent } from "../../components/Elements/Text/content";
import { Controllers } from "../../components/radium-omnibus/src";
import { TextField } from "@mui/material";
import { DropdownNewWithPlaceholder } from "../../components/radium-omnibus/src/fields/dropdownNew";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  FunctionDeleteErrorSameName,
  FunctionGetObjectIsError,
  TopperError,
} from "../../components/radium-omnibus/src/utils/Field.CheckError";
import {
  postSendApproverCustomerService,
  setIsError,
  setIsSuccess,
  setStateComponent,
} from "../../redux/actionAppbarSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDateApi } from "../../utils/util";
import { ConfirmModal } from "../../components/Panel/Modal/confirmModal";
import { useNavigate } from "react-router-dom";

const CustomerEppBiller = ({ setRouteName, setActionAppbar, dataRequest }) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState({});

  const [errorOpen, setErrorOpen] = React.useState(false);

  const [error, setError] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [dataToApi, setDataToApi] = React.useState({});
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const navigate = useNavigate();
  const {
    loadingAction,
    onClickAction,
    isSuccess,
    confirmText,
    message,
    isError,
  } = useSelector((state) => state.actionAppbar);
  const [loadData, setLoadData] = React.useState({});

  React.useEffect(() => {
    const loadString = sessionStorage.getItem("new_epp_biller");

    const isDuplicate =
      dataRequest?.originalData?.transaction_type === "NEWEPPBILLER" &&
      dataRequest?.originalData?.status === "Returned";

    if (loadString) {
      const jsonData = JSON.parse(loadString);

      setData(jsonData);
      setRouteName([
        { title: CUSTOMER_TITLE, path: `/customer` },
        {
          title: `${jsonData.customer_name}`,
          path: `/customer/${jsonData.customer_id}`,
        },
        // {
        //   title: "NEW EPP BILLER",
        // },
      ]);
      setActionAppbar("NEW_EPP_BILLER");
      sessionStorage.removeItem("new_epp_biller");
    } else if (isDuplicate) {
      console.log("dataRequest", dataRequest);
      setData({
        ...dataRequest?.originalData,
      });
      setLoadData({
        biller_code: dataRequest?.update_obj?.account_id,
        biller_name: dataRequest?.update_obj?.account_name,
        channel_account_id: dataRequest?.update_obj?.channel_account_id,
      });
      setRouteName([
        { title: CUSTOMER_TITLE, path: `/customer` },
        {
          title: `${dataRequest?.originalData?.customer_name}`,
          path: `/customer/${dataRequest?.originalData?.customer_id}`,
        },
        // {
        //   title: "NEW EPP BILLER",
        // },
      ]);
      setActionAppbar("NEW_EPP_BILLER");
    }
    const isObjectEmpty1 = !loadString;
    const isObjectEmpty2 = Object.keys(dataRequest).length === 0;
    if (isObjectEmpty1 && isObjectEmpty2) {
      navigate(-1);
    }
  }, [dataRequest]);

  return (
    <div className="w-full pt-xxl">
      {" "}
      {error && (
        <TopperError
          isShow={errorOpen}
          setOpen={setErrorOpen}
          open={errorOpen}
          errorArray={error}
        />
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="w-full overflow-auto ">
          <div className="grid grid-cols-2 w-full px-xl my-20px">
            <PBFEContent label="Customer Name" value={data?.customer_name} />
            <PBFEContent label="Customer ID" value={data?.customer_id} />
          </div>
          <div className="h-10px bg-gray-100"></div>

          <div className="grid grid-cols-8  gap-x-10 gap-y-2 pt-20px">
            <Controllers
              language="EN"
              showError={false}
              showSymbol={false}
              loadData={loadData}
              fields={[
                {
                  classNameCore: "col-span-2 px-10",
                  name: "biller_code",
                  fieldCustoms: ({ value, setValue, name, thisValue }) => {
                    return (
                      <TextField
                        id={name}
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        label={"Biller Code"}
                        variant="standard"
                        fullWidth
                        sx={{ input: { color: "#7A58BF" } }}
                        inputProps={{ maxLength: 50 }}
                        error={
                          thisValue?.value === "" ? false : thisValue?.error
                        }
                        helperText={
                          thisValue?.value === ""
                            ? "Limited " + value?.length + "/50"
                            : thisValue?.error
                            ? thisValue?.message
                            : "Limited " + value?.length + "/50"
                        }
                      />
                    );
                  },
                  required: true,
                },
                {
                  classNameCore: "col-span-2 px-10",
                  name: "biller_name",
                  fieldCustoms: ({ value, setValue, name, thisValue }) => {
                    return (
                      <TextField
                        id={name}
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        label={"Biller Name"}
                        variant="standard"
                        fullWidth
                        sx={{ input: { color: "#7A58BF" } }}
                        inputProps={{ maxLength: 100 }}
                        error={
                          thisValue?.value === "" ? false : thisValue?.error
                        }
                        helperText={
                          thisValue?.value === ""
                            ? "Limited " + value?.length + "/100"
                            : thisValue?.error
                            ? thisValue?.message
                            : "Limited " + value?.length + "/100"
                        }
                      />
                    );
                  },
                  required: true,
                },
                {
                  classNameCore: "col-span-2 px-10",
                  name: "channel_account_id",
                  fieldCustoms: ({ value, setValue, name, thisValue }) => {
                    return (
                      <TextField
                        id={name}
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        label={"Channel Account ID"}
                        variant="standard"
                        fullWidth
                        sx={{ input: { color: "#7A58BF" } }}
                        inputProps={{ maxLength: 13 }}
                        error={
                          thisValue?.value === "" ? false : thisValue?.error
                        }
                        helperText={
                          thisValue?.value === ""
                            ? "Limited " + value?.length + "/13"
                            : thisValue?.error
                            ? thisValue?.message
                            : "Limited " + value?.length + "/13"
                        }
                      />
                    );
                  },
                },
                {
                  type: "none",
                  classNameCore: "col-span-2 px-10",
                  render: () => {
                    return <div></div>;
                  },
                },
                {
                  type: "none",
                  classNameCore: "col-span-2 px-10",
                  render: () => {
                    return <PBFEContent label="Biller Status" value="Normal" />;
                  },
                },
                {
                  type: "none",
                  classNameCore: "col-span-2 px-10",
                  render: () => {
                    return <PBFEContent label="Bank Code" value="014" />;
                  },
                },
                {
                  type: "none",
                  classNameCore: "col-span-2 px-10",
                  render: () => {
                    return <PBFEContent label="Currency Code" value="THB" />;
                  },
                },
                {
                  type: "none",
                  classNameCore: "col-span-2 px-10",
                  render: () => {
                    return (
                      <PBFEContent
                        label="Activation Date"
                        value={new Date().toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                        })}
                      />
                    );
                  },
                },
              ]}
              classNameSubmit="hidden"
              onSubmit={async (form) => {
                const errorState = FunctionGetObjectIsError(form);

                if (errorState?.length > 0) {
                  setError(FunctionDeleteErrorSameName(errorState));
                  setErrorOpen(true);
                  return;
                }

                setErrorOpen(false);
                setError([]);

                const result = {
                  transaction_type: "NEWEPPBILLER",
                  account_id: form?.biller_code,
                  customer_id: data?.customer_id,
                  reference1: form?.biller_code,
                  remark: form?.biller_name,
                  data: {
                    display_obj: {},
                    update_obj: {
                      account_id: form?.biller_code,
                      account_name: form?.biller_name,
                      channel_account_id: form?.channel_account_id,
                      customer_id: data?.customer_id,
                      biller_status: "Normal",
                      bank_code: "014",
                      currency_code: "THB",
                      activation_date: formatDateApi(new Date()),
                    },
                  },
                };
                setDataToApi(result);
                setOpenConfirm(true);

                // console.log("CLICK :", {
                //   account_id: form?.biller_code,
                //   account_name: form?.biller_name,
                //   channel_account_id: form?.channel_account_id,
                //   customer_id: data?.customer_id,
                //   reference1: form?.biller_code,
                //   result,
                // });
              }}
            />
          </div>
        </div>
      </LocalizationProvider>
      <ConfirmModal
        onClick={() => {
          dispatch(
            postSendApproverCustomerService({
              data: dataToApi,
            })
          );
        }}
        loading={loadingAction}
        isSuccess={isSuccess}
        isError={isError}
        message={message}
        open={openConfirm}
        onClose={() => {
          dispatch(setIsError(false));
          setOpenConfirm(false);
          dispatch(setIsSuccess(false));
          if (isSuccess) {
            navigate(`/customer/${data?.customer_id}`);
            // window.location.reload();
          }
        }}
      />
    </div>
  );
};

export default CustomerEppBiller;

{
  /* <Controllers
            language="EN"
            showError={false}
            showSymbol={false}
            fields={[
              {
                classNameCore: "col-span-4 px-10",
                name: "account_name",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Account Name"}
                      variant="standard"
                      fullWidth
                      sx={{ input: { color: "#7A58BF" } }}
                      inputProps={{ maxLength: 100 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/100"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-4 px-10",
                name: "account_no",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Account No."}
                      variant="standard"
                      fullWidth
                      sx={{ input: { color: "#7A58BF" } }}
                      inputProps={{ maxLength: 100 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/100"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-2 px-10",
                name: "account_type",
                fieldCustoms: ({ value, setValue, thisValue }) => {
                  return (
                    <DropdownNewWithPlaceholder
                      value={value}
                      label={`Account Type`}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      options={["NO", "YES"]}
                      error={thisValue?.error}
                      helperText={thisValue?.error ? thisValue?.message : ""}
                    />
                  );
                },
                required: false,
              },
              {
                classNameCore: "col-span-2 px-10",
                name: "activation_date",
                fieldCustoms: ({ value, setValue }) => {
                  return (
                    <div className="">
                      <DesktopDatePicker
                        inputFormat="dd/MM/yyyy"
                        disablePast
                        //   disabled={
                        //     new Date(data?.effective_from).getTime() <=
                        //     new Date().getTime()
                        //   }
                        value={value?.from_date}
                        //   onChange={(e) =>
                        //     setValue({
                        //       ...value,
                        //       from_date: formatDateCheckValid(e),
                        //     })
                        //   }
                        renderInput={(params) => {
                          if (params?.inputProps?.placeholder) {
                            params.inputProps.placeholder = "DD/MM/YYYY";
                          }
                          return (
                            <TextField
                              {...params}
                              placeholder="DD/MM/YYYY"
                              InputProps={{
                                ...params.InputProps,
                                style: { color: "#7A58BF", fontSize: 16 },
                                placeholder: "DD/MM/YYYY",
                              }}
                              variant="standard"
                              label={"Activation Date"}
                              // error={
                              //   checkFromToDate(
                              //     value?.from_date,
                              //     value?.to_date,
                              //     null,
                              //     "from_date"
                              //   ) ||
                              //   FunctionCheckDateLength(
                              //     value?.from_date,
                              //     "from_date"
                              //   ).error
                              // }
                              // helperText={
                              //   FunctionCheckDateLength(
                              //     value?.from_date,
                              //     "from_date"
                              //   ).error
                              //     ? FunctionCheckDateLength(
                              //         value?.from_date,
                              //         "from_date"
                              //       ).message
                              //     : checkFromToDate(
                              //         value?.from_date,
                              //         value?.to_date,
                              //         null,
                              //         "from_date"
                              //       )
                              // }
                            />
                          );
                        }}
                      />
                    </div>
                  );
                },
              },
              {
                classNameCore: "col-span-2 px-10",
                name: "application_date",
                fieldCustoms: ({ value, setValue }) => {
                  return (
                    <div className="">
                      <DesktopDatePicker
                        inputFormat="dd/MM/yyyy"
                        disablePast
                        //   disabled={
                        //     new Date(data?.effective_from).getTime() <=
                        //     new Date().getTime()
                        //   }
                        value={value?.from_date}
                        //   onChange={(e) =>
                        //     setValue({
                        //       ...value,
                        //       from_date: formatDateCheckValid(e),
                        //     })
                        //   }
                        renderInput={(params) => {
                          if (params?.inputProps?.placeholder) {
                            params.inputProps.placeholder = "DD/MM/YYYY";
                          }
                          return (
                            <TextField
                              {...params}
                              placeholder="DD/MM/YYYY"
                              InputProps={{
                                ...params.InputProps,
                                style: { color: "#7A58BF", fontSize: 16 },
                                placeholder: "DD/MM/YYYY",
                              }}
                              variant="standard"
                              label={"Application Date"}
                              // error={
                              //   checkFromToDate(
                              //     value?.from_date,
                              //     value?.to_date,
                              //     null,
                              //     "from_date"
                              //   ) ||
                              //   FunctionCheckDateLength(
                              //     value?.from_date,
                              //     "from_date"
                              //   ).error
                              // }
                              // helperText={
                              //   FunctionCheckDateLength(
                              //     value?.from_date,
                              //     "from_date"
                              //   ).error
                              //     ? FunctionCheckDateLength(
                              //         value?.from_date,
                              //         "from_date"
                              //       ).message
                              //     : checkFromToDate(
                              //         value?.from_date,
                              //         value?.to_date,
                              //         null,
                              //         "from_date"
                              //       )
                              // }
                            />
                          );
                        }}
                      />
                    </div>
                  );
                },
              },
              {
                classNameCore: "col-span-2 px-10",
                name: "closing_date",
                fieldCustoms: ({ value, setValue }) => {
                  return (
                    <div className="">
                      <DesktopDatePicker
                        inputFormat="dd/MM/yyyy"
                        disablePast
                        //   disabled={
                        //     new Date(data?.effective_from).getTime() <=
                        //     new Date().getTime()
                        //   }
                        // value={value?.from_date}
                        //   onChange={(e) =>
                        //     setValue({
                        //       ...value,
                        //       from_date: formatDateCheckValid(e),
                        //     })
                        //   }
                        renderInput={(params) => {
                          if (params?.inputProps?.placeholder) {
                            params.inputProps.placeholder = "DD/MM/YYYY";
                          }
                          return (
                            <TextField
                              {...params}
                              placeholder="DD/MM/YYYY"
                              InputProps={{
                                ...params.InputProps,
                                style: { color: "#7A58BF", fontSize: 16 },
                                placeholder: "DD/MM/YYYY",
                              }}
                              variant="standard"
                              label={"Closing Date"}
                            />
                          );
                        }}
                      />
                    </div>
                  );
                },
              },
              {
                classNameCore: "col-span-2 px-10",
                name: "bank_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Bank Code"}
                      variant="standard"
                      fullWidth
                      sx={{ input: { color: "#7A58BF" } }}
                      inputProps={{ maxLength: 100 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/100"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-2 px-10",
                name: "branch_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Branch Code"}
                      variant="standard"
                      fullWidth
                      sx={{ input: { color: "#7A58BF" } }}
                      inputProps={{ maxLength: 100 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/100"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-2 px-10",
                name: "status",
                fieldCustoms: ({ value, setValue, thisValue }) => {
                  return (
                    <DropdownNewWithPlaceholder
                      value={value}
                      label={`Status`}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      options={["NO", "YES"]}
                      error={thisValue?.error}
                      helperText={thisValue?.error ? thisValue?.message : ""}
                    />
                  );
                },
                required: false,
              },
              {
                classNameCore: "col-span-2 px-10",
                name: "currency_code",
                fieldCustoms: ({ value, setValue, thisValue }) => {
                  return (
                    <DropdownNewWithPlaceholder
                      value={value}
                      label={`Currency Code`}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      options={["NO", "YES"]}
                      error={thisValue?.error}
                      helperText={thisValue?.error ? thisValue?.message : ""}
                    />
                  );
                },
                required: false,
              },
              {
                classNameCore: "col-span-4 px-10",
                name: "product",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Product"}
                      variant="standard"
                      fullWidth
                      sx={{ input: { color: "#7A58BF" } }}
                      inputProps={{ maxLength: 100 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/100"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-2 px-10",
                name: "oc_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"OC Code"}
                      variant="standard"
                      fullWidth
                      sx={{ input: { color: "#7A58BF" } }}
                      inputProps={{ maxLength: 100 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/100"
                      }
                    />
                  );
                },
                required: true,
              },
            ]}
          /> */
}
