import api from "./index";
import { apiConfig } from "../config";

const getOfferBundles = async (params, query) => {
  return api.instanceCorp.get(apiConfig.offerBundleAPI);
};

const getOfferBundlesInfo = async (params, query) => {
  return api.instanceCorp.get(
    `${apiConfig.offerBundleAPI}/${params.offer_code}`
  );
};

const getHistoryOfferBundle = async (params, query) => {
  return api.instanceCorp.get(`${apiConfig.offerBundleAPI}/histories${query}`);
};

const postOfferBundle = async (body) => {
  return api.instanceCorp.post(apiConfig.offerBundleAPI, {
    ...body,
  });
};

const updateOfferBundle = async (params, body) => {
  return api.instanceCorp.put(`${apiConfig.offerBundleAPI}/${params.id}`, {
    ...body,
  });
};

const getOfferBundleCustomerById = async (params, queryString) => {
  return api.instanceCorp.get(
    `${apiConfig.offerBundleAPI}/${params.customer_id}/unassigned-offers`
  );
};

const exportedObject = {
  getOfferBundles,
  getOfferBundlesInfo,
  getHistoryOfferBundle,
  postOfferBundle,
  updateOfferBundle,
  getOfferBundleCustomerById,
};

export default exportedObject;
