import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

reportWebVitals();

// import React from "react";
// import ReactDOM from "react-dom";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { Provider } from "react-redux";
// import store from "./redux/store";
// import { getUserProfileThunk } from "./redux/authSlice";

// // store.dispatch(getUserProfileThunk());

// ReactDOM.render(
//   <React.StrictMode>
// <Provider store={store}>
//   <App />
// </Provider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// reportWebVitals();
