import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api/authService";
import userProfileService from "../api/userProfile";

// utils
import {
  getTokenFromLocalStorage,
  checkUserLogin,
  saveTokenToLocalStorage,
  parseJwt,
  getUserFromStorage,
} from "../utils/util";

const user = getTokenFromLocalStorage();

export const getUserProfileThunk = createAsyncThunk(
  "auth/profile",
  async () => {
    try {
      const sessionData = getTokenFromLocalStorage();
      if (sessionData.access_token) {
        const { data: responseData } = await userProfileService.getUser();
        const userInfo = parseJwt(sessionData?.access_token);
        const user = {
          user: {
            ...responseData?.data,
            info: userInfo,
            profile: responseData.data,
          },
        };
        sessionStorage.setItem("user", JSON.stringify(user));
        return user;
      }

      return {
        user: null,
      };
    } catch (error) {
      console.error("auth get token error", error);
      return { user: null };
    }
  }
);

export const getTokenThunk = createAsyncThunk(
  "auth/token",
  async ({ code, session_state }, thunkAPI) => {
    try {
      const { data: responseData } = await authService.getToken(
        `?code=${code}&session_state=${session_state}`
      );

      saveTokenToLocalStorage(responseData?.data);
      const userInfo = parseJwt(responseData?.data?.access_token);
      return {
        user: {
          ...responseData?.data,
          info: userInfo,
        },
      };
    } catch (error) {
      console.error("auth get token error", error);
      return { user: null };
    }
  }
);

export const refreshTokenThunk = createAsyncThunk(
  "auth/token",
  async ({ refresh_token }, thunkAPI) => {
    try {
      const { data: responseData } = await authService.refreshToken(
        refresh_token
      );
      saveTokenToLocalStorage(responseData?.data);
    } catch (error) {
      console.error("refresh token error", error);
      return { user: null };
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    // const { data: responseData } = await authService.logout();
    sessionStorage.clear();
    return { user: null };
  } catch (error) {
    console.error("refresh token error", error);
    return { user: null };
  }
});

const initialState = checkUserLogin()
  ? { isLoggedIn: true, user: getUserFromStorage() }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [getTokenThunk.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.isLoggedIn = true;
    },
    [getTokenThunk.rejected]: (state, action) => {
      state.user = null;
      state.isLoggedIn = false;
    },
    [refreshTokenThunk.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
    },
    [refreshTokenThunk.rejected]: (state, action) => {
      state.user = null;
      state.isLoggedIn = false;
    },
    [logout.fulfilled]: (state, action) => {
      state.user = null;
      state.isLoggedIn = false;
    },
    [getUserProfileThunk.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.isLoggedIn = true;
    },
    [getUserProfileThunk.rejected]: (state, action) => {
      state.user = null;
      state.isLoggedIn = false;
    },
  },
});

export default authSlice.reducer;
