import * as React from "react";
import { TYPE_BUTTON, TYPE_NONE, TYPE_SUBMIT } from "../type";
import { TEXT_AUTHOR, TEXT_SUBMIT } from "../utils/Field.Constant";
import { FunctionLoadOriginalData } from "../utils/Field.LoadData";
import { FunctionSubmit } from "../utils/Field.Submit";
import CoreMain from "./CoreMain";
import { CoreStep } from "./CoreStep";

const RenderButton = ({
  fields,
  index,
  FunctionRemoveStep,
  loadData,
  parentIndex,
  parentKey,
  fieldIndex,
  grandParentIndex,
  grandParentKey,
  isLastStep,
  isFirstStep,
  initalValue,
  FunctionAddStep,
  showSymbol,
  arrayCount,
  currentData,
  FunctionEditStep,
}) => {
  const [firstInitData, setFirstInitData] = React.useState(false);

  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!loaded && initalValue) {
      setFirstInitData(initalValue);
      setLoaded(true);
    }
  }, [initalValue]);

  return (
    <div
      className={` ${TEXT_AUTHOR}-button-core ${
        fields[index].classNameCore ? fields[index].classNameCore : ""
      }`}
    >
      <React.Fragment>
        {fields[index].render({
          remove: FunctionRemoveStep,
          add: FunctionAddStep,
          edit: FunctionEditStep,
          originalData: FunctionLoadOriginalData({
            loadData,
            parentIndex,
            parentKey,
            fieldIndex,
            grandParentIndex,
            grandParentKey,
          }),
          grandParentIndex,
          grandParentKey,
          parentKey,
          parentIndex,
          fieldIndex,
          selfIndex: index,
          isLast: isLastStep,
          isFirst: isFirstStep,
          initalValue,
          firstInitData,
          arrayCount,
          loadData,
          currentData,
        })}
      </React.Fragment>
    </div>
  );
};

export const FunctionCore = ({
  fields,
  FieldReference,
  loadData,
  mode,
  language,
  grandParentIndex,
  grandParentKey,
  parentKey,
  parentIndex,
  fieldIndex,
  showError,
  reset,
  FunctionReset,
  initalValue = {},
  FunctionRemoveStep = () => {},
  FunctionAddStep = () => {},
  FunctionEditStep = () => {},
  isLastStep = false,
  isFirstStep = false,
  showSymbol,
  arrayCount = 0,
  currentData,
}) => {
  return fields?.map((_, index) => {
    if (fields[index].type === TYPE_SUBMIT) {
      return (
        <button
          id={`${TEXT_AUTHOR}-type-submit`}
          className={`${TEXT_AUTHOR}-submit ${fields[index].classNameSubmit}`}
          onClick={(e) => {
            FunctionSubmit(
              fields,
              FieldReference,
              fields[index].onSubmit,
              fields[index].renderAlertError
            );
          }}
        >
          {fields[index].labelSubmit
            ? fields[index].labelSubmit
            : TEXT_SUBMIT[language]}
        </button>
      );
    } else if (fields[index].type === TYPE_BUTTON) {
      return (
        <RenderButton
          key={`${TEXT_AUTHOR}-div-key-core-button-${index}`}
          fields={fields}
          index={index}
          FunctionRemoveStep={FunctionRemoveStep}
          loadData={loadData}
          parentIndex={parentIndex}
          parentKey={parentKey}
          fieldIndex={fieldIndex}
          grandParentIndex={grandParentIndex}
          grandParentKey={grandParentKey}
          isLastStep={isLastStep}
          isFirstStep={isFirstStep}
          initalValue={initalValue}
          FunctionAddStep={FunctionAddStep}
          arrayCount={arrayCount}
          currentData={currentData}
          FunctionEditStep={FunctionEditStep}
        />
      );
    } else if (fields[index].name || fields[index].type === TYPE_NONE) {
      return (
        <div
          key={`${TEXT_AUTHOR}-div-key-core-${index}`}
          className={` ${TEXT_AUTHOR}-core ${
            fields[index].classNameCore ? fields[index].classNameCore : ""
          }`}
        >
          <CoreMain
            key={`${TEXT_AUTHOR}-key-core-${index}`}
            mode={mode}
            fields={fields}
            index={index}
            FieldReference={FieldReference}
            loadData={loadData}
            language={language}
            fieldIndex={fieldIndex}
            parentIndex={parentIndex}
            parentKey={parentKey}
            grandParentIndex={grandParentIndex}
            grandParentKey={grandParentKey}
            showError={showError}
            reset={reset}
            FunctionReset={FunctionReset}
            initalValue={
              Object.keys(initalValue).length > 0
                ? initalValue
                : fields[index].name && fields[index].initalValue
                ? { [fields[index].name]: fields[index].initalValue }
                : {} || {}
            }
            FunctionRemoveStep={FunctionRemoveStep}
            FunctionAddStep={FunctionAddStep}
            originalData={FunctionLoadOriginalData({
              loadData,
              parentIndex,
              parentKey,
              fieldIndex,
              grandParentIndex,
              grandParentKey,
            })}
            isLastStep={isLastStep}
            isFirstStep={isFirstStep}
            showSymbol={showSymbol}
            currentData={currentData}
          />
        </div>
      );
    } else if (fields[index]?.key && fields[index]?.fields?.length > 0) {
      return (
        <div
          className={`${TEXT_AUTHOR}-core-step ${
            fields[index].classNameCore ? fields[index].classNameCore : ""
          } `}
        >
          <CoreStep
            showIndexZero={fields[index].showIndexZero}
            renderIndexZero={fields[index].renderIndexZero || false}
            mode={mode}
            fields={fields[index].fields}
            FieldReference={FieldReference}
            key={`${TEXT_AUTHOR}-key-core-step-${index}`}
            parentKey={fields[index]?.key}
            parentIndex={index}
            loadData={loadData}
            grandParentKey={fields[index]?.key}
            grandParentIndex={index}
            language={language}
            classNameAdd={fields[index].classNameAdd || ""}
            classNameRemove={fields[index].classNameRemove || ""}
            addLabel={fields[index].addLabel || ""}
            removeLabel={fields[index].removeLabel || ""}
            showError={showError}
            triggerDelete={fields[index].triggerDelete || false}
            initalValue={fields[index].initalValue || false}
            showButtonRemove={
              fields[index].showButtonRemove === false ? false : true
            }
            showButtonAdd={fields[index].showButtonAdd === false ? false : true}
            originalFields={fields}
          />
        </div>
      );
    } else if (fields[index].childKey && fields[index]?.fields?.length > 0) {
      return (
        <div
          className={`${TEXT_AUTHOR}-multicore-step ${
            fields[index].classNameCore ? fields[index].classNameCore : ""
          }`}
        >
          <CoreStep
            showIndexZero={fields[index].showIndexZero}
            renderIndexZero={fields[index].renderIndexZero || false}
            mode={mode}
            fields={fields[index].fields}
            FieldReference={FieldReference}
            key={`${TEXT_AUTHOR}-key-multicore-step-${index}`}
            grandParentKey={grandParentKey}
            grandParentIndex={grandParentIndex}
            parentKey={fields[index]?.childKey}
            parentIndex={fieldIndex}
            loadData={loadData}
            language={language}
            classNameAdd={fields[index].classNameAdd || ""}
            classNameRemove={fields[index].classNameRemove || ""}
            addLabel={fields[index].addLabel || ""}
            removeLabel={fields[index].removeLabel || ""}
            showError={showError}
            triggerDelete={fields[index].triggerDelete || false}
            initalValue={fields[index].initalValue || false}
            showButtonRemove={
              fields[index].showButtonRemove === false ? false : true
            }
            showButtonAdd={fields[index].showButtonAdd === false ? false : true}
            originalFields={fields}
          />
        </div>
      );
    } else {
      return null;
    }
  });
};
