import api from "./index";
import { apiConfig } from "../config";

const getBankBranch = async (params, query) => {
  return api.instanceCorp.get(`${apiConfig.getBankBranch}${query}`);
};

const getBankId = async () => {
  return api.instanceCorp.get(`${apiConfig.getBankId}`);
};

const exportedObject = {
  getBankBranch,
  getBankId,
};

export default exportedObject;
