import api from "./index";

// config
import { apiConfig } from "../config";

const getReport = async (params, queryString) => {
  return api.instanceCorp.get(`${apiConfig.getReport}${queryString}`, {
    headers: { "content-type": "multipart/form-data" },
  });
};

const generateReport = async (body) => {
  return api.instanceCorp.post(`${apiConfig.getReport}`, {
    ...body,
  });
};

const downloadReport = async (reportId) => {
  return api.instanceCorp.get(`${apiConfig.getReport}/${reportId}/download`, {
    responseType: "blob",
  });
};

export default {
  getReport,
  generateReport,
  downloadReport,
};
