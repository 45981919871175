import api from "./index";
import { apiConfig } from "../config";

const getSystemService = async (params, query) => {
  return api.instanceCorp.get(`${apiConfig.putSystemSetting}${query}`);
};

const putSystemService = async (body) => {
  return api.instanceCorp.put(`${apiConfig.putSystemSetting}`, body);
};

const exportedObject = {
  putSystemService,
  getSystemService,
};

export default exportedObject;
