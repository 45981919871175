import React, { useEffect } from "react";
// component
import { PBFELoading } from "../../components/Elements/Loading/loading";

// config
import { keyCloakRedirect } from "../../config";

function Login() {
  useEffect(() => {
    sessionStorage.clear();
    return window.open(keyCloakRedirect, "_self");
  }, []);

  return (
    <div className="h-screen flex items-center justify-center w-full ">
      <PBFELoading />
    </div>
  );
}

export default Login;
