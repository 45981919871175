import * as React from "react";
import { TYPE_FUNCTION } from "../type";
import { SIGN_REQUIRED, TEXT_AUTHOR } from "../utils/Field.Constant";
import { FieldStorage } from "../utils/Field.Storage";
import {
  STYLE_REQUIRED_FIELD_FLEX,
  STYLE_REQUIRED_RED_STAR,
} from "../utils/Field.Style";
import { FieldError } from "./Field.Error";
import { FieldRenderNoneEdit } from "./Field.RenderNonEdit";

export const FieldContainer = (props) => {
  if (
    typeof props.isEdit === TYPE_FUNCTION ? props.isEdit(props) : props.isEdit
  ) {
    return (
      <div
        className={`${TEXT_AUTHOR}-wrapper ${
          typeof props.classNameWrapper === TYPE_FUNCTION
            ? props.classNameWrapper(props)
            : props.classNameWrapper
        }`}
        key={`${TEXT_AUTHOR}-field-text-${props.index}`}
      >
        <div style={props.required ? STYLE_REQUIRED_FIELD_FLEX : {}}>
          <div className={`${TEXT_AUTHOR}-label ${props.classNameLabel}`}>
            {typeof props.label === TYPE_FUNCTION
              ? props.label(props)
              : props.label}
          </div>
          {props.required && props.showSymbol ? (
            <div style={STYLE_REQUIRED_RED_STAR}>{SIGN_REQUIRED}</div>
          ) : (
            <React.Fragment />
          )}
        </div>
        <div
          className={`${TEXT_AUTHOR}-sub-label ${
            typeof props.classNameSubLabel === TYPE_FUNCTION
              ? props.classNameSubLabel(props)
              : props.classNameSubLabel
          }`}
        >
          {typeof props.subLabel === TYPE_FUNCTION
            ? props.subLabel(props)
            : props.subLabel}
        </div>

        {props.inputBody}
        <FieldStorage
          FieldReference={props.FieldReference}
          name={props.name}
          value={props.value}
          FunctionGetThisValue={props.FunctionGetThisValue}
          index={props.index}
          reference={props.reference}
          fieldIndex={props.fieldIndex}
          grandParentIndex={props.grandParentIndex}
          grandParentKey={props.grandParentKey}
          parentIndex={props.parentIndex}
          parentKey={props.parentKey}
        />
        {props.renderFooter && props.renderFooter(props)}
        {props.showError && (
          <React.Fragment>
            {props?.renderError ? (
              props?.renderError({
                error: props?.FunctionGetThisValue?.message,
              })
            ) : (
              <div
                className={`${TEXT_AUTHOR}-error ${
                  typeof props.classNameError === TYPE_FUNCTION
                    ? props.classNameError({
                        error: props?.FunctionGetThisValue?.message,
                      })
                    : props.classNameError
                }`}
              >
                {props?.FunctionGetThisValue?.error && (
                  <FieldError message={props?.FunctionGetThisValue?.message} />
                )}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  } else if (props.render) {
    return props.render({
      value: props.value,
      loading: props.loading,
    });
  } else {
    return (
      <FieldRenderNoneEdit
        classNameLabel={props.classNameLabel}
        classNameValue={props.classNameValue}
        label={props.label}
        value={props.value}
        classNameSubLabel={props.classNameSubLabel}
        subLabel={props.subLabel}
        classNameWrapper={props.classNameWrapper}
      />
    );
  }
};
