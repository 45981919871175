import api from "./index";
import { apiConfig } from "../config";

const getUserGroup = async (params, query) => {
  return api.instanceCorp.get(apiConfig.getUserGroup);
};

const getUserGroupInfo = async (params, query) => {
  return api.instanceCorp.get(`${apiConfig.getUserGroup}/${params.id}`);
};

const putUserGroup = async (id, body) => {
  return api.instanceCorp.put(apiConfig.getUserGroup + "/" + id, {
    ...body,
  });
};

const postUserGroup = async (body) => {
  return api.instanceCorp.post(apiConfig.getUserGroup, {
    ...body,
  });
};

const exportedObject = {
  getUserGroup,
  getUserGroupInfo,
  putUserGroup,
  postUserGroup,
};

export default exportedObject;
