import api from "./index";
import { apiConfig } from "../config";

const getTaxScheme = async (params, query) => {
  return api.instanceCorp.get(apiConfig.getTaxSchemeAPI);
};

const getTaxSchemeInfo = async (params, query) => {
  return api.instanceCorp.get(
    `${apiConfig.getTaxSchemeAPI}/${params.tax_scheme_id}`
  );
};

const createTaxscheme = async (body) => {
  return api.instanceCorp.post(`${apiConfig.postTaxScheme}`, {
    ...body,
  });
};

const updateTaxscheme = async (tax_scheme_id,body) => {
  return api.instanceCorp.put(`${apiConfig.postTaxScheme}/${tax_scheme_id}`, {
    ...body,
  });
};


const exportedObject = {  
  getTaxScheme,
  getTaxSchemeInfo,
  createTaxscheme,
  updateTaxscheme
}

export default exportedObject;
