import * as React from "react";
import {
  TYPE_MODEL_ERROR_EMAIL,
  TYPE_MODEL_ERROR_LIMIT,
  TYPE_MODEL_ERROR_MINIMUM,
  TYPE_MODEL_ERROR_NO_SPACE,
  TYPE_MODEL_ERROR_NUMBER,
  TYPE_MODEL_ERROR_REQURIED,
} from "../type";

export const FunctionSum = (input) => {
  if (toString.call(input) !== "[object Array]") return false;

  let total = 0;
  for (let i = 0; i < input.length; i++) {
    if (isNaN(input[i])) {
      continue;
    }
    total += Number(input[i]);
  }
  return total;
};

export const FunctionMinus = (input) => {
  if (toString.call(input) !== "[object Array]") return false;

  let total = 0;
  for (let i = 0; i < input.length; i++) {
    if (isNaN(input[i])) {
      continue;
    }
    total -= Number(input[i]);
  }
  return total;
};

export const FunctionMultiple = (input) => {
  if (toString.call(input) !== "[object Array]") return false;

  let total = 1;
  for (let i = 0; i < input.length; i++) {
    if (isNaN(input[i])) {
      continue;
    }
    total *= Number(input[i]);
  }
  return total;
};

export const FunctionDivide = (input) => {
  if (toString.call(input) !== "[object Array]") return false;

  let total = input[0];
  for (let i = 1; i < input.length; i++) {
    if (isNaN(input[i])) {
      continue;
    }
    total /= Number(input[i]);
  }
  return total;
};

export const FunctionCalurate = (value, option, name, sender) => {
  if (value && option) {
    if (typeof option === "string") {
      if (option === "sum") {
        return FunctionSum(value);
      } else if (option === "minus") {
        return FunctionMinus(value);
      } else if (option === "multiple") {
        return FunctionMultiple(value);
      } else if (option === "divide") {
        return FunctionDivide(value);
      }
    } else {
      let original = [];
      let object = {};

      sender.map((s, i) => {
        original.push({ name: s, value: Number(value[i]) || 0 });
        object[s] = Number(value[i]) || 0;
      });

      return option({ value, name, sender, original, object });
    }
  } else {
    return "Error";
  }
};

export const FunctionCheckThaiNationalID = (id) => {
  if (id == null || id.length !== 13 || !/^[0-9]\d+$/.test(id)) return !1;
  let i,
    sum = 0;
  for (i = 0, sum = 0; i < 12; i++) {
    sum += parseInt(id.charAt(i)) * (13 - i);
  }
  let check = (11 - (sum % 11)) % 10;
  if (check === parseInt(id.charAt(12))) {
    return !0;
  }
  return !1;
};

export const FunctionCheckIsRequired = (input, alert) => {
  if (input === 0) {
    return "";
  } else if (input === "") {
    return alert;
  } else if (input === null) {
    return alert;
  } else if (input === undefined) {
    return alert;
  } else if (input) {
    return "";
  } else {
    return alert;
  }
};

export const FunctionAddUrlToFile = (file) => {
  if (file) {
    return URL.createObjectURL(file);
  } else {
    return;
  }
};

export const FunctionCheckIsNumber = (value, alert, error) => {
  const real_value = Number(value) >= 0 ? Number(value) : value;

  if (value === `-0`) {
    return error;
  } else if (isNaN(real_value)) {
    if (value === undefined) {
      return "";
    } else {
      return alert;
    }
  } else if (real_value >= 0) {
    return "";
  } else {
    return error;
  }
};

export const FunctionCheckEmail = (input, alert) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isEmail = emailRegex.test(String(input).toLowerCase());
  if (isEmail) {
    return "";
  } else {
    return alert;
  }
};

export const FunctionCheckLimit = (input, limit, alert) => {
  if (input && limit >= 0) {
    const checkIsString = toString.call(input) === "[object String]";
    const checkIsNumber = toString.call(input) === "[object Number]";
    if (checkIsString) {
      if (input.length > limit) {
        return alert;
      } else {
        return "";
      }
    } else if (checkIsNumber) {
      if (input > limit) {
        return alert;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const FunctionCheckMinimum = (input, minimum, alert) => {
  if (input && minimum > 0) {
    const checkIsString = toString.call(input) === "[object String]";
    const checkIsNumber = toString.call(input) === "[object Number]";
    if (checkIsString) {
      if (input.length < minimum) {
        return alert;
      } else {
        return "";
      }
    } else if (checkIsNumber) {
      if (input < minimum) {
        return alert;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const FunctionCheckNoSpace = (input, alert) => {
  if (input) {
    const checkIsString = toString.call(input) === "[object String]";
    if (checkIsString) {
      if (input.includes(" ")) {
        return alert;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const FunctionAdaptorToNormalForm = (objects) => {
  if (objects) {
    const new_obejct = {};
    const keys = Object.keys(objects);
    keys.forEach((key, i) => {
      if (objects[key].field_name) {
        new_obejct[objects[key].field_name] = objects[key].value || "";
      } else {
        const thisObjects = objects[key];
        const new_step = thisObjects.map((row) => {
          const new_row = {};
          const thisKey = Object.keys(row);
          thisKey.forEach((key) => {
            new_row[key] = row[key].value || "";
          });
          return new_row;
        });
        new_obejct[keys[i]] = new_step;
      }
    });
    return new_obejct;
  } else {
    return {};
  }
};

export const FunctionChangeSnakeCaseToNormalCase = (string) => {
  if (typeof string === "string") {
    const first = string?.slice(0, 1).toLocaleUpperCase();
    const last = string.replace(/([-_][a-z])/g, (group) => {
      return group.toUpperCase().replace("-", " ").replace("_", " ");
    });
    return first + last.slice(1);
  } else {
    return "-";
  }
};

export const FunctionGetModelError = (
  required,
  number,
  email,
  limit,
  minimum,
  no_space
) => {
  let modelError = [];

  if (required) {
    modelError.push(TYPE_MODEL_ERROR_REQURIED);
  }
  if (number) {
    modelError.push(TYPE_MODEL_ERROR_NUMBER);
  }
  if (email) {
    modelError.push(TYPE_MODEL_ERROR_EMAIL);
  }
  if (limit > 0) {
    modelError.push(`${TYPE_MODEL_ERROR_LIMIT}:${limit}`);
  }
  if (minimum > 0) {
    modelError.push(`${TYPE_MODEL_ERROR_MINIMUM}:${minimum}`);
  }
  if (no_space) {
    modelError.push(TYPE_MODEL_ERROR_NO_SPACE);
  }
  return modelError;
};
