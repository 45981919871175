import React from "react";

export const PBFEContent = ({
  label = "label",
  value = "value",
  valueStyle = "",
  mode = "",
  overwriteText = "text-label",
  w = "w-full",
  disable = false,
  isSCBColor = false,
}) => {
  return (
    <div className={mode} id="PBFEContent">
      <div
        id="PBFEContentLabel"
        className={`${overwriteText} text-textlabel ${w} `}
      >
        {label}
      </div>
      {Array.isArray(value) ? (
        <div className="flex flex-row space-x-1">
          {value?.map((item, index) => (
            <div
              key={index}
              className="bg-gray-200 rounded-xl font-bold p-1 text-label"
            >
              {item?.transaction_code}
            </div>
          ))}
        </div>
      ) : (
        <div
          id="PBFEContentValue"
          className={
            "text-value " +
            (isSCBColor ? "" : " font-bold ") +
            "break-words " +
            valueStyle +
            (disable
              ? " text-disable "
              : isSCBColor
              ? " text-textscb"
              : " text-textprimary")
          }
        >
          {value}
        </div>
      )}
    </div>
  );
};
