import * as React from "react";

export const FieldInitialReferenceStep = (props) => {
  let initialValue = {};
  props.fields
    ?.filter((s) => {
      return !s.childKey;
    })
    .forEach((k) => {
      initialValue[k.name] = "";
    });
  if (props.grandParentKey === props.parentKey) {
    props.FieldReference.current[props.parentIndex] = {
      [props.parentKey]: [initialValue],
    };
  } else if (
    props.grandParentKey !== props.parentKey &&
    props.grandParentIndex >= 0 &&
    props.parentIndex >= 0 &&
    props.FieldReference.current[props.grandParentIndex]
  ) {
    props.FieldReference.current[props.grandParentIndex][props.grandParentKey][
      props.parentIndex
    ] = {
      ...props.FieldReference.current[props.grandParentIndex][
        props.grandParentKey
      ][props.parentIndex],
      [props.parentKey]: [initialValue],
    };
  }

  return;
};
