import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import modalReducer from "./modalSlice";
import actionAppbarReducer from "./actionAppbarSlice";

const reducer = {
  auth: authReducer,
  modal: modalReducer,
  actionAppbar: actionAppbarReducer,
};

const store = configureStore({
  reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
