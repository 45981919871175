import NotFound from  "../../assets/icon/empty.svg" 


const NoFound = ({label}) => {
  return (
    <div className="flex flex-row w-full items-center">
      <img
        className="ml-auto"
        alt="error"
        src={NotFound}
      />
      <div className="text-label ml-10px mr-auto text-center text-textsecoundary">{label}</div>
    </div>
  );
};

export default NoFound;
