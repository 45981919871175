import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { SELECTION_WITDH } from "../../../constants/style";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AlertModal = ({
  open = false,
  action,
  title = "",
  description = "",
  textButton = "OK",
  hasBlur = false,
  className = "",
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={action}
      aria-describedby="alert-dialog-slide-description"
      className={"z-index-9999"}
      sx={
        hasBlur
          ? {
              backdropFilter: "blur(5px)",
            }
          : {}
      }
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={SELECTION_WITDH}>
        <DialogContentText id="alert-dialog-slide-description ">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={action}>
          {textButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
