import classNames from "classnames";

export const FooterButton = ({ text, action = null, disabled = false }) => {
  return (
    <div
      className={classNames(
        "flex justify-end pt-fourteen text-13px  font-medium ",
        { "text-textscb": !disabled, "text-gray-400": disabled }
      )}
    >
      <button disabled={disabled} onClick={action}>
        {text}
      </button>
    </div>
  );
};
