import { useEffect, useState } from "react";
import { SETTINGS_PATH_NAME, SETTINGS_ROUTE } from "../../constants/constant";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useQuery from "../../hooks/useQuery";
import settingService from "../../api/settingService";
import Skeleton from "react-loading-skeleton";
import { PBFESettingTable } from "../../components/Table/settingTable";
import { ERROR_MESSAGE_REQUIRED } from "../../utils/errorMessage";

const FormatValueSetting = (array) => {
  const res = array?.map((row) => {
    return {
      key: row?.description,
      value: row?.setting_value,
    };
  });
  return res;
};

const useStyles = makeStyles(() => ({
  noBorder: {
    // border: "none",
    borderColor: "rgb(255 255 255)",
    borderRadius: 0,
    textTransform: "uppercase",
  },
  input: {
    color: "#7A58BF",
    textTransform: "uppercase",
  },
}));

const Settings = ({
  setRouteName,
  setActionAppbar,
  editSetting,
  setSettingsGlobal,
}) => {
  const classes = useStyles();

  const { data, refetch, loading, error, setData } = useQuery(
    settingService.getSystemService
  );

  useEffect(() => {
    setRouteName([{ title: SETTINGS_PATH_NAME, path: SETTINGS_ROUTE }]);
    setActionAppbar(SETTINGS_PATH_NAME);
  }, [setRouteName, setActionAppbar]);

  useEffect(() => {
    if (data) {
      setSettingsGlobal(data);
    }
  }, [data, editSetting]);

  useEffect(() => {
    refetch();
  }, [editSetting]);

  const onChangeEditValue = (key) => (e) => {
    const newValue = e.target.value;

    const findEditIndex = data.findIndex((row) => {
      return row?.setting_name === key;
    });

    if (findEditIndex !== -1) {
      setData((prev) => {
        prev[findEditIndex].setting_value = newValue;
        return [...prev];
      });
    } else {
      return [];
    }
  };

  const editValue = (value) => {
    const findKey = data.find((row) => row?.setting_value === value);
    if (!editSetting) return <div className="w-32">{value}</div>;
    return (
      <div className="w-full">
        <TextField
          onChange={onChangeEditValue(findKey?.setting_name)}
          placeholder={""}
          size="small"
          margin="none"
          value={value}
          error={value === ""}
          variant="outlined"
          helperText={value !== "" ? "" : ERROR_MESSAGE_REQUIRED}
          classes={{ notchedOutline: classes.input }}
          InputProps={{
            classes: { notchedOutline: classes.noBorder },
            className: classes.input,
          }}
        />
      </div>
    );
  };

  return (
    <div className="pl-xl pr-20px pt-xxl w-full">
      {loading ? (
        <div className="p-fourteen">
          <Skeleton
            containerTestId="react-loading-skeleton"
            height={30}
            width={`100%`}
            count={9}
          />
        </div>
      ) : (
        <PBFESettingTable
          headCells={[
            {
              id: "key",
              label: "Key",
            },
            {
              id: "value",
              label: "Value",
            },
          ]}
          rows={FormatValueSetting(data)}
          formatControl={[null, editValue]}
          orderByDefault="key"
        />
      )}
    </div>
  );
};

export default Settings;
