import { TextField } from "@mui/material";
import _ from "lodash";
import { PBFECommonDropdown } from "../../components/Input/commonDropdown";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionSummary,
} from "../../components/Panel/Accordion/accordionMain";
import {
  ADD_SERVICE_TABLE_HEADER,
  END_OF_TIME,
  SERVICE_HEAD_CELL,
  TAX_HEAD_CELLS,
  VALIDATE_NUMBER_INPUT,
} from "../../constants/constant";
import {
  formatDateCheckValid,
  formateDate,
  formatPercent,
  getFromDateFutureRate,
  isValidDate,
  pad,
} from "../../utils/util";
import { FooterButton } from "../../components/Elements/Button/footerButton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import RemoveIcon from "../../assets/icon/remove.svg";
import { checkFromToDate, FunctionCheckDateLength } from "../../functions";
import PBFEInvalid from "../../components/Error/invalidData";
import { setErrorMessage } from "../../redux/actionAppbarSlice";
import { fr } from "date-fns/locale";
import { FunctionCheckNoSpace } from "../../components/radium-omnibus/src/utils/Field.Function";
import { ERROR_MESSAGE_NO_SPACE } from "../../components/radium-omnibus/src/utils/Field.Constant";

const Rates = ({ index, rates, setRates, deleteRates, setDeleteRates }) => {
  const onRateChange = (value, index, mode = "rate") => {
    const newRates = [...rates];
    if (mode === "rate") newRates[index].tax_rate = value;
    else if (
      String(value) === "Invalid Date" &&
      (mode === "effectiveDate" || mode === "end")
    ) {
      if (mode === "effectiveDate") {
        newRates[index].effective_date = "0";
      } else {
        newRates[index].end_date = "0";
      }
    } else {
      let formatDate = "";
      if (value?.getFullYear()) {
        formatDate =
          value?.getFullYear() +
          "-" +
          pad(value?.getMonth() + 1) +
          "-" +
          pad(value?.getDate());
      }

      if (mode === "effectiveDate") newRates[index].effective_date = formatDate;
      else newRates[index].end_date = formatDate;
    }
    setRates(newRates);
  };

  const removeRates = (index) => {
    const newRates = [...rates];
    if (newRates[index].tax_rate_id) {
      console.log("remove");
      setDeleteRates([...deleteRates, newRates[index]]);
      // var temp = [...deleteRates];
      // temp.push(newRates[index]);
      // setDeleteRates(temp);
    }
    newRates.splice(index, 1);
    setRates(newRates);
  };

  return (
    <div className="grid grid-cols-4 gap-8 mb-6 ">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TextField
          onChange={(e) => onRateChange(e.target.value, index, "rate")}
          value={rates[index].tax_rate}
          label="Rate(%)"
          variant="standard"
          inputProps={{ maxLength: 5 }}
          disabled={rates[index]?.disabled}
          error={
            rates[index]?.tax_rate?.length > 0 && isNaN(rates[index]?.tax_rate)
          }
          helperText={
            rates[index]?.tax_rate?.length > 0 &&
            isNaN(rates[index]?.tax_rate) &&
            VALIDATE_NUMBER_INPUT
          }
          // helperText={"Must be Number Limited " + (rates[index]?.tax_rate?.length ) + "/3"}
        />
        <DesktopDatePicker
          label="Date desktop"
          inputFormat="dd/MM/yyyy"
          disablePast
          disabled={rates[index]?.disabled}
          value={rates[index].effective_date}
          onChange={(e) => onRateChange(e, index, "effectiveDate")}
          renderInput={(params) => {
            if (params?.inputProps?.placeholder) {
              params.inputProps.placeholder = "DD/MM/YYYY";
            }
            return (
              <TextField
                {...params}
                placeholder="DD/MM/YYYY"
                InputProps={{
                  ...params.InputProps,
                  style: { color: "#7A58BF", fontSize: 16 },
                  placeholder: "DD/MM/YYYY",
                }}
                variant="standard"
                label={"Effective Date"}
                error={
                  checkFromToDate(
                    rates[index]?.effective_date,
                    rates[index]?.end_date,
                    rates[index - 1]?.end_date,
                    "from_date",
                    true,
                    "Effective Date cannot be equal to End Date"
                  ) ||
                  FunctionCheckDateLength(
                    rates[index]?.effective_date,
                    "from_date"
                  ).error
                }
                helperText={
                  FunctionCheckDateLength(
                    rates[index]?.effective_date,
                    "from_date"
                  ).error
                    ? FunctionCheckDateLength(
                        rates[index]?.effective_date,
                        "from_date"
                      ).message
                    : checkFromToDate(
                        rates[index]?.effective_date,
                        rates[index]?.end_date,
                        rates[index - 1]?.end_date,
                        "from_date",
                        true,
                        "Effective Date cannot be equal to End Date"
                      )
                }
              />
            );
          }}
        />
        <DesktopDatePicker
          label="End Date"
          inputFormat="dd/MM/yyyy"
          value={
            rates[index].end_date === END_OF_TIME ? "" : rates[index].end_date
          }
          onChange={(e) => onRateChange(e, index, "end")}
          // disablePast
          renderInput={(params) => {
            // setStringDateValue(params.inputProps.value);
            if (params?.inputProps?.placeholder) {
              params.inputProps.placeholder = "DD/MM/YYYY";
            }

            return (
              <TextField
                {...params}
                placeholder="DD/MM/YYYY"
                InputProps={{
                  ...params.InputProps,
                  style: { color: "#7A58BF", fontSize: 16 },
                  placeholder: "DD/MM/YYYY",
                }}
                variant="standard"
                error={
                  checkFromToDate(
                    rates[index]?.effective_date,
                    rates[index]?.end_date,
                    //rates[index - 1]?.end_date,
                    null,
                    "to_date",
                    true,
                    "Effective Date cannot be equal to End Date"
                  ) ||
                  (rates[index]?.end_date?.length > 0 &&
                    FunctionCheckDateLength(rates[index]?.end_date, "to_date")
                      .error)
                }
                helperText={
                  FunctionCheckDateLength(rates[index]?.end_date, "to_date")
                    .error
                    ? FunctionCheckDateLength(rates[index]?.end_date, "to_date")
                        .message
                    : checkFromToDate(
                        rates[index]?.effective_date,
                        rates[index]?.end_date,
                        //rates[index - 1]?.end_date,
                        null,
                        "to_date",
                        true,
                        "Effective Date cannot be equal to End Date"
                      )
                }
              />
            );
          }}
        />
        {index > 0 && (
          <div
            className="flex ml-8 text-14px items-center cursor-pointer text-textscb"
            onClick={() => removeRates(index)}
          >
            <img src={RemoveIcon} className="mr-10px" alt="header_image" />
            Remove
          </div>
        )}
      </LocalizationProvider>
    </div>
  );
};

export const TaxCreate = ({
  form,
  setForm,
  error,
  isCreate,
  rates,
  setRates,
  deleteRates,
  setDeleteRates,
}) => {
  const [expandError, setExpandError] = useState(true);
  useEffect(() => {
    if (isCreate) {
      setForm({ tax_type: "VAT", remark: "" });
    }
  }, [isCreate]);

  useEffect(() => {
    setExpandError(true);
  }, [error]);

  const onChangeType = (e) => {
    setForm({ ...form, tax_type: e.target.value });
  };

  const addRates = () => {
    // current before not equal null
    let newFutureToDate = "";
    let newFutureFromDate = "";

    if (rates.length > 0) {
      // newFutureFromDate = rates[rates.length - 1].end_date;

      if (rates[rates.length - 1].end_date !== "") {
        newFutureFromDate = new Date(rates[rates.length - 1].end_date);
        newFutureFromDate.setDate(newFutureFromDate.getDate() + 1);
      }
    }

    setRates([
      ...rates,
      {
        tax_rate: "",
        effective_date: formatDateCheckValid(newFutureFromDate),
        end_date: formatDateCheckValid(newFutureToDate),
      },
    ]);
  };

  return (
    <div className="w-full overflow-auto pt-xxl">
      <PBFEInvalid
        isShow={error.length > 0}
        message={""}
        setOpen={setExpandError}
        open={expandError}
        errorState={error}
        pt="0"
        requireShowField={false}
      />
      <Accordion
        id="AccordionStyle"
        className="pl-large"
        defaultExpanded={true || isCreate}
      >
        <div className="flex flex-row justify-between w-full">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className="text-value font-bold text-primarydark">General</div>
          </AccordionSummary>
        </div>
        <AccordionDetails>
          <div className="pr-xl ">
            <div className="grid grid-cols-4 gap-40px">
              <div className="col-span-2">
                <TextField
                  id="Name"
                  onChange={(e) =>
                    setForm({ ...form, tax_scheme_name: e.target.value })
                  }
                  value={form?.tax_scheme_name || ""}
                  label="Name"
                  variant="standard"
                  fullWidth
                  inputProps={{ maxLength: 64 }}
                  helperText={
                    "Limited " + (form?.tax_scheme_name?.length || 0) + "/64"
                  }
                />
              </div>
              <PBFECommonDropdown
                label={"Type"}
                options={["WHT", "VAT"]}
                value={form?.tax_type}
                onChange={onChangeType}
              />
              {true ? (
                <TextField
                  id="tax_scheme_id"
                  onChange={(e) =>
                    setForm({ ...form, tax_scheme_id: e.target.value })
                  }
                  value={form?.tax_scheme_id || ""}
                  label="Tax Scheme ID"
                  disabled={!isCreate}
                  error={
                    FunctionCheckNoSpace(form?.tax_scheme_id, "Error") !== ""
                  }
                  variant="standard"
                  helperText={
                    FunctionCheckNoSpace(form?.tax_scheme_id, "Error") !== ""
                      ? ERROR_MESSAGE_NO_SPACE["EN"]
                      : "Limited " + (form?.tax_scheme_id?.length || 0) + "/8"
                  }
                  inputProps={{ maxLength: 8 }}
                />
              ) : (
                <TextField
                  id="currentRate"
                  onChange={(e) =>
                    setForm({ ...form, current_tax_rate: e.target.value })
                  }
                  value={form?.current_tax_rate || ""}
                  label="Current Tax Rate"
                  variant="standard"
                  helperText={
                    "Limited " + (form?.current_tax_rate?.length || 0) + "/3"
                  }
                  inputProps={{ maxLength: 3 }}
                />
              )}

              <TextField
                id="GL Description"
                onChange={(e) =>
                  setForm({ ...form, gl_description: e.target.value })
                }
                value={form?.gl_description || ""}
                label="GL Description"
                variant="standard"
                helperText={
                  "Limited " + (form?.gl_description?.length || 0) + "/64"
                }
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                id="Product Code"
                onChange={(e) =>
                  setForm({ ...form, gl_product_code: e.target.value })
                }
                value={form?.gl_product_code || ""}
                label="Product Code"
                variant="standard"
                error={
                  form?.gl_product_code?.length > 0 &&
                  isNaN(form?.gl_product_code)
                }
                //   helperText={ (form?.gl_product_code?.length > 0 && isNaN(form?.gl_product_code) && VALIDATE_NUMBER_INPUT)}
                helperText={
                  "Must be Number Limited " +
                  (form?.gl_product_code?.length || 0) +
                  "/16"
                }
                inputProps={{ maxLength: 16 }}
              />
              <TextField
                id="Account Code"
                onChange={(e) =>
                  setForm({ ...form, gl_account_code: e.target.value })
                }
                value={form?.gl_account_code || ""}
                label="Account Code"
                variant="standard"
                error={
                  form?.gl_account_code?.length > 0 &&
                  isNaN(form?.gl_account_code)
                }
                //  helperText={form?.gl_account_code?.length > 0 && isNaN(form?.gl_account_code) && VALIDATE_NUMBER_INPUT}
                helperText={
                  "Must be Number Limited " +
                  (form?.gl_account_code?.length || 0) +
                  "/16"
                }
                inputProps={{ maxLength: 16 }}
              />
              <TextField
                id="Tax Code"
                onChange={(e) =>
                  setForm({ ...form, gl_tax_code: e.target.value })
                }
                value={form?.gl_tax_code || ""}
                label="Tax Code"
                variant="standard"
                error={
                  form?.gl_tax_code?.length > 0 && isNaN(form?.gl_tax_code)
                }
                // helperText={form?.gl_tax_code?.length > 0 && isNaN(form?.gl_tax_code) && VALIDATE_NUMBER_INPUT}
                helperText={
                  "Must be Number Limited " +
                  (form?.gl_tax_code?.length || 0) +
                  "/1"
                }
                inputProps={{ maxLength: 1 }}
              />
              <TextField
                id="GL RC"
                onChange={(e) =>
                  setForm({ ...form, gl_rc_code: e.target.value })
                }
                value={form?.gl_rc_code || ""}
                label="GL RC"
                variant="standard"
                error={form?.gl_rc_code?.length > 0 && isNaN(form?.gl_rc_code)}
                // helperText={form?.gl_oc_code?.length > 0 && isNaN(form?.gl_rc_code) && VALIDATE_NUMBER_INPUT}
                helperText={
                  "Must be Number Limited " +
                  (form?.gl_rc_code?.length || 0) +
                  "/16"
                }
                inputProps={{ maxLength: 16 }}
              />
              <TextField
                id="GL OC"
                onChange={(e) =>
                  setForm({ ...form, gl_oc_code: e.target.value })
                }
                value={form?.gl_oc_code || ""}
                label="GL OC"
                variant="standard"
                error={form?.gl_oc_code?.length > 0 && isNaN(form?.gl_oc_code)}
                //    helperText={form?.gl_oc_code?.length > 0 && isNaN(form?.gl_oc_code) && VALIDATE_NUMBER_INPUT}
                helperText={
                  "Must be Number Limited " +
                  (form?.gl_oc_code?.length || 0) +
                  "/16"
                }
                inputProps={{ maxLength: 16 }}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <div className="h-10px bg-gray-100"></div>
      <Accordion
        id="AccordionStyle"
        className="pl-large pr-xl"
        defaultExpanded={true}
      >
        <div className="flex flex-row justify-between w-full ">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className="text-value font-bold text-primarydark">Rates</div>
          </AccordionSummary>
        </div>
        <AccordionDetails>
          {rates.map((value, index) => {
            return (
              <Rates
                isCreate={isCreate}
                index={index}
                rates={rates}
                setRates={setRates}
                setDeleteRates={setDeleteRates}
                deleteRates={deleteRates}
                form={form}
                setForm={setForm}
              />
            );
          })}
          <FooterButton text="Add Future Rate" action={addRates} />
        </AccordionDetails>
      </Accordion>
      <div className="h-10px bg-gray-100"></div>
      <div className="mx-40px my-20px">
        <TextField
          id="remark"
          onChange={(e) => setForm({ ...form, remark: e.target.value })}
          value={form?.remark}
          label="REMARK"
          variant="filled"
          helperText={"Limited " + (form?.remark?.length || 0) + "/300"}
          fullWidth
          inputProps={{ maxLength: 300, style: { fontSize: 16 } }}
        />
      </div>

      {/* <PBFEAccordionWithButton
            label="Assigned To"
            buttons={[{ img: Plus, name: "ADD SERVICE", action: () => setIsAddActionService(true) }]}
            detail={
                <PBFECommonTable
                    showFooter={true}
                    orderByDefault="service_code"
                    headCells={SERVICE_HEAD_CELL}
                    formatControl={[null, deleteService]}
                    rows={form?.assign_service}
                    page={pageService}
                    setPage={setPageService}
                    showNoData={false}
                    showFields={["service_name", "service_code"]}
                />} /> */}

      {/* {isAddActionService && (
            <AsyncModalService
                title="Add Service"
                open={isAddActionService}
                setOpen={setIsAddActionService}
                onClose={() => setIsAddActionService(false)}
                dataFromApi={form?.assign_service}
                placeholder="Search Service Name / Code"
                serviceApi={serviceService.getService}
                setData={setServicesData}
                setServicesData={setServicesData}
                setIsSetBack={setIsSetBack}
                isSetBack={isSetBack}
                setAccordion={setAccordion}
            />
        )} */}
    </div>
  );
};
