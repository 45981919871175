import * as React from "react";
import { FieldContainer } from "../components/Field.Container";
import { TYPE_NONE } from "../type";
import { FunctionBody } from "../utils/Field.Body";
import { FunctionGetModelError } from "../utils/Field.Function";
import { FunctionInitalData, FunctionLoadData } from "../utils/Field.LoadData";
import { FunctionCheckValidation } from "../utils/Field.Validation";

const CoreContainer = ({
  mode = "normal",
  label = "",
  name = "",
  FieldReference = "",
  index = 0,
  disabled = false,
  loadData = {},
  language = "TH",
  style = {},
  required = false,
  type = "text",
  unit = "",
  placeholder = "",
  render = false,
  renderError = false,
  number = false,
  email = false,
  classNameInput = "",
  classNameLabel = "",
  classNameWrapper = "",
  classNameUnit = "",
  isEdit = true,
  classNameValue = "",
  subLabel = "",
  classNameSubLabel = "",
  options = [],
  isMultiple = false,
  classNameOptions = "",
  classNameOption = "",
  classNameValues = "",
  fieldCustoms = false,
  getPathUrl = "",
  accept = "image/jpeg,image/png",
  classNameError = "",
  fieldIndex = -1,
  grandParentIndex = -1,
  grandParentKey = "",
  parentIndex = -1,
  parentKey = "",
  direction = "vertical",
  limit = false,
  classNameIcon = "",
  showError = true,
  renderFooter = false,
  minimum = 0,
  international = true,
  select = [],
  defualtValue = "",
  count = 6, // default value of otp length
  labelUpload = false,
  trigger = false,
  dataType = "default",
  reset = false,
  FunctionReset = () => {},
  initalValue,
  FunctionAddStep,
  FunctionRemoveStep,
  originalData = "No Data",
  isLastStep,
  isFirstStep,
  showSymbol,
  errorCustoms = false,
  no_space = false,
  currentData = {},
}) => {
  const [value, setValue] = React.useState("");
  const [listener, setListener] = React.useState(null);

  const reference = React.useRef("");

  const FunctionGetThisValue = FunctionCheckValidation(
    mode,
    language,
    FunctionGetModelError(
      typeof required === "function" ? required({ value, listener }) : required,
      typeof number === "function" ? number({ value, listener }) : number,
      typeof email === "function" ? email({ value, listener }) : email,
      typeof limit === "function" ? limit({ value, listener }) : limit,
      typeof minimum === "function" ? minimum({ value, listener }) : minimum,
      typeof no_space === "function" ? no_space({ value, listener }) : no_space
    ),
    name,
    dataType === "default"
      ? value?.label === "" || value?.label
        ? value?.value
        : value || ""
      : value,

    type,
    errorCustoms
  );

  React.useEffect(() => {
    if (reset) {
      setValue("");
    }
  }, [reset]);

  React.useEffect(() => {
    if (initalValue && value === "") {
      FunctionInitalData({
        loadData,
        name,
        parentIndex,
        parentKey,
        fieldIndex,
        grandParentIndex,
        grandParentKey,
        type,
        value,
        setValue,
        options,
        initalValue,
      });
    }
  }, [initalValue]);

  React.useEffect(() => {
    FunctionLoadData({
      loadData,
      name,
      parentIndex,
      parentKey,
      fieldIndex,
      grandParentIndex,
      grandParentKey,
      type,
      value,
      setValue,
      options,
      initalValue,
    });
  }, [loadData]);

  return (
    <FieldContainer
      FunctionGetThisValue={FunctionGetThisValue}
      inputBody={
        <FunctionBody
          setListener={setListener}
          listener={listener}
          FunctionReset={FunctionReset}
          reset={reset}
          mode={mode}
          label={label}
          name={name}
          FieldReference={FieldReference}
          index={index}
          disabled={disabled}
          language={language}
          style={style}
          type={type}
          unit={unit}
          placeholder={placeholder}
          isEdit={isEdit}
          setValue={setValue}
          reference={reference}
          options={options}
          isMultiple={isMultiple}
          value={value}
          classNameInput={classNameInput}
          classNameLabel={classNameLabel}
          classNameWrapper={classNameWrapper}
          classNameUnit={classNameUnit}
          classNameValue={classNameValue}
          classNameSubLabel={classNameSubLabel}
          classNameValues={classNameValues}
          classNameOptions={classNameOptions}
          classNameOption={classNameOption}
          classNameIcon={classNameIcon}
          fieldCustoms={fieldCustoms}
          getPathUrl={getPathUrl}
          accept={accept}
          direction={direction}
          showError={showError}
          international={international}
          select={select}
          defualtValue={defualtValue}
          count={count}
          trigger={trigger}
          labelUpload={labelUpload}
          fieldIndex={fieldIndex}
          grandParentIndex={grandParentIndex}
          grandParentKey={grandParentKey}
          parentIndex={parentIndex}
          parentKey={parentKey}
          FunctionRemoveStep={FunctionRemoveStep}
          FunctionAddStep={FunctionAddStep}
          originalData={originalData}
          isLastStep={isLastStep}
          isFirstStep={isFirstStep}
          initalValue={initalValue}
          FunctionGetThisValue={FunctionGetThisValue}
          loadData={loadData}
          currentData={currentData}
        />
      }
      setValue={setValue}
      classNameLabel={classNameLabel}
      classNameWrapper={classNameWrapper}
      classNameValue={classNameValue}
      label={label}
      required={required}
      index={index}
      name={name}
      value={value}
      FieldReference={FieldReference}
      isEdit={isEdit}
      reference={reference}
      renderError={renderError}
      subLabel={subLabel}
      classNameSubLabel={classNameSubLabel}
      render={render}
      classNameError={classNameError}
      fieldIndex={fieldIndex}
      grandParentIndex={grandParentIndex}
      grandParentKey={grandParentKey}
      parentIndex={parentIndex}
      parentKey={parentKey}
      showError={showError}
      renderFooter={renderFooter}
      showSymbol={showSymbol}
      currentData={currentData}
    />
  );
};

export default CoreContainer;
