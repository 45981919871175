import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Check from "../../../assets/icon/check.svg";
import Close from "../../../assets/icon/close.svg";
import LoadingButton from "@mui/lab/LoadingButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ConfirmModal = ({
  open = false,
  isSuccess,
  isError,
  text = "Do you want to submit change request to approver ?",
  message = "Request Has Been Sent to Approver",
  confirmText = "CONFIRM",
  successText = "DISMISS",
  cancelText = "CANCEL",
  onClose,
  onClick,
  loading,
  isTextCenter = true,
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      className="z-index-9999"
    >
      <DialogContent sx={{ padding: "30px", width: "500px" }}>
        {isSuccess && (
          <div className="text-value flex w-full text-center font-semibold text-textprimary py-10px border-gray-300">
            <img alt="" src={Check} className="h-20px w-20px mr-12px"></img>{" "}
            {message}
          </div>
        )}
        {isError && (
          <div className="text-value flex w-full text-center font-semibold text-textprimary py-10px border-gray-300">
            <img
              alt=""
              src={Close}
              className="h-20px w-20px mr-12px text-red-400"
            ></img>
            {message}
          </div>
        )}
        {!isError && !isSuccess && (
          <div
            className={
              "text-value w-full  font-semibold text-textprimary py-10px border-gray-300" +
                isTextCenter ===
                true && "text-center"
            }
          >
            {text}
          </div>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 2, fontSize: 14 }}>
        {!isSuccess ? (
          <Button variant="text" onClick={onClose}>
            {cancelText}
          </Button>
        ) : (
          <Button onClick={onClose} variant="contained">
            {successText}
          </Button>
        )}

        {!isSuccess && !isError && (
          <LoadingButton
            loading={loading}
            onClick={onClick}
            variant="contained"
          >
            {confirmText}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
