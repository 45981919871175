import { keyCloakRedirect } from "./index";

// redux
import store from "../redux/store";
import { setModal } from "../redux/modalSlice";

const sestionExpire = {
  sessionExpiredModal: {
    title: "Session Expired",
    description: "Your session is expired. please login again.",
    actionModal: async () => {
      // const token = getTokenFromLocalStorage();
      // if (token !== undefined && token !== null) {
      //   await authService.logout({ refresh_token: token.refresh_token });
      // }
      // sessionStorage.clear();
      window.open(keyCloakRedirect, "_self");
    },
  },
  networkErrorModal: {
    title: "Network Error",
    description: "Can't connect to server. please try again later.",
    actionModal: () => {
      store.dispatch(setModal({ open: false }));
    },
  },
  sendApproverConfirm: {
    description: "Do you want to submit change request to approver ?",
  },
  sendApproverSuccess: {
    description: "Request Has Been Sent to Approver",
  },
  sendApproverError: {
    description: "Invalid data",
  },
};

export default sestionExpire;
