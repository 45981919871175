import { TextField } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import NoFound from "../../../components/Error/noFound";
import { Information } from "../../../components/Panel/Information/information";
import { modalConfig } from "../../../config";
import {
  postSendApproverCustomerService,
  setConfirmText,
  setStateComponent,
} from "../../../redux/actionAppbarSlice";
import { mappingServiceApprover } from "../../../utils/mappingData";
import {
  checkDuplicatePriority,
  checkDuplicateQualifer,
  checkErrorDate,
  checkFeeChargeAllDelete,
  getFeeChargeError,
  getFeeChargeOfGroup,
  onScrollToTop,
} from "../../../utils/util";

const AccountRenderService = ({
  data,
  dataRequest = {},
  accountTable = [],
  hideButton = false,
  isEdit = false,
  isNew = false,
  setIsEdit,
  setDebitProfile,
  debitProfile,
  setIsEditWhereData,
  customer,
  account,
  originalData,
  setErrorState,
  is_maker,
  setRouteName,
  errorState,
  checkConfirmError,
  setCheckConfirmError,
  refetch,
  setDataRequest = () => {},
  remark,
}) => {
  const dispatch = useDispatch();
  const [dataService, setDataService] = React.useState([]);
  // const [remark, setRemark] = React.useState("");

  // console.log("H: ", {
  //   data,
  //   dataRequest,
  //   account,
  // });

  React.useEffect(() => {
    setDataService(data?.service);
  }, []);

  React.useEffect(() => {
    if (dataService.length > 0) {
      let fee_charge = getFeeChargeOfGroup(dataService[0].charge_group);
      const checkCharge = checkFeeChargeAllDelete(fee_charge);

      if (!checkCharge) {
        setErrorState((prev) => {
          let temp = [...prev];
          temp = temp.filter((item) => item.field_name !== "all_fee_charge");
          return [...temp];
        });
      }
    }
  }, [dataService]);

  React.useEffect(() => {
    const dontDoThis = !dataService?.length || !isNew || !isEdit;
    if (!dontDoThis) {
      dispatch(
        setStateComponent({
          stateComponent: "customerService",
          onClickAction: onClickSendToApprover,
          onClickBeforeAction,
        })
      );
      dispatch(setConfirmText(modalConfig.sendApproverConfirm.description));
    }
  }, [
    dataService,
    debitProfile,
    errorState,
    checkConfirmError,
    dataRequest,
    setCheckConfirmError,
    originalData,
  ]);

  const onClickBeforeAction = (setShowConfirm) => {
    let checkError = false;
    const service = _.cloneDeep(dataService[0]);
    // console.log("service", {
    //   service,
    //   fcy: service?.fcy_flag,
    //   c: service?.ccy,
    // });

    // console.log("CLICK");

    const checkErroDateList = checkErrorDate(service);

    /////////////////////
    //FCY///

    if (
      service?.fcy_flag === "Y" &&
      (service?.ccy === "" ||
        service?.ccy === null ||
        service?.ccy === "undefined" ||
        service?.ccy === undefined)
    ) {
      checkError = true;
      setErrorState((prev) => [
        ...prev,
        {
          field_name: "ccy",
          show: true,
          message: "Pricing & Debit Currency cannot be empty",
        },
      ]);
    } else {
      setErrorState((prev) => [
        ...prev.filter((item) => item.field_name !== "ccy"),
      ]);
    }

    //Check all is the same currency

    if (service?.fcy_flag === "Y") {
      const ccy = service?.ccy;

      const pricingOptions = JSON.parse(
        sessionStorage.getItem("pricingOptions")
      );

      const thisCurrency = pricingOptions?.find(
        (item) => item.currency_code === ccy
      )?.numeric_code;

      let checkIsTheSame =
        debitProfile[0]?.posting_account_id?.slice(2, 5) === thisCurrency;

      if (!checkIsTheSame) {
        checkError = true;
        setErrorState((prev) => {
          let temp = _.cloneDeep(prev);
          return [
            ...temp,
            {
              field_name: "fcy_ccy",
              show: true,
              message:
                "Account Currency must be the same as Pricing & Debit Currency",
            },
          ];
        });
      } else {
        setErrorState((prev) => {
          let temp = _.cloneDeep(prev);
          return [...temp.filter((item) => item.field_name !== "fcy_ccy")];
        });
      }
    }

    //////////////////

    if (checkDuplicatePriority(service, true)) {
      checkError = true;
      setErrorState((prev) => [
        {
          field_name: "priority_empty",
          show: true,
          message: "Priority cannot be empty ,  - or 0",
        },
      ]);
    } else {
      setErrorState((prev) => {
        return [...prev.filter((item) => item.field_name !== "priority_empty")];
      });
    }

    const checkErrorDuplicate = checkDuplicatePriority(service);
    const checkListChargeError = getFeeChargeError(service);
    const checkErrorQualifer = checkDuplicateQualifer(service);

    if (checkErrorQualifer.length > 0) {
      const unqiue_error_state = _.unionBy(checkErrorQualifer, "field_name");
      setErrorState((prev) =>
        _.unionBy([...unqiue_error_state, ...prev], "field_name")
      );
      checkError = true;
    } else {
      setErrorState((prev) => [
        ...prev.filter((item) => item.field_name !== "qualifer"),
      ]);
    }

    if (checkListChargeError.length > 0) {
      checkError = true;
      setErrorState((prev) => [
        ...prev,
        { field_name: "validate_error", show: false },
      ]);
    } else {
      setErrorState((prev) => [
        ...prev.filter((item) => item.field_name !== "validate_error"),
      ]);
    }

    if (checkErroDateList.length > 0) {
      const unqiue_error_state = _.unionBy(checkErroDateList, "field_name");
      setErrorState((prev) =>
        _.unionBy([...unqiue_error_state, ...prev], "field_name")
      );
      checkError = true;
    } else {
      setErrorState((prev) => {
        return prev.filter((item) => {
          return item.field_name !== "overlap_date";
        });
      });
    }

    if (checkErrorDuplicate.length > 0) {
      const unqiue_error_state = _.unionBy(checkErrorDuplicate, "field_name");
      setErrorState((prev) =>
        _.unionBy([...unqiue_error_state, ...prev], "field_name")
      );
      checkError = true;
    } else {
      setErrorState((prev) => {
        return prev.filter((item) => {
          return item.field_name !== "priority";
        });
      });
    }

    let fee_charge = getFeeChargeOfGroup(dataService[0].charge_group);
    const checkCharge = checkFeeChargeAllDelete(fee_charge);

    if (checkCharge) {
      setErrorState((prev) => {
        let temp = _.unionBy(...prev, "field_name");
        return [
          ...temp,
          {
            error: true,
            message: "Please add fee charge at least 1 charge",
            field_name: "all_fee_charge",
            field_value: [],
            show: true,
          },
        ];
      });
      checkError = true;
    }

    if (!debitProfile.length) {
      checkError = true;
      setErrorState((prev) => {
        const find_exists = prev.find(
          (item) => item.field_name === "posting_account"
        );
        if (!find_exists) {
          prev.push({
            error: true,
            message: "Please select debit profile",
            field_name: "posting_account",
            field_value: [],
            show: true,
          });
        }
        return [...prev];
      });
    }

    if (
      errorState.filter((item) => {
        return ![
          "overlap_date",
          "validate_error",
          "priority",
          "qualifer",
        ].includes(item.field_name);
      }).length > 0
    ) {
      checkError = true;
    }

    console.log("errorState", { errorState, checkError });

    if (checkError) {
      setCheckConfirmError(true);
      onScrollToTop("invalid");
    } else {
      setShowConfirm(true);
    }
  };

  const onClickSendToApprover = () => {
    dispatch(
      postSendApproverCustomerService({
        data: mappingServiceApprover(
          {
            ...customer,
            ...account,
            service_name: dataService[0].service_name,
            service_code: dataService[0].service_code,
            posting_account: debitProfile,
            charge_group: dataService[0].charge_group,
            ccy: dataService[0].ccy,
            fcy_flag: dataService[0].fcy_flag,
          },
          originalData,
          dataRequest,
          remark
        ),
      })
    );
  };

  if (!data?.service?.length) return <NoFound label={"No Service Found"} />;

  return (
    <div>
      {dataService?.map((sevice_self, index) => {
        return (
          <Information
            refetch={refetch}
            setCheckConfirmError={setCheckConfirmError}
            account={account}
            customer={customer}
            setRouteName={setRouteName}
            is_maker={is_maker}
            originBigData={sevice_self}
            setIsEdit={setIsEdit}
            key={index}
            origin={dataService}
            index={index}
            account_table={sevice_self.posting_account}
            label={sevice_self.service_name + " - " + sevice_self.service_code}
            data={sevice_self}
            setData={setDataService}
            accountTable={accountTable}
            hideButton={hideButton}
            isEdit={isEdit}
            isNew={isNew}
            setIsEditWhereData={setIsEditWhereData}
            type="service"
            setDebitProfile={setDebitProfile}
            debitProfile={debitProfile}
            setErrorState={setErrorState}
            setDataRequest={setDataRequest}
          />
        );
      })}
      {/* {(isEdit || isNew) && (
        <div className="ml-40px mr-25px">
          <TextField
            id="remark"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            label="REMARK"
            helperText={"Limited " + remark?.length + "/300"}
            variant="filled"
            fullWidth
            inputProps={{ maxLength: 300 }}
          />
        </div>
      )} */}
    </div>
  );
};

export default AccountRenderService;
