import * as React from "react";

export const ICONS_PLUS_IMAGE = ({
  className = "",
  style = {
    width: "24px",
    height: "24px",
  },
  fill = "white",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      viewBox="0 0 20 20"
      fill={fill}
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const ICONS_EYE_ON = ({
  className = "",
  style = {
    width: "24px",
    height: "24px",
  },
  fill = "none",
  onClick = () => {},
}) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={className ? {} : style}
      fill={fill}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
      />
    </svg>
  );
};

export const ICONS_EYE_OFF = ({
  className = "",
  style = {
    width: "24px",
    height: "24px",
  },
  fill = "none",
  onClick = () => {},
}) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
      style={className ? {} : style}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
      />
    </svg>
  );
};

export const ICONS_UPLOAD = ({
  className = "",
  style = {
    width: "24px",
    height: "24px",
  },
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
      style={style}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
      />
    </svg>
  );
};

export const ICONS_ADJUSTMENTS = ({
  className = "",
  style = {
    width: "24px",
    height: "24px",
  },
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
      style={style}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
      />
    </svg>
  );
};

export const ICONS_ARROW_CIRCLE_DOWN = ({
  style = {
    width: "24px",
    height: "24px",
  },
  className = "",
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      fill={fill}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
      />
    </svg>
  );
};

export const ICONS_X = ({
  className = "",
  style = {
    width: "24px",
    height: "24px",
  },
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
      style={style}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
};

export const ICONS_EXCLAMATION_CIRCLE = ({
  className = "",
  style = {
    width: "24px",
  },
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
      style={style}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

export const ICONS_CHECK = ({
  className = "",
  style = {
    width: "24px",
  },
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      fill={fill}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
    </svg>
  );
};

export const ICONS_NOT_FOUND = () => {
  return (
    <svg
      style={{
        margin: "auto",
      }}
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6666 14.6667H18.6133L18.24 14.3067C19.5466 12.7867 20.3333 10.8133 20.3333 8.66667C20.3333 3.88 16.4533 0 11.6666 0C7.10662 0 3.37329 3.52 3.03996 8H5.73329C6.06662 5 8.57329 2.66667 11.6666 2.66667C14.9866 2.66667 17.6666 5.34667 17.6666 8.66667C17.6666 11.9867 14.9866 14.6667 11.6666 14.6667C11.44 14.6667 11.2266 14.6267 11 14.6V17.2933C11.2266 17.32 11.44 17.3333 11.6666 17.3333C13.8133 17.3333 15.7866 16.5467 17.3066 15.24L17.6666 15.6133V16.6667L24.3333 23.32L26.32 21.3333L19.6666 14.6667Z"
        fill="black"
        fillOpacity="0.54"
      />
      <path
        d="M7.62659 10.4271L4.33326 13.7204L1.03993 10.4271L0.0932617 11.3738L3.3866 14.6671L0.0932617 17.9604L1.03993 18.9071L4.33326 15.6138L7.62659 18.9071L8.57326 17.9604L5.27993 14.6671L8.57326 11.3738L7.62659 10.4271Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
};

export const ICONS_CHEVRON_DOWN = ({
  className = "",
  style = {
    width: "24px",
    height: "24px",
  },
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
      fill={fill}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
};

export const ICONS_CHEVRON_UP = ({
  className = "",
  style = {
    width: "24px",
    height: "24px",
  },
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
      style={style}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
    </svg>
  );
};
