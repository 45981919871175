import api from "./index";
import { apiConfig } from "../config";

const getRetails = async (params, query) => {
  return api.instanceRetail.get(apiConfig.getRetailOfferAPI);
};

const getRetailInfo = async (params, query) => {
  return api.instanceRetail.get(
    `${apiConfig.getRetailOfferAPI}/${params.offer_code}`
  );
};


const exportedObject = { getRetails,
  getRetailInfo, }

export default exportedObject;

