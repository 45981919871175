import React, { useState, createContext } from "react";

export const DrawerContext = createContext({});

function DrawerContextProvider({ children }) {
  const [openDrawer, setOpenDrawer] = useState(true);

  return (
    <DrawerContext.Provider
      value={{
        openDrawer,
        setOpenDrawer,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}

export default DrawerContextProvider;
