import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Slide,
} from "@mui/material";
import React from "react";
import { ModalType } from "../../../constants/constant";
import SelectAll from "../../../assets/icon/select_all.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const UserRoleModal = ({
  title = "User Group",
  open,
  onClose,
  action,
  type,
  options = [],
  setOptions,
  checkedSelectAll,
  setCheckedSelectAll,
}) => {
  const onCheck = (index) => {
    let temp = [...options];
    temp[index].checked = !temp[index].checked;
    setOptions(temp);
  };

  const unSelectAll = () => {
    let temp = [...options];
    for (let i = 0; i < temp.length; i++) temp[i].checked = false;
    setCheckedSelectAll(false);
    setOptions(temp);
  };

  const selectAll = () => {
    let temp = [...options];
    for (let i = 0; i < temp.length; i++) temp[i].checked = true;
    setCheckedSelectAll(true);
    setOptions(temp);
  };

  const Choice = ({ label, checked, index }) => {
    return (
      <div className="my-2 border-b border-gray-300">
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              name={label}
              onChange={() => onCheck(index)}
            />
          }
          label={label}
        />
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm"
      keepMounted={false}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent sx={{ padding: 0, height: "420px" }}>
        <div className="relative">
          <div className="sticky top-0 z-40 bg-white text-value   text-center font-semibold text-textprimary py-10px border-b border-gray-300">
            {title}
          </div>
          <div className="px-lg py-lg grid z-30">
            {options.map((item, index) => {
              return (
                <Choice
                  // label="Digital Collect"
                  label={item.label ? item.label : "Digital Collect"}
                  checked={item.checked}
                  index={index}
                />
              );
            })}
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ padding: 2, fontSize: 14 }}>
        {type === ModalType.Role && (
          <Button
            onClick={selectAll}
            className="text-textscb text-left inline-block mb-3"
            startIcon={<img alt="" src={SelectAll} />}
            sx={{ marginRight: "auto" }}
          >
            {"Select All"}
          </Button>
        )}
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={options?.every((option) => option?.checked === false)}
          variant="contained"
          onClick={action}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
