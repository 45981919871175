import React from "react";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import { PBFEContent } from "../../components/Elements/Text/content";
import PBFEError from "../../components/Error/alertError";
import {
  PBFEAccordionMain,
  PBFEAccordionSearch,
  PBFEAccordionWithButton,
} from "../../components/Panel/Accordion/accordionMain";
import { AsyncModal } from "../../components/Panel/Modal/asyncModal";
import {
  ADD_OFFER_TABLE_HEADER,
  ADD_SERVICE_TABLE_HEADER,
  CUSTOMER_TITLE,
  MINIMUM_SEARCH_ACTIVE,
  SERVICE_ADDRESS_TABLE_HEADER,
} from "../../constants/constant";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Plus from "../../assets/icon/plus.svg";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import EditIcon from "../../assets/icon/edit.svg";
import {
  BundleOfferInformation,
  OfferInformation,
  ServiceInformation,
} from "../../components/Panel/Information/information";
import useQuery from "../../hooks/useQuery";
import customerService from "../../api/customerService";
import {
  checkCurrentDate,
  checkDuplicatePriority,
  checkDuplicateQualifer,
  checkErrorDate,
  getFeeChargeError,
  getValidateOfferError,
  mappingForServiceInfo,
  onScrollToTop,
  prePareBundleOffer,
  prePareOffer,
  prePareService,
  WrapTextEnter,
  WrapTextEnterEmail,
} from "../../utils/util";
import { PBFECommonTable } from "../../components/Table/commonTable";
import { useSelector } from "react-redux";
import { HistoryModal } from "../../components/Panel/Modal/historyModal";
import {
  setStateComponent,
  postSendApproverCustomerService,
  setDisabledAction,
  setConfirmText,
} from "../../redux/actionAppbarSlice";
import { PBFEHeader } from "../../components/Elements/Text/header";
import {
  mappingServiceEditApprover,
  mappingOfferEditApprover,
  mappingBundleOfferEditApprover,
} from "../../utils/mappingData";
import { modalConfig } from "../../config";
import PBFEInvalid from "../../components/Error/invalidData";
import _, { cloneDeep } from "lodash";
import makerCheckerService from "../../api/makerCheckerService";
import historyService from "../../api/historyService";
import HISTORY_IMAGE from "../../assets/icon/history.svg";
import { Button, TextField } from "@mui/material";
import { mappingServiceInfo } from "../../utils/mappingServiceInfo";
import CustomerRenderAddNewOffer from "./render/Customer.RenderAddNewOffer";
import CustomerRenderInformation from "./render/Customer.RenderInfomation";
import CustomerRenderAddNewService from "./render/Customer.RenderAddNewService";
import CustomerRenderService from "./render/Customer.RenderService";
import CustomerRenderGeneralInfomation from "./render/Customer.RenderGeneralInfomation";
import CustomerRenderAccountInfomation from "./render/Customer.RenderAccountInfomation";
import CustomerEditServiceAddress from "./render/Customer.EditServiceAddress";
import CustomerAddNewOfferBundle from "./render/Customer.AddNewOfferBundle";
import CustomerRenderBundleOffer from "./render/Customer.RenderBundleOffer";
import CustomerRenderOffer from "./render/Customer.RenderOffer";
import CustomerAddNewServiceDuplicate from "./render/Customer.AddNewServiceDuplicate";
import CustomerAddNewOfferDuplicate from "./render/Cusotmer.AddNewOfferDuplicate";
import CustomerAddNewBundleOfferDuplicate from "./render/Customer.AddNewBundleOfferDuplicate";
import { data_dih, refemock } from "../../utils/testMockData";
import { PageState } from "../../components/radium-omnibus/page/Page.State";

export const PBFECustomerInfo = ({
  setRouteName,
  dataRequest,
  setDataRequest,
  setActionAppbar,
  routeName,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const prePareData = (data) => {
    const service = data?.service?.map((service) => {
      return {
        ...service,
        charge_group: service.charge_group.map((group) => {
          return {
            ...group,
            fee_charge: group.fee_charge.map((charge) => {
              const posibleValue = Object.keys(charge?.qualifiers || {});
              if (charge?.fee_value?.conditions?.length > 0) {
                charge.fee_value.conditions = charge.fee_value.conditions.map(
                  (third) => {
                    let thisQualifiers = third?.qualifiers;
                    posibleValue?.forEach((thisKey) => {
                      if (
                        !thisQualifiers?.[String(thisKey).toLocaleLowerCase()]
                      ) {
                        thisQualifiers = {
                          ...thisQualifiers,
                          [thisKey]: [],
                        };
                      }
                    });
                    return {
                      ...third,
                      qualifiers: thisQualifiers,
                    };
                  }
                );
              }
              return {
                ...charge,
                isError: false,
                isRemove: false,
                checkCurrentDate: checkCurrentDate(
                  new Date(charge.from_date),
                  new Date(charge.to_date)
                ),
              };
            }),
          };
        }),
      };
    });

    const offer = data?.offer?.map((offer) => {
      return {
        ...offer,
        checkCurrentDate: checkCurrentDate(
          new Date(offer.from_date),
          new Date(offer.to_date)
        ),
      };
    });

    const bundle_offer = data?.bundle_offer?.map((bundle) => {
      return {
        ...bundle,
        checkCurrentDate: checkCurrentDate(
          new Date(bundle.from_date),
          new Date(bundle.to_date)
        ),
      };
    });

    return {
      ...data,
      service,
      offer,
      bundle_offer,
    };
  };
  const [reload, setReload] = React.useState(false);

  const {
    data,
    setData,
    loading,
    error,
    originalData: originDataQuery,
    refetch,
  } = useQuery(
    customerService.getCustomerById,
    {
      customer_id: params.customer_id,
    },
    {},
    [reload],
    { prePareData, checkFirstLoad: true }
  );

  const [accountTable, setAccountTable] = React.useState([]);
  const [originalData, setOriginalData] = React.useState([]);
  const [searchedPage, setSearchedPage] = React.useState("");
  const [accountPage, setAccountPage] = React.useState(0);
  const [radioService, setRadioService] = React.useState(null);
  const [radioOffer, setRadioOffer] = React.useState(null);
  const [radioBundleOffer, setRadioBundleOffer] = React.useState(null);
  const [isAddActionService, setIsAddActionService] = React.useState(false);
  const [isAddActionOffer, setIsAddActionOffer] = React.useState(false);
  const [showHistory, setShowHistory] = React.useState(false);
  const [loadingAction, setLoadingAction] = React.useState(false);
  const [isNewService, setIsNewService] = React.useState(false);
  const [isNewOffer, setIsNewOffer] = React.useState(false);
  const [isNewBundleOffer, setIsNewBundleOffer] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [indexServiceAddress, setIndexServiceAddress] = React.useState(-1);
  const [isEditWhereData, setIsEditWhereData] = React.useState("");
  const [debitProfile, setDebitProfile] = React.useState([]);
  const [errorState, setErrorState] = React.useState([]);
  const [checkConfirmError, setCheckConfirmError] = React.useState(false);
  const [duplicate, setDuplicate] = React.useState(false);
  const [remark, setRemark] = React.useState("");
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [summitEditServiceInfo, setSummitEditServiceInfo] =
    React.useState(false);
  const [submitForm, setSubmitForm] = React.useState({});
  const [serviceInfoError, setServiceInfoError] = React.useState(false);
  const [duplicateData, setDuplicateData] = React.useState();
  const [customerAddressData, setCustomerAddressData] = React.useState({});
  const [isAddActionBundleOffer, setIsAddActionBundleOffer] =
    React.useState(false);

  const isService =
    Object?.keys(isEditWhereData)?.findIndex((i) => {
      return i === "service_code";
    }) !== -1;

  const isBundleOffer =
    Object?.keys(isEditWhereData)?.findIndex((i) => {
      return i === "cust_bndl_offer_id" || i === "tran_conditions";
    }) !== -1;

  // console.log("isEditWhereData", {
  //   isEditWhereData,
  //   dataRequest,
  //   isBundleOffer,
  //   isService,
  // });

  // const isOffer =
  //   Object?.keys(isEditWhereData)?.findIndex((i) => {
  //     return i === "offer_code";
  //   }) !== -1;

  const FunctionOne = () => {
    if (indexServiceAddress === -1) setActionAppbar("CUSTOMER_SERVICE");

    setErrorModalMessage("");
    const dontDoThis = !data || isNewService || isNewOffer || isNewBundleOffer;
    if (!dontDoThis) {
      dispatch(
        setStateComponent({
          stateComponent: "customerService",
          onClickAction: onClickSendToApprover,
          onClickBeforeAction: onClickBeforeAction,
        })
      );
      dispatch(setConfirmText(modalConfig.sendApproverConfirm.description));
      if (!isEditWhereData) {
        dispatch(setDisabledAction({ disabledAction: true }));
      }
      if (isEditWhereData) {
        dispatch(setDisabledAction({ disabledAction: false }));
      }
    }
  };

  const FunctionTwo = () => {
    if (data) {
      setAccountTable(data.account);
      setOriginalData(data.account);
    }
  };

  const FunctionThree = () => {
    if (
      routeName.length < 3 &&
      dataRequest?.transaction_type !== "UPDATESERVICEADDRESS"
    ) {
      setIndexServiceAddress(-1);
    }
    setServiceInfoError(false);
  };

  const FunctionFour = () => {
    if (isNewService || isNewOffer || isNewBundleOffer || isEdit) {
      setCheckConfirmError(false);
      setErrorState([]);
    }
  };

  const FunctionFive = () => {
    if (data) {
      setCustomerAddressData(data);
      if (isEdit && dataRequest?.transaction_type !== "UPDATESERVICEADDRESS")
        return;

      setRouteName([
        { title: CUSTOMER_TITLE, path: "/customer" },
        {
          title: `${data.customer_name}`,
          path: `/customer/${params.customer_id}`,
        },
      ]);
    }
  };

  const FunctionSix = () => {
    setRemark("");
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  };

  const FunctionSeven = () => {
    const queryStringUrl = queryString.parse(location.search);
    if (!dataRequest?.transaction_type) {
      // console.log("data req change", dataRequest);
      setDuplicateData();
      setReload(!reload);
      return;
    }
    if (Object.keys(dataRequest).length > 0 && queryStringUrl.transaction_id) {
      setDuplicate(true);
      dispatch(setDisabledAction({ disabledAction: false }));
      if (dataRequest.transaction_type === "NEWSERVICE") {
        setIsNewService(true);
      } else if (dataRequest.transaction_type === "UPDATESERVICE") {
        setIsEdit(true);
        setIsEditWhereData(prePareService(_.cloneDeep(dataRequest.raw_data)));
        setRouteName([
          { title: CUSTOMER_TITLE, path: `/${dataRequest.customer_name}` },
          {
            title: `${dataRequest.customer_name}`,
            path: `/customer/${dataRequest.customer_id}`,
            setSelected: setIsEdit,
            check: true,
            action: () => {
              setIsEditWhereData("");
              setErrorState([]);
            },
          },
          {
            title: "EDIT SERVICE",
          },
        ]);
      } else if (dataRequest.transaction_type === "NEWOFFER") {
        setIsNewOffer(true);
      } else if (dataRequest.transaction_type === "UPDATEOFFER") {
        setIsEdit(true);
        setIsEditWhereData(prePareOffer(_.cloneDeep(dataRequest.raw_data)));
        setRouteName([
          { title: CUSTOMER_TITLE, path: `/${dataRequest.customer}` },
          {
            title: `${dataRequest.customer_name}`,
            path: `/customer/${dataRequest.customer_id}`,
            setSelected: setIsEdit,
            check: true,
            action: () => {
              setIsEditWhereData("");
              setErrorState([]);
            },
          },
          {
            title: "EDIT OFFER",
          },
        ]);
      } else if (dataRequest.transaction_type === "NEWBUNDLEOFFER") {
        setIsNewBundleOffer(true);
      } else if (dataRequest.transaction_type === "UPDATEBUNDLEOFFER") {
        setIsEdit(true);
        setIsEditWhereData(
          prePareBundleOffer(_.cloneDeep(dataRequest.raw_data))
        );
        setRouteName([
          { title: CUSTOMER_TITLE, path: `/${dataRequest.customer}` },
          {
            title: `${dataRequest.customer_name}`,
            path: `/customer/${dataRequest.customer_id}`,
            setSelected: setIsEdit,
            check: true,
            action: () => {
              setIsEditWhereData("");
              setErrorState([]);
            },
          },
          {
            title: "EDIT BUNDLED OFFER",
          },
        ]);
      } else if (dataRequest.transaction_type === "UPDATESERVICEADDRESS") {
        setActionAppbar("EDIT_ADDRESS");
        setIndexServiceAddress(dataRequest.transaction_id);
        setDuplicateData({
          ...dataRequest.update_obj,
          transaction_id: dataRequest.transaction_id,
          customer_name: dataRequest.customer_name,
          customer_id: dataRequest.customer_id,
          account_id: dataRequest.account_id,
        });
      }
    }
  };

  React.useEffect(() => {
    FunctionOne();
  }, [
    data,
    isEditWhereData,
    debitProfile,
    errorState,
    dataRequest,
    indexServiceAddress,
    submitForm,
    serviceInfoError,
  ]);

  React.useEffect(() => {
    FunctionTwo();
  }, [setRouteName, data]);

  React.useEffect(() => {
    FunctionThree();
  }, [routeName]);

  React.useEffect(() => {
    FunctionFour();
  }, [isNewOffer, isNewService, isEdit]);

  React.useEffect(() => {
    FunctionFive();
  }, [data]);

  React.useEffect(() => {
    FunctionSix();
  }, [isEdit, isNewOffer, isNewService]);

  const onBackButtonEvent = async () => {
    if (isEdit || isNewService || isNewOffer || isNewBundleOffer) {
      setIsEdit(false);
      setIsNewService(false);
      setIsNewOffer(false);
      setIsNewBundleOffer(false);
      setRadioService(null);
      setRadioOffer(null);
      setRadioBundleOffer(null);
      dispatch(setDisabledAction({ disabledAction: true }));
      await refetch({ customer_id: params.customer_id }, {});
    } else {
      navigate("/customer");
    }
  };

  React.useEffect(() => {
    FunctionSeven();
  }, [dataRequest]);

  const requestSearch = (searchedVal) => {
    setSearchedPage(searchedVal.target.value);
    setAccountPage(0);
    if (searchedVal?.target?.value?.length < MINIMUM_SEARCH_ACTIVE) {
      setAccountTable(originalData);
      return;
    }
    if (originalData.length === 0) return;
    const filteredRows = originalData.filter((row) => {
      return (
        row.account_name
          .toLowerCase()
          .includes(searchedVal.target.value.toLowerCase()) ||
        row?.account_id
          .toLowerCase()
          .includes(searchedVal.target.value.toLowerCase())
      );
    });
    setAccountTable(filteredRows);
  };

  const addService = (e) => {
    e.stopPropagation();
    setIsAddActionService(true);
  };

  const addOffer = (e) => {
    e.stopPropagation();
    setIsAddActionOffer(true);
  };

  const addBundleOffer = (e) => {
    e.stopPropagation();
    setIsAddActionBundleOffer(true);
  };

  const onClickBeforeAction = (setShowConfirm) => {
    let checkError = false;

    if (indexServiceAddress > -1) {
      /// validate email here
      if (serviceInfoError) {
        checkError = true;
      }
      setSummitEditServiceInfo(!summitEditServiceInfo);
    } else {
      if (isService) {
        console.log("IS IN SERVICE");
        const service = _.cloneDeep(isEditWhereData);
        const checkErroDateList = checkErrorDate(service);
        if (checkDuplicatePriority(service, true)) {
          checkError = true;
          setErrorState((prev) => [
            {
              field_name: "priority_empty",
              show: true,
              message: "Priority cannot be empty ,  - or 0",
            },
          ]);
        } else {
          checkError = false;
          setErrorState((prev) => [
            ...prev.filter((item) => item.field_name !== "priority_empty"),
          ]);
        }
        const checkErrorDuplicate = checkDuplicatePriority(service);
        const checkListChargeError = getFeeChargeError(service);
        const checkErrorQualifer = checkDuplicateQualifer(service);

        if (checkErrorQualifer.length > 0) {
          const unqiue_error_state = _.unionBy(
            checkErrorQualifer,
            "field_name"
          );
          setErrorState((prev) =>
            _.unionBy([...unqiue_error_state, ...prev], "field_name")
          );
          checkError = true;
        } else {
          setErrorState((prev) => [
            ...prev.filter((item) => item.field_name !== "qualifer"),
          ]);
        }

        if (checkListChargeError.length > 0) {
          checkError = true;
          setErrorState((prev) => [
            ...prev,
            { field_name: "validate_error", show: false },
          ]);
        } else {
          setErrorState((prev) => [
            ...prev.filter((item) => item.field_name !== "validate_error"),
          ]);
        }

        if (checkErroDateList.length > 0) {
          const unqiue_error_state = _.unionBy(checkErroDateList, "field_name");
          setErrorState((prev) => {
            return _.unionBy([...unqiue_error_state, ...prev], "field_name");
          });
          checkError = true;
        } else {
          setErrorState((prev) => {
            return prev.filter((item) => {
              return item.field_name !== "overlap_date";
            });
          });
        }

        if (checkErrorDuplicate.length > 0) {
          const unqiue_error_state = _.unionBy(
            checkErrorDuplicate,
            "field_name"
          );
          setErrorState((prev) =>
            _.unionBy([...unqiue_error_state, ...prev], "field_name")
          );
          checkError = true;
        } else {
          setErrorState((prev) => {
            return prev.filter((item) => {
              return item.field_name !== "priority";
            });
          });
        }
      } else {
        console.log("IN ELSE");
        const listError = getValidateOfferError(_.cloneDeep(isEditWhereData));

        if (listError.length > 0) {
          checkError = true;
          setErrorState((prev) => {
            return [...prev, { field_name: "validate_error", show: false }];
          });
        } else {
          setErrorState((prev) =>
            prev.filter((item) => item.field_name !== "validate_error")
          );
        }
      }
      if (
        !debitProfile.filter((profile) => profile.action !== "DELETE").length
      ) {
        setErrorState((prev) => {
          const find_exists = prev.find(
            (item) => item.field_name === "posting_account"
          );
          if (!find_exists) {
            prev.push({
              error: true,
              message: "Please select debit profile",
              field_name: "posting_account",
              field_value: [],
              show: true,
            });
          }
          return [...prev];
        });
        checkError = true;
      } else {
        if (isService) {
          const originalData = JSON.parse(
            sessionStorage.getItem("originalData")
          );

          const findServiceOriginal = originalData.service.find(
            (service) => service.service_code === isEditWhereData.service_code
          );

          const cloneDebitProfile = _.cloneDeep(debitProfile);
          const clonePositingAccount = _.cloneDeep(
            findServiceOriginal.posting_account
          );

          const cloneFindServiceOriginal = _.cloneDeep(findServiceOriginal);
          const cloneIsEditWhereData = _.cloneDeep(isEditWhereData);

          delete cloneFindServiceOriginal.posting_account;
          delete cloneIsEditWhereData.posting_account;

          const compareServiceOriginal = JSON.stringify(
            cloneFindServiceOriginal
          );
          const compareService = JSON.stringify(cloneIsEditWhereData);

          const compareDebitProfile = JSON.stringify(cloneDebitProfile);
          const comparePostingAccount = JSON.stringify(clonePositingAccount);

          // console.log("IN CHANGE OR NOT :", {
          //   compareServiceOriginal: compareServiceOriginal.length,
          //   compareService: compareService.length,
          //   compareDebitProfile: compareDebitProfile.length,
          //   comparePostingAccount: comparePostingAccount.length,
          //   isEditWhereData,
          // });

          if (
            compareServiceOriginal.length === compareService.length &&
            comparePostingAccount === compareDebitProfile
          ) {
            setErrorState((prev) => {
              const find_exists = prev.find(
                (item) => item.field_name === "not_update"
              );
              if (!find_exists) {
                prev.push({
                  error: true,
                  message: "No data changed",
                  field_name: "not_update",
                  field_value: [],
                  show: true,
                });
              }
              return [...prev];
            });
            checkError = true;
          } else {
            setErrorState((prev) => {
              const filterExist = prev.filter(
                (item) => item.field_name !== "not_update"
              );
              return [...filterExist];
            });
          }
        } else if (isBundleOffer) {
          const originalData = dataRequest?.hasOwnProperty("raw_data")
            ? { offer: [_.omit(dataRequest?.raw_data, ["action"])] }
            : JSON.parse(sessionStorage.getItem("originalData"));

          let findOfferOriginal = null;

          if (originalData?.bundle_offer) {
            findOfferOriginal = originalData?.bundle_offer?.find(
              (d) => d?.offer_code === isEditWhereData?.offer_code
            );
          }

          if (originalData?.offer && !originalData?.bundle_offer) {
            findOfferOriginal = originalData?.offer?.find(
              (d) => d?.offer_code === isEditWhereData?.offer_code
            );
          }

          console.log("originalData", {
            originalData,
            findOfferOriginal,
            isEditWhereData,
          });

          const cloneFindOfferOriginal = _.cloneDeep(findOfferOriginal);
          const cloneIsEditWhereData = _.cloneDeep(
            _.omit(isEditWhereData, ["action"])
          );

          const cloneDebitProfile = _.cloneDeep(debitProfile);
          const clonePositingAccount = _.cloneDeep(
            findOfferOriginal.posting_account
          );

          delete cloneFindOfferOriginal.posting_account;
          delete cloneIsEditWhereData.posting_account;

          const compareOfferOriginal = JSON.stringify(cloneFindOfferOriginal);
          const compareOffer = JSON.stringify(cloneIsEditWhereData);

          const compareDebitProfile = JSON.stringify(cloneDebitProfile);
          const comparePostingAccount = JSON.stringify(clonePositingAccount);

          if (
            compareOfferOriginal === compareOffer &&
            comparePostingAccount === compareDebitProfile
          ) {
            setErrorState((prev) => {
              const find_exists = prev.find(
                (item) => item.field_name === "not_update"
              );
              if (!find_exists) {
                prev.push({
                  error: true,
                  message: "No data changed",
                  field_name: "not_update",
                  field_value: [],
                  show: true,
                });
              }
              return [...prev];
            });
            checkError = true;
          } else {
            setErrorState((prev) => {
              const filterExist = prev.filter(
                (item) => item.field_name !== "not_update"
              );
              return [...filterExist];
            });
          }
        } else {
          const originalData = dataRequest?.hasOwnProperty("raw_data")
            ? { offer: [_.omit(dataRequest?.raw_data, ["action"])] }
            : JSON.parse(sessionStorage.getItem("originalData"));

          const findOfferOriginal = originalData?.offer?.find(
            (offer) => offer?.offer_code === isEditWhereData?.offer_code
          );

          const cloneFindOfferOriginal = _.cloneDeep(findOfferOriginal);
          const cloneIsEditWhereData = _.cloneDeep(
            _.omit(isEditWhereData, ["action"])
          );

          const cloneDebitProfile = _.cloneDeep(debitProfile);
          const clonePositingAccount = _.cloneDeep(
            findOfferOriginal.posting_account
          );

          delete cloneFindOfferOriginal.posting_account;
          delete cloneIsEditWhereData.posting_account;

          const compareOfferOriginal = JSON.stringify(cloneFindOfferOriginal);
          const compareOffer = JSON.stringify(cloneIsEditWhereData);

          const compareDebitProfile = JSON.stringify(cloneDebitProfile);
          const comparePostingAccount = JSON.stringify(clonePositingAccount);

          if (
            compareOfferOriginal === compareOffer &&
            comparePostingAccount === compareDebitProfile
          ) {
            setErrorState((prev) => {
              const find_exists = prev.find(
                (item) => item.field_name === "not_update"
              );
              if (!find_exists) {
                prev.push({
                  error: true,
                  message: "No data changed",
                  field_name: "not_update",
                  field_value: [],
                  show: true,
                });
              }
              return [...prev];
            });
            checkError = true;
          } else {
            setErrorState((prev) => {
              const filterExist = prev.filter(
                (item) => item.field_name !== "not_update"
              );
              return [...filterExist];
            });
          }
        }
      }
    }

    if (
      errorState.filter((item) => {
        return ![
          "not_update",
          "overlap_date",
          "validate_error",
          "priority",
          "qualifer",
        ].includes(item.field_name);
      }).length > 0 &&
      indexServiceAddress === -1
    ) {
      checkError = true;
    }

    // console.log("errorState", errorState);
    if (checkError || errorState.length > 0) {
      setCheckConfirmError(true);
      onScrollToTop("invalid");
    } else {
      setShowConfirm(true);
    }
  };

  const onClickSendToApprover = () => {
    if (indexServiceAddress > -1) {
      if (dataRequest?.transaction_id && duplicateData) {
        return dispatch(
          postSendApproverCustomerService({
            data: mappingServiceInfo(
              duplicateData,
              duplicateData,
              submitForm,
              dataRequest?.transaction_id
            ),
          })
        );
      }
      return dispatch(
        postSendApproverCustomerService({
          data: mappingServiceInfo(
            data,
            data.service_info[indexServiceAddress],
            submitForm,
            null
          ),
        })
      );
    }

    if (isService) {
      return dispatch(
        postSendApproverCustomerService({
          data: mappingServiceEditApprover(
            {
              ...isEditWhereData,
              posting_account: debitProfile,
            },
            dataRequest,
            remark
          ),
        })
      );
    } else if (isBundleOffer) {
      return dispatch(
        postSendApproverCustomerService({
          data: mappingBundleOfferEditApprover(
            {
              ...isEditWhereData,
              posting_account: debitProfile,
            },
            dataRequest,
            remark
          ),
        })
      );
    } else {
      return dispatch(
        postSendApproverCustomerService({
          data: mappingOfferEditApprover(
            {
              ...isEditWhereData,
              posting_account: debitProfile,
            },
            dataRequest,
            remark
          ),
        })
      );
    }
  };

  const actionModalService = async () => {
    try {
      setLoadingAction(true);
      const { data: responseData } =
        await makerCheckerService.getMakerCheckerValidatePending(
          {},
          `?customer_id=${params.customer_id}&code=${radioService}&tran_type=NEWSERVICE`
        );

      if (responseData.data.pending === "N") {
        setIsNewService(true);
        setIsAddActionService(false);
        dispatch(setDisabledAction({ disabledAction: false }));
        setRouteName([
          { title: CUSTOMER_TITLE, path: "/customer" },
          {
            title: `${data.customer_name}`,
            path: `/customer/${params.customer_id}`,
            setSelected: setIsNewService,
            check: true,
            action: () => {
              setIsEditWhereData("");
              setErrorState([]);
            },
          },
          {
            title: "ADD SERVICE",
          },
        ]);

        return;
      }

      setErrorModalMessage(responseData.data);
    } catch (error) {
    } finally {
      setLoadingAction(false);
    }
  };

  const actionModalOffer = async () => {
    try {
      setLoadingAction(true);
      const { data: responseData } =
        await makerCheckerService.getMakerCheckerValidatePending(
          {},
          `?customer_id=${params.customer_id}&code=${radioOffer}&tran_type=NEWOFFER`
        );

      if (responseData.data.pending === "N") {
        setIsNewOffer(true);
        setIsAddActionOffer(false);
        dispatch(setDisabledAction({ disabledAction: false }));
        setRouteName([
          { title: CUSTOMER_TITLE, path: "/customer" },
          {
            title: `${data.customer_name}`,
            path: `/customer/${params.customer_id}`,
            setSelected: setIsNewOffer,
            check: true,
            action: () => {
              setIsEditWhereData("");
              setErrorState([]);
            },
          },
          {
            title: "ADD OFFER",
          },
        ]);
        return;
      }

      setErrorModalMessage(responseData.data);
    } catch (error) {
    } finally {
      setLoadingAction(false);
    }
  };

  const actionModalBundleOffer = async () => {
    try {
      setLoadingAction(true);
      const { data: responseData } =
        await makerCheckerService.getMakerCheckerValidatePending(
          {},
          `?customer_id=${params.customer_id}&code=${radioBundleOffer}&tran_type=NEWBUNDLEOFFER`
        );

      if (responseData.data.pending === "N") {
        setIsNewBundleOffer(true);
        setIsAddActionBundleOffer(false);
        dispatch(setDisabledAction({ disabledAction: false }));
        setRouteName([
          { title: CUSTOMER_TITLE, path: "/customer" },
          {
            title: `${data.customer_name}`,
            path: `/customer/${params.customer_id}`,
            setSelected: setIsNewBundleOffer,
            check: true,
            action: () => {
              setIsEditWhereData("");
              setErrorState([]);
            },
          },
          {
            title: "ADD BUNDLED OFFER",
          },
        ]);
        return;
      }

      setErrorModalMessage(responseData.data);
    } catch (error) {
    } finally {
      setLoadingAction(false);
    }
  };

  const callbackFilterService = (value) => (row) => {
    return (
      row.service_name.toLowerCase().includes(value.toLowerCase()) ||
      row.service_code.toLowerCase().includes(value.toLowerCase())
    );
  };

  const callbackFilterOffer = (value) => (row) => {
    return (
      row.offer_name.toLowerCase().includes(value.toLowerCase()) ||
      row.offer_code.toLowerCase().includes(value.toLowerCase())
    );
  };

  const callbackNavigate = (row) => {
    navigate(`/customer/${params.customer_id}/history/${row.request_id}`);
  };

  const EditServiceAddress = (index) => {
    const OnClickEditServiceAddress = () => {
      setActionAppbar("EDIT_ADDRESS");
      setIndexServiceAddress(index);
      setRouteName([
        { title: CUSTOMER_TITLE, path: "/customer" },
        {
          title: `${data.customer_name}`,
          path: `/customer/${params.customer_id}`,
          setSelected: setIsNewService,
          check: true,
          action: () => {
            setIsEditWhereData("");
            setErrorState([]);
          },
        },
        {
          title: "EDIT ADDRESS",
        },
      ]);
    };

    if (
      user?.menu?.customer === "E" &&
      data?.service_info[index]?.edit_flag === "Y"
    )
      return (
        <Button
          variant="text"
          onClick={() => {
            OnClickEditServiceAddress();
          }}
          sx={{ fontSize: "14px", marginTop: "0px" }}
          startIcon={<img src={EditIcon}></img>}
        ></Button>
      );

    return <div></div>;
  };

  const refreshDIH = async (e) => {
    e.stopPropagation();
    try {
      const { data: responseData } = await customerService.refreshDIH(
        data.customer_id
      );

      // const responseData = { data: refemock };

      // setCustomerAddressData((prev) => {
      // let cloneData = cloneDeep({ ...prev });
      // cloneData = {
      //   ...cloneData,
      //   ...responseData?.data,
      // };
      // return cloneData;
      // });

      // if (responseData?.data) {
      //   setData((prev) => {
      //     let cloneData = cloneDeep({ ...prev });
      //     if (responseData?.data?.data) {
      //       cloneData = {
      //         ...cloneData,
      //         ...responseData?.data?.data,
      //       };
      //     } else {
      //       cloneData = {
      //         ...cloneData,
      //         ...responseData?.data,
      //       };
      //     }

      //     return cloneData;
      //     // return prev;
      //   });
      // }
      // return;

      setCustomerAddressData(responseData.data);
      setReload(!reload);
    } catch (error) {
      setCheckConfirmError(true);
      setErrorState([{ message: "Unable to Refresh DIH" }]);
    }
  };

  if (loading)
    return (
      <div className="h-screen m-auto">
        <PBFELoading />
      </div>
    );

  if (error) return <PBFEError error={error} />;

  if (isNewService) {
    return (
      <React.Fragment>
        {duplicate ? (
          <CustomerAddNewServiceDuplicate
            dataRequest={dataRequest}
            setRouteName={setRouteName}
            setIsNewService={setIsNewService}
            data={dataRequest?.raw_data}
            is_maker={data?.is_maker === "Y"}
            originalData={data}
            customer={{
              customer_name: data.customer_name,
              customer_id: data.customer_id,
            }}
            service_code={radioService}
            accountTable={accountTable}
            isAddActionService={isAddActionService}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            setErrorState={setErrorState}
            errorState={errorState}
            checkConfirmError={checkConfirmError}
            setCheckConfirmError={setCheckConfirmError}
          />
        ) : (
          <CustomerRenderAddNewService
            is_maker={data?.is_maker === "Y"}
            originalData={data}
            customer={{
              customer_name: data.customer_name,
              customer_id: data.customer_id,
            }}
            setRadioService={setRadioService}
            service_code={radioService}
            accountTable={accountTable}
            isAddActionService={isAddActionService}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            setErrorState={setErrorState}
            errorState={errorState}
            checkConfirmError={checkConfirmError}
            setCheckConfirmError={setCheckConfirmError}
          />
        )}
        {isAddActionService && (
          <AsyncModal
            setErrorModalMessage={setErrorModalMessage}
            errorModalMessage={errorModalMessage}
            customer_id={params.customer_id}
            title="Add Service"
            open={isAddActionService}
            onClose={() => setIsAddActionService(false)}
            radioValue={radioService}
            setRadio={setRadioService}
            serviceApi={customerService.getServiceCustomerById}
            params={{
              customer_id: params.customer_id,
            }}
            query={{
              customer_id: params.customer_id,
              user_id: user?.info?.preferred_username,
            }}
            placeholder="Search Service Name / Code"
            option_field_label="service_name"
            option_field_value="service_code"
            orderByDefault="service_name"
            action={actionModalService}
            loadingAction={loadingAction}
            head_cells={ADD_SERVICE_TABLE_HEADER}
            showFields={["service_name", "service_code"]}
            callbackFilter={callbackFilterService}
          />
        )}
      </React.Fragment>
    );
  }

  if (isNewOffer) {
    return (
      <React.Fragment>
        {duplicate ? (
          <CustomerAddNewOfferDuplicate
            dataRequest={dataRequest}
            setRouteName={setRouteName}
            setIsNewService={setIsNewService}
            data={dataRequest?.raw_data}
            is_maker={data?.is_maker === "Y"}
            originalData={data}
            customer={{
              customer_name: data.customer_name,
              customer_id: data.customer_id,
            }}
            offer_code={radioOffer}
            accountTable={accountTable}
            isAddActionOffer={isAddActionOffer}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            setErrorState={setErrorState}
            errorState={errorState}
            checkConfirmError={checkConfirmError}
            setCheckConfirmError={setCheckConfirmError}
          />
        ) : (
          <CustomerRenderAddNewOffer
            is_maker={data?.is_maker === "Y"}
            originalData={data}
            customer={{
              customer_name: data.customer_name,
              customer_id: data.customer_id,
            }}
            offer_code={radioOffer}
            setRadioOffer={setRadioOffer}
            accountTable={accountTable}
            isAddActionOffer={isAddActionOffer}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            setErrorState={setErrorState}
            errorState={errorState}
            checkConfirmError={checkConfirmError}
            setCheckConfirmError={setCheckConfirmError}
          />
        )}
        {isAddActionOffer && (
          <AsyncModal
            setErrorModalMessage={setErrorModalMessage}
            errorModalMessage={errorModalMessage}
            customer_id={params.customer_id}
            title="Add Offer"
            open={isAddActionOffer}
            onClose={() => setIsAddActionOffer(false)}
            radioValue={radioOffer}
            setRadio={setRadioOffer}
            serviceApi={customerService.getOfferCustomerById}
            params={{
              customer_id: params.customer_id,
            }}
            query={{
              customer_id: params.customer_id,
              user_id: user?.info?.preferred_username,
            }}
            option_field_label="offer_name"
            option_field_value="offer_code"
            action={actionModalOffer}
            loadingAction={loadingAction}
            placeholder={"Search Offer Name / Code"}
            orderByDefault="offer_name"
            head_cells={ADD_OFFER_TABLE_HEADER}
            showFields={["offer_name", "offer_code"]}
            callbackFilter={callbackFilterOffer}
          />
        )}
      </React.Fragment>
    );
  }

  if (isNewBundleOffer) {
    return (
      <React.Fragment>
        {duplicate ? (
          <CustomerAddNewBundleOfferDuplicate
            dataRequest={dataRequest}
            setRouteName={setRouteName}
            setIsNewService={setIsNewService}
            data={dataRequest?.raw_data}
            is_maker={data?.is_maker === "Y"}
            originalData={data}
            customer={{
              customer_name: data.customer_name,
              customer_id: data.customer_id,
            }}
            offer_code={radioBundleOffer}
            accountTable={accountTable}
            isAddActionOffer={isAddActionBundleOffer}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            setErrorState={setErrorState}
            errorState={errorState}
            checkConfirmError={checkConfirmError}
            setCheckConfirmError={setCheckConfirmError}
          />
        ) : (
          <CustomerAddNewOfferBundle
            is_maker={data?.is_maker === "Y"}
            originalData={data}
            customer={{
              customer_name: data.customer_name,
              customer_id: data.customer_id,
            }}
            offer_code={radioBundleOffer}
            setRadioOffer={setRadioBundleOffer}
            accountTable={accountTable}
            isAddActionOffer={isAddActionBundleOffer}
            debitProfile={debitProfile}
            setDebitProfile={setDebitProfile}
            setErrorState={setErrorState}
            errorState={errorState}
            checkConfirmError={checkConfirmError}
            setCheckConfirmError={setCheckConfirmError}
          />
        )}
        {isAddActionBundleOffer && (
          <AsyncModal
            setErrorModalMessage={setErrorModalMessage}
            errorModalMessage={errorModalMessage}
            customer_id={params.customer_id}
            title="Add Bundled Offer"
            open={isAddActionBundleOffer}
            onClose={() => setIsAddActionBundleOffer(false)}
            radioValue={radioOffer}
            setRadio={setRadioBundleOffer}
            serviceApi={customerService.getOfferCustomerById}
            params={{
              customer_id: params.customer_id,
            }}
            query={{
              customer_id: params.customer_id,
              user_id: user?.info?.preferred_username,
            }}
            option_field_label="offer_name"
            option_field_value="offer_code"
            action={actionModalBundleOffer}
            loadingAction={loadingAction}
            placeholder={"Search Offer Name / Code"}
            orderByDefault="offer_name"
            head_cells={ADD_OFFER_TABLE_HEADER}
            showFields={["offer_name", "offer_code"]}
            callbackFilter={callbackFilterOffer}
          />
        )}
      </React.Fragment>
    );
  }

  if (isEdit) {
    let foundIndexOriginal = 0;

    if (originDataQuery) {
      foundIndexOriginal = isBundleOffer
        ? originDataQuery?.bundle_offer?.findIndex(
            (offer) => offer?.offer_code === isEditWhereData?.offer_code
          )
        : isService
        ? originDataQuery?.service?.findIndex(
            (service) => service?.service_code === isEditWhereData?.service_code
          )
        : originDataQuery?.offer?.findIndex(
            (offer) => offer?.offer_code === isEditWhereData?.offer_code
          );
    }

    return (
      <div id="edit" className="w-full">
        <PBFEInvalid
          isShow={checkConfirmError && errorState.length > 0}
          message=""
          setOpen={setCheckConfirmError}
          open={checkConfirmError}
          errorState={errorState}
        />

        <div className="w-full overflow-auto">
          <div className="grid grid-cols-2 w-full px-xl my-20px">
            <PBFEContent label="Customer Name" value={data?.customer_name} />
            <PBFEContent label="Customer ID" value={data?.customer_id} />
          </div>
          <div className="h-10px bg-gray-100"></div>
          <React.Fragment>
            <PBFEAccordionMain
              label={
                isBundleOffer
                  ? "Bundled Offer"
                  : isService
                  ? "Service"
                  : "Offer"
              }
              detail={
                <PBFEAccordionMain
                  pl="ml-20px"
                  customHeader={
                    <PBFEHeader
                      isOffer={!isService}
                      p=""
                      label={
                        isService
                          ? isEditWhereData?.service_name +
                            " - " +
                            isEditWhereData?.service_code
                          : isEditWhereData?.offer_name +
                            " - " +
                            isEditWhereData?.offer_code
                      }
                      purple={true}
                      hideButton={true}
                    />
                  }
                  detail={[
                    isBundleOffer ? (
                      <BundleOfferInformation
                        data={isEditWhereData}
                        index={foundIndexOriginal}
                        origin={origin}
                        setData={setData}
                        account_table={isEditWhereData?.posting_account}
                        accountTable={accountTable}
                        isEdit={isEdit}
                        setDebitProfile={setDebitProfile}
                        debitProfile={debitProfile}
                        setErrorState={setErrorState}
                        setDataDuplicate={setIsEditWhereData}
                      />
                    ) : isService ? (
                      <ServiceInformation
                        data={isEditWhereData}
                        index={foundIndexOriginal}
                        origin={origin}
                        setData={setData}
                        account_table={isEditWhereData?.posting_account}
                        isEdit={isEdit}
                        accountTable={accountTable}
                        setDebitProfile={setDebitProfile}
                        debitProfile={debitProfile}
                        setErrorState={setErrorState}
                        // hideButton={hideButton}
                        // isNew={isNew}
                      />
                    ) : (
                      <OfferInformation
                        data={isEditWhereData}
                        index={foundIndexOriginal}
                        origin={origin}
                        setData={setData}
                        account_table={isEditWhereData?.posting_account}
                        accountTable={accountTable}
                        isEdit={isEdit}
                        setDebitProfile={setDebitProfile}
                        debitProfile={debitProfile}
                        setErrorState={setErrorState}
                        setDataDuplicate={setIsEditWhereData}
                      />
                    ),
                  ]}
                />
              }
            />
            <div className="ml-40px mr-25px">
              <TextField
                id="remark"
                onChange={(e) => setRemark(e.target.value)}
                value={remark}
                label="REMARK"
                variant="filled"
                helperText={"Limited " + remark?.length + "/300"}
                fullWidth
                inputProps={{ maxLength: 300 }}
              />
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }

  if (indexServiceAddress > -1) {
    return (
      <CustomerEditServiceAddress
        setDataRequest={setDataRequest}
        setRouteName={setRouteName}
        duplicateData={duplicateData}
        setServiceInfoError={setServiceInfoError}
        indexServiceAddress={indexServiceAddress}
        data={data}
        setSubmitForm={setSubmitForm}
        summitEditServiceInfo={summitEditServiceInfo}
      />
    );
  }

  return (
    <div className="w-full">
      <PBFEInvalid
        //  isShow={true}
        isShow={checkConfirmError && errorState.length > 0}
        message=""
        setOpen={setCheckConfirmError}
        open={checkConfirmError}
        requireShowField={false}
        errorState={errorState}
      />
      <div className="w-full overflow-auto">
        {!isNewService && isAddActionService && (
          <AsyncModal
            setErrorModalMessage={setErrorModalMessage}
            errorModalMessage={errorModalMessage}
            customer_id={params.customer_id}
            title="Add Service"
            open={isAddActionService}
            onClose={() => setIsAddActionService(false)}
            radioValue={radioService}
            setRadio={setRadioService}
            serviceApi={customerService.getServiceCustomerById}
            query={{
              customer_id: params.customer_id,
              user_id: user?.info?.preferred_username,
            }}
            params={{
              customer_id: params.customer_id,
            }}
            option_field_label="service_name"
            option_field_value="service_code"
            action={actionModalService}
            loadingAction={loadingAction}
            orderByDefault="service_name"
            head_cells={ADD_SERVICE_TABLE_HEADER}
            showFields={["service_name", "service_code"]}
            callbackFilter={callbackFilterService}
            placeholder="Search Service Name / Code"
          />
        )}
        {!isNewOffer && isAddActionOffer && (
          <AsyncModal
            setErrorModalMessage={setErrorModalMessage}
            errorModalMessage={errorModalMessage}
            customer_id={params.customer_id}
            title="Add Offer"
            open={isAddActionOffer}
            onClose={() => setIsAddActionOffer(false)}
            radioValue={radioOffer}
            setRadio={setRadioOffer}
            serviceApi={customerService.getOfferCustomerById}
            query={{
              customer_id: params.customer_id,
              user_id: user?.info?.preferred_username,
            }}
            params={{
              customer_id: params.customer_id,
            }}
            option_field_label="offer_name"
            option_field_value="offer_code"
            action={actionModalOffer}
            loadingAction={loadingAction}
            orderByDefault="offer_name"
            head_cells={ADD_OFFER_TABLE_HEADER}
            showFields={["offer_name", "offer_code"]}
            callbackFilter={callbackFilterOffer}
            placeholder="Search Offer Name / Code"
          />
        )}
        {!isNewBundleOffer && isAddActionBundleOffer && (
          <AsyncModal
            setErrorModalMessage={setErrorModalMessage}
            errorModalMessage={errorModalMessage}
            customer_id={params.customer_id}
            title="Add Bundled Offer"
            open={isAddActionBundleOffer}
            onClose={() => setIsAddActionBundleOffer(false)}
            radioValue={radioBundleOffer}
            setRadio={setRadioBundleOffer}
            serviceApi={customerService.getBundleOfferCustomerById}
            query={{
              customer_id: params.customer_id,
              user_id: user?.info?.preferred_username,
            }}
            params={{
              customer_id: params.customer_id,
            }}
            option_field_label="offer_name"
            option_field_value="offer_code"
            action={actionModalBundleOffer}
            loadingAction={loadingAction}
            orderByDefault="offer_name"
            head_cells={ADD_OFFER_TABLE_HEADER}
            showFields={["offer_name", "offer_code"]}
            callbackFilter={callbackFilterOffer}
            placeholder="Search Offer Name / Code"
          />
        )}
        {showHistory && (
          <HistoryModal
            callbackNavigate={callbackNavigate}
            open={showHistory}
            onClose={() => setShowHistory(false)}
            serviceApi={historyService.getHistoryById}
            query={{
              customer_id: params.customer_id,
              // account_id: "147645013000036",
              offset: 0,
              limit: 100,
            }}
          />
        )}
        <div className="grid grid-cols-2 w-full px-xl my-20px">
          <PBFEContent label="Customer Name" value={data?.customer_name} />
          <PBFEContent label="Customer ID" value={data?.customer_id} />
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setShowHistory(true);
          }}
          className="flex  items-center ml-auto justify-end space-x-2  px-xl mb-8 mr-4px  text-textscb text-sm"
        >
          <img className="cursor-pointer" src={HISTORY_IMAGE} />
          <div className="cursor-pointer">HISTORY</div>
        </button>

        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionMain
          label="General Info"
          detail={<CustomerRenderGeneralInfomation data={data} />}
        />
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionMain
          label="Customer Info for Legal Document"
          isRefresh={true}
          refreshText="Refresh from DIH"
          onClick={refreshDIH}
          detail={<CustomerRenderInformation data={customerAddressData} />}
        />
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionMain
          label="Service Customer Info for Legal Document"
          detail={
            <PageState
              name="page-service-address"
              initalState={0}
              body={(props) => {
                return (
                  <React.Fragment>
                    <div className="border-t">
                      <PBFECommonTable
                        disabledCursor={true}
                        page={props.state}
                        setPage={props.setState}
                        orderByDefault="service_code"
                        headCells={SERVICE_ADDRESS_TABLE_HEADER}
                        rows={mappingForServiceInfo(data?.service_info)}
                        formatControl={[
                          EditServiceAddress,
                          null,
                          null,
                          null,
                          null,
                          WrapTextEnter,
                          WrapTextEnter,
                          WrapTextEnterEmail,
                          null,
                        ]}
                        showFooter={true}
                      />
                    </div>
                  </React.Fragment>
                );
              }}
            />
          }
        />
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionSearch
          label="Account"
          requestSearch={requestSearch}
          searched={searchedPage}
          placeholderSearch={"Search Account Name / No."}
          elementFrontOfSearch={
            <React.Fragment>
              {data?.is_maker === "Y" ? (
                <button
                  onClick={() => {
                    sessionStorage.setItem(
                      "new_epp_biller",
                      JSON.stringify(data)
                    );
                    navigate(`/customer/${params.customer_id}/new_epp_biller`);
                  }}
                  className="my-auto text-textscbdark"
                >
                  Add ePP Biller
                </button>
              ) : (
                ""
              )}
            </React.Fragment>
          }
          detail={
            <CustomerRenderAccountInfomation
              searchedPage={searchedPage}
              accountPage={accountPage}
              setAccountPage={setAccountPage}
              accountTable={accountTable}
            />
          }
        />
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionWithButton
          label="Service"
          buttons={
            (data?.is_maker === "Y" && [
              { img: Plus, name: "ADD SERVICE", action: addService },
            ]) ||
            []
          }
          detail={
            <CustomerRenderService
              setDataRequest={setDataRequest}
              setErrorState={setErrorState}
              customer={{
                customer_id: data.customer_id,
                customer_name: data.customer_name,
              }}
              is_maker={data?.is_maker === "Y"}
              data={data}
              accountTable={accountTable}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              setRouteName={setRouteName}
              setIsEditWhereData={setIsEditWhereData}
              debitProfile={debitProfile}
              setDebitProfile={setDebitProfile}
              errorState={errorState}
              setCheckConfirmError={setCheckConfirmError}
              refetch={refetch}
            />
          }
        />
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionWithButton
          buttons={
            (data?.is_maker === "Y" && [
              { img: Plus, name: "ADD OFFER", action: addOffer },
            ]) ||
            []
          }
          label="Offer"
          detail={
            <CustomerRenderOffer
              setDataRequest={setDataRequest}
              refetch={refetch}
              customer={{
                customer_id: data.customer_id,
                customer_name: data.customer_name,
              }}
              is_maker={data?.is_maker === "Y"}
              data={data}
              accountTable={accountTable}
              isEdit={isEdit}
              setRouteName={setRouteName}
              setIsEdit={setIsEdit}
              setIsEditWhereData={setIsEditWhereData}
              debitProfile={debitProfile}
              setDebitProfile={setDebitProfile}
              setCheckConfirmError={setCheckConfirmError}
              setErrorState={setErrorState}
            />
          }
        />
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionWithButton
          buttons={
            (data?.is_maker === "Y" && [
              { img: Plus, name: "ADD BUNDLED OFFER", action: addBundleOffer },
            ]) ||
            []
          }
          label="Bundled Offer"
          detail={
            <CustomerRenderBundleOffer
              setDataRequest={setDataRequest}
              refetch={refetch}
              customer={{
                customer_id: data.customer_id,
                customer_name: data.customer_name,
              }}
              is_maker={data?.is_maker === "Y"}
              data={data}
              accountTable={accountTable}
              isEdit={isEdit}
              setRouteName={setRouteName}
              setIsEdit={setIsEdit}
              setIsEditWhereData={setIsEditWhereData}
              debitProfile={debitProfile}
              setDebitProfile={setDebitProfile}
              setCheckConfirmError={setCheckConfirmError}
              setErrorState={setErrorState}
            />
          }
        />
      </div>
    </div>
  );
};
