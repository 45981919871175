import CircularProgress from "@mui/material/CircularProgress";

export const PBFELoading = () => {
  return (
    <div className="flex items-center h-full w-full">
      <div className="m-auto">
        <div className="ml-2">
          <CircularProgress size={"40px"} />
        </div>
        <div className="text-textsecoundary">Loading</div>
      </div>
    </div>
  );
};
