import * as React from "react";

const TEXT_AUTHOR = "radium-omnibus";

export const FunctionGetStateValue = (name = "") => {
  const findData = document.querySelector(`#${TEXT_AUTHOR}-state-${name}`);
  if (findData?.className) {
    const thisClassName = findData.className;
    const ThisValue = thisClassName.substring(
      thisClassName.indexOf("(") + 1,
      thisClassName.indexOf(")")
    );

    return JSON.parse(ThisValue);
  } else {
    return "Not Found";
  }
};

export const FunctionGetAllStateValue = () => {
  const findAllData = document.querySelectorAll(`.${TEXT_AUTHOR}-state`);

  let thisData = {};

  findAllData.forEach((data) => {
    const thisClassName = data.className;
    const ThisValue = thisClassName.substring(
      thisClassName.indexOf("(") + 1,
      thisClassName.indexOf(")")
    );

    const thisName = thisClassName.substring(
      thisClassName.indexOf("[") + 1,
      thisClassName.indexOf("]")
    );

    thisData = {
      ...thisData,
      [thisName]: JSON.parse(ThisValue),
    };
  });

  return thisData;
};

export const PageState = ({ body, initalState = "", name = "" }) => {
  const [state, setState] = React.useState(initalState);

  return (
    <React.Fragment>
      <div
        id={`${TEXT_AUTHOR}-state-${name}`}
        className={`${TEXT_AUTHOR}-state ${name} [${name}] : (${JSON.stringify(
          state
        )})`}
        defaultValue={state}
        style={{
          display: "none",
        }}
      />
      {body({ state, setState })}
    </React.Fragment>
  );
};
