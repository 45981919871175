import { useDispatch } from "react-redux";
import { PBFELoading } from "../../../components/Elements/Loading/loading";
import { PBFEContent } from "../../../components/Elements/Text/content";
import PBFEError from "../../../components/Error/alertError";
import PBFEInvalid from "../../../components/Error/invalidData";
import { PBFEAccordionMain } from "../../../components/Panel/Accordion/accordionMain";
import useQuery from "../../../hooks/useQuery";
import { formatDateApi } from "../../../utils/util";
import AccountRenderService from "./Account.RenderService";
import serviceService from "../../../api/serviceService";
import React from "react";
import { TextField } from "@mui/material";
import { TYPE_ONE_TIME } from "../../../constants/constant";

const AccountAddNewService = ({
  account,
  customer,
  service_code,
  accountTable,
  debitProfile,
  setDebitProfile,
  isAddActionService,
  originalData,
  setErrorState,
  is_maker,
  errorState,
  setCheckConfirmError,
  checkConfirmError,
  setRadioService,
}) => {
  const dispatch = useDispatch();
  const [remark, setRemark] = React.useState("");

  const prePareData = (data) => {
    return {
      ...data,
      fee_charge: data.fee_charge.map((charge) => {
        return {
          ...charge,
          from_date: formatDateApi(new Date()),
          to_date:
            charge?.pricing_method === TYPE_ONE_TIME
              ? formatDateApi(new Date())
              : data.effective_to,
          isError: false,
          isRemove: false,
        };
      }),
    };
  };

  const { data, error, loading } = useQuery(
    serviceService.getServiceInfo,
    {
      service_code: service_code,
    },
    {},
    [],
    { prePareData, checkFirstLoad: true }
  );

  React.useEffect(() => {
    setRadioService(null);
  }, []);

  if (loading)
    return (
      <div className="h-screen m-auto">
        <PBFELoading />
      </div>
    );

  if (error) return <PBFEError error={error} />;

  return (
    <div className="w-full">
      <PBFEInvalid
        isShow={checkConfirmError && errorState.length > 0}
        message=""
        setOpen={setCheckConfirmError}
        open={checkConfirmError}
        errorState={errorState}
      />
      <div className="w-full overflow-auto">
        <div className="grid grid-cols-2 w-full px-xl my-20px">
          <PBFEContent label="Account Name" value={account?.account_name} />
          <PBFEContent label="Account No." value={account?.account_id} />
          <PBFEContent label="Customer Name" value={customer?.customer_name} />
          <PBFEContent label="Customer ID" value={customer?.customer_id} />
        </div>
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionMain
          label="Service"
          detail={
            isAddActionService ? (
              <div></div>
            ) : (
              <AccountRenderService
                setErrorState={setErrorState}
                checkConfirmError={checkConfirmError}
                setCheckConfirmError={setCheckConfirmError}
                errorState={errorState}
                is_maker={is_maker}
                originalData={originalData}
                customer={customer}
                account={account}
                debitProfile={debitProfile}
                setDebitProfile={setDebitProfile}
                remark={remark}
                data={{
                  service: [
                    {
                      ...data,
                      posting_account: [],
                      charge_group: new Array(data?.fee_charge?.length)
                        .fill({})
                        .map((_, i) => {
                          return {
                            // ...data?.fee_charge[i],
                            charge_code: data?.fee_charge[i].charge_code,
                            charge_name: data?.fee_charge[i].charge_name,
                            fee_charge: [
                              {
                                ...data?.fee_charge[i],
                              },
                            ],
                          };
                        }),
                    },
                  ],
                }}
                isEdit={true}
                isNew={true}
                accountTable={accountTable}
              />
            )
          }
        />
        <div className="ml-40px mr-25px">
          <TextField
            id="remark"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            label="REMARK"
            variant="filled"
            helperText={"Limited " + remark?.length + "/300"}
            fullWidth
            inputProps={{ maxLength: 300 }}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountAddNewService;
