import React, { memo, useEffect } from "react";
import { PBFEAccordionMain } from "../../components/Panel/Accordion/accordionMain";
import { PBFEContent } from "../../components/Elements/Text/content";
import { PBFEHeader } from "../../components/Elements/Text/header";
import useQuery from "../../hooks/useQuery";
import offersService from "../../api/offersService";
import {
  NewVersionRenderQualifiers,
  NewVersionRenderQualifiersEditMode,
  PBFEFeeTable,
} from "../../components/Table/feeTable";
import { useNavigate, useParams } from "react-router-dom";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import RemoveIcon from "../../assets/icon/remove.svg";

import {
  formatDateApi,
  formatDateCheckValid,
  formateDate,
  yesNoMapping,
} from "../../utils/util";
import PBFEError from "../../components/Error/alertError";
import {
  PBFERenderHeaderChargeFromService,
  PBFERenderHeaderChargeFromServicePosting,
} from "../WaitingForApproval/approvalDetail";
import { Controllers } from "../../components/radium-omnibus/src";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField, TableContainer, Table } from "@mui/material";
import DropdownNew, {
  DropdownObject,
} from "../../components/radium-omnibus/src/fields/dropdownNew";
import { RenderTable } from "../ServiceDetail";
import { AsyncModal } from "../../components/Panel/Modal/asyncModal";
import {
  ADD_SERVICE_TABLE_HEADER,
  MAPPING_ALL_TYPE,
  PRICING_WITHOUT_DEBIT,
} from "../../constants/constant";
import serviceApi from "../../api/serviceService";
import api from "../../api";
import { apiConfig } from "../../config";
import _, { cloneDeep } from "lodash";
import {
  FunctionDeleteErrorSameName,
  FunctionGetObjectIsError,
  TopperError,
} from "../../components/radium-omnibus/src/utils/Field.CheckError";
import {
  checkFromToDate,
  FunctionCheckDataChangeOffer,
  FunctionCheckDateLength,
  FunctionCheckDateMoreThanOneDay,
  FunctionCheckDatIsPast,
  FunctionCheckNonValue,
  FunctionCheckPriorityError,
} from "../../functions";
import { FooterButton } from "../../components/Elements/Button/footerButton";
import { ConfirmModal } from "../../components/Panel/Modal/confirmModal";
import { ERROR_MESSAGE_REQUIRED } from "../../utils/errorMessage";
import { TABLE_STYLE } from "../../constants/style";
import { TableNewRenderQualifiers } from "../../components/Table/tableCellQualifiers";
import {
  FunctionGetErrorFromDomByRay,
  FunctionMappingDataFeeCharge,
} from "../ServiceDetail/functions/SD.OnSubmit";
import Plus from "../../assets/icon/plus.svg";

export const MetaData = ({ data }) => {
  return (
    <div id="MetaData" className="px-xl py-lg ">
      <div className="flex">
        <div className="w-full">
          <PBFEContent label="Name" value={data?.offer_name} />
        </div>
        <div className="w-content">
          <PBFEContent label="Code" value={data?.offer_code} />
        </div>
      </div>
      <div className="grid grid-cols-3 mt-25px">
        <PBFEContent
          label="From"
          value={formateDate(
            data?.from_date ? data?.from_date : data?.effective_from
          )}
        />
        <PBFEContent
          label="To"
          value={formateDate(
            data?.to_date ? data?.to_date : data?.effective_to
          )}
        />
      </div>
    </div>
  );
};

export const RenderOfferDetail = ({ data }) => {
  return (
    <div>
      {data?.offer_fee_service.length > 0 &&
        data?.offer_fee_service.map((service, index) => {
          return (
            <React.Fragment key={index}>
              {/* <PBFEAccordionMain
                key={`key-of-ac-${index}`}
                label={`${service.service_name} - ${service.service_code}`}
                detail={
                  <React.Fragment>
                    {service?.offer_fee_charge?.map((charge, index) => {
                      return (
                        <React.Fragment key={charge.charge_code + index}>
                          <PBFEAccordionMain
                            headerDetail={
                              <div className="px-6 mt-25px">
                                <PBFERenderHeaderChargeFromService
                                  charge={{ fee_charge: { ...charge } }}
                                />
                                <PBFERenderHeaderChargeFromServicePosting
                                  charge={{ fee_charge: { ...charge } }}
                                />
                              </div>
                            }
                            key={charge.charge_code + index}
                            label={`${charge.charge_code} - ${charge.charge_name}`}
                            detail={<PBFEFeeTable data={charge} />}
                            hasToogle
                            toogleStatus={charge.price_without_post === "Y"}
                            toogleLabel={PRICING_WITHOUT_DEBIT}
                          />
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                }
              /> */}
              <PBFEHeader
                key={"header" + index}
                label={`${service.service_name} - ${service.service_code}`}
              />
              {service?.offer_fee_charge?.map((charge, index) => {
                return (
                  <React.Fragment key={charge.charge_code + index}>
                    <PBFEAccordionMain
                      headerDetail={
                        <div className="px-6 mt-25px">
                          <PBFERenderHeaderChargeFromService
                            charge={{ fee_charge: { ...charge } }}
                          />
                          <PBFERenderHeaderChargeFromServicePosting
                            charge={{ fee_charge: { ...charge } }}
                          />
                        </div>
                      }
                      key={charge.charge_code + index}
                      label={`${charge.charge_code} - ${charge.charge_name}`}
                      detail={<PBFEFeeTable data={charge} />}
                      fullScreen={charge.price_without_post === "Y"}
                      hasToogle={charge.price_without_post === "Y"}
                      showLabel={charge.price_without_post === "Y"}
                      toogleLabel={PRICING_WITHOUT_DEBIT}
                    />
                  </React.Fragment>
                );
              })}
              <div className="h-10px bg-gray-100"></div>
            </React.Fragment>
          );
        })}
    </div>
  );
};

const RenderAddService = ({ props }) => {
  const [open, setOpen] = React.useState(false);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [radioService, setRadioService] = React.useState(null);
  const [loadingAction, setLoadingAction] = React.useState(false);
  const [selected, setSelected] = React.useState(false);


  React.useEffect(() => {
    if (radioService && selected) {
      LoadService();
      setSelected(false);
      setRadioService(false);
    }
    return;
  }, [selected]);

  const LoadService = async () => {
    const { data } = await api.instanceCorp.get(
      `${apiConfig.getServiceAPI}/${radioService}`
    );

    let thisData = cloneDeep(data?.data);

    // const isEditFeeData = props?.loadData?.offer_fee_service?.

    const findIndexThatDelete =
      props?.currentData?.offer_fee_service?.findIndex((row) => {
        return thisData?.service_code === row?.service_code;
      });

    // console.log("RenderAddService", { props, thisData, findIndexThatDelete });

    if (findIndexThatDelete >= 0) {
      // let cloneTempData = cloneDeep(
      //   props?.loadData?.offer_fee_service[findIndexThatDelete]
      // ); //ผิดมันต้องเอาค่าที่ หลังจาก ลบไปแล้วมา ไม่ใช่ค่าตั่งต้น

      let cloneTempData = cloneDeep(
        props?.currentData?.offer_fee_service[findIndexThatDelete]
      );

      // console.log("cloneTempData", cloneTempData);

      const thisNewFeeCharge = thisData?.fee_charge?.map((row) => {
        const findOldSelf = cloneTempData?.offer_fee_charge?.findIndex(
          (row2) => {
            return row?.charge_code === row2?.charge_code;
          }
        );

        if (findOldSelf >= 0) {
          // console.log("FOUND", { findOldSelf, row });
          return cloneTempData?.offer_fee_charge[findOldSelf];
        } else {
          // console.log("LOAD", row);
          return { ...row };
        }
      });

      console.log("thisNewFeeCharge", thisNewFeeCharge);

      thisData = {
        ...thisData,
        fee_charge: thisNewFeeCharge,
      };

      props.edit(
        {
          ...thisData,
          service_code: thisData?.service_code,
          service_name: thisData?.service_name,
          offer_fee_charge: thisData?.fee_charge,
        },
        findIndexThatDelete
      );

      // props.add({
      //   ...thisData,
      //   service_code: thisData?.service_code,
      //   service_name: thisData?.service_name,
      //   offer_fee_charge: thisData?.fee_charge,
      // });

      // setTimeout(() => {
      //   props.remove(findIndexThatDelete);
      // }, 500);

      // console.log("cloneTempData", { cloneTempData, thisData });
      // props.add(cloneTempData);

      //ขั้นแรกเราเอา data ใหม่ไปทับอันเก่าให้ได้ก่อน
      //หลังจากนั้นให่ hook data เก่าออกมา แล้ว เชคว่า ส่งออกมาได้ไหม ุุถ่้าได้ ก็เอา ค่าเก้ามาทับ ก็จบเลย
      //แล้ว ถ้าไม่ไดเ ให้เราเอา ค่า มา ทำข้างใรน lib แทน
    } else {
      props.add({
        ...thisData,
        service_code: thisData?.service_code,
        service_name: thisData?.service_name,
        offer_fee_charge: thisData?.fee_charge
          ? thisData?.fee_charge
          : thisData?.offer_fee_charge,
      });
    }
  };

  const callbackFilterService = (value) => (row) => {
    return (
      row.service_name.toLowerCase().includes(value.toLowerCase()) ||
      row.service_code.toLowerCase().includes(value.toLowerCase())
    );
  };

  if (props?.isLast) {
    return (
      <React.Fragment>
        <FooterButton
          text={
            <div className="flex space-x-1">
              <img
                alt=""
                className="ml-10px h-15px w-15px cursor-pointer"
                src={Plus}
              />
              <div className="mx-1 my-auto">Add Service</div>
            </div>
          }
          action={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        />
        {open && (
          <AsyncModal
            setErrorModalMessage={setErrorModalMessage}
            errorModalMessage={errorModalMessage}
            // customer_id={params.customer_id}
            title="Add Service"
            open={open}
            onClose={() => setOpen(false)}
            radioValue={radioService}
            setRadio={setRadioService}
            serviceApi={serviceApi.getService}
            // params={{
            //   customer_id: params.customer_id,
            // }}
            // query={{
            //   customer_id: params.customer_id,
            //   user_id: user?.info?.preferred_username,
            // }}
            placeholder="Search Service Name / Code"
            option_field_label="service_name"
            option_field_value="service_code"
            orderByDefault="service_name"
            callBackData={(callBackData) => {
              return callBackData?.filter((d) => {
                return d?.fcy_flag === "N";
              });
            }}
            action={(e) => {
              setSelected(true);
              setOpen(false);
            }}
            loadingAction={loadingAction}
            head_cells={ADD_SERVICE_TABLE_HEADER}
            showFields={["service_name", "service_code"]}
            callbackFilter={callbackFilterService}
            // isDom={true}
            // refId={"PBFEHeader"}
          />
        )}
      </React.Fragment>
    );
  } else {
    return <React.Fragment />;
  }
};

const onChangeOfferFeeCharge = ({ name, value, setValue, index }) => {
  setValue((prev) => {
    let temp = [...prev];
    temp[index] = {
      ...temp[index],
      [name]: value,
    };
    return [...temp];
  });
};

export const RenderOfferFeeCharge = ({ props, setValue }) => {
  const [originalData, setOriginalData] = React.useState([]);

  // React.useEffect(() => {
  //   // if (originalData?.length === 0) {
  //   setOriginalData(props?.value);
  //   // }
  //   return () => {};
  // }, [props?.value]);

  React.useEffect(() => {
    setOriginalData(props?.value);
  }, [props.value]);

  // console.log("props", props);

  const isCreate =
    props.isFirst &&
    props.isLast &&
    props.fieldIndex === 0 &&
    (props.initalValue?.offer_fee_charge?.length <= 0 ||
      props?.initalValue === false);

  if (isCreate) {
    return <React.Fragment />;
  } else {
    return (
      <div>
        {props?.value?.length > 0 &&
          props?.value?.map((charge, index) => {
            const isQualifer =
              Object.keys(charge?.qualifiers || {})?.length > 0;

            const isDisabledCharge =
              charge?.pricing_method === "ONE_TIME" ||
              charge?.pricing_method === "SUBSCRIPTION";

            // const thisOfferFeeService =
            //   props?.currentData?.offer_fee_service[props.fieldIndex];

            // let posibleValueOfQualifires =
            //   (thisOfferFeeService?.offer_fee_charge?.length > 0
            //     ? thisOfferFeeService?.offer_fee_charge?.find((d) => {
            //         return d?.charge_code === charge?.charge_code;
            //       })?.qualifiers
            //     : {}) || {};

            // console.log("posibleValueOfQualifires", {
            //   posibleValueOfQualifires,
            //   selfIndex: index,
            //   dataIndex: thisOfferFeeService?.offer_fee_charge,
            //   props,
            //   thisOfferFeeService,
            // });

            // if (
            //   thisOfferFeeService?.length > 0 &&
            //   thisOfferFeeService[index]?.offer_fee_charge?.length > 0
            // ) {
            //   const test = console.log("test", test);
            //   posibleValueOfQualifires = test;
            // }

            // console.log("posibleValueOfQualifires", {
            //   posibleValueOfQualifires,
            //   charge,
            //   index,
            //   thisOfferFeeService,
            // });

            // thisOfferFeeService?.length > 0
            //   ? thisOfferFeeService[index]?.find((d) => {
            //       return d?.charge_code === charge?.charge_code;
            //     })?.qualifiers
            //   : {};

            return (
              <PBFEAccordionMain
                toogleStatus={charge.price_without_post === "Y"}
                toogleLabel={PRICING_WITHOUT_DEBIT}
                hasToogle={true}
                isEdit={true}
                isRemove={props?.value?.length > 1}
                onClick={(e) => {
                  setValue((prev) => {
                    let clone = cloneDeep([...prev]);
                    clone[index] = {
                      ...clone[index],
                      price_without_post:
                        clone[index].price_without_post === "N" ? "Y" : "N",
                    };
                    return clone;
                  });
                }}
                onClickRemove={() => {
                  setValue((prev) => {
                    let cloneDeep = _.cloneDeep([...prev]);
                    cloneDeep.splice(index, 1);
                    return cloneDeep;
                  });
                }}
                pl="pb-xxs pt-xxs"
                // headerDetail={
                //   < div className="px-6 mt-25px" >
                //     <PBFERenderHeaderChargeFromService
                //       charge={{ fee_charge: { ...charge } }}
                //     />
                //   </div>
                // }
                key={charge.charge_code + index}
                label={`${charge.charge_code} - ${charge.charge_name}`}
                // detail={<PBFEFeeTable data={charge} />}
                detail={
                  <div>
                    <div className="gap-10 my-25px grid grid-cols-8">
                      <div className="col-span-2">
                        <DropdownObject
                          value={charge?.pricing_method}
                          label={`Price Method`}
                          onChange={(e) => {
                            const etv = e.target.value;

                            if (etv === "SUBSCRIPTION") {
                              onChangeOfferFeeCharge({
                                setValue,
                                value: etv,
                                name: "pricing_method",
                                index,
                              });
                              onChangeOfferFeeCharge({
                                setValue,
                                value: "MONTHLY",
                                name: "pricing_frequency",
                                index,
                              });
                              onChangeOfferFeeCharge({
                                setValue,
                                value: "MONTHLY",
                                name: "posting_frequency",
                                index,
                              });
                              onChangeOfferFeeCharge({
                                setValue,
                                value: "NO",
                                name: "transaction_consolidation",
                                index,
                              });
                              setValue((prev) => {
                                if (!isQualifer) {
                                  let clone = cloneDeep([...prev]);
                                  clone[index] = {
                                    ...clone[index],
                                    fee_value: {
                                      tier: "NO",
                                      min: "",
                                      max: "",
                                      fixed_amount: "",
                                      value: [
                                        {
                                          fixed_amount: "",
                                          fix_amount: "",
                                          rate: "",
                                          min: "",
                                          max: "",
                                        },
                                      ],
                                    },
                                  };
                                  return clone;
                                } else {
                                  return prev;
                                }
                              });
                            } else if (etv === "TRAN_VOLUME") {
                              onChangeOfferFeeCharge({
                                setValue,
                                value: etv,
                                name: "pricing_method",
                                index,
                              });
                              onChangeOfferFeeCharge({
                                setValue,
                                value: "MONTHLY",
                                name: "pricing_frequency",
                                index,
                              });
                              onChangeOfferFeeCharge({
                                setValue,
                                value: "MONTHLY",
                                name: "posting_frequency",
                                index,
                              });
                              onChangeOfferFeeCharge({
                                setValue,
                                value: "MONTHLY",
                                name: "transaction_consolidation",
                                index,
                              });
                            } else if (etv === "TRAN_VALUE") {
                              onChangeOfferFeeCharge({
                                setValue,
                                value: etv,
                                name: "pricing_method",
                                index,
                              });
                              onChangeOfferFeeCharge({
                                setValue,
                                value: "MONTHLY",
                                name: "pricing_frequency",
                                index,
                              });
                              onChangeOfferFeeCharge({
                                setValue,
                                value: "MONTHLY",
                                name: "posting_frequency",
                                index,
                              });
                              onChangeOfferFeeCharge({
                                setValue,
                                value: "MONTHLY",
                                name: "transaction_consolidation",
                                index,
                              });
                            } else if (etv === "ONE_TIME") {
                              onChangeOfferFeeCharge({
                                setValue,
                                value: etv,
                                name: "pricing_method",
                                index,
                              });
                              onChangeOfferFeeCharge({
                                setValue,
                                value: "ONE_TIME",
                                name: "pricing_frequency",
                                index,
                              });
                              onChangeOfferFeeCharge({
                                setValue,
                                value: "DAILY",
                                name: "posting_frequency",
                                index,
                              });
                              onChangeOfferFeeCharge({
                                setValue,
                                value: "NO",
                                name: "transaction_consolidation",
                                index,
                              });

                              setValue((prev) => {
                                if (!isQualifer) {
                                  let clone = cloneDeep([...prev]);
                                  clone[index] = {
                                    ...clone[index],
                                    fee_value: {
                                      tier: "NO",
                                      min: "",
                                      max: "",
                                      fixed_amount: "",
                                      value: [
                                        {
                                          fixed_amount: "",
                                          fix_amount: "",
                                          rate: "",
                                          min: "",
                                          max: "",
                                        },
                                      ],
                                    },
                                  };
                                  return clone;
                                } else {
                                  return prev;
                                }
                              });
                            }
                          }}
                          options={
                            isQualifer
                              ? [
                                  {
                                    label: "Transaction Value",
                                    value: "TRAN_VALUE",
                                  },
                                  {
                                    label: "Transaction Volume",
                                    value: "TRAN_VOLUME",
                                  },
                                ]
                              : [
                                  {
                                    label: "Subscription",
                                    value: "SUBSCRIPTION",
                                  },
                                  {
                                    label: "Transaction Value",
                                    value: "TRAN_VALUE",
                                  },
                                  {
                                    label: "Transaction Volume",
                                    value: "TRAN_VOLUME",
                                  },
                                  {
                                    label: "One-Time",
                                    value: "ONE_TIME",
                                  },
                                ]
                          }
                        />
                      </div>
                      <div className="col-span-2">
                        <DropdownObject
                          value={charge.pricing_frequency}
                          label={`Pricing Frequency`}
                          onChange={(e) => {
                            if (e.target.value === "DAILY") {
                              onChangeOfferFeeCharge({
                                setValue,
                                value: e.target.value,
                                name: "pricing_frequency",
                                index,
                              });

                              onChangeOfferFeeCharge({
                                setValue,
                                value: "TRANSACTION",
                                name: "transaction_consolidation",
                                index,
                              });

                              if (charge?.pricing_method === "ONE_TIME") {
                                onChangeOfferFeeCharge({
                                  setValue,
                                  value: "ONE_TIME",
                                  name: "posting_frequency",
                                  index,
                                });
                              }

                              if (charge?.pricing_method !== "ONE_TIME") {
                                onChangeOfferFeeCharge({
                                  setValue,
                                  value: "MONTHLY",
                                  name: "posting_frequency",
                                  index,
                                });
                              }
                            } else if (
                              e.target.value === "MONTHLY" &&
                              charge?.pricing_method === "SUBSCRIPTION"
                            ) {
                              onChangeOfferFeeCharge({
                                setValue,
                                value: e.target.value,
                                name: "pricing_frequency",
                                index,
                              });

                              onChangeOfferFeeCharge({
                                setValue,
                                value: "NO",
                                name: "transaction_consolidation",
                                index,
                              });

                              onChangeOfferFeeCharge({
                                setValue,
                                value: "MONTHLY",
                                name: "posting_frequency",
                                index,
                              });
                            } else if (e.target.value === "MONTHLY") {
                              onChangeOfferFeeCharge({
                                setValue,
                                value: e.target.value,
                                name: "pricing_frequency",
                                index,
                              });

                              onChangeOfferFeeCharge({
                                setValue,
                                value: "MONTHLY",
                                name: "transaction_consolidation",
                                index,
                              });

                              onChangeOfferFeeCharge({
                                setValue,
                                value: "MONTHLY",
                                name: "posting_frequency",
                                index,
                              });
                            } else if (e.target.value === "YEARLY") {
                              onChangeOfferFeeCharge({
                                setValue,
                                value: e.target.value,
                                name: "pricing_frequency",
                                index,
                              });

                              onChangeOfferFeeCharge({
                                setValue,
                                value: "NO",
                                name: "transaction_consolidation",
                                index,
                              });

                              onChangeOfferFeeCharge({
                                setValue,
                                value: "YEARLY",
                                name: "posting_frequency",
                                index,
                              });
                            } else if (e.target.value === "ONE_TIME"){
                              onChangeOfferFeeCharge({
                                setValue,
                                value: e.target.value,
                                name: "pricing_frequency",
                                index,
                              })                            }
                          }}
                          options={
                            charge?.pricing_method === "SUBSCRIPTION"
                              ? [
                                  {
                                    label: "Monthly",
                                    value: "MONTHLY",
                                  },
                                  {
                                    label: "Yearly",
                                    value: "YEARLY",
                                  },
                                ]
                              : charge?.pricing_method === "TRAN_VALUE"
                              ? [
                                  {
                                    label: "Monthly",
                                    value: "MONTHLY",
                                  },
                                  {
                                    label: "Daily",
                                    value: "DAILY",
                                  },
                                ]
                              : charge?.pricing_method === "TRAN_VOLUME"
                              ? [
                                  {
                                    label: "Monthly",
                                    value: "MONTHLY",
                                  },
                                  {
                                    label: "Daily",
                                    value: "DAILY",
                                  },
                                ]
                              : charge?.pricing_method === "ONE_TIME"
                              ? [
                                  {
                                    label: "One-Time",
                                    value: "ONE_TIME",
                                  },
                                ]
                              : []
                          }
                        />
                      </div>

                      <div className="col-span-2">
                        <DropdownObject
                          value={charge?.transaction_consolidation}
                          label={`Transaction Consolidation`}
                          onChange={(e) => {
                            onChangeOfferFeeCharge({
                              setValue,
                              value: e.target.value,
                              name: "transaction_consolidation",
                              index,
                            });
                          }}
                          // options={["DAILY", "MONTHLY", "TRANSACTION"]}
                          options={
                            charge?.pricing_method === "SUBSCRIPTION"
                              ? [
                                  {
                                    label: "No",
                                    value: "NO",
                                  },
                                ]
                              : charge?.pricing_method === "TRAN_VALUE"
                              ? [
                                  {
                                    label: "Monthly",
                                    value: "MONTHLY",
                                  },
                                  {
                                    value: "TRANSACTION",
                                    label: "Transaction",
                                  },
                                ]
                              : charge?.pricing_method === "TRAN_VOLUME"
                              ? [
                                  {
                                    label: "Monthly",
                                    value: "MONTHLY",
                                  },
                                  {
                                    value: "TRANSACTION",
                                    label: "Transaction",
                                  },
                                ]
                              : charge?.pricing_method === "ONE_TIME"
                              ? [
                                  {
                                    label: "No",
                                    value: "NO",
                                  },
                                ]
                              : []
                          }
                        />
                      </div>

                      <div className="col-span-1">
                        <DropdownNew
                          value={charge?.vat_type}
                          label={`VAT`}
                          onChange={(e) => {
                            onChangeOfferFeeCharge({
                              setValue,
                              value: e.target.value,
                              name: "vat_type",
                              index,
                            });
                          }}
                          options={["NO", "INCLUDE", "EXCLUDE"]}
                        />
                      </div>
                      <div className="col-span-1">
                        <DropdownNew
                          value={yesNoMapping(charge?.wht_type)}
                          label={`WHT`}
                          onChange={(e) => {
                            onChangeOfferFeeCharge({
                              setValue,
                              value: e.target.value === "YES" ? "Y" : "N",
                              name: "wht_type",
                              index,
                            });
                          }}
                          options={["YES", "NO"]}
                        />
                      </div>
                      <div className="col-span-2">
                        <PBFEContent
                          label="Posting Type"
                          isSCBColor={true}
                          value={
                            charge?.posting_type ? charge?.posting_type : "-"
                          }
                        />
                      </div>
                      <div className="col-span-2">
                        <PBFEContent
                          label="Posting Frequency"
                          isSCBColor={true}
                          value={
                            charge?.posting_frequency
                              ? MAPPING_ALL_TYPE[charge?.posting_frequency]
                              : "-"
                          }
                        />
                      </div>
                      <div className="col-span-2">
                        <PBFEContent
                          label="Posting Consolidation"
                          isSCBColor={true}
                          value={
                            charge?.posting_consolidation
                              ? charge?.posting_consolidation
                              : "-"
                          }
                        />
                      </div>

                      <div className="col-span-1">
                        <PBFEContent
                          label="Monthly Pricing Day"
                          isSCBColor={true}
                          value={
                            charge?.monthly_pricing_day
                              ? charge?.monthly_pricing_day
                              : "-"
                          }
                        />
                      </div>
                      <div className="col-span-1">
                        <PBFEContent
                          label="Qualifiers"
                          isSCBColor={true}
                          value={isQualifer ? "YES" : "NO"}
                        />
                      </div>
                    </div>
                    {isQualifer && (
                      <TableContainer sx={TABLE_STYLE}>
                        <Table sx={TABLE_STYLE}>
                          <TableNewRenderQualifiers />
                          {/* <NewVersionRenderQualifiersEditMode
                            value={charge?.qualifiers}
                            setValue={setValue}
                            isOffer={true}
                            chargeIndexOffer={index}
                          /> */}
                          <NewVersionRenderQualifiers
                            qualifiers={charge?.qualifiers}
                          />
                        </Table>
                      </TableContainer>
                    )}
                    <RenderTable
                      value={charge?.fee_value}
                      setValue={setValue}
                      index={index}
                      isQualifer={isQualifer}
                      posibleValueOfQualifires={
                        originalData
                          ? originalData?.find((d) => {
                              return d?.charge_code === charge?.charge_code;
                            })?.qualifiers
                          : {}
                      }
                      fieldIndex={props.fieldIndex}
                      isDisabledCharge={isDisabledCharge}
                    />
                  </div>
                }
              />
            );
          })}
      </div>
    );

    // return (
    //   <div>
    //     {props?.value?.length > 0 &&
    //       props?.value?.map((row, index) => {
    //         return (
    //           <div className="grid grid-cols-8 gap-4" key={`index-of-${index}`}>
    //             <div className="flex col-span-8 justify-between">
    //               <div className="flex ">
    //                 <div className="mr-8 text-textscb">
    //                   {row?.charge_code} {" - "}
    //                   {row?.charge_name}
    //                 </div>
    //                 {/* Remove เล็ก ของแต่ละ charge */}
    //                 {props?.value?.length > 1 ? (
    //                   <div
    //                     onClick={() => {
    //                       setValue((prev) => {
    //                         let cloneDeep = _.cloneDeep([...prev]);
    //                         cloneDeep.splice(index, 1);
    //                         return cloneDeep;
    //                       });
    //                     }}
    //                   >
    //                     Remove
    //                   </div>
    //                 ) : (
    //                   <div></div>
    //                 )}
    //               </div>
    //               <FormGroup>
    //                 <FormControlLabel
    //                   control={
    //                     <Checkbox
    //                       checked={
    //                         row?.price_without_post === "Y" ? true : false
    //                       }
    //                       onClick={(e) => {
    //                         e.stopPropagation();
    //                         onChangeOfferFeeCharge({
    //                           setValue,
    //                           value: e.target.checked === true ? "Y" : "N",
    //                           name: "price_without_post",
    //                           index,
    //                         });
    //                       }}
    //                     />
    //                   }
    //                   label={`Price without debit`}
    //                 />
    //               </FormGroup>
    //             </div>

    //             <div className="col-span-8">
    //               <RenderTable
    //                 value={row?.fee_value}
    //                 setValue={setValue}
    //                 index={index}
    //               />
    //             </div>
    //           </div>
    //         );
    //       })}
    //   </div>
    // );
  }
};

export const RenderButtonRemove = ({ props }) => {
  // Remove ใหญ้้ หายทั้ง charge เลยกดทีเดียว
  const [value, setValue] = React.useState(false);

  React.useEffect(() => {
    if (props?.originalData?.service_name) {
      // setTimeout(() => {
      setValue(props?.originalData);
      // }, 200);
    } else if (
      props?.initalValue?.service_name &&
      !props?.originalData?.service_name
    ) {
      // setTimeout(() => {
      setValue(props?.initalValue);
      // }, 200);
    }
    return;
  }, [props]);

  // console.log("props", {
  //   props,
  //   init: props?.initalValue,
  //   original: props?.originalData,
  //   value: value,
  // });

  return (
    <div className="flex justify-between mt-8  border-0 border-t-2 border-t-gray-200 px-10 ">
      <PBFEHeader
        p="pt-large"
        label={value ? value?.service_name + " - " + value?.service_code : ""}
      />
      {/* <div
        onClick={() => {
          props.remove();
          if (props?.fieldIndex === 0 && props?.isLast && props?.isFirst) {
            setTimeout(() => {
              props.add({
                service_code: "",
                service_name: "",
                offer_fee_charge: [],
              });
            }, 100);
          }
        }}
      >
        Remove
      </div> */}
      <div
        className="flex text-value items-center cursor-pointer text-textscb"
        onClick={async () => {
          await props.remove();
          // if (props?.fieldIndex === 0 && props?.isLast && props?.isFirst) {
          //   setTimeout(() => {
          //     props.add({
          //       service_code: "",
          //       service_name: "",
          //       offer_fee_charge: [],
          //     });
          //   }, 100);
          // }
        }}
      >
        <img src={RemoveIcon} className="mr-10px" alt="header_image" />
        Remove
      </div>
    </div>
  );
};

const RenderControllerMemo = memo(
  ({
    isCreate,
    data,
    setError,
    setErrorOpen,
    setSubmitData,
    setShowConfirm,
  }) => {

    return (
      <div className="grid grid-cols-8 pt-4 gap-4">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controllers
            language="EN"
            showError={false}
            showSymbol={false}
            loadData={
              isCreate
                ? {}
                : {
                    ...data,
                    remark: "",
                    from_date_to_date: {
                      from_date: formatDateCheckValid(
                        new Date(data?.from_date)
                      ),
                      to_date: formatDateCheckValid(new Date(data?.to_date)),
                    },
                  }
            }
            fields={[
              {
                classNameCore: "col-span-4 px-10",
                name: "offer_name",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Name"}
                      sx={{ input: { color: "#7A58BF" } }}
                      variant="standard"
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? "Limited " + value?.length + "/100"
                          : thisValue?.error
                          ? thisValue?.message
                          : "Limited " + value?.length + "/100"
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-2",
                type: "none",
                render: () => {
                  return <div></div>;
                },
              },

              {
                classNameCore: "col-span-2 pr-10",
                name: "offer_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  if (isCreate) {
                    return (
                      <TextField
                        id={name}
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        label={"Code"}
                        variant="standard"
                        sx={{ input: { color: "#7A58BF" } }}
                        fullWidth
                        inputProps={{ maxLength: 40 }}
                        error={
                          thisValue?.value === "" ? false : thisValue?.error
                        }
                        helperText={
                          thisValue?.value === ""
                            ? "Limited " + value?.length + "/40"
                            : thisValue?.error
                            ? thisValue?.message
                            : "Limited " + value?.length + "/40"
                        }
                      />
                    );
                  } else {
                    return (
                      <PBFEContent
                        isSCBColor={true}
                        label="Code"
                        value={value}
                      />
                    );
                  }
                },
                required: true,
                no_space: true,
              },
              {
                classNameCore: "col-span-4 px-10 ",
                name: "from_date_to_date",
                initalValue: {
                  from_date: formatDateCheckValid(new Date()),
                  to_date: formatDateCheckValid(
                    new Date("9999-12-31T00:00:00")
                  ),
                },
                errorCustoms: ({ value }) => {
                  const checkFromDate =
                    checkFromToDate(
                      value?.from_date,
                      value?.to_date,
                      null,
                      "from_date"
                    ) || false;
                  const checkToDate =
                    checkFromToDate(
                      value?.from_date,
                      value?.to_date,
                      null,
                      "to_date"
                    ) || false;

                  const checkFromDateLength = FunctionCheckDateLength(
                    value?.from_date,
                    "from_date"
                  );

                  const checkToDateLength = FunctionCheckDateLength(
                    value?.to_date,
                    "to_date"
                  );

                  const checkDateMoreThanOneDay =
                    FunctionCheckDateMoreThanOneDay(
                      value?.from_date,
                      value?.to_date
                    );

                  // console.log(
                  //   "checkDateMoreThanOneDay",
                  //   checkDateMoreThanOneDay
                  // );

                  const isPast = FunctionCheckDatIsPast(data?.from_date);

                  if (
                    value.from_date === "NaN-NaN-NaN" ||
                    value.to_date === "NaN-NaN-NaN"
                  ) {
                    return "Invalid date format";
                  } else if (value?.from_date === "") {
                    return ERROR_MESSAGE_REQUIRED;
                  } else if (!checkDateMoreThanOneDay) {
                    return "To date must be greater than from date";
                  } else if (checkFromDateLength.error) {
                    return checkFromDateLength.message;
                  } else if (checkToDateLength.error) {
                    return checkToDateLength.message;
                  } else if (checkToDate) {
                    return checkToDate;
                  } else if (checkFromDate) {
                    return checkFromDate;
                  } else if (
                    FunctionCheckDatIsPast(value?.from_date) &&
                    !isPast
                  ) {
                    return "From date must not be in the past";
                  } else {
                    return "";
                  }
                },
                fieldCustoms: ({ value, setValue }) => {
                  return (
                    <div className="grid grid-cols-2 gap-x-10">
                      <DesktopDatePicker
                        label="Date desktop"
                        inputFormat="dd/MM/yyyy"
                        disablePast
                        // disabled={!isCreate && index === 0}
                        disabled={
                          new Date(data?.from_date).getTime() <=
                          new Date().getTime()
                        }
                        value={value?.from_date}
                        onChange={(e) =>
                          setValue({
                            ...value,
                            from_date: formatDateCheckValid(e),
                          })
                        }
                        renderInput={(params) => {
                          if (params?.inputProps?.placeholder) {
                            params.inputProps.placeholder = "DD/MM/YYYY";
                          }
                          return (
                            <TextField
                              {...params}
                              placeholder="DD/MM/YYYY"
                              InputProps={{
                                ...params.InputProps,
                                style: { color: "#7A58BF", fontSize: 16 },
                                placeholder: "DD/MM/YYYY",
                              }}
                              variant="standard"
                              label={"From"}
                              error={
                                checkFromToDate(
                                  value?.from_date,
                                  value?.to_date,
                                  null,
                                  "from_date"
                                ) ||
                                FunctionCheckDateLength(
                                  value?.from_date,
                                  "from_date"
                                ).error
                              }
                              helperText={
                                value?.from_date === "NaN-NaN-NaN"
                                  ? "Invalid date format"
                                  : FunctionCheckDateLength(
                                      value?.from_date,
                                      "from_date"
                                    ).error
                                  ? FunctionCheckDateLength(
                                      value?.from_date,
                                      "from_date"
                                    ).message
                                  : checkFromToDate(
                                      value?.from_date,
                                      value?.to_date,
                                      null,
                                      "from_date"
                                    )
                              }
                            />
                          );
                        }}
                      />
                      <DesktopDatePicker
                        label="Date desktop"
                        inputFormat="dd/MM/yyyy"
                        disablePast
                        // disabled={!isCreate && index === 0}
                        value={
                          value?.to_date === "9999-12-31"
                            ? null
                            : value?.to_date
                        }
                        onChange={(e) =>
                          setValue({
                            ...value,
                            to_date: formatDateCheckValid(e),
                          })
                        }
                        renderInput={(params) => {
                          if (params?.inputProps?.placeholder) {
                            params.inputProps.placeholder = "DD/MM/YYYY";
                          }
                          return (
                            <TextField
                              {...params}
                              placeholder="DD/MM/YYYY"
                              InputProps={{
                                ...params.InputProps,
                                style: { color: "#7A58BF", fontSize: 16 },
                                placeholder: "DD/MM/YYYY",
                              }}
                              variant="standard"
                              label={"To"}
                              error={
                                checkFromToDate(
                                  value?.from_date,
                                  value?.to_date,
                                  null,
                                  "to_date"
                                ) ||
                                FunctionCheckDateLength(
                                  value?.to_date,
                                  "to_date"
                                ).error ||
                                !FunctionCheckDateMoreThanOneDay(
                                  value?.from_date,
                                  value?.to_date
                                )
                              }
                              helperText={
                                value?.to_date === "NaN-NaN-NaN"
                                  ? "Invalid date format"
                                  : !FunctionCheckDateMoreThanOneDay(
                                      value?.from_date,
                                      value?.to_date
                                    )
                                  ? "To date must be greater than from date"
                                  : FunctionCheckDateLength(
                                      value?.to_date,
                                      "to_date"
                                    ).error
                                  ? FunctionCheckDateLength(
                                      value?.to_date,
                                      "to_date"
                                    ).message
                                  : checkFromToDate(
                                      value?.from_date,
                                      value?.to_date,
                                      null,
                                      "to_date"
                                    )
                              }
                            />
                          );
                        }}
                      />
                    </div>
                  );
                },
              },

              {
                classNameCore: "col-span-8 grid grid-cols-8 gap-4",
                key: "offer_fee_service",
                showButtonAdd: false,
                showButtonRemove: false,
                showIndexZero: false,
                renderIndexZero: (props) => {
                  return (
                    <div className="col-span-8 px-10">
                      <RenderAddService props={props} />
                    </div>
                  );
                },
                fields: [
                  {
                    classNameCore: "col-span-8",
                    type: "button",
                    render: (props) => {
                      if (
                        props.isFirst &&
                        props.isLast &&
                        props.fieldIndex === 0 &&
                        (props.initalValue?.offer_fee_charge?.length <= 0 ||
                          props?.initalValue === false) &&
                        !props?.originalData
                      ) {
                        return <></>;
                      } else {
                        return <RenderButtonRemove props={props} />;
                      }
                    },
                  },
                  {
                    classNameCore: "col-span-8 px-10",
                    name: "offer_fee_charge",
                    fieldCustoms: (props) => {
                      return (
                        <RenderOfferFeeCharge
                          props={props}
                          setValue={props.setValue}
                        />
                      );
                    },
                  },
                  {
                    name: "service_code",
                    classNameCore: "hidden",
                    fieldCustoms: () => {
                      return <React.Fragment />;
                    },
                  },
                  {
                    name: "service_name",
                    classNameCore: "hidden",
                    fieldCustoms: () => {
                      return <React.Fragment />;
                    },
                  },
                  {
                    classNameCore: "col-span-8 px-10 ",
                    type: "button",
                    render: (props) => {
                      if (
                        props.isFirst &&
                        props.isLast &&
                        props.fieldIndex === 0 &&
                        (props.initalValue?.offer_fee_charge?.length <= 0 ||
                          props?.initalValue === false)
                      ) {
                        return <React.Fragment />;
                      } else {
                        return (
                          <React.Fragment>
                            {" "}
                            {props?.inFrist ? (
                              <React.Fragment />
                            ) : (
                              <div
                                className="text-end flex justify-end cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  props.remove();
                                }}
                              >
                                Delete
                              </div>
                            )}
                          </React.Fragment>
                        );
                      }
                    },
                  },
                  {
                    classNameCore: "col-span-8 px-10",
                    type: "button",
                    render: (props) => {
                      return <RenderAddService props={props} />;
                    },
                  },
                ],
              },
              {
                classNameCore: "col-span-8 px-10",
                name: "remark",
                fieldCustoms: ({ value, setValue, name }) => {
                  return (
                    <TextField
                      id={name}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      label={"Remark"}
                      helperText={"Limited " + value?.length + "/300"}
                      variant="standard"
                      sx={{ input: { color: "#7A58BF" } }}
                      fullWidth
                      inputProps={{ maxLength: 300 }}
                    />
                  );
                },
              },
            ]}
            onSubmit={async (thisNewData) => {
              let FormatData = {
                ...thisNewData,
                from_date: formatDateApi(
                  thisNewData?.from_date_to_date?.from_date
                ),
                to_date: formatDateApi(thisNewData?.from_date_to_date?.to_date),
              };

              if (isCreate) {
                FormatData = {
                  ...FormatData,
                  raw_data: FormatData,
                };
              }

              if (!isCreate) {
                FormatData = {
                  ...FormatData,
                  raw_data: data,
                };
              }

              const errorState = FunctionGetObjectIsError(FormatData);

              let isSameData = false;

              let isErrorFeeValue = false;

              const cloneValue = cloneDeep({ ...FormatData });
              if (cloneValue?.offer_fee_service?.length > 0) {
                cloneValue?.offer_fee_service?.forEach((first) => {
                  first?.offer_fee_charge?.forEach((second) => {
                    if (second?.fee_value?.conditions?.length > 0) {
                      second?.fee_value?.conditions?.forEach((third) => {
                        if (third?.value?.length > 0) {
                          third?.value?.forEach((row) => {
                            const thisError =
                              FunctionCheckNonValue(row?.fix_amount) &&
                              FunctionCheckNonValue(row?.rate);
                            if (thisError) {
                              isErrorFeeValue = true;
                            }
                          });
                        }
                      });
                    }
                    if (second?.fee_value?.value?.length > 0) {
                      second?.fee_value?.value?.forEach((row) => {
                        if (
                          FunctionCheckNonValue(row?.fix_amount) &&
                          FunctionCheckNonValue(row?.rate)
                        ) {
                          isErrorFeeValue = true;
                        }
                      });
                    }
                  });
                });
              }

              if (isErrorFeeValue) {
                errorState.push({
                  error: true,
                  field_name: "fee_value",
                  message: "fee value is required",
                  value: "",
                });
              }

              if (!isCreate) {
                isSameData = FunctionCheckDataChangeOffer(data, FormatData);
              }

              if (
                !FormatData.offer_fee_service ||
                FormatData.offer_fee_service.length < 1 ||
                !FormatData.offer_fee_service[0].service_code
              ) {
                errorState.push({
                  error: true,
                  field_name: "Service",
                  message: "Please Select a Service",
                  value: "",
                });
              }

              if (isSameData) {
                errorState.push({
                  error: true,
                  field_name: "No data change",
                  message: "Nothing to Update",
                  value: "",
                });
              }

              const isErrorPriority = FunctionCheckPriorityError(FormatData);

              if (isErrorPriority.isError) {
                errorState.push({
                  error: isErrorPriority.isError,
                  field_name: "Priority",
                  message: isErrorPriority.message,
                  value: "",
                });
              }

              FunctionGetErrorFromDomByRay(errorState);

              let mappingData = cloneDeep(FormatData);

              delete mappingData.from_date_to_date;

              mappingData = {
                ...mappingData,
                to_date:
                  mappingData?.to_date === "" || mappingData?.to_date === null
                    ? "9999-12-31T00:00:00"
                    : mappingData?.to_date,
                offer_fee_service: mappingData?.offer_fee_service?.map(
                  (item) => {
                    return {
                      ...item,
                      offer_fee_charge: FunctionMappingDataFeeCharge(
                        item?.offer_fee_charge
                      ),
                    };
                  }
                ),
              };

              console.log("FormatData : ", {
                FormatData,
                errorState,
                mappingData,
              });

              if (errorState?.length > 0) {
                setError(FunctionDeleteErrorSameName(errorState));
                setErrorOpen(true);
                return;
              }

              setSubmitData(mappingData);
              setShowConfirm(true);
              setErrorOpen(false);
            }}
            classNameSubmit="hidden"
          />
        </LocalizationProvider>
      </div>
    );
  }
);

export const RenderOfferEditMode = ({ data, isCreate, params }) => {
  const [error, setError] = React.useState([]);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [loadingAction, setLoadingAction] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [submitData, setSubmitData] = React.useState(null);
  const [serverError, setServerError] = React.useState(null);

  const navigate = useNavigate();

  const submited = async () => {
    try {
      setLoadingAction(true);
      let ret;
      if (isCreate) {
        ret = await offersService.postOffer(submitData);
      } else {
        ret = await offersService.updateOffer(params, submitData);
      }
      if (ret.data.status.description === "Success") {
        setIsSuccess(true);
        setShowConfirm(false);
        setServerError(null);
        navigate("/offer");
      }
    } catch (e) {
      console.log("", e.response.data.status.description);
      setServerError(e.response.data.status.description);
    } finally {
      setLoadingAction(false);
    }
  };

  return (
    <div className="w-full pt-xxl">
      {error && (
        <TopperError
          isShow={errorOpen}
          setOpen={setErrorOpen}
          open={errorOpen}
          errorArray={error}
        />
      )}
      <ConfirmModal
        onClick={submited}
        loading={loadingAction}
        isSuccess={isSuccess}
        isError={serverError}
        message={serverError}
        open={showConfirm}
        confirmText="OK"
        onClose={() => {
          setShowConfirm(false);
          setIsSuccess(false);
          setServerError(null);
        }}
        text={
          "Would you like to " + (isCreate ? "Create" : "Edit") + " Offer ?"
        }
      />
      <RenderControllerMemo
        isCreate={isCreate}
        data={data}
        setError={setError}
        setErrorOpen={setErrorOpen}
        setSubmitData={setSubmitData}
        setShowConfirm={setShowConfirm}
      />
    </div>
  );
};

export const OfferDetail = ({ setRouteName, setActionAppbar, action }) => {
  const params = useParams();
  const [isEdit, setIsEdit] = React.useState(false);

  const isCreate = params?.offer_code === "create";

  // const prePareData = (data) => {
  //   return {
  //     ...data,
  //     // from_date: formatDateApi(new Date()),
  //     // to_date: data.to_date ? formatDateApi(data.to_date) : null,
  //   };
  // };

  const { data, loading, error } = useQuery(
    offersService.getOffersInfo,
    {
      offer_code: params.offer_code,
    },
    {},
    [],
    {
      prePareData: null,
      checkFirstLoad: isCreate ? false : true,
    }
  );

  useEffect(() => {
    if (isCreate) {
      setRouteName([
        { title: "OFFER", path: "/offer" },
        { title: "CREATE", path: `/offer/create` },
      ]);
    } else {
      setRouteName([
        { title: "OFFER", path: "/offer" },
        { title: data?.offer_code, path: `/offer/${data?.offer_code}` },
      ]);
      setActionAppbar("");
    }
  }, [data, setRouteName]);

  useEffect(() => {
    let mounted = true;
    if (action === "EDIT") {
      setIsEdit(true);
      setRouteName([
        { title: "OFFER", path: `/offer` },
        {
          title: `${data.offer_code}`,
          path: `/offer/${data.offer_code}`,
          setSelected: setIsEdit,
          check: true,
        },
        {
          title: "EDIT OFFER",
        },
      ]);
    }
    return () => {
      mounted = false;
    };
  }, [action]);

  if (loading) {
    return (
      <div className="h-screen m-auto">
        <PBFELoading />
      </div>
    );
  }

  if (error) return <PBFEError error={error} />;

  if (isEdit || isCreate) {
    return (
      <RenderOfferEditMode data={data} isCreate={isCreate} params={params} />
    );
  } else {
    return (
      <div className="w-full pt-xxl">
        <MetaData data={data} />
        <div className="h-10px bg-gray-100"></div>

        <RenderOfferDetail data={data} />
        {/* <div className="h-10px bg-gray-100"></div> */}
      </div>
    );
  }
};
