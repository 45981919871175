import React, { Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import {
  MAX_VALUE,
  PRICING_METHOD,
  TIER_TYPE_FORMAT,
  RATE_TYPE,
  QUALIFIER_TYPE,
} from "../../constants/constant";
import {
  GRAY_100,
  GRAY_BORDER_INSIDE,
  TABLE_STYLE,
  UNDERLINE,
  WHITE_BORDER,
  WHITE_BORDER_INSIDE,
} from "../../constants/style";
import {
  calculateRowSpan,
  formatNumberComma,
  formatNumberMax,
  formatNumberMin,
  formatEmpty,
} from "../../utils/util";
import {
  TableCellsQualifiers,
  TableNewRenderQualifiers,
} from "./tableCellQualifiers";
import { InputForTable } from "../radium-omnibus/src/fields/inputForTable";
import { cloneDeep } from "lodash";
import { FunctionChangeSnakeCaseToNormalCase } from "../radium-omnibus/src/utils/Field.Function";

export const TabelRowData = ({ step, info, index, underline }) => {
  return (
    <Fragment>
      <TableRow
        sx={{
          backgroundColor: GRAY_100,
        }}
      >
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
          }}
        >
          {step.from === null || step.from === undefined
            ? formatEmpty(step.from)
            : formatNumberMin(step.from)}
        </TableCell>
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
          }}
        >
          {step.to === null || step.to === undefined
            ? formatEmpty(step.to)
            : formatNumberMax(step.to)}
        </TableCell>
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
          }}
        >
          {formatEmpty(formatNumberComma(step.fix_amount))}
        </TableCell>
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
          }}
        >
          {step.rate === null || step.rate === undefined
            ? formatEmpty(step.rate)
            : formatNumberComma(step.rate)}
        </TableCell>
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
          }}
        >
          {step?.rate_type
            ? RATE_TYPE[step.rate_type]
            : formatEmpty(step.rate_type)}
        </TableCell>

        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
          }}
        >
          {formatEmpty(formatNumberComma(step.min_tier))}
        </TableCell>

        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
          }}
        >
          {formatEmpty(formatNumberComma(step.max_tier))}
        </TableCell>

        {index === 0 && (
          <TableCell
            sx={WHITE_BORDER}
            rowSpan={calculateRowSpan(info.value.length)}
          >
            {formatEmpty(formatNumberComma(info.min))}
          </TableCell>
        )}
        {index === 0 && (
          <TableCell
            sx={WHITE_BORDER}
            rowSpan={calculateRowSpan(info.value.length)}
          >
            {formatEmpty(formatNumberComma(info.max))}
          </TableCell>
        )}
      </TableRow>
    </Fragment>
  );
};

export const TableForQualifiers = ({ data }) => {
  return (
    <Fragment>
      {data?.fee_value?.conditions?.length > 0 &&
        data?.fee_value?.conditions?.map((info, index) => {
          return (
            <Fragment key={index}>
              <TableRow sx={{ backgroundColor: GRAY_100 }}>
                <TableCell
                  sx={{
                    width: "60px",
                    borderBottom: GRAY_BORDER_INSIDE,
                    borderTop: WHITE_BORDER_INSIDE,
                  }}
                  rowSpan={calculateRowSpan(info?.value?.length)}
                >
                  {formatEmpty(info?.priority)}
                </TableCell>

                {Object.keys(data.qualifiers).length > 0 &&
                  Object.keys(info.qualifiers).map((qualifier, index) => {
                    const qualifierValue = info.qualifiers[qualifier];

                    return (
                      <TableCell
                        key={index}
                        sx={{
                          width: "110px",
                          borderBottom: GRAY_BORDER_INSIDE,
                          borderTop: WHITE_BORDER_INSIDE,
                        }}
                        rowSpan={calculateRowSpan(info?.value?.length)}
                      >
                        <div className="flex items-center w-full flex-col">
                          {typeof qualifierValue === "string" ? (
                            <div className="mr-1" key={index}>
                              {qualifierValue.replace("_", " ")}
                            </div>
                          ) : (
                            <React.Fragment>
                              {qualifierValue?.length > 0 ? (
                                <React.Fragment>
                                  {qualifierValue?.map((type, index) => {
                                    return (
                                      <div className="mr-1" key={index}>
                                        {RATE_TYPE[type]
                                          ? RATE_TYPE[type]
                                          : type}
                                        {index !==
                                          info.qualifiers[qualifier].length -
                                            1 && ","}
                                      </div>
                                    );
                                  })}
                                </React.Fragment>
                              ) : (
                                "-"
                              )}
                            </React.Fragment>
                          )}
                        </div>
                      </TableCell>
                    );
                  })}

                <TableCell
                  sx={{
                    width: "60px",
                    borderBottom: GRAY_BORDER_INSIDE,
                    borderTop: WHITE_BORDER_INSIDE,
                  }}
                  rowSpan={calculateRowSpan(info?.value?.length)}
                >
                  {TIER_TYPE_FORMAT[info.tier]}
                </TableCell>
              </TableRow>
              {info?.value?.map((step, index) => {
                return (
                  <TabelRowData
                    key={index}
                    step={step}
                    info={info}
                    index={index}
                    underline={index < info.value.length - 1 || index > 0}
                  />
                );
              })}
            </Fragment>
          );
        })}
    </Fragment>
  );
};

export const TableForNonQualifiers = ({ data, type }) => {
  return (
    <Fragment>
      <TableRow>
        {/* <TableCell
          rowSpan={MAX_VALUE}
          sx={{
            width: "120px",
            backgroundColor: "#FFF",
            borderBottom: WHITE_BORDER_INSIDE,
            borderTop: WHITE_BORDER_INSIDE,
          }}
        >
          {PRICING_METHOD[data.pricing_method]}
        </TableCell> */}

        {/* <TableCell
          rowSpan={MAX_VALUE}
          sx={{
            width: "120px",
            backgroundColor: "#FFF",
            borderBottom: WHITE_BORDER_INSIDE,
            borderTop: WHITE_BORDER_INSIDE,
          }}
        >
          {formatEmpty(data?.priority)}
        </TableCell> */}

        <TableCell
          rowSpan={MAX_VALUE}
          sx={{
            backgroundColor: GRAY_100,
            width: "60px",
            borderBottom: GRAY_BORDER_INSIDE,
            borderTop: WHITE_BORDER_INSIDE,
          }}
        >
          {TIER_TYPE_FORMAT[data?.fee_value?.tier]}
        </TableCell>
      </TableRow>
      {data?.fee_value?.value?.map((info, index) => (
        <TabelRowData
          key={index}
          step={info}
          info={data?.fee_value}
          index={index}
          underline={index < data?.fee_value.value.length - 1 || index > 0}
        />
      ))}
    </Fragment>
  );
};

export const NewVersionRenderQualifiers = ({ qualifiers }) => {
  const keys = Object.keys(qualifiers);

  const newQualifers = keys.map((key) => {
    return {
      key,
      value: qualifiers[key],
    };
  });

  return newQualifers?.map((qualifier, index) => {
    // <div className="flex items-center w-full flex-col" key={index}>
    //   {qualifier?.key}
    // </div>;
    return (
      <TableBody key={`key-of-new-q-${index}`}>
        <TableRow sx={{ backgroundColor: GRAY_100 }}>
          <TableCell
            sx={{
              width: "60px",
              borderBottom: GRAY_BORDER_INSIDE,
              borderTop: WHITE_BORDER_INSIDE,
            }}
            // rowSpan={calculateRowSpan(info?.value?.length)}
          >
            {QUALIFIER_TYPE[qualifier?.key]
              ? QUALIFIER_TYPE[qualifier?.key]
              : FunctionChangeSnakeCaseToNormalCase(qualifier?.key) || "-"}
          </TableCell>

          <TableCell
            sx={{
              width: "60px",
              borderBottom: GRAY_BORDER_INSIDE,
              borderTop: WHITE_BORDER_INSIDE,
            }}
            // rowSpan={calculateRowSpan(info?.value?.length)}
          >
            {qualifier?.value?.length > 0
              ? JSON.stringify(qualifier?.value)
                  ?.replace("[", "")
                  .replace("]", "")
              : "-"}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  });
};

export const NewVersionRenderQualifiersEditMode = ({
  value,
  setValue,
  isOffer = false,
  chargeIndexOffer = -1,
}) => {
  // const x = {
  //   zone: ["IN", "OUT", "outsite"],
  //   channel: [
  //     "TELL",
  //     "ENET",
  //     "LEAD",
  //     "PSS",
  //     "ATM",
  //     "CDM",
  //     "BCMS",
  //     "BNET",
  //     "BPOS",
  //     "PTNR",
  //     "EDC",
  //   ],
  //   charge_type: ["V", "P", "X"],
  // };

  const keys = Object.keys(value);

  React.useEffect(() => {
    if (isOffer) {
      if (
        // loaded === false &&
        setValue &&
        keys?.length > 0 &&
        Array.isArray(value) === false
      ) {
        const newQualifers = keys?.map((key) => {
          // console.log("map ", value[key]);
          return {
            key,
            value: value[key],
            // string: `${value[key]?.join(", ")}`,
            string: JSON.stringify(value[key])
              ?.replace("[", "")
              .replace("]", ""),
          };
        });
        setValue((prev) => {
          let clone = cloneDeep([...prev]);
          clone[chargeIndexOffer].qualifiers = newQualifers;
          return clone;
        });
        // setLoaded(true);
      }
    } else {
      if (
        keys?.length > 0 &&
        // loaded === false &&
        setValue &&
        Array.isArray(value) === false
      ) {
        const newQualifers = keys.map((key) => {
          // console.log("map ", value[key]);
          return {
            key,
            value: value[key],
            // string: `${value[key]?.join(", ")}`,
            string: JSON.stringify(value[key])
              ?.replace("[", "")
              .replace("]", ""),
          };
        });

        setValue(newQualifers);
        // setLoaded(true);
      }
    }
    return () => {};
  }, [value]);

  if (value?.length > 0) {
    return value?.map((qualifier, index) => {
      return (
        <TableBody key={`key-of-new-q-${index}`}>
          <TableRow sx={{ backgroundColor: GRAY_100 }}>
            <TableCell
              sx={{
                width: "60px",
                borderBottom: GRAY_BORDER_INSIDE,
                borderTop: WHITE_BORDER_INSIDE,
              }}
              // rowSpan={calculateRowSpan(info?.value?.length)}
            >
              {QUALIFIER_TYPE[qualifier?.key]
                ? QUALIFIER_TYPE[qualifier?.key]
                : FunctionChangeSnakeCaseToNormalCase(qualifier?.key) || "-"}
            </TableCell>

            <TableCell
              sx={{
                width: "60px",
                borderBottom: GRAY_BORDER_INSIDE,
                borderTop: WHITE_BORDER_INSIDE,
              }}
              // rowSpan={calculateRowSpan(info?.value?.length)}
            >
              {/* {qualifier?.value?.length > 0 ? qualifier?.value?.join(", ") : "-"} */}
              <InputForTable
                fullWidth={true}
                name="string"
                onChange={(e) => {
                  if (isOffer) {
                    setValue((prev) => {
                      let cloneData = cloneDeep([...prev]);
                      cloneData[chargeIndexOffer].qualifiers[index].string =
                        e.target.value;
                      return cloneData;
                    });
                  } else {
                    setValue((prev) => {
                      let cloneData = cloneDeep(prev);
                      cloneData[index].string = e.target.value;
                      cloneData[index].value = e.target.value.split(",");
                      return cloneData;
                    });
                  }
                  // const newQualifiers = value.map((item, index) => {
                  //   if (index === index) {
                  //     return {
                  //       ...item,
                  //       string: e.target.value,
                  //       value: e.target.value.split(","),
                  //     };
                  //   } else {
                  //     return item;
                  //   }
                  // });
                }}
                value={qualifier?.string}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      );
    });
  } else {
    return <React.Fragment />;
  }
};

export const PBFEFeeTable = ({ data }) => {
  const isQualifer =
    Object.keys(data?.qualifiers ? data?.qualifiers : {})?.length > 0 || false;

  return (
    <div id={data?.charge_code} className="border-t border-b pb-fourteen">
      <TableContainer>
        {isQualifer && (
          <Table sx={TABLE_STYLE}>
            <TableNewRenderQualifiers />
            <NewVersionRenderQualifiers qualifiers={data?.qualifiers} />
          </Table>
        )}
        <Table sx={TABLE_STYLE}>
          <TableCellsQualifiers data={data} />
          <TableBody sx={{ marginTop: "16px" }}>
            {isQualifer ? (
              <TableForQualifiers data={data} />
            ) : (
              <TableForNonQualifiers data={data} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
