import * as React from "react";
import { TYPE_ERROR_ARRAY, TYPE_ERROR_NORMAL } from "../type";
import { Alert, AlertTitle, Collapse } from "@mui/material";

export const FunctionCheckIsError = (object, mode) => {
  if (!object) return;

  let error =
    mode === TYPE_ERROR_NORMAL
      ? {
          error: false,
          message: "",
          name: "",
        }
      : [];

  const keys = Object.keys(object);
  keys.forEach((key) => {
    if (object[key]?.error) {
      if (mode === TYPE_ERROR_NORMAL) {
        error = {
          error: true,
          message: object[key]?.message,
          name: key,
        };
      }

      if (mode === TYPE_ERROR_ARRAY) {
        error.push({
          error: true,
          message: object[key]?.message,
          name: key,
        });
      }
      // if (document) {
      //   document?.getElementById(key)?.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //   });
      // }
    } else {
      if (object[key]?.length > 0) {
        const arrayKeys = object[key];
        for (let i = 0; i < arrayKeys.length; i++) {
          const arrayKey = arrayKeys[i];
          const thisKey = Object.keys(arrayKey);
          for (let j = 0; j < thisKey.length; j++) {
            const thisStep = arrayKey[thisKey[j]];

            if (thisStep.error) {
              if (mode === TYPE_ERROR_NORMAL) {
                error = {
                  error: true,
                  message: thisStep?.message,
                  name: `thisStep.field_name-${key}[${i}]-${thisKey[j]}`,
                };
              }
              if (mode === TYPE_ERROR_ARRAY) {
                error.push({
                  error: true,
                  message: thisStep?.message,
                  name: `thisStep.field_name-${key}[${i}]-${thisKey[j]}`,
                });
              }

              // if (document) {
              //   document.getElementById(thisStep?.field_name)?.scrollIntoView({
              //     behavior: "smooth",
              //     block: "center",
              //   });
              // }
            }
          }
        }
      }
    }
  });
  return error;
};

export const FunctionGetObjectIsError = (data = {}) => {
  if (Object.keys(data).length > 0) {
    let errorState = [];
    const ObjectValues = Object.values(data);

    ObjectValues.forEach((item) => {
      const isArray = Array.isArray(item);
      if (isArray && item.length > 0) {
        item?.forEach((arrayItem) => {
          const ObjectValuesArray = Object.values(arrayItem);
          ObjectValuesArray.forEach((arrayItemValue) => {
            if (arrayItemValue?.error) {
              errorState.push(arrayItemValue);
            }
          });
        });
      } else if (item?.error === true) {
        errorState.push(item);
      } else {
        return;
      }
      return item;
    });

    return errorState;
  } else {
    return;
  }
};

export const FunctionChangCamelCaseToSnakeCase = (string) => {
  return string.replace(/([A-Z])/g, (g) => `_${g[0].toLowerCase()}`);
};

export const FunctionChangeSnakeCaseToCamelCase = (string) => {
  return string.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace("-", "").replace("_", "")
  );
};

export const FunctionChangeSnakeCaseToNormalCase = (string) => {
  try {
    const first = string?.slice(0, 1).toLocaleUpperCase();
    const last = string.replace(/([-_][a-z])/g, (group) => {
      return group.toUpperCase().replace("-", " ").replace("_", " ");
    });
    return first + last.slice(1);
  } catch (error) {}
};

export const TopperError = ({ open, setOpen, errorArray = [] }) => {
  return (
    <div className={`w-full`} id="invalid">
      <Collapse in={open}>
        <Alert
          onClose={() => {
            setOpen(false);
          }}
          severity="error"
        >
          <AlertTitle>ValidationError</AlertTitle>
          <ul className="px-4 list-decimal">
            {errorArray.length > 0 &&
              errorArray.map((item, index) => {
                return (
                  <li key={`key-of-error-${index}`}>
                    {FunctionChangeSnakeCaseToNormalCase(item?.field_name)} :{" "}
                    {item.message}
                  </li>
                );
              })}
          </ul>
        </Alert>
      </Collapse>
    </div>
  );
};

export const FunctionDeleteErrorSameName = (errorState) => {
  const errorStateName = errorState.map((item) => item?.field_name);
  const errorStateNameUnique = [...new Set(errorStateName)];
  const errorStateUnique = errorStateNameUnique.map((item) => {
    const errorStateFilter = errorState.filter((itemFilter) => {
      return itemFilter?.field_name === item && isNaN(Number(item));
    });
    return errorStateFilter[0];
  });
  return errorStateUnique?.filter((item) => item?.error);
};
