import React, { Fragment, useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import _ from "lodash";
import {
  MAX_VALUE,
  RATE_TYPE,
  EDIT_COLUMN_WITHOUT_QUALIFIERS,
  PRICING_FREQUENCY,
  VAT_TYPE,
  WHT_TYPE,
  PRICING_FREQUENCY_FOR_SUBSCRIPTION,
  PRICING_METHOD_FOR_DROPDOWN,
  MAPPING_ALL_TYPE,
  PRICING_FREQUENCY_FOR_TRAN_VALUE,
  PRICING_METHOD_FOR_SUBSCRIPTION,
  TYPE_ONE_TIME,
  TYPE_SUBSCRIPTION,
  PRICING_METHOD_FOR_ONE_TIME,
  PRICING_FREQUENCY_FOR_ONE_TIME,
  MAPPING_TRANSACTION_CONSOLIDATION,
  RATE_TYPE_NEW_OPTION,
  WHT_TYPE_CANNOT_NO,
  VAT_TYPE_CANNOT_NO,
} from "../../constants/constant";
import {
  GRAY_100,
  GRAY_BORDER_INSIDE,
  TABLE_STYLE,
  UNDERLINE,
  WHITE_BORDER_INSIDE,
  WHITE_BORDER_NOPADDING,
  WHITE_BORDER_NOPADDING_W_35,
} from "../../constants/style";
import {
  calculateRowSpan,
  formatEmpty,
  getClearValueObject,
} from "../../utils/util";
import { TableCellsEditQualifiers } from "./tableCellQualifiers";
import { PBFEDropdown } from "../Input/dropdown";
import { PBFEDatePicker } from "../Input/datePicker";
import { TableDropdown } from "../Input/tableDropdown";
import Plus from "../../assets/icon/plus.svg";
import DeleteIcon from "../../assets/icon/delete.svg";
import {
  formatConstantToOptions,
  FormatNumber,
  FormatRateType,
} from "../../functions";
import RemoveIcon from "../../assets/icon/remove.svg";
import { TextField } from "@mui/material";
import { InputForTable, NumberFormatCustom } from "../Input/inputForTable";
import { PBFEContent } from "../Elements/Text/content";
import { ServiceContext } from "../Panel/Information/servicesDetail";
import { ERROR_MESSAGE_MINUS } from "../../utils/errorMessage";

export const TableCellRemove = ({
  data,
  step,
  info,
  index,
  underline,
  hasTier,
  setData,
  primaryField,
  primaryIndex,
  isEdit = false,
  removeRow,
  removeRowForQualifiers,
  feeValueIndex,
  hasQualifer,
  conditionIndex,
}) => {
  const checkCondition =
    (hasQualifer && conditionIndex === 0) ||
    (!hasQualifer && feeValueIndex) === 0;

  return (
    <TableCell
      disabled={checkCondition}
      onClick={
        checkCondition
          ? () => { }
          : hasQualifer
            ? removeRowForQualifiers(conditionIndex, feeValueIndex)
            : removeRow(feeValueIndex, index)
      }
      sx={WHITE_BORDER_NOPADDING_W_35}
      rowSpan={calculateRowSpan(
        (info?.length === undefined
          ? info.value.length - 1
          : info?.length - 1) + (isEdit && hasTier ? 1 : 0)
      )}
      align="left"
    >
      <img
        alt=""
        src={DeleteIcon}
        className={
          isEdit && conditionIndex > 0
            ? "text-textscb h-15px w-15px  cursor-pointer mr-16px"
            : "hidden"
        }
      />
    </TableCell>
  );
};

export const EditRowData = ({
  data,
  step,
  info,
  index,
  underline,
  hasTier,
  setData,
  primaryField,
  isEdit = false,
  isNew = false,
  removeRow,
  removeRowForQualifiers,
  primaryIndex = 0,
  feeValueIndex = 0,
  conditionIndex = 0,
  onChangeFieldForQualifers,
  onChangeFieldDropDownSingleForQualifiers,
  onChangeFieldDropDownSingle,
  onChangeFieldDropDownValueSingle,
  onChangeFieldDropDownValueSingleForQualifiers,
  hasQualifer = false,
  setErrorState,
  originData,
  // setErrorLocal,
  // errorLocal,
}) => {
  // const [errorLocal, setErrorLocal] = useState([]);
  const infoCtx = useContext(ServiceContext);

  const onChangeField = () => (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      prev[primaryIndex][primaryField][feeValueIndex].fee_value.value[index][
        name
      ] = value;
      prev[primaryIndex][primaryField][feeValueIndex].action = "EDIT";
      return [...prev];
    });
    infoCtx?.setHasChange(!infoCtx?.hasChange);
  };

  const onChangeFieldInfo = () => (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      prev[primaryIndex][primaryField][feeValueIndex].fee_value[name] = value;
      prev[primaryIndex][primaryField][feeValueIndex].action = "EDIT";
      return [...prev];
    });
    infoCtx?.setHasChange(!infoCtx?.hasChange);
  };

  const deleteRow = () => {
    setData((prev) => {
      if (
        prev[primaryIndex][primaryField][feeValueIndex]?.qualifiers !== null &&
        prev[primaryIndex][primaryField][feeValueIndex]?.qualifiers !==
        undefined
      ) {
        prev[primaryIndex][primaryField][feeValueIndex].fee_value.conditions[
          conditionIndex
        ].value = prev[primaryIndex][primaryField][
          feeValueIndex
        ].fee_value.conditions[conditionIndex].value.filter(
          (_, currentIndex) => {
            return currentIndex !== index;
          }
        );
        prev[primaryIndex][primaryField][feeValueIndex].action = "EDIT";
        return [...prev];
      } else {
        prev[primaryIndex][primaryField][feeValueIndex].fee_value.value = prev[
          primaryIndex
        ][primaryField][feeValueIndex].fee_value.value.filter(
          (_, currentIndex) => {
            return currentIndex !== index;
          }
        );
        prev[primaryIndex][primaryField][feeValueIndex].action = "EDIT";
        return [...prev];
      }
    });
    infoCtx?.setHasChange(!infoCtx?.hasChange);
  };

  const isTierNo = data?.fee_value?.tier === "NO";

  return (
    <Fragment>
      <TableRow
        sx={{
          backgroundColor: GRAY_100,
        }}
      >
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
            height: "1px",
            padding: 1,
            minWidth: "90px",
            //    width: "90px",
            verticalAlign: isEdit && "top",
          }}
        >
          <InputForTable
            primaryField={primaryField}
            primaryIndex={primaryIndex}
            feeValueIndex={feeValueIndex}
            conditionIndex={conditionIndex}
            // setErrorLocal={setErrorLocal}
            originData={originData}
            hasTier={hasTier}
            name="from"
            disabled={!isEdit || (!hasTier && isEdit)}
            onChange={
              hasQualifer
                ? onChangeFieldForQualifers(
                  conditionIndex,
                  feeValueIndex,
                  true,
                  index
                )
                : onChangeField()
            }
            value={
              // hasTier ?   (Number(step.from) === 0 ? step.from : step.from) : "-"
              isTierNo
                ? ""
                : hasTier && isEdit
                  ? step?.from === 0
                    ? "MIN"
                    : step?.from
                  : step?.from === 0
                    ? "MIN"
                    : step?.from
            }
            setErrorState={setErrorState}
            tier={info?.tier}
            sync_data={
              step.to === 999999999.99
                ? step.to
                : step.to === 0
                  ? 0
                  : step.to === "0"
                    ? 0
                    : step.to
                      ? step.to
                      : ""
            }
            sync_name="to"
            group={
              data?.fee_value?.conditions?.length > 0
                ? data.fee_value.conditions[conditionIndex].value
                : data?.fee_value?.value?.length > 0
                  ? data?.fee_value?.value
                  : {}
            }
            index={index}
          />
        </TableCell>
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
            height: "1px",
            padding: 1,
            minWidth: "90px",
            verticalAlign: isEdit && "top",
            //     width: "90px",
            // verticalAlign: errorLocal?.length > 0 ? "top" : "",
          }}
        >
          <InputForTable
            index={index}
            primaryField={primaryField}
            primaryIndex={primaryIndex}
            feeValueIndex={feeValueIndex}
            conditionIndex={conditionIndex}
            // setErrorLocal={setErrorLocal}
            originData={originData}
            hasTier={hasTier}
            name="to"
            disabled={!isEdit || (!hasTier && isEdit)}
            onChange={
              hasQualifer
                ? onChangeFieldForQualifers(
                  conditionIndex,
                  feeValueIndex,
                  true,
                  index
                )
                : onChangeField()
            }
            value={
              isTierNo
                ? "-"
                : isEdit
                  ? step?.to === 999999999.99
                    ? "MAX"
                    : step?.to
                  : step?.to === 999999999.99
                    ? "MAX"
                    : step?.to
            }
            setErrorState={setErrorState}
            tier={info?.tier}
          />
        </TableCell>
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
            height: "1px",
            padding: 1,
            verticalAlign: isEdit && "top",
            // verticalAlign: errorLocal?.length > 0 ? "top" : "",
          }}
        >
          <InputForTable
            index={index}
            primaryField={primaryField}
            primaryIndex={primaryIndex}
            feeValueIndex={feeValueIndex}
            conditionIndex={conditionIndex}
            // setErrorLocal={setErrorLocal}
            originData={originData}
            hasTier={hasTier}
            isNumber={true}
            name="fix_amount"
            disabled={!isEdit}
            onChange={
              hasQualifer
                ? onChangeFieldForQualifers(
                  conditionIndex,
                  feeValueIndex,
                  true,
                  index
                )
                : onChangeField()
            }
            value={step.fix_amount === 0 ? "0" : step.fix_amount}
            setErrorState={setErrorState}
          />
        </TableCell>
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
            height: "1px",
            padding: 1,
            verticalAlign: isEdit && "top",
            // verticalAlign: errorLocal?.length > 0 ? "top" : "",
          }}
        >
          <InputForTable
            index={index}
            primaryField={primaryField}
            primaryIndex={primaryIndex}
            feeValueIndex={feeValueIndex}
            conditionIndex={conditionIndex}
            // setErrorLocal={setErrorLocal}
            originData={originData}
            hasTier={hasTier}
            isNumber={true}
            name="rate"
            disabled={!isEdit}
            onChange={
              hasQualifer
                ? onChangeFieldForQualifers(
                  conditionIndex,
                  feeValueIndex,
                  true,
                  index
                )
                : onChangeField()
            }
            value={step.rate}
            setErrorState={setErrorState}
            sync_data={step.rate_type}
            sync_name="rate_type"
          />
        </TableCell>
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
            height: "1px",
            padding: 1,
            verticalAlign: isEdit && "top",
            // verticalAlign: errorLocal?.length > 0 ? "top" : "",
          }}
        >
          {!isEdit ? (
            <div style={{ marginTop: isEdit ? "10px" : "0px" }}>
              {step?.rate_type
                ? RATE_TYPE[step.rate_type]
                : formatEmpty(step.rate_type)}
            </div>
          ) : (
            <TableDropdown
              index={index}
              primaryField={primaryField}
              primaryIndex={primaryIndex}
              feeValueIndex={feeValueIndex}
              conditionIndex={conditionIndex}
              // setErrorLocal={setErrorLocal}
              setErrorState={setErrorState}
              error={
                (step.rate && step.rate_type === "-") ||
                  (step.rate && !step.rate_type)
                  ? true
                  : false
              }
              disabled={!isEdit || (!hasTier && isEdit)}
              options={RATE_TYPE_NEW_OPTION}
              value={FormatRateType(step.rate_type)}
              name="rate_type"
              onChange={
                hasQualifer
                  ? onChangeFieldDropDownValueSingleForQualifiers(
                    conditionIndex,
                    feeValueIndex,
                    index
                  )
                  : onChangeFieldDropDownValueSingle(feeValueIndex, index)
              }
            />
          )}
        </TableCell>

        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
            height: "1px",
            padding: 1,
            verticalAlign: isEdit && "top",
          }}
        >
          <InputForTable
            index={index}
            primaryField={primaryField}
            primaryIndex={primaryIndex}
            feeValueIndex={feeValueIndex}
            conditionIndex={conditionIndex}
            // setErrorLocal={setErrorLocal}
            originData={originData}
            hasTier={hasTier}
            isNumber={true}
            name="min_tier"
            disabled={!isEdit || (!hasTier && isEdit)}
            onChange={
              hasQualifer
                ? onChangeFieldForQualifers(
                  conditionIndex,
                  feeValueIndex,
                  true,
                  index
                )
                : onChangeField()
            }
            value={step?.min_tier ? step.min_tier : "-"}
            setErrorState={setErrorState}
            sync_data={step.max_tier}
            sync_name="max_tier"
          />
        </TableCell>

        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
            height: "1px",
            padding: 1,
            verticalAlign: isEdit && "top",
            // verticalAlign: errorLocal?.length > 0 ? "top" : "",
          }}
        >
          <InputForTable
            index={index}
            primaryField={primaryField}
            primaryIndex={primaryIndex}
            feeValueIndex={feeValueIndex}
            conditionIndex={conditionIndex}
            // setErrorLocal={setErrorLocal}
            originData={originData}
            hasTier={hasTier}
            isNumber={true}
            name="max_tier"
            disabled={!isEdit || (!hasTier && isEdit)}
            onChange={
              hasQualifer
                ? onChangeFieldForQualifers(
                  conditionIndex,
                  feeValueIndex,
                  true,
                  index
                )
                : onChangeField()
            }
            sync_data={step.min_tier}
            value={step?.max_tier ? step.max_tier : "-"}
            setErrorState={setErrorState}
          />
        </TableCell>
        {isEdit && (
          <TableCell
            sx={{
              borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
              borderBottom: underline ? UNDERLINE : "0px",
              height: "1px",
              padding: 0,
              minWidth: "25px",
              width: "25px",
            }}
          >
            <img
              alt=""
              onClick={deleteRow}
              src={DeleteIcon}
              className={
                hasTier && index > 0
                  ? "text-textscb  h-15px w-15px cursor-pointer"
                  : "hidden"
              }
            />
          </TableCell>
        )}

        {index === 0 && (
          <TableCell
            sx={{
              ...WHITE_BORDER_NOPADDING,
            }}
            rowSpan={calculateRowSpan(
              (info?.length === undefined
                ? info.value.length - 1
                : info?.length - 1) + (isEdit && hasTier ? 1 : 0)
            )}
          >
            <InputForTable
              index={index}
              primaryField={primaryField}
              primaryIndex={primaryIndex}
              feeValueIndex={feeValueIndex}
              conditionIndex={conditionIndex}
              // setErrorLocal={setErrorLocal}
              originData={originData}
              hasTier={hasTier}
              isNumber={true}
              name="min"
              disabled={!isEdit}
              onChange={
                hasQualifer
                  ? onChangeFieldForQualifers(conditionIndex, feeValueIndex)
                  : onChangeFieldInfo()
              }
              value={
                hasQualifer
                  ? info?.min === 0
                    ? "0"
                    : info?.min
                  : data?.fee_value?.min === 0
                    ? "0"
                    : data?.fee_value?.min
              }
              setErrorState={setErrorState}
              sync_data={hasQualifer ? info?.max : data?.fee_value?.max}
              sync_name="max"
            />
          </TableCell>
        )}
        {index === 0 && (
          <TableCell
            sx={{
              ...WHITE_BORDER_NOPADDING,
              // verticalAlign: errorLocal?.length > 0 ? "top" : "",
            }}
            rowSpan={calculateRowSpan(
              (info?.length === undefined
                ? info.value.length - 1
                : info?.length - 1) + (isEdit && hasTier ? 1 : 0)
            )}
          >
            <InputForTable
              index={index}
              primaryField={primaryField}
              primaryIndex={primaryIndex}
              feeValueIndex={feeValueIndex}
              conditionIndex={conditionIndex}
              // setErrorLocal={setErrorLocal}
              originData={originData}
              hasTier={hasTier}
              isNumber={true}
              name="max"
              disabled={!isEdit}
              onChange={
                hasQualifer
                  ? onChangeFieldForQualifers(conditionIndex, feeValueIndex)
                  : onChangeFieldInfo()
              }
              value={
                hasQualifer
                  ? info.max === 0
                    ? "0"
                    : info.max
                  : data?.fee_value?.max === 0
                    ? "0"
                    : data?.fee_value?.max
              }
              setErrorState={setErrorState}
            />
            {/* <input
              onBlur={
                hasQualifer
                  ? onChangeFieldForQualifers(conditionIndex, feeValueIndex)
                  : onChangeFieldInfo()
              }
              name="max"
              disabled={!isEdit}
              className={
                " h-full w-full  focus:outline-none focus:ring-2 focus:ring-textscb bg-gray-100 " +
                (isEdit ? "text-textscb" : "")
              }
              defaultValue={formatEmpty(formatNumberComma(info.max))}
            /> */}
          </TableCell>
        )}

        {isEdit && index === 0 && (
          <TableCellRemove
            step={step}
            info={info}
            index={index}
            underline={underline}
            hasTier={hasTier}
            setData={setData}
            primaryField={primaryField}
            primaryIndex={primaryIndex}
            isEdit={isEdit}
            removeRow={removeRow}
            removeRowForQualifiers={removeRowForQualifiers}
            feeValueIndex={feeValueIndex}
            data={data}
            hasQualifer={hasQualifer}
            conditionIndex={conditionIndex}
          />
        )}
      </TableRow>
    </Fragment>
  );
};

const RenderCondtion = ({
  info,
  conditionIndex,
  hasTier,
  index,
  data,
  setData,
  isEdit = false,
  onChangeFieldForQualifers = () => { },
  onChangeFieldDropDownSingleForQualifiers = () => { },
  onChangeFieldDropDownValueSingle = () => { },
  onChangeFieldDropDownValueSingleForQualifiers = () => { },
  onChangeFieldDropDownMultiForQualifiers = () => { },
  feeValueIndex,
  addTierForQualifiers,
  removeRowForQualifiers,
  primaryField,
  primaryIndex,
  removeRow,
  isOffer,
  setErrorState,
  originData,
}) => {
  const [errorLocal, setErrorLocal] = useState([]);

  return (
    <Fragment key={index}>
      <TableRow sx={{ backgroundColor: GRAY_100 }}>
        <TableCell
          sx={{
            width: "60px",
            height: "1px",
            padding: 1,
            borderBottom: GRAY_BORDER_INSIDE,
            borderTop: WHITE_BORDER_INSIDE,
          }}
          rowSpan={calculateRowSpan(
            info?.value?.length + (isEdit && hasTier ? 1 : 0)
          )}
        >
          <InputForTable
            originData={originData}
            isNumber={true}
            formatNumber={false}
            name="priority"
            disabled={
              !isEdit ||
              !(data?.qualifiers && Object.keys(data?.qualifiers).length > 0)
            }
            onChange={onChangeFieldForQualifers(index, feeValueIndex)}
            value={info?.priority}
            setErrorState={setErrorState}
          />
        </TableCell>

        {Object.keys(info?.qualifiers)?.length > 0 &&
          Object.keys(info?.qualifiers)?.map((qualifierKey, index) => {
            let originalKey = {};

            Object?.keys(data?.qualifiers)?.map((key) => {
              originalKey = {
                ...originalKey,
                [_.upperCase(key)]: data?.qualifiers[key],
              };
            });

            const upperCaseQualifierKey = _.upperCase(qualifierKey);

            return (
              <TableCell
                key={`${index}_cell_table_qualifires`}
                sx={{
                  width: "60px",
                  height: "54px",
                  padding: 1,
                  borderBottom: GRAY_BORDER_INSIDE,
                  borderTop: WHITE_BORDER_INSIDE,
                }}
                rowSpan={
                  calculateRowSpan(info?.value?.length) +
                  (isEdit && hasTier ? 1 : 0)
                }
              >
                {isEdit ? (
                  <TableDropdown
                    options={originalKey && originalKey[upperCaseQualifierKey]}
                    value={info.qualifiers[qualifierKey]}
                    name={qualifierKey}
                    isMulti={true}
                    conditionIndex={conditionIndex}
                    onChange={onChangeFieldDropDownMultiForQualifiers}
                  />
                ) : typeof info.qualifiers[qualifierKey] !== "string" ? (
                  info.qualifiers[qualifierKey]?.length > 0 ? (
                    info.qualifiers[qualifierKey].join(",\n")
                  ) : (
                    "-"
                  )
                ) : (
                  info.qualifiers[qualifierKey]
                )}
              </TableCell>
            );
          })}

        <TableCell
          sx={{
            width: "60px",
            height: "54px",
            padding: 1,
            borderBottom: GRAY_BORDER_INSIDE,
            borderTop: WHITE_BORDER_INSIDE,
          }}
          rowSpan={calculateRowSpan(
            info?.value?.length + (isEdit && hasTier ? 1 : 0)
          )}
        >
          {isEdit ? (
            <TableDropdown
              options={
                info?.value?.length > 1
                  ? ["STEP", "SLAB"]
                  : ["NO", "STEP", "SLAB"]
              }
              value={info?.tier}
              name="tier"
              onChange={onChangeFieldDropDownSingleForQualifiers(
                conditionIndex,
                feeValueIndex,
                index
              )}
            />
          ) : (
            <div>{info?.tier}</div>
          )}
        </TableCell>
      </TableRow>

      {info?.value?.map((step, index) => {
        return (
          <EditRowData
            errorLocal={errorLocal}
            setErrorLocal={setErrorLocal}
            originData={originData}
            data={data}
            key={index}
            step={step}
            info={info}
            index={index}
            hasTier={hasTier}
            underline={index < info?.value?.length - 1 || index > 0}
            setData={setData}
            isEdit={isEdit}
            removeRow={removeRow}
            removeRowForQualifiers={removeRowForQualifiers}
            primaryField={primaryField}
            primaryIndex={primaryIndex}
            feeValueIndex={feeValueIndex}
            conditionIndex={conditionIndex}
            onChangeFieldForQualifers={onChangeFieldForQualifers}
            onChangeFieldDropDownSingleForQualifiers={
              onChangeFieldDropDownSingleForQualifiers
            }
            onChangeFieldDropDownValueSingle={onChangeFieldDropDownValueSingle}
            onChangeFieldDropDownValueSingleForQualifiers={
              onChangeFieldDropDownValueSingleForQualifiers
            }
            hasQualifer
            setErrorState={setErrorState}
          />
        );
      })}
      {hasTier && isEdit && (
        <TableRow
          sx={{
            backgroundColor: GRAY_100,
            padding: 0,
          }}
        >
          <TableCell
            colSpan={EDIT_COLUMN_WITHOUT_QUALIFIERS}
            sx={{
              // borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
              borderBottom: "0px",
              height: "1px",
              padding: 0,
              //padding: "10px",
              //paddingRight:"0px"
            }}
          >
            <div className="w-full flex justify-end  text-textscb text-10px px-0 py-fourteen">
              <div>TIER</div>
              <img
                alt=""
                onClick={addTierForQualifiers(feeValueIndex, index)}
                className="ml-10px h-15px w-15px cursor-pointer"
                src={Plus}
              ></img>
            </div>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

export const TableEditForQualifiers = ({
  data,
  setData,
  isEdit = false,
  onChangeFieldForQualifers = () => { },
  onChangeFieldDropDownSingleForQualifiers = () => { },
  onChangeFieldDropDownValueSingle = () => { },
  onChangeFieldDropDownValueSingleForQualifiers = () => { },
  index: feeValueIndex,
  addTierForQualifiers,
  removeRowForQualifiers,
  primaryField,
  primaryIndex,
  removeRow,
  isOffer,
  setErrorState,
  originData,
}) => {
  const onChangeFieldDropDownMultiForQualifiers = (e, name, conditionIndex) => {
    const value = e.target.value;

    if (typeof value === "string" ? value?.split(",") : value) {
      setData((priv) => {
        // const current =
        // priv[primaryIndex][primaryField][feeValueIndex].fee_value.conditions;
        priv[primaryIndex][primaryField][feeValueIndex].fee_value.conditions[
          conditionIndex
        ].qualifiers[name] = value;
        priv[primaryIndex][primaryField][feeValueIndex].action = "EDIT";
        return [...priv];
      });
    }
  };

  return (
    <Fragment>
      {data?.fee_value?.conditions?.map((info, index) => {
        const hasTier = info?.tier !== "NO";

        const conditionIndex = index;

        return (
          <RenderCondtion
            key={index}
            info={info}
            index={index}
            hasTier={hasTier}
            conditionIndex={conditionIndex}
            data={data}
            setData={setData}
            isEdit={isEdit}
            onChangeFieldForQualifers={onChangeFieldForQualifers}
            onChangeFieldDropDownSingleForQualifiers={
              onChangeFieldDropDownSingleForQualifiers
            }
            onChangeFieldDropDownValueSingle={onChangeFieldDropDownValueSingle}
            onChangeFieldDropDownValueSingleForQualifiers={
              onChangeFieldDropDownValueSingleForQualifiers
            }
            onChangeFieldDropDownMultiForQualifiers={
              onChangeFieldDropDownMultiForQualifiers
            }
            feeValueIndex={feeValueIndex}
            addTierForQualifiers={addTierForQualifiers}
            removeRowForQualifiers={removeRowForQualifiers}
            primaryField={primaryField}
            primaryIndex={primaryIndex}
            removeRow={removeRow}
            isOffer={isOffer}
            setErrorState={setErrorState}
            originData={originData}
          />
        );
      })}
    </Fragment>
  );
};

export const TableEditForNonQualifiers = ({
  data,
  setData,
  primaryField,
  primaryIndex,
  isEdit = false,
  isNew = false,
  index: feeValueIndex,
  onChangeField,
  addTier,
  onChangeFieldDropDownSingle = () => { },
  onChangeFieldDropDownValueSingle = () => { },
  onChangeFieldDropDownValueSingleForQualifiers = () => { },
  removeRow,
  removeRowForQualifiers,
  isOffer,
  setErrorState,
  originData,
}) => {
  const [errorLocal, setErrorLocal] = useState([]);
  const hasTier = data?.fee_value?.tier !== "NO";

  return (
    <Fragment>
      <TableRow sx={{ backgroundColor: GRAY_100 }}>
        <TableCell
          sx={{
            width: "60px",
            height: "1px",
            padding: 0,
            borderBottom: GRAY_BORDER_INSIDE,
            borderTop: WHITE_BORDER_INSIDE,
          }}
          rowSpan={calculateRowSpan(
            data?.fee_value?.value?.length + (isEdit && hasTier ? 1 : 0)
          )}
        >
          {isEdit ? (
            <InputForTable
              originData={originData}
              isNumber={true}
              name="priority"
              disabled={
                !isEdit ||
                !(data?.qualifiers && Object.keys(data?.qualifiers).length > 0)
              }
              onChange={onChangeField(feeValueIndex)}
              value={data?.fee_value?.priority}
              setErrorState={setErrorState}
            />
          ) : (
            <div>
              {data?.fee_value?.priority ? data?.fee_value?.priority : "-"}
            </div>
          )}
        </TableCell>
        <TableCell
          sx={{
            width: "60px",
            height: "54px",
            padding: 0,
            borderBottom: GRAY_BORDER_INSIDE,
            borderTop: WHITE_BORDER_INSIDE,
          }}
          rowSpan={calculateRowSpan(
            data?.fee_value?.value?.length + (isEdit && hasTier ? 1 : 0)
          )}
        >
          {isEdit ? (
            <TableDropdown
              name="tier"
              onChange={onChangeFieldDropDownSingle(feeValueIndex)}
              options={
                data?.fee_value?.value?.length > 1
                  ? ["STEP", "SLAB"]
                  : ["NO", "STEP", "SLAB"]
              }
              value={data?.fee_value?.tier}
            />
          ) : (
            <div>{data?.fee_value?.tier}</div>
          )}
        </TableCell>
      </TableRow>
      {data?.fee_value?.value?.map((info, index) => {
        return (
          <EditRowData
            originData={originData}
            key={index}
            step={info}
            info={data?.fee_value?.value}
            hasTier={hasTier}
            index={index}
            underline={index < data?.fee_value?.value?.length - 1 || index > 0}
            isEdit={isEdit}
            removeRow={removeRow}
            feeValueIndex={feeValueIndex}
            data={data}
            setData={setData}
            primaryField={primaryField}
            primaryIndex={primaryIndex}
            removeRowForQualifiers={removeRowForQualifiers}
            onChangeFieldDropDownSingle={onChangeFieldDropDownSingle}
            onChangeFieldDropDownValueSingle={onChangeFieldDropDownValueSingle}
            onChangeFieldDropDownValueSingleForQualifiers={
              onChangeFieldDropDownValueSingleForQualifiers
            }
            setErrorState={setErrorState}
            errorLocal={errorLocal}
            setErrorLocal={setErrorLocal}
          />
        );
      })}
      {hasTier && isEdit && (
        <TableRow
          sx={{
            // borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: "0px",
            backgroundColor: GRAY_100,
            height: "1px",
            padding: 0,
          }}
        >
          <TableCell
            colSpan={EDIT_COLUMN_WITHOUT_QUALIFIERS}
            sx={{
              // borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
              borderBottom: "0px",
              height: "1px",
              padding: 0,
            }}
          >
            <div className="w-full flex justify-end  text-textscb text-10px px-0 py-fourteen">
              <div>TIER</div>
              <img
                alt=""
                onClick={addTier(feeValueIndex)}
                className="ml-10px h-15px w-15px cursor-pointer"
                src={Plus}
              ></img>
            </div>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

export const PBFEEditFeeTable = ({
  isEdit,
  data,
  setData,
  index,
  primaryIndex,
  primaryField,
  isNew,
  isOffer,
  onChangeField,
  hasChange,
  addTier,
  onChangeFieldDropDownSingle,
  onChangeFieldDropDownSingleForQualifiers,
  onChangeFieldForQualifers,
  onChangeFieldDropDownValueSingle,
  onChangeFieldDropDownValueSingleForQualifiers,
  addTierForQualifiers,
  removeRow,
  removeRowForQualifiers,
  isGroup,
  originData,
  lengthGroup,
  setErrorState,
  group = {},
  currentLast,
  setErrorLocalFee,
  //origin,
}) => {

 console.log("new origin " , originData)
  const [checkAddQualifer, setCheckAddQualifer] = useState(false);
  const [addType, setAddType] = useState("");

  const [errorFixAmount, setErrorFixAmount] = useState(false);
  const [checkChange, setCheckChange] = useState(false);

  const infoCtx = useContext(ServiceContext);

  useEffect(() => {
    if (group?.fee_charge?.length > 0) {
      const checkError = group?.fee_charge
        ?.map((item) => {
          return item?.isRemove === false && item?.isError === true;
        })
        .filter((item) => item);
      setErrorLocalFee(checkError.length > 0);
    }
  }, [checkChange, data.isRemove, data.isError]);

  useEffect(() => {
    if (
      (data?.pricing_method === TYPE_SUBSCRIPTION ||
        data?.pricing_method === TYPE_ONE_TIME) &&
      setErrorState
    ) {

      let field_name = `fix_amount_subscription_${primaryIndex}_${index}`;

      if (data?.fee_value?.value[0]?.fix_amount === "") {
        setErrorState((prev) => {
          let obj_error = {
            error: true,
            message: "requied field fix amount",
            field_name: field_name,
          };

          let temp = [...prev];
          temp.push(obj_error);
          temp = _.unionBy(temp, "field_name");
          return [...temp];
        });
        setErrorFixAmount(true);
      } else if (data?.fee_value?.value[0].fix_amount > 999999999.99) {
        setErrorState((prev) => {
          let obj_error = {
            error: true,
            message: "fix amount max 999999999.99",
            field_name: field_name,
          };

          let temp = [...prev];
          temp.push(obj_error);
          temp = _.unionBy(temp, "field_name");
          return [...temp];
        });
      } else if (Number(data?.fee_value?.value[0].fix_amount) < 0) {
        setErrorState((prev) => {
          let obj_error = {
            error: true,
            message: "fix amount min 0",
            field_name: field_name,
          };

          let temp = [...prev];
          temp.push(obj_error);
          temp = _.unionBy(temp, "field_name");
          return [...temp];
        });
      } else {
        setErrorState((prev) => {
          let temp = [...prev];
          temp = temp.filter((item) => item.field_name !== field_name);
          return [...temp];
        });
        setErrorFixAmount(false);
      }
    }
  }, [infoCtx?.hasChange]);

  useEffect(() => {
    if (addType === "addQualifer") {
      setData((prev) => {
        if (
          prev[primaryIndex][primaryField][index]?.fee_value?.conditions
            ?.length > 0
        ) {
          const lastCondition = JSON.parse(
            sessionStorage.getItem("last_condition")
          );

          prev[primaryIndex][primaryField][index]?.fee_value?.conditions?.push({
            ...getClearValueObject(lastCondition),
            tier: "NO",
          });

          prev[primaryIndex][primaryField][index].action = "EDIT";
          return [...prev];
        }
        return [...prev];
      });
      infoCtx?.setHasChange(!infoCtx?.hasChange);
    }
  }, [checkAddQualifer]);

  const addQualifier = () => {
    setData((prev) => {
      if (
        prev[primaryIndex][primaryField][index]?.fee_value?.conditions?.length >
        0
      ) {
        const conditions =
          prev[primaryIndex][primaryField][index]?.fee_value?.conditions;

        const last_condition = conditions[conditions.length - 1];

        sessionStorage.setItem(
          "last_condition",
          JSON.stringify(last_condition)
        );
        setCheckAddQualifer(!checkAddQualifer);
        setAddType("addQualifer");
        return prev;
      }
      return prev;
    });
    infoCtx?.setHasChange(!infoCtx?.hasChange);
  };

  const onChangeTextField = (e) => {
    const { name, value } = e.target;

    setData((prev) => {
      // console.log("E: ", { value, name, prev });

      if (
        prev[primaryIndex][primaryField][index]?.pricing_method ===
        TYPE_SUBSCRIPTION ||
        prev[primaryIndex][primaryField][index]?.pricing_method ===
        TYPE_ONE_TIME
      ) {
        prev[primaryIndex][primaryField][index].fee_value.value[0].fix_amount =
          value;
        prev[primaryIndex][primaryField][index].action = "EDIT";
        return [...prev];
      }
      prev[primaryIndex][primaryField][index][name] = value;
      prev[primaryIndex][primaryField][index].action = "EDIT";
      return [...prev];
    });
    infoCtx?.setHasChange(!infoCtx?.hasChange);
  };

  const onClickDeleteAdded = () => {
    setData((prev) => {
      prev[primaryIndex][primaryField][index].isRemove = true;
      prev[primaryIndex][primaryField][index].action = "DELETE";
      return [...prev];
    });
    infoCtx?.setHasChange(!infoCtx?.hasChange);
  };

  const checkOptionPricingFrequency = () => {
    const pricing_method = data?.pricing_method;
    if (pricing_method === TYPE_SUBSCRIPTION) {
      return PRICING_FREQUENCY_FOR_SUBSCRIPTION;
    } else if (
      pricing_method === "TRAN_VALUE" ||
      pricing_method === "TRAN_VOLUME"
    ) {
      return PRICING_FREQUENCY_FOR_TRAN_VALUE;
    } else if (pricing_method === TYPE_ONE_TIME) {
      return PRICING_FREQUENCY_FOR_ONE_TIME;
    }

    return PRICING_FREQUENCY;
  };

  const renderRemove = () => {
    if (isEdit && lengthGroup > 1 && !data?.isRemove) {
      if (data?.checkCurrentDate) {
        return null;
      }

      return (
        <div
          className="flex ml-36px text-14px w-42px items-center cursor-pointer text-textscb px-0"
          onClick={(e) => {
            e.stopPropagation();
            onClickDeleteAdded(e);
          }}
        >
          <img src={RemoveIcon} className="mr-10px" alt="header_image" />
          Remove
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <div
        className={`grid grid-cols-5 ${!data.isRemove && "gap-40px mb-20px"}`}
      >
        {!currentLast?.current?.isRemove && (
          <React.Fragment>
            {(currentLast.index === index || !isGroup) && (
              <React.Fragment>
                <PBFEDropdown
                  disabled={isEdit && !isNew}
                  label="Pricing Method"
                  keyName="pricing_method"
                  rows={data}
                  setData={setData}
                  index={index}
                  isEdit={isEdit}
                  isNew={isNew}
                  primaryIndex={primaryIndex}
                  primaryField={primaryField}
                  options={
                    data?.pricing_method === TYPE_ONE_TIME
                      ? formatConstantToOptions(PRICING_METHOD_FOR_ONE_TIME)
                      : data?.pricing_method === TYPE_SUBSCRIPTION
                        ? formatConstantToOptions(PRICING_METHOD_FOR_SUBSCRIPTION)
                        : formatConstantToOptions(PRICING_METHOD_FOR_DROPDOWN)
                  }
                />
                <PBFEDropdown
                  disabled={isEdit && !isNew}
                  label={`Pricing Frequency`}
                  keyName="pricing_frequency"
                  rows={data}
                  setData={setData}
                  index={index}
                  isEdit={isEdit}
                  primaryIndex={primaryIndex}
                  primaryField={primaryField}
                  defaultValue={data?.pricing_method === TYPE_ONE_TIME ? "ONE_TIME" : null}
                  options={formatConstantToOptions(
                    checkOptionPricingFrequency()
                  )}
                />

                {data?.pricing_method === TYPE_SUBSCRIPTION && !isOffer ? (
                  <PBFEDropdown
                    disabled={(isEdit && !isNew) || (originData && originData[index].vat_type === "NO")}
                    label="VAT"
                    keyName="vat_type"
                    rows={data}
                    setData={setData}
                    index={index}
                    isEdit={isEdit}
                    primaryIndex={primaryIndex}
                    primaryField={primaryField}
                    options={formatConstantToOptions(originData && originData[index]?.vat_type === "NO"? VAT_TYPE:VAT_TYPE_CANNOT_NO)}
                  />
                ) : null}

                {(data?.pricing_method === TYPE_SUBSCRIPTION && isOffer) ||
                  (data?.pricing_method === TYPE_ONE_TIME && isOffer) ? (
                  <PBFEContent
                    mode="flex w-full"
                    label={`Fixed Amount`}
                    value={
                      data?.fee_value?.value?.length >= 0
                        ? formatEmpty(
                          FormatNumber(data?.fee_value?.value[0].fix_amount)
                        )
                        : "-"
                    }
                  />
                ) : null}

                {data?.pricing_method === TYPE_ONE_TIME ? (
                  <React.Fragment />
                ) : data?.pricing_method === TYPE_SUBSCRIPTION ? (
                  <div></div>
                ) : (
                  <PBFEDropdown
                    disabled={isEdit && !isNew}
                    label="Transaction Consolidation"
                    keyName="transaction_consolidation"
                    rows={data}
                    setData={setData}
                    index={index}
                    isEdit={isEdit}
                    primaryIndex={primaryIndex}
                    primaryField={primaryField}
                    options={formatConstantToOptions(
                      MAPPING_TRANSACTION_CONSOLIDATION
                    )}
                  />
                )}

                {data?.pricing_method === TYPE_SUBSCRIPTION ? (
                  <React.Fragment />
                ) : (
                  <PBFEDropdown
                    disabled={(isEdit && !isNew) || (originData && originData[index]?.vat_type === "NO")}
                    label="VAT"
                    keyName="vat_type"
                    rows={data}
                    setData={setData}
                    index={index}
                    isEdit={isEdit}
                    primaryIndex={primaryIndex}
                    primaryField={primaryField}
                    options={formatConstantToOptions(originData && originData[index]?.vat_type === "NO"? VAT_TYPE:VAT_TYPE_CANNOT_NO)}
                  />
                )}

                {data?.pricing_method === TYPE_ONE_TIME ? (
                  <div></div>
                ) : data?.pricing_method === TYPE_SUBSCRIPTION ? (
                  <div></div>
                ) : (
                  <PBFEDropdown
                    disabled={(isEdit && !isNew) || (originData && originData[index]?.wht_type === "N")}
                    label="WHT"
                    keyName="wht_type"
                    rows={data}
                    setData={setData}
                    index={index}
                    isEdit={isEdit}
                    primaryIndex={primaryIndex}
                    primaryField={primaryField}
                    options={formatConstantToOptions(originData && originData[index]?.wht_type === "N"?WHT_TYPE:WHT_TYPE_CANNOT_NO)}
                  />
                )}

                {data?.pricing_method === TYPE_ONE_TIME && <div></div>}
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {!isOffer && (
          <React.Fragment>
            <PBFEDatePicker
              hasChange={hasChange}
              disabled={isEdit && !isNew && data?.checkCurrentDate}
              keyName="from_date"
              data={data}
              setData={setData}
              index={index}
              label={"Effective From"}
              isEdit={isEdit}
              primaryIndex={primaryIndex}
              primaryField={primaryField}
              group={group}
              setErrorState={setErrorState}
              originalData={originData}
              setErrorLocalFee={setErrorLocalFee}
              setCheckChange={setCheckChange}
            />
            {data?.pricing_method === TYPE_ONE_TIME ? (
              <React.Fragment />
            ) : (
              <PBFEDatePicker
                hasChange={hasChange}
                keyName="to_date"
                data={data}
                setData={setData}
                index={index}
                label="To"
                isEdit={isEdit}
                primaryIndex={primaryIndex}
                primaryField={primaryField}
                group={group}
                setErrorState={setErrorState}
                originalData={originData}
                setErrorLocalFee={setErrorLocalFee}
                setCheckChange={setCheckChange}
              />
            )}
          </React.Fragment>
        )}

        {(data?.pricing_method === TYPE_SUBSCRIPTION ||
          data?.pricing_method === TYPE_ONE_TIME) &&
          !data.isRemove && [
            isEdit && !data?.checkCurrentDate ? (
              <React.Fragment>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: { color: "#7A58BF", fontSize: 16 },
                    inputComponent: NumberFormatCustom,
                  }}
                  name="fixed_amount"
                  id="standard-required"
                  label="Fixed Amount"
                  // defaultValue={
                  //   data?.fee_value?.value?.length >= 0
                  //     ? data?.fee_value?.value[0].fix_amount
                  //     : ""
                  // }
                  value={
                    data?.fee_value?.value?.length >= 0
                      ? data?.fee_value?.value[0].fix_amount
                      : ""
                  }
                  variant="standard"
                  onChange={onChangeTextField}
                  error={
                    (data?.fee_value?.value?.length > 0 &&
                      data?.fee_value?.value[0].fix_amount > 999999999.99) ||
                    errorFixAmount ||
                    Number(data?.fee_value?.value[0].fix_amount) < 0
                    // ||
                    // (data?.pricing_method === TYPE_ONE_TIME &&
                    //   data?.fee_value?.value?.length > 0 &&
                    //   (data?.fee_value?.value[0].fix_amount === 0 ||
                    //     data?.fee_value?.value[0].fix_amount === "" ||
                    //     data?.fee_value?.value[0].fix_amount === "0" ||
                    //     data?.fee_value?.value[0].fix_amount === "-"))
                  }
                  helperText={
                    data?.fee_value?.value?.length > 0 &&
                      data?.fee_value?.value[0].fix_amount > 999999999.99
                      ? "Value cannot be greater than 999999999.99"
                      : errorFixAmount
                        ? "Required field fix amount"
                        : Number(data?.fee_value?.value[0].fix_amount) < 0
                          ? ERROR_MESSAGE_MINUS
                          : // : data?.pricing_method === TYPE_ONE_TIME &&
                          //   data?.fee_value?.value?.length > 0 &&
                          //   (data?.fee_value?.value[0].fix_amount === 0 ||
                          //     data?.fee_value?.value[0].fix_amount === "" ||
                          //     data?.fee_value?.value[0].fix_amount === "0" ||
                          //     data?.fee_value?.value[0].fix_amount === "-")
                          // ? "Value cannot be 0"
                          ""
                  }
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!isOffer && (
                  <PBFEContent
                    mode="flex w-full"
                    label={`Fixed Amount`}
                    value={
                      data?.fee_value?.value?.length >= 0
                        ? formatEmpty(
                          FormatNumber(data?.fee_value?.value[0].fix_amount)
                        )
                        : "-"
                    }
                  />
                )}
              </React.Fragment>
            ),
          ]}

        {!isEdit && isNew && lengthGroup > 1 && !data?.isRemove && (
          <div
            className="flex  text-14px items-center cursor-pointer text-textscb"
            onClick={(e) => {
              e.stopPropagation();
              onClickDeleteAdded(e);
            }}
          >
            <img src={RemoveIcon} className="mr-10px" alt="header_image" />
            Remove
          </div>
        )}
        {renderRemove()}
      </div>
      {data?.pricing_method !== TYPE_SUBSCRIPTION &&
        data?.pricing_method !== TYPE_ONE_TIME &&
        !data?.isRemove && (
          <div
            id={data?.charge_code}
            className="border-t border-b pb-fourteen overflow-x-auto"
          >
            <TableContainer>
              <Table sx={TABLE_STYLE}>
                <TableCellsEditQualifiers
                  isEdit={isEdit && !data?.checkCurrentDate}
                  data={data}
                  withPricingMethod={false}
                />
                <TableBody sx={{ marginTop: "16px" }}>
                  {data?.fee_value?.conditions?.length > 0 ? (
                    <TableEditForQualifiers
                      data={data}
                      setData={setData}
                      isEdit={isEdit && !data?.checkCurrentDate}
                      primaryField={primaryField}
                      primaryIndex={primaryIndex}
                      index={index}
                      onChangeField={onChangeField}
                      onChangeFieldForQualifers={onChangeFieldForQualifers}
                      onChangeFieldDropDownSingleForQualifiers={
                        onChangeFieldDropDownSingleForQualifiers
                      }
                      addTierForQualifiers={addTierForQualifiers}
                      removeRowForQualifiers={removeRowForQualifiers}
                      onChangeFieldDropDownValueSingle={
                        onChangeFieldDropDownValueSingle
                      }
                      onChangeFieldDropDownValueSingleForQualifiers={
                        onChangeFieldDropDownValueSingleForQualifiers
                      }
                      removeRow={removeRow}
                      isOffer={isOffer}
                      setErrorState={setErrorState}
                      originData={originData}
                    />
                  ) : (
                    <TableEditForNonQualifiers
                      data={data}
                      setData={setData}
                      isEdit={isEdit && !data?.checkCurrentDate}
                      primaryField={primaryField}
                      primaryIndex={primaryIndex}
                      index={index}
                      onChangeField={onChangeField}
                      addTier={addTier}
                      onChangeFieldDropDownSingle={onChangeFieldDropDownSingle}
                      removeRow={removeRow}
                      removeRowForQualifiers={removeRowForQualifiers}
                      onChangeFieldDropDownValueSingle={
                        onChangeFieldDropDownValueSingle
                      }
                      onChangeFieldDropDownValueSingleForQualifiers={
                        onChangeFieldDropDownValueSingleForQualifiers
                      }
                      isOffer={isOffer}
                      originData={originData}
                      setErrorState={setErrorState}
                    />
                  )}
                  {((isEdit && !data?.checkCurrentDate) || isNew) &&
                    data?.qualifiers !== null && (
                      <TableRow sx={{ backgroundColor: GRAY_100 }}>
                        <TableCell
                          sx={{
                            height: "1px",
                            padding: 0,
                            borderBottom: GRAY_BORDER_INSIDE,
                            borderTop: WHITE_BORDER_INSIDE,
                          }}
                          colSpan={MAX_VALUE}
                        >
                          <div className="w-full flex justify-end px-10px text-textscb text-10px py-10px">
                            <div onClick={addQualifier} className="flex">
                              <div>QUALIFIER</div>
                              <img
                                className="ml-10px h-15px w-15px cursor-pointer"
                                src={Plus}
                              ></img>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      {/* {renderFooter()} */}
    </div>
  );
};
