import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import TablePagination from "@mui/material/TablePagination";
import {
  MORE_THAN_SEARCH_ACTIVE,
  ROW_PER_PAGE,
} from "../../constants/constant";
import {
  TABLE_STYLESTANDARD_WITDH,
  TABLE_STYLE_WITHOUT_BOTTOM_BORDER,
  TABLE_STYLE_WITH_HOVER_STANDARD_WITDH,
  TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER,
} from "../../constants/style";
import NoRecordFound from "../Error/noRecordFound";

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    disabledSort = false,
    disabledSortList = [],
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const getDisabledSort = (headCell) => {
    if (disabledSort) {
      return disabledSort;
    } else if (disabledSortList.length > 0) {
      return disabledSortList.some((item) => item === headCell.id);
    }

    return false;
  };

  return (
    <TableHead>
      <TableRow hover={false}>
        {headCells.map((headCell) => {
          return (
            <TableCell
              // rowSpan={2}
              // colSpan={}
              key={headCell.id}
              align={"left"}
              padding={"normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                // aria-rowspan={2}
                disabled={getDisabledSort(headCell)}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export const PBFECommonTableAsync = ({
  filter = {},
  headCells,
  rows,
  showFooter = false,
  selectable = false,
  disabledSort,
  onClickRow,
  page = 0,
  setPage,
  formatControl,
  showFields = [],
  searched,
  refetch,
  limit,
  total_records,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  sortBy,
  setSortBy,
  isSearch = true,
  disabledSortList = [],
  rowPerRecord,
  cursorPointer = true,
}) => {
  const rowPerPageOverlap = rowPerRecord ? rowPerRecord : ROW_PER_PAGE;

  const handleRequestSort = async (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const order_by = isAsc ? "desc" : "asc";
    await refetch(
      {},
      {
        offset: page,
        limit,
        search_criteria: searched,
        sort_by: order_by,
        order_by: property,
        ...filter,
      }
    );
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortBy(!sortBy);
    // setPage(0);
  };

  const handleChangePage = async (event, newPage) => {
    await refetch(
      {},
      {
        offset: newPage,
        limit,
        search_criteria: searched,
        sort_by: order,
        order_by: orderBy,
        ...filter,
      }
    );
    setPage(newPage);
  };

  const convertShowFieldsObject = (row) => {
    let temp = [];
    if (showFields.length === 0) {
      /// Non Show Field case
      Object.keys(row).map((key) => temp.push(row[key]));
    } else {
      for (let i = 0; i < showFields.length; i++) {
        const key = showFields[i].split(",");
        var val = "";
        for (let i = 0; i < key.length; i++) {
          if (row[key[i]]) {
            if (i === 2) val += " / "; /// have id case
            val += row[key[i]];
            if (key.length > 1 && i === 0) val += "|"; /// header case
          }
        }
        // if (row[key]) {
        //   temp.push(row[key]);
        // } else {
        //   temp.push("");
        // }
        temp.push(val);
      }
    }
    return temp;
  };

  return (
    <div>
      <TableContainer>
        {searched?.length > MORE_THAN_SEARCH_ACTIVE && rows?.length <= 0 ? (
          <NoRecordFound />
        ) : (
          <Table
            sx={
              cursorPointer
                ? TABLE_STYLE_WITH_HOVER_STANDARD_WITDH
                : TABLE_STYLESTANDARD_WITDH
            }
            aria-label="simple table"
          >
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              rowCount={rows?.length}
              onRequestSort={handleRequestSort}
              disabledSort={disabledSort}
              disabledSortList={disabledSortList}
            />
            <TableBody>
              {isSearch &&
                rows?.map((row, index) => {
                  return (
                    <TableRow
                      id="table-row"
                      key={index}
                      hover={selectable}
                      onClick={() => onClickRow && onClickRow(row)}
                      sx={
                        cursorPointer
                          ? TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER
                          : TABLE_STYLE_WITHOUT_BOTTOM_BORDER
                      }
                    >
                      {convertShowFieldsObject(row)?.map((item, index) => {
                        return (
                          <TableCell id="table-cell" align="left" key={index}>
                            {formatControl === undefined ||
                            formatControl[index] == null
                              ? item
                              : formatControl[index](item, row)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {isSearch && rows?.length > 0 && showFooter && (
        <div className="border-t">
          <TablePagination
            component="div"
            count={total_records}
            rowsPerPage={rowPerPageOverlap}
            page={page}
            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[]}
            showFirstButton={true}
            showLastButton={true}
          />
        </div>
      )}
    </div>
  );
};
