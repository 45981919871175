import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import NumberFormat from "react-number-format";
import {
  checkFormToValue,
  FormatNumber,
  getValidateError,
  renderDropdownValue2,
} from "../../functions";

export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={name === "rate" ? 99 : 2}
      className={`${other.className} inputForNumber`}
    />
  );
});

export const FormatCustomForFromTo = React.forwardRef(
  function FormatCustomForFromTo(props, ref) {
    const { onChange, name, ...other } = props;

    return (
      <input
        {...other}
        getInputRef={ref}
        onChange={(e) => {
          onChange({
            target: {
              name: name,
              value: e.target.value,
            },
          });
        }}
        className={`${other.className} inputForNumber`}
      />
    );
  }
);

const useStyles = makeStyles(() => ({
  noBorder: {
    // border: "none",
    borderColor: "rgb(243 244 246)",
    borderRadius: 0,
    textTransform: "uppercase",
  },
  input: {
    color: "#7A58BF",
    textTransform: "uppercase",
  },
  isNumber: {
    minWidth: "100px",
  },
}));
export const InputForTable = ({
  errorText = "",
  value,
  onChange,
  disabled,
  isNumber = false,
  name,
  isEdit,
  formatNumber = true,
  setErrorState,
  tier = "",
  hasTier,
  sync_data = "",
  sync_name = "",
  group,
  index,
  originalData,
  setErrorLocal,
  errorLocal,
  primaryIndex = 0,
  feeValueIndex = 0,
  conditionIndex = 0,
  primaryField,
}) => {
  const classes = useStyles();

  const getError = (errorText === "" &&
    name &&
    getValidateError(name, value, tier, sync_name, sync_data)) || {
    error: false,
    message: "",
    tier: "",
  };

  const getPrvError =
    group?.length > 1 &&
    index > 0 &&
    checkFormToValue(
      value,
      group[index - 1][sync_name] ? group[index - 1][sync_name] : 0
    );

  // useEffect(() => {
  //   let field_name_error = `${name}_${primaryIndex}_${feeValueIndex}_${conditionIndex}_${index}`;

  //   if (setErrorState) {
  //     setErrorState((prev) => {
  //       let temp = [...prev];

  //       let obj_error = {
  //         ...getError,
  //         field_name: field_name_error,
  //       };

  //       if (getPrvError) {
  //         obj_error = {
  //           error: true,
  //           field_name: field_name_error,
  //           field_value: value,
  //           message: getPrvError,
  //           tier: tier,
  //         };
  //       }

  //       if (obj_error.error) {
  //         if ((!hasTier && name === "from") || (!hasTier && name) === "to") {
  //           return [...prev];
  //         }

  //         temp.push({
  //           ...obj_error,
  //           field_name: field_name_error,
  //           index,
  //         });

  //         temp = _.unionBy(temp, "field_name");

  //         return [...temp];
  //       } else {
  //         if (name === `max` && !obj_error.error) {
  //           temp = temp.filter(
  //             (item) =>
  //               item.field_name !==
  //               `min_${primaryIndex}_${feeValueIndex}_${conditionIndex}`
  //           );
  //         } else {
  //           temp = temp.filter((item) => item.field_name !== field_name_error);
  //         }

  //         if (name === `max_tier` && !obj_error.error) {
  //           const getError = (errorText === "" &&
  //             name &&
  //             getValidateError(
  //               "min_tier",
  //               sync_data,
  //               tier,
  //               "max_tier",
  //               value
  //             )) || {
  //             error: false,
  //             message: "",
  //             tier: "",
  //           };
  //           if (!getError.error) {
  //             temp = temp.filter(
  //               (item) =>
  //                 item.field_name !==
  //                 `min_tier_${primaryIndex}_${feeValueIndex}_${conditionIndex}_${index}`
  //             );
  //           } else {
  //             temp.push({
  //               ...getError,
  //               field_name: `min_tier_${primaryIndex}_${feeValueIndex}_${conditionIndex}_${index}`,
  //               index,
  //             });
  //             temp = _.unionBy(temp, "field_name");
  //           }
  //         } else {
  //           temp = temp.filter((item) => item.field_name !== field_name_error);
  //         }
  //         return [...temp];
  //       }
  //     });
  //   } else {
  //     return null;
  //   }
  // }, [setErrorState, value, originalData, infoCtx?.hasChange]);

  if (disabled && !isEdit) {
    if (typeof renderDropdownValue2(name, value) === "number" && formatNumber) {
      // return numeral(renderDropdownValue2(name, value)).format("0,0.00");
      return (
        <div className="text-14px py-10px">
          {FormatNumber(renderDropdownValue2(name, value))}
        </div>
      );
    } else {
      return (
        <div className="text-14px py-10px">
          {renderDropdownValue2(name, value)}
        </div>
      );
    }
  }

  const getRenderValue = () => {
    // if (isNumber || name === "from" || name === "to") {
    //   const splitValue = String(value).split(".");
    //   if (splitValue.length > 0 && value) {
    //     const firstValue = splitValue[0];
    //     const secondValue = splitValue.length > 1 ? `.${splitValue[1]}` : "";
    //     if (firstValue.length > 7) {
    //       return firstValue.slice(0, 7) + `${secondValue}`;
    //     }
    //   }

    //   return value;
    // }

    return value;
  };

  return (
    <TextField
      // disabled={disabled}
      placeholder={"-"}
      size="small"
      margin="none"
      //defaultValue={getRenderValue()}
      value={getRenderValue()}
      name={name}
      error={
        getPrvError?.length > 0
          ? true
          : errorText !== ""
          ? errorText
          : getError?.error && getError?.message !== ""
      }
      onChange={onChange}
      //onBlur={onChange}
      variant="outlined"
      helperText={
        getPrvError
          ? getPrvError
          : errorText !== ""
          ? errorText
          : getError?.message
      }
      classes={{ notchedOutline: classes.input }}
      InputProps={{
        classes: { notchedOutline: classes.noBorder },
        className: !disabled && classes.input,
        inputComponent:
          (isNumber && NumberFormatCustom) ||
          (["from", "to"].includes(name) && FormatCustomForFromTo),
      }}
      // inputProps={{
      //   maxLength: 7,
      // }}
    />
  );
};
