import { PBFEContent } from "../../../components/Elements/Text/content";
import {
  ACCOUNT_STATUS_BG_ERROR,
  MAPPING_CARD_TYPE,
  MAPPING_CLOSURE_TYPE,
} from "../../../constants/constant";
import { formateDate, formatEmpty } from "../../../utils/util";

const AccountGeneralInfomation = ({ data }) => {
  return (
    <div className="grid grid-cols-4 gap-y-6 gap-x-2">
      <PBFEContent label="Account Type" value={data.account_type} />
      <PBFEContent
        label="Activation Date"
        value={formateDate(data.activation_date, "-")}
      />
      <PBFEContent
        label="Application Date"
        value={formateDate(data.application_date, "-")}
      />
      <PBFEContent
        label="Closing Date"
        value={formateDate(data.closing_date, "-")}
      />
      <div className="col-span-2">
        <PBFEContent label="Bank Code" value={formatEmpty(data.bank_code)} />
      </div>
      <div className="col-span-2">
        <PBFEContent
          label="Closure Type"
          value={formatEmpty(MAPPING_CLOSURE_TYPE[data.closure_type])}
        />
      </div>
      <PBFEContent label="Branch Code" value={formatEmpty(data.branch_code)} />
      <PBFEContent
        label="Currency Code"
        value={formatEmpty(data.currency_code)}
      />
      <PBFEContent label="OC Code" value={formatEmpty(data?.oc_code)} />
      <PBFEContent
        label="Joint Account"
        value={formatEmpty(data?.joint_account)}
      />
      <PBFEContent
        label="Card Type"
        value={formatEmpty(MAPPING_CARD_TYPE[data?.card_type])}
      />
      <div className="col-span-2">
        <PBFEContent
          label="Primary Card Reference"
          value={formatEmpty(data?.primary_card_reference)}
        />
      </div>
      <PBFEContent
        label="Account Status"
        value={formatEmpty(data?.status)}
        valueStyle={
          (data.status === "DOR" || data.status === "Dormant") &&
          ACCOUNT_STATUS_BG_ERROR
        }
      />
      <PBFEContent label="Product" value={formatEmpty(data?.product)} />
      <div className="col-span-2">
        <PBFEContent
          label="Channel Account ID"
          value={formatEmpty(data?.channel_account_id)}
        />
      </div>
      <PBFEContent
        label="Payroll Indicator"
        value={formatEmpty(data?.payroll_indicator)}
      />
    </div>
  );
};

export default AccountGeneralInfomation;
