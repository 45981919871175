import React, { useState } from "react";
import { useNavigate, useLocation, useResolvedPath } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";

//Collapse
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

const CustomListBar = styled(ListItem)(
  ({ isactive = "false", theme, ...props }) => ({
    "&:hover": {
      backgroundColor: "rgba(122, 88, 191, 0.15)",
    },
    "&:active": {
      backgroundColor: "rgba(122, 88, 191, 0.15)",
    },
    backgroundColor:
      isactive === "true" ? "rgba(122, 88, 191, 0.15)" : "transparent",
    borderRadius: `4px`,
  })
);
const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "rgba(122, 88, 191, 0.15)",
  },
  "&:active": {
    backgroundColor: "rgba(122, 88, 191, 0.15)",
  },
  borderRadius: `4px`,
}));

export const CustomListBarNavLink = ({ item, icon: Icon, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const resolvedPath = useResolvedPath(item.path);

  const onClickListBar = () => {
    if (!item.path) return;
    navigate(`${item.path}`);
  };

  const getActive = () => {
    const { pathname } = location;
    const { pathname: toPathname } = resolvedPath;

    return pathname.startsWith(toPathname).toString();
  };

  return (
    <CustomListBar button onClick={onClickListBar} isactive={getActive()}>
      <ListItemIcon>
        {item.imgIcon ? (
          <img alt="" src={item.imgIcon} />
        ) : (
          <Icon sx={{ fontSize: "18px" }} />
        )}
      </ListItemIcon>
      <div className="text-medium-10px">{item.text}</div>
    </CustomListBar>
  );
};

function PBFEListbarCollapse({
  defaultOpen = true,
  textHeader = "",
  items = [],
}) {
  const [openCollapse, setOpenCollapse] = useState(defaultOpen);

  const handleClick = () => {
    setOpenCollapse(!openCollapse);
  };

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <CustomListItemButton onClick={handleClick}>
        {openCollapse ? (
          <ExpandLess sx={{ fontSize: "16px", marginRight: "5px" }} />
        ) : (
          <ExpandMore sx={{ fontSize: "16px", marginRight: "5px" }} />
        )}
        <div className="text-medium-12px  ">{textHeader}</div>
      </CustomListItemButton>

      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <List>
          {items.length > 0 &&
            items?.map((item, index) => {
              const { icon } = item;
              return (
                <CustomListBarNavLink key={index} item={item} icon={icon} />
              );
            })}
        </List>
      </Collapse>
    </List>
  );
}

export default PBFEListbarCollapse;
