import React from "react";
import { useNavigate } from "react-router-dom";
import { PBFECommonTable } from "../../../components/Table/commonTable";
import { HEAD_ACCOUNT_CELLS } from "../../../constants/constant";
import { formatEmpty } from "../../../utils/util";

const CustomerRenderAccountInfomation = React.memo(
  ({ searchedPage, accountPage, setAccountPage, accountTable }) => {
    const navigate = useNavigate();

    return (
      <PBFECommonTable
        sort_name_by="account_name"
        searched={searchedPage}
        page={accountPage}
        setPage={setAccountPage}
        headCells={HEAD_ACCOUNT_CELLS}
        rows={accountTable}
        showFooter={true}
        selectable={true}
        orderByDefault="account_name"
        onClickRow={(row) => {
          navigate(`/account/${row?.account_id}`);
        }}
        formatControl={[formatEmpty, formatEmpty]}
        showFields={["account_name", "account_id"]}
      />
    );
  }
);

export default CustomerRenderAccountInfomation;
