import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { getWordSpaceUppercase } from "../../utils/util";
import { QUALIFIER_TYPE } from "../../constants/constant";
import React from "react";

export const TableNewRenderQualifiers = ({ data }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ width: "25%" }}>Qualifier</TableCell>
        <TableCell sx={{ width: "75%" }}>Possible Values</TableCell>
      </TableRow>
    </TableHead>
  );
};

const TableHeadQualifiers = ({ data }) => {
  if (data?.qualifiers && data?.fee_value?.conditions?.length > 0) {
    let qualiferObjectKey = {};
    data?.fee_value?.conditions?.forEach((condition) => {
      const listQualifierKeys = Object.keys(condition?.qualifiers);
      listQualifierKeys.forEach((qualiferKey) => {
        qualiferObjectKey = {
          ...qualiferObjectKey,
          [qualiferKey]: "",
        };
      });
    });

    const listQualifierKeys = Object.keys(qualiferObjectKey);

    if (listQualifierKeys?.length > 0) {
      return listQualifierKeys.map((qualifier, index) => {
        return (
          <TableCell key={index}>
            {QUALIFIER_TYPE[qualifier]
              ? QUALIFIER_TYPE[qualifier]
              : getWordSpaceUppercase(qualifier?.replace("_", " "))}
          </TableCell>
        );
      });
    }
    return null;
  }

  return null;
};

export const TableCellsQualifiers = ({ data, withPricingMethod = true }) => {
  return (
    <TableHead>
      <TableRow>
        {/* {withPricingMethod && data?.pricing_method && (
          <TableCell>Pricing Method</TableCell>
        )} */}
        {/* {(data?.qualifiers !== null || data?.qualifiers !== undefined) &&
          Object.keys(data?.qualifiers).length > 0 &&
          Object.keys(data?.qualifiers).map((qualifier, index) => {
            return (
              <TableCell key={index}>
                {_.capitalize(qualifier?.replace("_", " "))}
              </TableCell>
            );
          })} */}
        {data?.fee_value?.conditions?.length > 0 && (
          <React.Fragment>
            <TableCell>Priority</TableCell>
          </React.Fragment>
        )}
        <TableHeadQualifiers data={data} />

        <TableCell>Tier</TableCell>
        <TableCell>From</TableCell>
        <TableCell>To</TableCell>
        <TableCell>Fixed Amount</TableCell>
        <TableCell>Rate</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Tier Min</TableCell>
        <TableCell>Tier Max</TableCell>
        <TableCell>Min Charge</TableCell>
        <TableCell>Max Charge</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const TableCellsEditQualifiers = ({
  data,
  withPricingMethod = true,
  isEdit = false,
}) => {
  return (
    <TableHead>
      <TableRow>
        {withPricingMethod && data?.pricing_method && (
          <TableCell>Pricing Method</TableCell>
        )}

        <TableCell>Priority</TableCell>
        <TableHeadQualifiers data={data} />
        {/* {data?.qualifiers &&
          Object.keys(data?.qualifiers).length > 0 &&
          Object.keys(data?.qualifiers).map((qualifier, index) => {
            return (
              <TableCell key={index}>
                {_.capitalize(qualifier?.replace("_", " "))}
              </TableCell>
            );
          })} */}
        <TableCell>Tier</TableCell>
        <TableCell>From</TableCell>
        <TableCell>To</TableCell>
        <TableCell>Fixed Amount</TableCell>
        <TableCell>Rate</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Tier Min</TableCell>
        <TableCell>Tier Max</TableCell>
        {isEdit && <TableCell></TableCell>}
        <TableCell>Min Charge</TableCell>
        <TableCell>Max Charge</TableCell>
        {isEdit && <TableCell></TableCell>}
      </TableRow>
    </TableHead>
  );
};
