import React from "react";
import { Alert, AlertTitle, Collapse } from "@mui/material";

function PBFEError({ error, mt = "16" }) {
  const [open, setOpen] = React.useState(true);

  return (
    <div className={"w-full mt-" + mt + " h-32"}>
      <Collapse in={open}>
        <Alert onClose={() => setOpen(false)} severity="error">
          <AlertTitle>Server Error</AlertTitle>
          {error?.message}
        </Alert>
      </Collapse>
    </div>
  );
}

export default PBFEError;
