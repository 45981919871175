import React, { useEffect, useState } from "react";
import {
  CUSTOMER_ROUTE,
  CUSTOMER_TITLE,
  HEADER_TABEL_CUSTOMER,
  MORE_THAN_SEARCH_ACTIVE,
} from "../../constants/constant";
import PBFEError from "../../components/Error/alertError";
import { useNavigate } from "react-router-dom";
import useQuery from "../../hooks/useQuery";

// api
import customerService from "../../api/customerService";
import PBFESearchbar from "../../components/Elements/Searchbar/searchbar";
import { PBFECommonTableAsync } from "../../components/Table/commonTableAsync";
import Skeleton from "react-loading-skeleton";
import NoRecordFound from "../../components/Error/noRecordFound";

function Customer({ setRouteName, setActionAppbar }) {
  const navigate = useNavigate();

  const limit = 15;

  const [searched, setSearched] = useState("");
  const [page, setPage] = useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer_name");
  const [sortBy, setSortBy] = React.useState(true);
  const [checkFirstLanding, setCheckFirstLanding] = useState(true);

  const {
    data: rows,
    setData: setRows,
    loading,
    error,
    refetch,
    totalRecords,
  } = useQuery(
    customerService.getCustomer,
    {},
    {
      offset: 0,
      limit,
      search_criteria: "",
      order_by: orderBy,
      sort_by: order,
    },
    [],
    { checkFirstLoad: false }
  );

  useEffect(() => {
    setRouteName([{ title: CUSTOMER_TITLE, path: CUSTOMER_ROUTE }]);
    setActionAppbar("");
  }, [setRouteName]);

  const requestSearch = async (searchedVal) => {
    const value = searchedVal.target.value;
    setSearched(value);
    setPage(0);
    setCheckFirstLanding(false);
    if (value?.length > MORE_THAN_SEARCH_ACTIVE) {
      await refetch(
        {},
        {
          offset: 0,
          limit,
          search_criteria: value,
          sort_by: order,
          order_by: orderBy,
        }
      );
      return;
    }
    if (value?.length === 0) {
      setCheckFirstLanding(true);
    }
    setRows([]);
  };

  if (error) return <PBFEError error={error} />;

  return (
    <div className="w-full">
      <div className="flex justify-end w-full mt-50px pt-md">
        <PBFESearchbar
          placeholder={"Search Customer Name / ID"}
          onChange={requestSearch}
          value={searched}
        />
      </div>
      {loading ? (
        <div className="p-fourteen">
          <Skeleton
            containerTestId="react-loading-skeleton"
            height={30}
            width={`100%`}
            count={9}
          />
        </div>
      ) : (
        <React.Fragment>
          {rows?.length ? (
            <PBFECommonTableAsync
              sort_name_by="customer_name"
              searched={searched}
              page={page}
              setPage={setPage}
              headCells={HEADER_TABEL_CUSTOMER}
              rows={rows}
              showFooter={true}
              selectable={true}
              onClickRow={(row) => {
                navigate(`/customer/${row.customer_id}`);
              }}
              showFields={["customer_name", "customer_id"]}
              refetch={refetch}
              limit={limit}
              total_records={totalRecords}
              orderByDefault="customer_name"
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              sortBy={sortBy}
              setSortBy={setSortBy}
              isSearch={searched?.length > MORE_THAN_SEARCH_ACTIVE}
            />
          ) : (
            <React.Fragment>
              {!checkFirstLanding && <NoRecordFound />}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default Customer;
