import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ACCOUNT_HEAD_CELLS } from "../../../constants/constant";
import { setDisabledAction } from "../../../redux/actionAppbarSlice";
import { PBFEHeader } from "../../Elements/Text/header";
import { PBFEDatePicker } from "../../Input/datePicker";
import { PBFEEditTable } from "../../Table/editTable";
import { PBFEAccordionMain } from "../Accordion/accordionMain";
// import { AccountModal } from "../Modal/accountModal";
import { ServiceDetail } from "./servicesDetail";
import makerCheckerService from "../../../api/makerCheckerService";
import {
  fillZeroIfEmpty,
  formatDateApi,
  mappingUT10012,
  onScrollToTop,
} from "../../../utils/util";
import serviceService from "../../../api/serviceService";
import { Api, MailOutlineOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { ConfirmModal } from "../Modal/confirmModal";
import { RenderDetail } from "../../../pages/OfferBundleDetail";
import { FunctionFillEmptyQualifier } from "../../../functions";
import { DropdownNewWithPlaceholder } from "../../radium-omnibus/src/fields/dropdownNew";
import { PBFEContent } from "../../Elements/Text/content";
import exportedObject from "../../../api";
import { cloneDeep } from "lodash";

export const OfferInformation = ({
  data,
  index,
  setData,
  account_table,
  isEdit,
  isOffer,
  accountTable,
  debitProfile,
  setDebitProfile,
  setErrorState,
  isNew,
  setDataDuplicate = () => {},
}) => {
  const [dataService, setDataService] = useState([]);
  useEffect(() => {
    if (data) {
      setDataService(data?.service);
    }
  }, [data]);

  if (!data) return "";

  return (
    <React.Fragment>
      <div className="grid grid-cols-5 gap-40px my-25px">
        <PBFEDatePicker
          originalData={dataService}
          isNew={isNew}
          isOffer={true}
          keyName="from_date"
          data={data}
          setData={setData}
          index={index}
          label={"Effective From"}
          isEdit={isEdit}
          disabled={data.checkCurrentDate}
          setErrorState={setErrorState}
          setDataDuplicate={setDataDuplicate}
          // primaryIndex={primaryIndex}
          // primaryField={primaryField}
        />
        <PBFEDatePicker
          originalData={dataService}
          isNew={isNew}
          isOffer={true}
          keyName="to_date"
          data={data}
          setData={setData}
          index={index}
          label="To"
          isEdit={isEdit}
          setErrorState={setErrorState}
          setDataDuplicate={setDataDuplicate}
          // primaryIndex={primaryIndex}
          // primaryField={primaryField}
        />
      </div>
      {(data?.posting_account[index] !== undefined ||
        data?.posting_account[index] !== null) && (
        <PBFEAccordionMain
          pl="ml-20px"
          label="Debit Profile"
          detail={
            <PBFEEditTable
              isNew={isNew}
              isEdit={isEdit}
              originRow={data}
              headCells={ACCOUNT_HEAD_CELLS}
              setRows={setData}
              rows={account_table}
              selectable={true}
              orderByDefault=""
              primaryIndex={index}
              primaryField="posting_account"
              accountTable={accountTable}
              // debitProfile={debitProfile}
              // setDebitProfile={setDebitProfile}
              // onClickRow={onClickRow}
              globalDebitProfile={debitProfile}
              setDebitProfileGlobal={setDebitProfile}
              setErrorState={setErrorState}
            />
          }
        />
      )}
      {dataService?.map((info, index) => {
        return (
          <PBFEAccordionMain
            pl="ml-20px"
            customHeader={
              <div>
                <PBFEHeader
                  p=""
                  label={info.service_name + " - " + info.service_code}
                  purple={true}
                  editable={false}
                  hideButton={true}
                />
              </div>
            }
            detail={
              <ServiceDetail
                key={`${info.service_code}-${index}`}
                isOffer={isOffer}
                primaryIndex={index}
                primaryField="fee_charge"
                origin={info}
                setData={setDataService}
                data={info.fee_charge}
                account_table={account_table}
                isEdit={false}
                setErrorState={setErrorState}
              />
            }
          />
        );
      })}
      {/* <AccountModal
        selectAccount={selectAccount}
        open={modal}
        title={"Edit Account"}
        action={editRow}
        setSelectAccount={setSelectAccount}
        textButton="Confirm"
        onClose={() => setModal(false)}
        accountTable={accountTable}
        debitProfile={debitProfile}
      /> */}
    </React.Fragment>
  );
};

export const ServiceInformation = ({
  data,
  index,
  setData,
  account_table,
  isEdit,
  accountTable,
  isNew,
  debitProfile,
  setDebitProfile,
  originBigData,
  setErrorState,
  setHasChages,
  hasChages,
  accountMaster = null,
}) => {
  const [dataGroup, setDataGroup] = React.useState([]);
  const [pricingOptions, setPricingOptions] = React.useState([]);

  useEffect(() => {
    if (data) {
      setDataGroup(fillZeroIfEmpty(data?.charge_group));
    }
  }, []);

  useEffect(() => {
    if (dataGroup.length > 0) {
      if (isNew) {
        setData((prev) => {
          prev[index].charge_group = [...dataGroup];
          return [...prev];
        });
      }
    }
  }, [dataGroup]);

  //RAY : LOAD OPTION ของ Currency จาก Account Master ID ข้างบน
  React.useEffect(() => {
    if (accountMaster?.account_id) {
      const LoadOptions = async () => {
        const {
          data: { data: arrayOptions },
        } = await exportedObject.instanceCorp.get(
          `/v1/accounts/${accountMaster?.account_id}/currencies`
        );

        if (arrayOptions?.length > 0) {
          setPricingOptions(arrayOptions.map((item) => item.currency_code));
          sessionStorage.setItem(
            "pricingOptions",
            JSON.stringify(arrayOptions)
          );
          // AUTO SET CURRENCY CODE

          const duplicateCcy = sessionStorage.getItem("duplicate-ccy");

          if (duplicateCcy) {
            setData((prev) => {
              if (isNew) {
                prev[index].ccy = arrayOptions?.find(
                  (item) => item.currency_code === duplicateCcy
                )?.currency_code;
                return [...prev];
              } else {
                return prev;
              }
            });
            sessionStorage.removeItem("duplicate-ccy");
          } else {
            setData((prev) => {
              if (isNew) {
                prev[index].ccy = arrayOptions[0].currency_code;
                return [...prev];
              } else {
                return prev;
              }
            });
          }
        }
      };

      LoadOptions();
    }
  }, [accountMaster?.account_id]);

  // const LoadOptions = async () => {
  //   const {
  //     data: { data: arrayOptions },
  //   } = await exportedObject.instanceCorp.get(
  //     `/v1/accounts/${debitProfile[0]?.posting_account_id}/currencies`
  //   );

  //   if (arrayOptions?.length > 0) {
  //     setPricingOptions(arrayOptions.map((item) => item.currency_code));
  //   }
  // };

  // LoadOptions();

  // React.useEffect(() => {
  //   //มีไว้เพื่อ clar value เมื่อ debit profile มีการเปลี่ยนแปลง เพราะ อาจจะเกิดปัญหา เปลี่ยน จาก USD ไปเป็น JPY แต่ ค่าใน debit profile ยังเป็น USD
  //   if (debitProfile?.length > 0) {
  //     if (isNew) {
  //       setData((prev) => {
  //         if (isNew) {
  //           prev[index].pricing_currency = "";
  //           return [...prev];
  //         } else {
  //           // prev.charge_currency = e.target.value;
  //           // console.log("prev: ", prev);
  //           // return { ...prev };
  //           return prev;
  //         }
  //       });
  //     }

  //     if (debitProfile[0]?.posting_account_id) {
  // const LoadOptions = async () => {
  //   const {
  //     data: { data: arrayOptions },
  //   } = await exportedObject.instanceCorp.get(
  //     `/v1/accounts/${debitProfile[0]?.posting_account_id}/currencies`
  //   );

  //   if (arrayOptions?.length > 0) {
  //     setPricingOptions(arrayOptions.map((item) => item.currency_code));
  //   }
  // };

  // LoadOptions();
  //       // const fiveDigit = String(debitProfile[0]?.posting_account_id).slice(
  //       //   2,
  //       //   5
  //       // );
  //       // if (fiveDigit === "840") {
  //       //   setPricingOptions(["USD", "THB"]);
  //       // } else if (fiveDigit === "392") {
  //       //   setPricingOptions(["JPY", "THB"]);
  //       // } else {
  //       //   setPricingOptions(["THB"]);
  //       // }
  //     }
  //   }
  // }, [debitProfile]);

  // console.log("DATA: ", {
  //   dataGroup,
  //   data,
  //   originBigData,
  //   debitProfile,
  //   accountTable,
  //   account_table,
  //   accountMaster,
  //   pricingOptions,
  // });

  return (
    <React.Fragment>
      <div className="w-[25%] p-4">
        {/* {(data?.fcy_flag === "Y" ||
          (originBigData?.ccy && originBigData?.ccy !== "THB") ||
          (isEdit && data?.ccy && data?.ccy !== "THB")) && ( */}
        {data?.fcy_flag === "Y" && (
          <React.Fragment>
            {isNew ? (
              <DropdownNewWithPlaceholder
                label={"Pricing & Debit Currency"}
                options={pricingOptions}
                onChange={(e) => {
                  if (debitProfile?.length > 0) {
                    const LoadPricingOptions = JSON.parse(
                      sessionStorage.getItem("pricingOptions")
                    );

                    const thisCurrency = LoadPricingOptions?.find(
                      (item) => item.currency_code === e.target.value
                    )?.numeric_code;

                    let first = debitProfile[0]?.posting_account_id?.slice(
                      2,
                      5
                    );

                    let checkIsTheSame = first === thisCurrency;

                    // console.log("ccy", {
                    //   thisCurrency,
                    //   checkIsTheSame,
                    //   debitProfile,
                    //   first,
                    // });
                    if (checkIsTheSame) {
                      setErrorState((prev) => {
                        let temp = cloneDeep(prev);
                        return [
                          ...temp.filter(
                            (item) => item.field_name !== "fcy_ccy"
                          ),
                        ];
                      });
                    }
                  }

                  setData((prev) => {
                    if (isNew) {
                      prev[index].ccy = e.target.value;
                      return [...prev];
                    } else {
                      // prev.charge_currency = e.target.value;
                      // console.log("prev: ", prev);
                      // return { ...prev };
                      return prev;
                    }
                  });
                }}
                value={data?.ccy} //::remark ไปเอา data มาใส่ด้วย
              />
            ) : (
              <PBFEContent
                label="Pricing & Debit Currency"
                value={data?.ccy ? data?.ccy : "-"}
              />
            )}
          </React.Fragment>
        )}
      </div>
      {(data?.posting_account[index] !== undefined ||
        data?.posting_account[index] !== null) && (
        <PBFEAccordionMain
          pl="ml-3px"
          label="Debit Profile"
          detail={
            <PBFEEditTable
              isEdit={isEdit}
              isNew={isNew}
              originRow={data}
              headCells={ACCOUNT_HEAD_CELLS}
              setRows={setData}
              rows={account_table}
              selectable={true}
              orderByDefault=""
              primaryIndex={index}
              primaryField="posting_account"
              accountTable={accountTable}
              // onClickRow={onClickRow}
              // debitProfile={debitProfile}
              // setDebitProfile={setDebitProfile}
              globalDebitProfile={debitProfile}
              setDebitProfileGlobal={setDebitProfile}
              setErrorState={setErrorState}
              accountMaster={accountMaster}
            />
          }
        />
      )}
      {dataGroup?.length > 0 &&
        dataGroup?.map((group, index) => {
          if (!group?.fee_charge?.length) return;

          if (group?.charge_code === "UT10012") {
            group.fee_charge = mappingUT10012(group.fee_charge);
          }

          return (
            <ServiceDetail
              key={`service-detail-${index}`}
              primaryIndex={index}
              primaryField="fee_charge"
              origin={data}
              isEdit={isEdit}
              setData={setDataGroup}
              data={group.fee_charge}
              account_table={account_table}
              accountTable={accountTable}
              charge_code={group.charge_code}
              charge_name={group.charge_name}
              isNew={isNew}
              isGroup={true}
              lenghGroup={group?.fee_charge?.length}
              originBigData={originBigData}
              setErrorState={setErrorState}
              group={group}
            />
          );
        })}
    </React.Fragment>
  );
};

export const BundleOfferInformation = ({
  data,
  index,
  setData,
  account_table,
  isEdit,
  accountTable,
  debitProfile,
  setDebitProfile,
  setErrorState,
  isNew,
  setDataDuplicate = () => {},
}) => {
  // const [dataService, setDataService] = useState([]);
  // useEffect(() => {
  //   if (!data) return;
  //   setDataService(data);
  // }, [data]);

  // console.log("dataService", dataService);

  return (
    <React.Fragment>
      <div className="grid grid-cols-5 gap-40px my-25px">
        <PBFEDatePicker
          originalData={data}
          isNew={isNew}
          isBundle={true}
          keyName="from_date"
          data={data}
          setData={setData}
          index={index}
          label={"Effective From"}
          isEdit={isEdit}
          disabled={data.checkCurrentDate}
          setErrorState={setErrorState}
          setDataDuplicate={setDataDuplicate}
          // primaryIndex={primaryIndex}
          // primaryField={primaryField}
        />
        <PBFEDatePicker
          originalData={data}
          isNew={isNew}
          isBundle={true}
          keyName="to_date"
          data={data}
          setData={setData}
          index={index}
          label="To"
          isEdit={isEdit}
          setErrorState={setErrorState}
          setDataDuplicate={setDataDuplicate}
          // primaryIndex={primaryIndex}
          // primaryField={primaryField}
        />
      </div>
      {(data?.posting_account[index] !== undefined ||
        data?.posting_account[index] !== null) && (
        <PBFEAccordionMain
          pl="ml-20px"
          label="Debit Profile"
          detail={
            <PBFEEditTable
              isNew={isNew}
              isEdit={isEdit}
              originRow={data}
              headCells={ACCOUNT_HEAD_CELLS}
              setRows={setData}
              rows={account_table}
              selectable={true}
              orderByDefault=""
              primaryIndex={index}
              primaryField="posting_account"
              accountTable={accountTable}
              // debitProfile={debitProfile}
              // setDebitProfile={setDebitProfile}
              // onClickRow={onClickRow}
              globalDebitProfile={debitProfile}
              setDebitProfileGlobal={setDebitProfile}
              setErrorState={setErrorState}
            />
          }
        />
      )}
      {data && <RenderDetail data={data} />}

      {/* {dataService?.map((info, index) => {
        return (
          <PBFEAccordionMain
            pl="ml-20px"
            customHeader={
              <div>
                <PBFEHeader
                  p=""
                  label={info.service_name + " - " + info.service_code}
                  purple={true}
                  editable={false}
                  hideButton={true}
                />
              </div>
            }
            detail={
              <ServiceDetail
                key={`${info.service_code}-${index}`}
                isOffer={false}
                primaryIndex={index}
                primaryField="fee_charge"
                origin={info}
                setData={setDataService}
                data={info.fee_charge}
                account_table={account_table}
                isEdit={false}
                setErrorState={setErrorState}
              />
            }
          />
        );
      })} */}
      {/* <AccountModal
        selectAccount={selectAccount}
        open={modal}
        title={"Edit Account"}
        action={editRow}
        setSelectAccount={setSelectAccount}
        textButton="Confirm"
        onClose={() => setModal(false)}
        accountTable={accountTable}
        debitProfile={debitProfile}
      /> */}
    </React.Fragment>
  );
};

export const Information = ({
  label,
  data,
  index,
  isOffer = false,
  setData,
  account_table,
  accountTable,
  isEdit = false,
  isNew = false,
  hideButton,
  setIsEdit,
  setIsEditWhereData,
  type,
  setDebitProfile,
  debitProfile,
  originBigData,
  setErrorState = () => {},
  setRouteName,
  customer,
  account,
  setCheckConfirmError,
  refetch = () => {},
  setDataRequest = () => {},
  isBundle = false,
  infoData = null,
}) => {
  const dispatch = useDispatch();

  const [dataCurrent, setDataCurrent] = useState([]);
  const [hasChages, setHasChages] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [isError, setIsError] = useState(false);

  const toogleEdit = async (e) => {
    e.stopPropagation();

    let pathname = "";
    let name = "";
    let id = "";

    if (account?.account_id) {
      pathname = "account";
      name = account?.account_name;
      id = account?.account_id;
    } else {
      pathname = "customer";
      name = customer?.customer_name;
      id = customer?.customer_id;
    }

    try {
      let queryString = "";
      let tran_type = "";
      let code = "";

      if (isOffer) {
        tran_type = "UPDATEOFFER";
        code = data.offer_code;
      } else if (isBundle) {
        tran_type = "UPDATEBUNDLEOFFER";
        code = data.offer_code;
      } else {
        tran_type = "UPDATESERVICE";
        code = data.service_code;
      }

      if (customer?.customer_id && account?.account_id) {
        queryString = `?customer_id=${customer.customer_id}&account_id=${account.account_id}&tran_type=${tran_type}&code=${code}`;
      } else {
        queryString = `?customer_id=${customer.customer_id}&tran_type=${tran_type}&code=${code}`;
      }

      const { data: responseData } =
        await makerCheckerService.getMakerCheckerValidatePending(
          {},
          queryString
        );

      const params = {
        customer_id: customer?.customer_id,
        account_id: account?.account_id,
      };

      if (responseData.data.pending === "N") {
        setErrorState((prev) => {
          let temp = [...prev];
          temp = temp.filter((item) => item.field_name !== "validate_pending");
          return [...temp];
        });

        setCheckConfirmError(true);
        if (isOffer) {
          setRouteName([
            {
              title: pathname,
              path: `/${pathname}`,
            },
            {
              title: `${name}`,
              path: `/${pathname}/${id}`,
              setSelected: setIsEdit,
              check: true,
              action: () => {
                setErrorState([]);
                refetch(params, {});
                setIsEditWhereData("");
              },
            },
            {
              title: "EDIT OFFER",
            },
          ]);
        } else if (isBundle) {
          setRouteName([
            {
              title: pathname,
              path: `/${pathname}`,
            },
            {
              title: `${name}`,
              path: `/${pathname}/${id}`,
              setSelected: setIsEdit,
              check: true,
              action: () => {
                setErrorState([]);
                refetch(params, {});
                setIsEditWhereData("");
              },
            },
            {
              title: "EDIT BUNDLED OFFER",
            },
          ]);
        } else {
          setRouteName([
            {
              title: pathname,
              path: `/${pathname}`,
            },
            {
              title: `${name}`,
              path: `/${pathname}/${id}`,
              setSelected: setIsEdit,
              check: true,
              action: () => {
                setErrorState([]);
                refetch(params, {});
                setIsEditWhereData("");
              },
            },
            {
              title: "EDIT SERVICE",
            },
          ]);
        }

        if (!isOffer && !isBundle) {
          const { data: dataService } = await serviceService.getServiceInfo({
            service_code: code,
          });
          let templateService = dataService.data;

          data.charge_group.forEach((curr) => {
            const foundChargeCode = templateService.fee_charge.findIndex(
              (item) => item.charge_code === curr.charge_code
            );

            if (foundChargeCode !== -1) {
              templateService.fee_charge?.splice(foundChargeCode, 1);
            }
          });
          const newGroup = templateService.fee_charge.map((charge) => {
            const newFeeCharge = {
              ...charge,
              from_date: formatDateApi(),
              to_date: "",
              isRemove: true,
              isTemplate: true,
            };

            delete newFeeCharge.charge_code;
            delete newFeeCharge.charge_name;

            return {
              charge_code: charge.charge_code,
              charge_name: charge.charge_name,
              fee_charge: [newFeeCharge],
            };
          });
          data.charge_group = [...data.charge_group, ...newGroup];
        }

        const originalData = JSON.parse(sessionStorage.getItem("originalData"));

        if (originalData.service) {
          const foundServiceIndex = originalData?.service?.findIndex((item) => {
            return (
              item.customer_fee_service_id === data.customer_fee_service_id
            );
          });
          originalData.service[foundServiceIndex] = data;
        }

        sessionStorage.setItem("originalData", JSON.stringify(originalData));

        const newData = FunctionFillEmptyQualifier(data);

        setIsEditWhereData(newData);
        setIsEdit(!isEdit);
        dispatch(setDisabledAction({ disabledAction: false }));
        setDataRequest({});
        onScrollToTop("edit");
        return;
      } else {
        setErrorState((prev) => {
          let temp = [...prev];
          temp = temp.filter((item) => item.field_name !== "validate_pending");

          return [
            ...temp,
            {
              field_name: "validate_pending",
              message: responseData.data.description,
              show: true,
            },
          ];
        });
        setCheckConfirmError(true);
        onScrollToTop("invalid");
      }
    } catch (error) {
      console.log("edit check error", error);
    }
  };

  const onRemoveService = (e) => {
    e.stopPropagation();
    setDataCurrent(data);
    setData((prev) => {
      prev[index] = {
        ...data,
        isRemove: true,
        charge_group: [],
        fee_charge: [],
        service: [],
        posting_account: [],
      };
      return [...prev];
    });
  };

  const onRemoveOffer = (e) => {
    e.stopPropagation();
    setDataCurrent(data);
    setData((prev) => {
      prev[index] = {
        ...data,
        isRemove: true,
        service: [],
        posting_account: [],
      };
      return [...prev];
    });
  };

  const onAdd = (e) => {
    e.stopPropagation();
    setData((prev) => {
      prev[index] = {
        ...dataCurrent,
      };
      return [...prev];
    });
  };

  const stopService = async (e) => {
    e.stopPropagation();
    try {
      setLoading(true);
      var ret;
      if (isOffer)
        ret = await serviceService.emailToStopService(
          null,
          data.customer_offer_id
        );
      else
        ret = await serviceService.emailToStopService(
          data.customer_fee_service_id,
          null
        );
      setIsSuccess(true);
      setIsError(false);
    } catch (e) {
      // console.log(e)
      setIsError(true);
      setErrorMsg(e.response.data.status.description);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment className="w-full">
      <ConfirmModal
        onClick={stopService}
        loading={loading}
        isSuccess={isSuccess}
        isError={isError}
        message={!isSuccess ? errorMsg : "ดำเนินการส่งอีเมล สำเร็จ"}
        open={showConfirm}
        confirmText="ส่ง mail"
        cancelText="ยกเลิก"
        successText="ปิด"
        onClose={() => {
          setShowConfirm(false);
          setIsSuccess(false);
        }}
        text={"ท่านต้องการส่ง Email เพื่อยกเลิกการใช้บริการนี้หรือไม่"}
      />
      <PBFEAccordionMain
        pl="pl-20px w-full"
        fullScreen={true}
        customHeader={
          <div className="flex flex-row justify-between space-x-20px w-full">
            <PBFEHeader
              p=""
              label={label}
              purple={true}
              editable={isEdit === true ? false : true}
              isRemove={data?.isRemove}
              hideButton={
                data?.is_maker === "N" || data?.charge_group?.length === 0
              }
              isOffer={isOffer}
              onRemove={type === "service" ? onRemoveService : onRemoveOffer}
              onPress={toogleEdit}
              onAdd={onAdd}
            />

            {!(
              isNew ||
              data?.is_maker === "N" ||
              data?.charge_group?.length === 0
            ) && (
              <Button
                variant="text"
                loadingPosition="start"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowConfirm(true);
                }}
                sx={{ fontWeight: "bold" }}
                startIcon={
                  <MailOutlineOutlined
                    style={{ fontSize: 24, fontWeight: "bold" }}
                  />
                }
              >
                Email แจ้งยกเลิกบริการ
              </Button>
            )}
          </div>
        }
        detail={
          isBundle ? (
            <BundleOfferInformation
              label={label}
              data={data}
              index={index}
              setData={setData}
              account_table={account_table}
              accountTable={accountTable}
              isEdit={isEdit}
              isNew={isNew}
              debitProfile={debitProfile}
              setDebitProfile={setDebitProfile}
              originBigData={originBigData}
              setErrorState={setErrorState}
              setHasChages={setHasChages}
              hasChages={hasChages}
            />
          ) : isOffer ? (
            <OfferInformation
              label={label}
              data={data}
              index={index}
              setData={setData}
              account_table={account_table}
              accountTable={accountTable}
              isEdit={isEdit}
              isNew={isNew}
              debitProfile={debitProfile}
              setDebitProfile={setDebitProfile}
              originBigData={originBigData}
              setErrorState={setErrorState}
              setHasChages={setHasChages}
              hasChages={hasChages}
            />
          ) : (
            <React.Fragment>
              {data?.charge_group?.length > 0 && (
                <ServiceInformation
                  label={label}
                  data={data}
                  index={index}
                  setData={setData}
                  account_table={account_table}
                  isEdit={isEdit}
                  accountTable={accountTable}
                  hideButton={hideButton}
                  isNew={isNew}
                  debitProfile={debitProfile}
                  setDebitProfile={setDebitProfile}
                  originBigData={originBigData}
                  setErrorState={setErrorState}
                  setHasChages={setHasChages}
                  hasChages={hasChages}
                  accountMaster={account}
                />
              )}
            </React.Fragment>
          )
        }
      />
    </React.Fragment>
  );
};
