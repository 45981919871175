import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import PBFESearchbar from "../Elements/Searchbar/searchbar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { SELECTION_WITDH, TABLE_STYLE_WITH_HOVER } from "../../constants/style";
import { TablePagination } from "@mui/material";
import { ROW_PER_PAGE } from "../../constants/constant";
import NoRecordFound from "../Error/noRecordFound";
import _ from "lodash";

function PBFESideTable({
  searchPlacholder = "Search Scheme Name",
  setInfo,
  labelKey,
  valueKey,
  requestSearch,
  rows,
  loading,
  searched,
  valueSuffix = "",
  page,
  setPage,
  setHistory,
  history,
  routeName,
}) {
  //const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedRow, selectRow] = useState(-1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const tabRowSelect = (row, index) => {
    if (history) {
      setHistory(false);
    }
    selectRow(index);
    setInfo(row);
  };

  return (
    <div
      style={SELECTION_WITDH}
      className="flex flex-col relative h-full w-320px "
    >
      <PBFESearchbar
        id="PBFESearchbar"
        border="border-b fixed bg-white w-320px "
        onChange={requestSearch}
        value={searched}
        placeholder={searchPlacholder}
      />
      <div className="mb-auto mt-50px">
        <TableContainer
          style={{
            maxHeight: "100%",
          }}
        >
          {loading ? (
            <Skeleton
              containerTestId="react-loading-skeleton"
              height={40}
              width={`100%`}
              count={3}
            />
          ) : rows?.length <= 0 && searched?.length >= 3 ? (
            <NoRecordFound />
          ) : (
            <Table
              className=""
              sx={TABLE_STYLE_WITH_HOVER}
              aria-label="custom pagination table"
            >
              <TableBody>
                {(ROW_PER_PAGE > 0
                  ? rows?.slice(
                      page * ROW_PER_PAGE,
                      page * ROW_PER_PAGE + ROW_PER_PAGE
                    )
                  : rows
                )?.map((row, index) => (
                  <TableRow
                    sx={TABLE_STYLE_WITH_HOVER}
                    id="table-row"
                    selected={index === selectedRow}
                    onClick={() => tabRowSelect(row, index)}
                    hover
                    key={index}
                  >
                    <TableCell
                      sx={TABLE_STYLE_WITH_HOVER}
                      component="th"
                      scope="row"
                      id="table-label"
                    >
                      <Column value={row[labelKey]} status={row.status} />
                    </TableCell>
                    <TableCell
                      id="table-value"
                      style={{ width: 20 }}
                      align="right"
                    >
                      {row[valueKey]}
                      {valueSuffix}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>
      {rows?.length > 0 && (
        <div className="bg-white  sticky bottom-0 right-0 border-t  z-20">
          <TablePagination
            id="PBFETablePagination"
            component="div"
            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
            showFirstButton={true}
            showLastButton={true}
            count={rows?.length}
            rowsPerPage={ROW_PER_PAGE}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[]}
          />
        </div>
      )}
    </div>
  );
}

const Column = ({ value, status }) => {
  if (status !== undefined)
    return (
      <div className="flex">
        <div
          className={
            "my-auto mr-5px w-10px h-10px rounded-full " +
            (_.lowerCase(status) === "active" ? "bg-green-500" : "bg-gray-400")
          }
        />
        {value}
      </div>
    );

  return <div>{value}</div>;
};
export default PBFESideTable;
