import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavLink } from "react-router-dom";
import { Checkbox, FormControlLabel } from "@mui/material";
import { PBFECommonTable } from "../../components/Table/commonTable";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
} from "../../components/Panel/Accordion/accordionMain";
import { PBFETaxInfo } from "../../components/Panel/TaxInfo/taxInfo";
import useQuery from "../../hooks/useQuery";
import taxSchemeService from "../../api/taxSchemeService";
import { TAX_HEAD_CELLS } from "../../constants/constant";
import {
  formatDateCheckValid,
  formateDate,
  formateDateWithOutNullValue,
  formatPercent,
  isValidDate,
} from "../../utils/util";
import PBFEError from "../../components/Error/alertError";
import { TaxCreate } from "./taxCreate";
import { HistoryModal } from "../../components/Panel/Modal/historyModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import historyService from "../../api/historyService";
import { cloneDeep } from "lodash";

export const TaxInfoMain = ({
  validateError,
  selectedTaxScheme,
  history,
  setHistory,
  isEdit = false,
  form,
  setForm,
  rates,
  setRates,
  deleteRates,
  setDeleteRates,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [showHistory, setShowHistory] = useState(false);
  const [loaded, setLoaded] = React.useState(false);

  // const [taxRates, setTaxRates] = useState([]);
  const { data, loading, error } = useQuery(
    taxSchemeService.getTaxSchemeInfo,
    {
      tax_scheme_id: selectedTaxScheme?.tax_scheme_id || selectedTaxScheme,
    },
    {},
    [selectedTaxScheme]
  );

  const callbackNavigate = (row) => {
    navigate(
      `/taxScheme/history/${data.tax_scheme_id}/${row.tax_scheme_history_id}`
    );
  };

  useEffect(() => {
    if (history) {
      setRates(data?.tax_rate_history);
    } else {
      /// un check history
      var temp = [];
      for (let i = 0; i < data?.tax_rate_history?.length; i++) {
        if (isValidDate(data?.tax_rate_history[i].end_date)) {
          const x = data?.tax_rate_history?.map((row) => {
            return {
              ...row,
              end_date:
                row?.end_date === "9999-12-31T00:00:00"
                  ? ""
                  : formatDateCheckValid(row.end_date),
              effective_date: formatDateCheckValid(row.effective_date),
              disabled:
                new Date(formatDateCheckValid(row.effective_date)) <
                new Date().setHours(0, 0, 0, 0),
            };
          })[i];
          temp.push(x);
        }
      }

      setRates(temp);
    }

    if (setForm) setForm(data);
    setShowHistory(false);
  }, [history, data]);

  useEffect(() => {
    if (isEdit) {
      var temp = [];
      for (let i = 0; i < data?.tax_rate_history?.length; i++) {
        if (isValidDate(data?.tax_rate_history[i].end_date)) {
          const x = data?.tax_rate_history?.map((row) => {
            return {
              ...row,
              end_date:
                row?.end_date === "9999-12-31T00:00:00"
                  ? ""
                  : formatDateCheckValid(row.end_date),
              effective_date: formatDateCheckValid(row.effective_date),
              disabled:
                new Date(formatDateCheckValid(row.effective_date)) <
                new Date().setHours(0, 0, 0, 0),
            };
          })[i];
          temp.push(x);
        }
      }

      setRates(cloneDeep(temp.sort((a, b) => a?.tax_rate_id - b?.tax_rate_id)));

      setLoaded(true);
    }
    return () => {
      setLoaded(false);
    };
  }, [isEdit]);

  // console.log("CHECK POINT: ", {
  //   error,
  //   loading,
  //   data,
  //   isEdit,
  //   form,
  //   rates,
  //   deleteRates,
  // });

  const checkRealEdit =
    typeof isEdit === "object" ? Object.keys(isEdit).length > 0 : isEdit;

  if (loading) return <PBFELoading />;

  if (error) return <PBFEError error={error} />;

  if (checkRealEdit && loaded)
    return (
      <TaxCreate
        deleteRates={deleteRates}
        setDeleteRates={setDeleteRates}
        rates={rates}
        setRates={setRates}
        isCreate={false}
        form={form}
        setForm={setForm}
        error={validateError}
      />
    );
  return (
    <div className="w-full overflow-auto ">
      {showHistory ? (
        <HistoryModal
          isTaxScheme={true}
          callbackNavigate={callbackNavigate}
          open={showHistory}
          onClose={() => setShowHistory(false)}
          serviceApi={historyService.getTaxSchemeHistory}
          query={{
            tax_scheme_id: data.tax_scheme_id,
            offset: 0,
            limit: 100,
          }}
        />
      ) : (
        <div className="pt-xxl"></div>
      )}
      <PBFETaxInfo
        id="PBFETaxInfo"
        data={data}
        type="Tax"
        setShowHistory={setShowHistory}
      />
      <div className="h-10px bg-gray-100"></div>

      <Accordion
        id="AccordionStyle"
        className="pl-large"
        defaultExpanded={true}
      >
        <div className="flex flex-row justify-between w-full">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className="text-value font-bold text-primarydark">Rates</div>
          </AccordionSummary>

          <div className="flex h-6 my-10px">
            <FormControlLabel
              control={
                <Checkbox
                  onClick={(e) => {
                    e.stopPropagation();
                    setHistory(!history);
                  }}
                />
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
              label="History"
              checked={history}
            />
          </div>
        </div>
        <AccordionDetails>
          <PBFECommonTable
            headCells={TAX_HEAD_CELLS}
            rows={rates}
            history={history}
            orderByDefault="effective_date"
            showFields={["tax_rate", "effective_date", "end_date"]}
            formatControl={[
              formatPercent,
              formateDate,
              formateDateWithOutNullValue,
            ]}
          />
        </AccordionDetails>
      </Accordion>
      <div className="h-10px bg-gray-100"></div>

      <Accordion className="pl-large" defaultExpanded={true}>
        <div className="flex flex-row justify-between w-full">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className="text-value font-bold text-primarydark">
              Assigned To
            </div>
          </AccordionSummary>
          <div />
        </div>
        <AccordionDetails>
          {data?.assign_service
            ?.sort((a, b) => {
              return a.service_name.toString().localeCompare(b.service_name);
            })
            ?.map((menu, index) => {
              return (
                <div className="mb-15px">
                  <NavLink
                    key={index}
                    className="cursor-pointer text-primarydark font-bold text-14px hover:text-hover"
                    to={`/service/${menu.service_code}`}
                  >
                    {menu.service_name}
                  </NavLink>
                </div>
              );
            })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
