import { cloneDeep } from "lodash";
import * as React from "react";
import { FunctionCore } from ".";
import { TYPE_FUNCTION } from "../type";
import { FieldAddStep, FieldDataToKeepThisStep } from "../utils/Field.AddStep";
import {
  TEXT_ADD_ARRAY,
  TEXT_AUTHOR,
  TEXT_DELETE_ARRAY,
} from "../utils/Field.Constant";
import {
  FieldDeleteStep,
  FunctionDeleteReferenceAfterDelete,
} from "../utils/Field.DeleteStep";
import {
  FieldDataToKeepThisEditStep,
  FieldEditStep,
} from "../utils/Field.EditStep";
import { FunctionGetValueFromFieldReference } from "../utils/Field.Submit";
export const CoreSelfStep = ({ props }) => {
  const [thisStep, setThisStep] = React.useState([props.fields]);
  const [keepData, setKeepData] = React.useState({});
  const [addData, setAddData] = React.useState(false);
  const [deleteIndex, setDeleteIndex] = React.useState(-1);
  const [editIndex, setEditIndex] = React.useState(-1);
  const [addIndex, setAddIndex] = React.useState(null);
  const [showZero, setShowZero] = React.useState(true);
  const [loadedZero, setLoadedZero] = React.useState(true);
  const [editData, setEditData] = React.useState(false);

  const thisLoadData = keepData[props.grandParentKey || props.parentKey]
    ? keepData
    : props.loadData;

  React.useEffect(() => {
    try {
      if (props.loadData) {
        if (
          props.grandParentIndex &&
          props.parentKey &&
          props.loadData[props.parentKey]?.length > 0 &&
          props.grandParentKey === props.parentKey
        ) {
          const create = new Array(
            props.loadData[props.parentKey]?.length
          ).fill(props.fields);
          setThisStep(create);
        } else if (
          props.grandParentIndex &&
          props.parentKey &&
          props.loadData[props.grandParentKey][props.parentIndex][
            props.parentKey
          ]?.length > 0 &&
          props.grandParentKey !== props.parentKey
        ) {
          const create = new Array(
            props.loadData[props.grandParentKey][props.parentIndex][
              props.parentKey
            ]?.length
          ).fill(props.fields);
          setThisStep(create);
        } else {
          return;
        }
      } else {
        return;
      }
    } catch (error) {
      console.error("ERROR TO ADD ARRAY :", error);
    }
  }, [props.loadData]);

  React.useEffect(() => {
    if (deleteIndex >= 0) {
      FunctionDeleteReferenceAfterDelete(props, deleteIndex, setDeleteIndex);
    } else {
      return;
    }
  }, [deleteIndex]);

  React.useEffect(() => {
    if (addIndex !== null && addData) {
      FieldDataToKeepThisStep(
        props,
        setKeepData,
        addData,
        addIndex,
        setAddIndex
      );
    } else {
      return;
    }
  }, [addIndex]);

  React.useEffect(() => {
    if (editIndex !== null && editData) {
      FieldDataToKeepThisEditStep(
        props,
        editIndex,
        setKeepData,
        setEditIndex,
        editData
      );
    } else {
      return;
    }
  }, [editIndex]);

  React.useEffect(() => {
    const isLoadZero =
      props.loadData[props.parentKey || props.grandParentKey] || {};
    const isRealZero = Object.keys(isLoadZero)?.length === 0;
    if (loadedZero) {
      if (isRealZero) {
        setShowZero(props.showIndexZero === false ? false : true);
      }
      if (props.showIndexZero === false && isRealZero) {
        setThisStep([]);
      }
      setLoadedZero(false);
    } else {
      return;
    }
  }, [props.showIndexZero]);

  React.useEffect(() => {
    if (thisStep.length === 0 && props.renderIndexZero) {
      setShowZero(false);
    }
  }, [thisStep]);

  const FunctionAddStep = (data, index = -1) => {
    FieldAddStep(props, setThisStep, index);
    if (data) {
      setAddData(data);
      setAddIndex(index);
    }
  };

  const FunctionEditStep = (data, index = -1) => {
    FieldEditStep(props, setThisStep, index);
    if (data) {
      setEditData(data);
      setEditIndex(index);
    }
  };

  if (thisStep.length > 0 && thisLoadData && showZero) {
    return (
      <React.Fragment>
        {thisStep?.map((step, index) => {
          return (
            <React.Fragment>
              <FunctionCore
                isLastStep={thisStep.length === index + 1}
                isFirstStep={index === 0}
                initalValue={props?.initalValue}
                language={props.language}
                mode={props.mode}
                fields={step}
                parentKey={props.parentKey}
                key={`key-core-step-${props.parentKey}`}
                FieldReference={props.FieldReference}
                parentIndex={props.parentIndex}
                fieldIndex={index}
                loadData={thisLoadData}
                grandParentKey={props.grandParentKey}
                grandParentIndex={props.grandParentIndex}
                showError={props.showError}
                arrayCount={thisStep?.length}
                currentData={FunctionGetValueFromFieldReference(
                  props.originalFields,
                  props.FieldReference
                )}
                FunctionAddStep={(data) => {
                  FunctionAddStep(data, index);
                }}
                FunctionRemoveStep={(callBackIndex = false) => {
                  FieldDeleteStep(
                    props,
                    callBackIndex !== false ? callBackIndex : index,
                    setThisStep,
                    setKeepData,
                    setDeleteIndex
                  );
                }}
                FunctionEditStep={(data, index) => {
                  FunctionEditStep(data, index);
                }}
              />
              {index > 0 && props.showButtonRemove && (
                <div
                  onClick={() => {
                    FieldDeleteStep(
                      props,
                      index,
                      setThisStep,
                      setKeepData,
                      setDeleteIndex
                    );
                  }}
                  className={`${TEXT_AUTHOR}-remove ${
                    props.classNameRemove ? props.classNameRemove : ""
                  }`}
                >
                  {props.removeLabel
                    ? props.removeLabel
                    : TEXT_DELETE_ARRAY[props.language]}
                </div>
              )}
            </React.Fragment>
          );
        })}
        {props.showButtonAdd && (
          <div
            className={`${TEXT_AUTHOR}-add ${
              typeof props.classNameAdd === TYPE_FUNCTION
                ? props.classNameAdd(props)
                : props.classNameAdd
            }`}
            onClick={() => {
              FunctionAddStep();
            }}
          >
            {props.addLabel ? props.addLabel : TEXT_ADD_ARRAY[props.language]}
          </div>
        )}
      </React.Fragment>
    );
  } else if (props.renderIndexZero && !showZero) {
    return (
      <React.Fragment>
        {props.renderIndexZero({
          add: (data) => {
            FunctionAddStep(data, -1);
            setShowZero(true);
          },
          isLast: true,
          isFirst: true,
        })}
      </React.Fragment>
    );
  } else {
    return (
      <div
        onClick={() => {
          FunctionAddStep("", -1);
        }}
      >
        ADD
      </div>
    );
  }
};
