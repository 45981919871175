import { TextField } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { modalConfig } from "../../../config";
import {
  postSendApproverCustomerService,
  setConfirmText,
  setStateComponent,
} from "../../../redux/actionAppbarSlice";
import {
  checkDuplicatePriority,
  checkDuplicateQualifer,
  checkErrorDate,
  checkFeeChargeAllDelete,
  getFeeChargeError,
  getFeeChargeOfGroup,
  onScrollToTop,
} from "../../../utils/util";
import _ from "lodash";
import { mappingServiceApprover } from "../../../utils/mappingData";
import NoFound from "../../../components/Error/noFound";
import { Information } from "../../../components/Panel/Information/information";
import { TYPE_ONE_TIME } from "../../../constants/constant";

const CustomerRenderService = ({
  dataRequest = {},
  data,
  accountTable = [],
  hideButton = false,
  isEdit = false,
  isNew = false,
  setIsEdit,
  setDebitProfile,
  debitProfile,
  setIsEditWhereData,
  customer,
  originalData,
  setErrorState,
  is_maker,
  setRouteName,
  errorState,
  checkConfirmError,
  setCheckConfirmError,
  refetch,
  setDataRequest = () => {},
  remark,
}) => {
  const [dataService, setDataService] = React.useState([]);
  // const [remark, setRemark] = React.useState("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    setDataService(data?.service);
    // setRemark(data?.remark || "");
  }, []);

  React.useEffect(() => {
    if (dataService.length > 0) {
      let fee_charge = getFeeChargeOfGroup(dataService[0].charge_group);
      const checkCharge = checkFeeChargeAllDelete(fee_charge);

      if (!checkCharge) {
        setErrorState((prev) => {
          let temp = [...prev];
          temp = temp.filter((item) => item.field_name !== "all_fee_charge");
          return [...temp];
        });
      }
    }
  }, [dataService]);

  React.useEffect(() => {
    const dontDoThis = !dataService?.length || !isNew || !isEdit;
    if (!dontDoThis) {
      dispatch(
        setStateComponent({
          stateComponent: "customerService",
          onClickAction: onClickSendToApprover,
          onClickBeforeAction,
        })
      );
      dispatch(setConfirmText(modalConfig.sendApproverConfirm.description));
    }
  }, [
    dataService,
    debitProfile,
    errorState,
    checkConfirmError,
    errorState,
    dataRequest,
    originalData,
  ]);

  const onClickBeforeAction = (setShowConfirm) => {
    const service = _.cloneDeep(dataService[0]);
    let checkError = false;

    // console.log("service", service);

    const checkErroDateList = checkErrorDate(service);
    if (checkDuplicatePriority(service, true)) {
      checkError = true;
      setErrorState((prev) => [
        {
          field_name: "priority_empty",
          show: true,
          message: "Priority cannot be empty ,  - or 0",
        },
      ]);
    } else {
      checkError = false;
      setErrorState((prev) => [
        ...prev.filter((item) => item.field_name !== "priority_empty"),
      ]);
    }

    const checkErrorDuplicate = checkDuplicatePriority(service);
    const checkListChargeError = getFeeChargeError(service);
    const checkErrorQualifer = checkDuplicateQualifer(service);

    // console.log("checkErrorDuplicate", checkErrorDuplicate);
    // console.log("checkListChargeError", checkListChargeError);
    // console.log("checkErrorQualifer", checkErrorQualifer);

    if (checkErrorQualifer.length > 0) {
      const unqiue_error_state = _.unionBy(checkErrorQualifer, "field_name");
      setErrorState((prev) =>
        _.unionBy([...unqiue_error_state, ...prev], "field_name")
      );
      checkError = true;
    } else {
      setErrorState((prev) => [
        ...prev.filter((item) => item.field_name !== "qualifer"),
      ]);
    }

    // if (service?.fee_charge?.length > 0) {
    //   const cloneFee = _.cloneDeep(service.fee_charge);
    //   cloneFee.forEach((item) => {
    //     if (
    //       item.fee_value.value.length > 0 &&
    //       item?.pricing_method === TYPE_ONE_TIME
    //     ) {
    //       const thisValue = item.fee_value.value;
    //       // console.log("X : ", item.fee_value.value);
    //       thisValue?.forEach((value) => {
    //         if (
    //           value?.fix_amount === 0 ||
    //           value?.fix_amount === "" ||
    //           value?.fix_amount === "0" ||
    //           value?.fix_amount === "-"
    //         ) {
    //           checkListChargeError.push(true);
    //           // setErrorState((prev) => {
    //           //   const find_exists = prev.find(
    //           //     (item) => item.field_name === "fix_amount"
    //           //   );
    //           //   if (!find_exists) {
    //           //     prev.push({
    //           //       error: true,
    //           //       message: "Fix amount cannot be empty or 0",
    //           //       field_name: "fix_amount",
    //           //       field_value: [],
    //           //       show: true,
    //           //     });
    //           //   }
    //           //   return [...prev];
    //           // });
    //           // checkError = true;
    //         }
    //       });
    //     }
    //   });
    // }

    if (checkListChargeError.length > 0) {
      checkError = true;
      setErrorState((prev) => [
        ...prev,
        { field_name: "validate_error", show: false },
      ]);
    } else {
      setErrorState((prev) => [
        ...prev.filter((item) => item.field_name !== "validate_error"),
      ]);
    }

    if (checkErroDateList.length > 0) {
      const unqiue_error_state = _.unionBy(checkErroDateList, "field_name");
      setErrorState((prev) =>
        _.unionBy([...prev, ...unqiue_error_state], "field_name")
      );
      checkError = true;
    } else {
      setErrorState((prev) => {
        return prev.filter((item) => {
          return item.field_name !== "overlap_date";
        });
      });
    }

    if (checkErrorDuplicate.length > 0) {
      const unqiue_error_state = _.unionBy(checkErrorDuplicate, "field_name");
      setErrorState((prev) =>
        _.unionBy([...unqiue_error_state, ...prev], "field_name")
      );
      checkError = true;
    } else {
      setErrorState((prev) => {
        return prev.filter((item) => {
          return item.field_name !== "priority";
        });
      });
    }

    let fee_charge = getFeeChargeOfGroup(dataService[0].charge_group);
    const checkCharge = checkFeeChargeAllDelete(fee_charge);

    if (checkCharge) {
      setErrorState((prev) => {
        let temp = _.unionBy(...prev, "field_name");
        return [
          ...temp,
          {
            error: true,
            message: "Please add fee charge at least 1 charge",
            field_name: "all_fee_charge",
            field_value: [],
            show: true,
          },
        ];
      });
      checkError = true;
    }

    if (
      !debitProfile.filter((profile) => profile?.action !== "DELETE").length
    ) {
      setErrorState((prev) => {
        const find_exists = prev.find(
          (item) => item.field_name === "posting_account"
        );
        if (!find_exists) {
          prev.push({
            error: true,
            message: "Please select debit profile",
            field_name: "posting_account",
            field_value: [],
            show: true,
          });
        }
        return [...prev];
      });
      checkError = true;
    }

    // const findErrorInDom = document?.querySelectorAll(".Mui-error");

    // //  Mui-error
    // //   MuiFormHelperText-contained

    // if (findErrorInDom?.length > 0) {
    //   findErrorInDom.forEach((item) => {
    //     const name = String(item.id).split("-")[1];
    //     const errorMessage = item.innerText;
    //     setErrorState((prev) => {
    //       const find_exists = prev.find((item) => item.field_name === name);
    //       if (!find_exists) {
    //         prev.push({
    //           error: true,
    //           message: errorMessage,
    //           field_name: name,
    //           field_value: [],
    //           show: true,
    //         });
    //       }
    //       return [...prev];
    //     });
    //   });
    // }
    if (
      errorState.filter((item) => {
        return ![
          "overlap_date",
          "validate_error",
          "priority",
          "qualifer",
        ].includes(item.field_name);
      }).length > 0
    ) {
      checkError = true;
    }

    if (checkError) {
      setCheckConfirmError(true);
      onScrollToTop("invalid");
    } else {
      setShowConfirm(true);
    }
  };

  const onClickSendToApprover = () => {
    dispatch(
      postSendApproverCustomerService({
        data: mappingServiceApprover(
          {
            ...customer,
            service_name: dataService[0].service_name,
            service_code: dataService[0].service_code,
            posting_account: debitProfile,
            charge_group: dataService[0].charge_group,
          },
          originalData,
          dataRequest,
          remark
        ),
      })
    );
  };

  if (!data?.service?.length) return <NoFound label={"No Service Found"} />;

  return (
    <div>
      {dataService?.map((sevice_self, index) => {
        return (
          <Information
            setDataRequest={setDataRequest}
            refetch={refetch}
            customer={customer}
            setRouteName={setRouteName}
            is_maker={is_maker}
            originBigData={sevice_self}
            setIsEdit={setIsEdit}
            key={index}
            origin={dataService}
            index={index}
            account_table={sevice_self.posting_account}
            label={sevice_self.service_name + " - " + sevice_self.service_code}
            data={sevice_self}
            setData={setDataService}
            accountTable={accountTable}
            hideButton={hideButton}
            isEdit={isEdit}
            isNew={isNew}
            setIsEditWhereData={setIsEditWhereData}
            type="service"
            setDebitProfile={setDebitProfile}
            debitProfile={debitProfile}
            setErrorState={setErrorState}
            setCheckConfirmError={setCheckConfirmError}
          />
        );
      })}
      {/* {(isEdit || isNew) && (
        <div className="ml-40px mr-25px">
          <TextField
            id="remark"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            label="REMARK"
            variant="filled"
            helperText={"Limited " + remark?.length + "/300"}
            fullWidth
            inputProps={{ maxLength: 300 }}
          />
        </div>
      )} */}
    </div>
  );
};

export default CustomerRenderService;
