import {
  TYPE_BUTTON,
  TYPE_ERROR_ARRAY,
  TYPE_ERROR_NORMAL,
  TYPE_NONE,
} from "../type";
import { FunctionCheckIsError } from "./Field.CheckError";

export const FunctionGetValueFromFieldReference = (fields, FieldReference) => {
  let new_form = {};

  fields.forEach((k, i) => {
    if (k.key) {
      const value = FieldReference.current[i];
      const selfValue = value[k.key];
      new_form[k.key] = selfValue;
    } else {
      const value = FieldReference.current[i];
      if (k.name) {
        new_form[k.name] = value;
      }
    }
  });

  return new_form;
};

export const FunctionSubmit = (
  fields,
  FieldReference,
  onSubmit,
  renderAlertError,
  error,
  FunctionReset,
  resetAfterSubmit
) => {
  if (fields && FieldReference && onSubmit) {
    let new_form = {};

    fields.forEach((k, i) => {
      if (k.key) {
        const value = FieldReference.current[i];
        let selfValue = value[k.key];
        selfValue?.map((xin) => {
          const xkeys = Object.keys(xin);
          xkeys.forEach((xk) => {
            if (
              xin[xk] === undefined ||
              xin[xk] === null ||
              xin[xk] === "" ||
              xin[xk] === "undefined"
            ) {
              delete xin[xk];
            }
          });
        });

        new_form[k.key] = selfValue;
      } else if (
        k?.name &&
        k.name !== "undefined" &&
        k?.type !== TYPE_NONE &&
        k?.type !== TYPE_BUTTON &&
        k.name !== undefined
      ) {
        const value = FieldReference.current[i];
        new_form[k.name] = value;
      }
    });

    const isError = FunctionCheckIsError(new_form, error);

    if (isError?.error && error === TYPE_ERROR_NORMAL) {
      if (renderAlertError) {
        renderAlertError({
          message: isError.message,
          name: isError.name,
          error: isError,
        });
        return;
      } else {
        alert(`name: ${isError.name} - error : ${isError.message}`);
        return;
      }
    }

    if (isError?.length > 0 && error === TYPE_ERROR_ARRAY) {
      if (renderAlertError) {
        renderAlertError({ error: isError });
        return;
      } else {
        alert(
          `${isError?.map((e) => {
            return `name : ${e.name} - error : ${e.message}`;
          })}`
        );
        return;
      }
    }

    onSubmit(new_form);

    if (resetAfterSubmit) {
      FunctionReset();
    }
  } else {
    return;
  }
};
