import api from "./index";
import { apiConfig } from "../config";

const getUserService = async (params, query, headers = {}) => {
  return api.instanceCorp.get(apiConfig.getUserService, { headers });
};

const getUserCharge = async (params, query, headers = {}) => {
  return api.instanceCorp.get(apiConfig.getUserCharges, { headers });
};

const getUserMaker= async (params, query, headers = {}) => {
  return api.instanceCorp.get(apiConfig.getUserMaker, { headers });
};

const getUserChecker = async (params, query, headers = {}) => {
  return api.instanceCorp.get(apiConfig.getUserChecker, { headers });
};

const exportedObject = {
  getUserService,
  getUserCharge,
  getUserMaker,
  getUserChecker
};

export default exportedObject;
