import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import React, { memo, useEffect, useState } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import PBFESearchbar from "../../Elements/Searchbar/searchbar";
import RemoveIcon from "../../../assets/icon/remove.svg";
import PlusIcon from "../../../assets/icon/plus.svg";

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    border: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const PBFEAccordionMain = ({
  id = "AccordionMain",
  label,
  pl = "pl-large pb-xxs pt-xxs",
  toogleStatus = false,
  hasToogle = false,
  toogleLabel = "",
  detail = <div></div>,
  customHeader = null,
  isEdit = false,
  requestSearch,
  searched,
  placeholderSearch,
  onClick,
  defaultChecked,
  isRemove = false,
  isAdd = false,
  isRefresh = false,
  disabled = false,
  onClickRemove,
  onClickAdd,
  showLabel,
  defaultExpanded = true,
  refreshText = "Refresh",
  headerDetail = false,
  fullScreen = false,
  wfull = "w-full",
  footer = "",
}) => {
  return (
    <Accordion id={id} defaultExpanded={defaultExpanded} className={pl}>
      <div className="flex flex-row justify-between  ">
        <AccordionSummary
          sx={pl !== "pl-large pb-xxs pt-xxs" ? { padding: 0 } : {}}
          expandIcon={<ExpandMoreIcon />}
          className={fullScreen ? wfull : ""}
        >
          <div
            id="AccordionLable"
            className="text-value w-full font-bold text-primarydark flex"
          >
            {customHeader === null ? label : customHeader}
            {isRemove && (
              <div
                className="flex ml-8 text-14px items-center cursor-pointer text-textscb"
                onClick={onClickRemove}
              >
                <img src={RemoveIcon} className="mr-10px" alt="header_image" />
                Remove
              </div>
            )}
            {isRefresh && (
              <div
                className="flex ml-8 text-10px items-center cursor-pointer bg-purpleLight rounded-full p-1 px-2"
                onClick={onClick}
              >
                {refreshText}
              </div>
            )}
            {isAdd && (
              <div
                className="flex ml-8 text-14px items-center cursor-pointer text-textscb"
                onClick={onClickAdd}
              >
                <img src={PlusIcon} className="mr-10px" alt="header_image" />
                Add
              </div>
            )}
          </div>
        </AccordionSummary>
        {/* {fullScreen&&
        <div> */}
        <div
          onClick={(e) => e.stopPropagation()}
          className={
            "flex h-6  w-72 justify-end" + (hasToogle ? "" : " hidden")
          }
        >
          {isEdit ? (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={toogleStatus}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (onClick) {
                        onClick(e);
                      }
                    }}
                  />
                }
                label={toogleLabel}
              />
            </FormGroup>
          ) : (
            showLabel && (
              <React.Fragment>
                <div className="mr-15px text-textprimary text-value mt-10px">
                  {toogleLabel}
                </div>
              </React.Fragment>
            )
          )}
        </div>
        {/* </div>}  */}
        {requestSearch && (
          <div className="flex justify-end w-full mt-50px pt-md">
            <PBFESearchbar
              placeholder={placeholderSearch}
              onChange={requestSearch}
              value={searched}
            />
          </div>
        )}
      </div>
      {headerDetail ? headerDetail : <React.Fragment />}
      <AccordionDetails sx={{ paddingTop: 0 }}>{detail}</AccordionDetails>
      {footer}
    </Accordion>
  );
};

export const PBFEAccordionWithButton = memo(
  ({
    id = "AccordionMain",
    label,
    pl = "pl-large pb-xxs pt-xxs",
    detail = <div></div>,
    customHeader = null,
    buttons = [],
  }) => {
    return (
      <Accordion id={id} defaultExpanded={true} className={pl}>
        <div className="flex flex-row justify-between w-full">
          <AccordionSummary
            sx={pl !== "pl-large pb-xxs pt-xxs" ? { padding: 0 } : {}}
            expandIcon={<ExpandMoreIcon />}
          >
            <div
              id="AccordionLable"
              className="text-value font-bold text-primarydark"
            >
              {customHeader == null ? label : customHeader}
            </div>
          </AccordionSummary>
          <div className={"flex h-6 space-x-4 mr-15px mt-15px"}>
            {buttons.map((button, index) => {
              return (
                <button
                  key={index}
                  onClick={button?.action}
                  className="flex text-textscb cursor-pointer"
                >
                  <img alt="" className="w-15px h-15px" src={button?.img}></img>
                  <div className="ml-10px text-textscb text-10px">
                    {button.name}
                  </div>
                </button>
              );
            })}
          </div>
        </div>

        <AccordionDetails sx={{ paddingTop: 0 }}>{detail}</AccordionDetails>
      </Accordion>
    );
  }
);

export const PBFEAccordionSearch = memo(
  ({
    id = "AccordionMain",
    label,
    pl = "pl-large pb-xxs pt-xxs",
    detail = <div></div>,
    customHeader = null,
    requestSearch,
    searched,
    placeholderSearch,
    button,
    expanded,
    onchangeAccordion,
    elementFrontOfSearch = null,
  }) => {
    const [o, setO] = useState(true);

    useEffect(() => {
      if (expanded !== undefined && expanded !== null) {
        setO(expanded);
      }
    }, [expanded]);

    return (
      <Accordion
        expanded={o}
        onChange={
          onchangeAccordion ? () => onchangeAccordion() : () => setO(!o)
        }
        id={id}
        // defaultExpanded={true}
        className={pl}
      >
        <div className="flex justify-between w-full">
          <div className="flex flex-row">
            <AccordionSummary
              sx={pl !== "pl-large pb-xxs pt-xxs" ? { padding: 0 } : {}}
              expandIcon={<ExpandMoreIcon />}
            >
              <div className="flex flex-row justify-between w-full">
                <div
                  id="AccordionLable"
                  className="text-value font-bold text-primarydark"
                >
                  {customHeader == null ? label : customHeader}
                </div>
              </div>
            </AccordionSummary>
            {button && (
              <button
                onClick={button?.action}
                className="flex text-textscb my-auto ml-4 cursor-pointer"
              >
                <img alt="" className="w-15px h-15px" src={button?.img}></img>
                <div className="ml-10px text-textscb text-10px">
                  {button.name}
                </div>
              </button>
            )}
          </div>

          {requestSearch && (
            <div className="flex">
              {elementFrontOfSearch && elementFrontOfSearch}

              <PBFESearchbar
                placeholder={placeholderSearch}
                onChange={requestSearch}
                value={searched}
              />
            </div>
          )}
        </div>

        <AccordionDetails sx={{ paddingTop: 0 }}>{detail}</AccordionDetails>
      </Accordion>
    );
  }
);
