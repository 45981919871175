import { Autocomplete, TextField } from "@mui/material";

const defaultOption = [
  { label: "Transactions Volume", id: "Transactions Volume" },
  { label: "Subcription", id: "Subcription" },
];

export const PBFEAutocomplete = ({
  options = defaultOption,
  label,
  isEdit = true,
  value,
  setValue,
  auto = true,
  disableCloseOnSelect = true,
}) => {
  return (
    <div>
      <Autocomplete
        options={options}
        id="pbfe-auto-complete"
        disableCloseOnSelect={disableCloseOnSelect}
        onChange={(e) => setValue(e.target.textContent)}
        disabled={!isEdit}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { color: "#7A58BF", fontSize: 16 },
            }}
            label={label}
            variant="standard"
          />
        )}
      />
    </div>
  );

  if (auto) {
    return (
      <Autocomplete
        // disablePortal
        id=""
        options={options}
        clearOnBlur={false}
        // value={value}
        // onChange={(e) => setValue(e.target.textContent)}
        disabled={!isEdit}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { color: "#7A58BF", fontSize: 16 },
            }}
            variant="standard"
            label={label}
            error={false}
          />
        )}
      />
    );
  } else {
    return (
      <Autocomplete
        disablePortal
        id=""
        options={options}
        defaultValue={""}
        value={""}
        onChange={(e) => setValue(e.target.textContent)}
        disabled={!isEdit}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { color: "#7A58BF", fontSize: 16 },
            }}
            variant="standard"
            label={label}
            error={false}
          />
        )}
      />
    );
  }
};
