import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import offersBundleService from "../../api/offersBundleService";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import { PBFEContent } from "../../components/Elements/Text/content";
import PBFEError from "../../components/Error/alertError";
import { Controllers } from "../../components/radium-omnibus/src"; // V.1.9.6 for only scb
import { PBFEFeeTable } from "../../components/Table/feeTable";
import { ADD_SERVICE_TABLE_HEADER, MAX_VALUE } from "../../constants/constant";
import {
  GRAY_100,
  GRAY_BORDER_INSIDE,
  TABLE_STYLE,
  WHITE_BORDER_INSIDE,
} from "../../constants/style";
import useQuery from "../../hooks/useQuery";
import { formatDateApi, formatDateCheckValid } from "../../utils/util";
import { PBFERenderHeaderChargeFromService } from "../WaitingForApproval/approvalDetail";

import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Plus from "../../assets/icon/plus.svg";

import _, { cloneDeep } from "lodash";
import {
  checkFromToDate,
  FunctionCheckDataChangeBundleOffer,
  FunctionCheckDateLength,
  FunctionCheckDatIsPast,
  FunctionCheckFormatStringBeforeArray,
  FunctionRenameKeyError,
} from "../../functions";
import { InputForTable } from "../../components/radium-omnibus/src/fields/inputForTable";
import DeleteIcon from "../../assets/icon/delete.svg";
import DropdownNew, {
  DropdownObject,
} from "../../components/radium-omnibus/src/fields/dropdownNew";
import api from "../../api";
import {
  FunctionDeleteErrorSameName,
  FunctionGetObjectIsError,
  TopperError,
} from "../../components/radium-omnibus/src/utils/Field.CheckError";
import { RenderTable } from "../ServiceDetail";
import { AsyncModal } from "../../components/Panel/Modal/asyncModal";
import serviceApi from "../../api/serviceService";
import { apiConfig } from "../../config";
import { MetaData } from "../OfferDetail";
import FieldText from "../../components/radium-omnibus/src/fields/FieldText";
import { ConfirmModal } from "../../components/Panel/Modal/confirmModal";
import { ERROR_MESSAGE_REQUIRED } from "../../utils/errorMessage";
import {
  FunctionCheckBooleanNumber,
  FunctionGetErrorFromDomByRay,
} from "../ServiceDetail/functions/SD.OnSubmit";

// export const MetaData = ({ data }) => {
//   return (
//     <div id="MetaData" className="px-xl py-lg ">
//       <div className="flex">
//         <div className="w-full">
//           <PBFEContent label="Name" value={data?.offer_name} />
//         </div>
//         <div className="w-content">
//           <PBFEContent label="Code" value={data?.offer_code} />
//         </div>
//       </div>
//       <div className="grid grid-cols-3 mt-25px">
//         <PBFEContent label="From" value={formateDate(data?.from_date)} />
//         <PBFEContent label="To" value={formateDate(data?.to_date)} />
//       </div>
//     </div>
//   );
// };

const RenderAddService = ({ setValue, value }) => {
  const [open, setOpen] = React.useState(false);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [radioService, setRadioService] = React.useState(null);
  const [loadingAction, setLoadingAction] = React.useState(false);
  const [selected, setSelected] = React.useState(false);

  React.useEffect(() => {
    if (radioService && selected) {
      LoadService();
      setSelected(false);
      setRadioService(false);
    }
    return;
  }, [selected]);

  const LoadService = async () => {
    const { data } = await api.instanceCorp.get(
      `${apiConfig.getServiceAPI}/${radioService}`
    );

    const thisData = data?.data;
    const thisDataNotDuplicate = {
      ...thisData,
      fee_charge: thisData?.fee_charge?.filter((fee) => {
        const findSelfIndex = value?.findIndex((feeCharge) => {
          return feeCharge?.charge_code === fee?.charge_code;
        });
        return findSelfIndex === -1;
      }),
    };

    if (thisDataNotDuplicate?.fee_charge?.length > 0) {
      let prePairData = [];
      thisDataNotDuplicate?.fee_charge?.forEach((fee) => {
        if (fee?.qualifiers?.channel?.length > 0) {
          prePairData.push({
            service_code: thisDataNotDuplicate?.service_code,
            charge_code: fee?.charge_code,
            qualifiers_string:
              fee?.qualifiers?.channel?.length > 0
                ? JSON.stringify(fee?.qualifiers?.channel)
                    .replace("[", "")
                    .replace("]", "")
                : "",
            // qualifiers_string: fee?.qualifiers?.channel?.join(","),
          });
        } else {
          prePairData.push({
            service_code: thisDataNotDuplicate?.service_code,
            charge_code: fee?.charge_code,
            qualifiers_string: "",
          });
        }
      });

      setValue((prev) => {
        let temp = [...prev];

        return [...temp, ...prePairData];
      });
    }
  };

  const callbackFilterService = (value) => (row) => {
    return (
      row.service_name.toLowerCase().includes(value.toLowerCase()) ||
      row.service_code.toLowerCase().includes(value.toLowerCase())
    );
  };

  return (
    <React.Fragment>
      <div className="w-full flex justify-end px-10px text-textscb text-10px py-10px">
        <div
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
          className="flex"
        >
          <div>Service </div>
          <img className="ml-10px h-15px w-15px cursor-pointer" src={Plus} />
        </div>
      </div>
      {open && (
        <AsyncModal
          setErrorModalMessage={setErrorModalMessage}
          errorModalMessage={errorModalMessage}
          // customer_id={params.customer_id}
          title="Add Service"
          open={open}
          onClose={() => setOpen(false)}
          radioValue={radioService}
          setRadio={setRadioService}
          serviceApi={serviceApi.getService}
          callBackData={(callBackData) => {
            return callBackData?.filter((d) => {
              return d?.fcy_flag === "N";
            });
          }}
          // params={{
          //   customer_id: params.customer_id,
          // }}
          // query={{
          //   customer_id: params.customer_id,
          //   user_id: user?.info?.preferred_username,
          // }}
          placeholder="Search Service Name / Code"
          option_field_label="service_name"
          option_field_value="service_code"
          orderByDefault="service_name"
          action={(e) => {
            setSelected(true);
            setOpen(false);
          }}
          loadingAction={loadingAction}
          head_cells={ADD_SERVICE_TABLE_HEADER}
          showFields={["service_name", "service_code"]}
          callbackFilter={callbackFilterService}
          // isDom={true}
          // refId={"input-service_code"}
        />
      )}
    </React.Fragment>
  );
};

const onChangeServiceTable = (setValue, value, index, name) => {
  setValue((prev) => {
    let temp = [...prev];
    temp[index][name] = value;
    return [...temp];
  });
};

const RenderTableService = ({ value, setValue, isEdit }) => {
  return (
    <div
      className={`${
        isEdit ? "" : "px-xl"
      } border-t border-b pb-fourteen w-full overflow-x-auto`}
    >
      <TableContainer>
        <Table sx={TABLE_STYLE}>
          <TableHead>
            <TableRow>
              <TableCell>Service Code</TableCell>
              <TableCell>Charge Code</TableCell>
              <TableCell>Channels</TableCell>
              {isEdit && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          {value?.length > 0 &&
            value?.map((item, index) => {
              return (
                <TableBody key={`key-of-new-table-${index}`}>
                  <TableRow sx={{ backgroundColor: GRAY_100 }}>
                    <TableCell
                      sx={{
                        width: "60px",
                        borderBottom: GRAY_BORDER_INSIDE,
                        borderTop: WHITE_BORDER_INSIDE,
                      }}
                    >
                      <InputForTable
                        name="service_code"
                        // disabled={!isEdit}
                        disabled={true}
                        onChange={(e) => {
                          onChangeServiceTable(
                            setValue,
                            e.target.value,
                            index,
                            "service_code"
                          );
                        }}
                        value={item?.service_code}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "50px",
                        borderBottom: GRAY_BORDER_INSIDE,
                        borderTop: WHITE_BORDER_INSIDE,
                      }}
                    >
                      <InputForTable
                        name="charge_code"
                        // disabled={!isEdit}
                        disabled={true}
                        onChange={(e) => {
                          onChangeServiceTable(
                            setValue,
                            e.target.value,
                            index,
                            "charge_code"
                          );
                        }}
                        value={item?.charge_code}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "500px",
                        borderBottom: GRAY_BORDER_INSIDE,
                        borderTop: WHITE_BORDER_INSIDE,
                      }}
                    >
                      <InputForTable
                        fullWidth={true}
                        name="qualifiers_string"
                        disabled={!isEdit}
                        onChange={(e) => {
                          onChangeServiceTable(
                            setValue,
                            e.target.value,
                            index,
                            "qualifiers_string"
                          );
                        }}
                        value={item?.qualifiers_string}
                      />
                    </TableCell>
                    {isEdit && (
                      <TableCell
                        sx={{
                          width: "20px",
                          borderBottom: GRAY_BORDER_INSIDE,
                          borderTop: WHITE_BORDER_INSIDE,
                        }}
                      >
                        <img
                          onClick={() => {
                            setValue((prev) => {
                              let temp = [...prev];
                              temp.splice(index, 1);
                              return [...temp];
                            });
                          }}
                          className=" h-15px w-15px cursor-pointer flex ml-auto mr-10px"
                          src={DeleteIcon}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              );
            })}
          {isEdit && (
            <TableRow sx={{ backgroundColor: GRAY_100 }}>
              <TableCell
                sx={{
                  height: "1px",
                  padding: 0,
                  borderBottom: GRAY_BORDER_INSIDE,
                  borderTop: WHITE_BORDER_INSIDE,
                }}
                colSpan={MAX_VALUE}
              >
                <RenderAddService setValue={setValue} value={value} />
              </TableCell>
            </TableRow>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

const RenderControllerMemo = memo(
  ({
    isCreate,
    data,
    setError,
    setErrorOpen,
    setSubmitData,
    setShowConfirm,
  }) => {
    return (
      <div className="grid grid-cols-8 space-x-4 px-10 space-y-5 mt-10">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controllers
            language="EN"
            showError={false}
            showSymbol={false}
            loadData={
              isCreate
                ? {
                    // monthly_price_day: 1,
                    // pricing_method: "TRANSACTION_VOLUME",
                    // pricing_frequency: "MONTHLY",
                  }
                : {
                    ...data,
                    remark: "",
                    from_date_to_date: {
                      from_date: formatDateCheckValid(
                        new Date(data?.from_date)
                      ),
                      to_date: formatDateCheckValid(new Date(data?.to_date)),
                    },
                    tran_conditions_prepair: data?.tran_conditions?.map(
                      (item) => {
                        return {
                          ...item,
                          qualifiers_string:
                            item?.qualifiers?.channel?.length > 0 &&
                            item?.qualifiers?.channel[0] !== ""
                              ? JSON.stringify(item?.qualifiers?.channel)
                                  .replace("[", "")
                                  .replace("]", "")
                              : "",
                          // item?.qualifiers?.channel?.join(","),
                        };
                      }
                    ),
                  }
            }
            fields={[
              {
                classNameCore: "col-span-4 mt-5",
                name: "offer_name",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <div className="ml-4">
                      <FieldText
                        lable={"Name"}
                        name={name}
                        value={value}
                        setValue={setValue}
                        limit={100}
                        thisValue={thisValue}
                        fullWidth={true}
                      />
                    </div>
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-2",
                type: "none",
                render: () => {
                  return <div></div>;
                },
              },
              {
                classNameCore: "col-span-2",
                name: "offer_code",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  if (isCreate) {
                    return (
                      <FieldText
                        lable={"Code"}
                        name={name}
                        value={value}
                        setValue={setValue}
                        limit={40}
                        thisValue={thisValue}
                        fullWidth={true}
                      />
                    );
                  } else {
                    return <PBFEContent label="Offer Code" value={value} />;
                  }
                },
                required: true,
                no_space: true,
              },

              {
                classNameCore: "col-span-4 ",
                name: "from_date_to_date",
                initalValue: {
                  from_date: formatDateCheckValid(new Date()),
                  to_date: formatDateCheckValid(
                    new Date("9999-12-31T00:00:00")
                  ),
                },
                errorCustoms: ({ value }) => {
                  const checkFromDate =
                    checkFromToDate(
                      value?.from_date,
                      value?.to_date,
                      null,
                      "from_date"
                    ) || false;
                  const checkToDate =
                    checkFromToDate(
                      value?.from_date,
                      value?.to_date,
                      null,
                      "to_date"
                    ) || false;

                  const checkFromDateLength = FunctionCheckDateLength(
                    value?.from_date,
                    "from_date"
                  );

                  const checkToDateLength = FunctionCheckDateLength(
                    value?.to_date,
                    "to_date"
                  );

                  const isPast = FunctionCheckDatIsPast(data?.from_date);

                  if (
                    value.from_date === "NaN-NaN-NaN" ||
                    value.to_date === "NaN-NaN-NaN"
                  ) {
                    return "Invalid date format";
                  } else if (value?.from_date === "") {
                    return ERROR_MESSAGE_REQUIRED;
                  } else if (checkFromDateLength.error) {
                    return checkFromDateLength.message;
                  } else if (checkToDateLength.error) {
                    return checkToDateLength.message;
                  } else if (checkToDate) {
                    return checkToDate;
                  } else if (checkFromDate) {
                    return checkFromDate;
                  } else if (
                    FunctionCheckDatIsPast(value?.from_date) &&
                    !isPast
                  ) {
                    return "From date must not be in the past";
                  } else {
                    return "";
                  }
                },
                fieldCustoms: ({ value, setValue }) => {
                  return (
                    <div className="grid grid-cols-2 gap-4">
                      <DesktopDatePicker
                        label="Date desktop"
                        inputFormat="dd/MM/yyyy"
                        disablePast
                        disabled={
                          new Date(data?.from_date).getTime() <=
                          new Date().getTime()
                        }
                        // disabled={!isCreate && index === 0}
                        value={value?.from_date}
                        onChange={(e) =>
                          setValue({
                            ...value,
                            from_date: formatDateCheckValid(e),
                          })
                        }
                        renderInput={(params) => {
                          if (params?.inputProps?.placeholder) {
                            params.inputProps.placeholder = "DD/MM/YYYY";
                          }
                          return (
                            <TextField
                              {...params}
                              placeholder="DD/MM/YYYY"
                              InputProps={{
                                ...params.InputProps,
                                style: { color: "#7A58BF", fontSize: 16 },
                                placeholder: "DD/MM/YYYY",
                              }}
                              variant="standard"
                              label={"From"}
                              error={
                                checkFromToDate(
                                  value?.from_date,
                                  value?.to_date,
                                  null,
                                  "from_date"
                                ) ||
                                FunctionCheckDateLength(
                                  value?.from_date,
                                  "from_date"
                                ).error
                              }
                              helperText={
                                value?.from_date === "NaN-NaN-NaN"
                                  ? "Invalid date format"
                                  : FunctionCheckDateLength(
                                      value?.from_date,
                                      "from_date"
                                    ).error
                                  ? FunctionCheckDateLength(
                                      value?.from_date,
                                      "from_date"
                                    ).message
                                  : checkFromToDate(
                                      value?.from_date,
                                      value?.to_date,
                                      null,
                                      "from_date"
                                    )
                              }
                            />
                          );
                        }}
                      />
                      <DesktopDatePicker
                        label="Date desktop"
                        inputFormat="dd/MM/yyyy"
                        disablePast
                        // disabled={!isCreate && index === 0}
                        value={
                          value?.to_date === "9999-12-31"
                            ? null
                            : value?.to_date
                        }
                        onChange={(e) =>
                          setValue({
                            ...value,
                            to_date: formatDateCheckValid(e),
                          })
                        }
                        renderInput={(params) => {
                          if (params?.inputProps?.placeholder) {
                            params.inputProps.placeholder = "DD/MM/YYYY";
                          }
                          return (
                            <TextField
                              {...params}
                              placeholder="DD/MM/YYYY"
                              InputProps={{
                                ...params.InputProps,
                                style: { color: "#7A58BF", fontSize: 16 },
                                placeholder: "DD/MM/YYYY",
                              }}
                              variant="standard"
                              label={"To"}
                              error={
                                checkFromToDate(
                                  value?.from_date,
                                  value?.to_date,
                                  null,
                                  "to_date"
                                ) ||
                                FunctionCheckDateLength(
                                  value?.to_date,
                                  "to_date"
                                ).error
                              }
                              helperText={
                                value?.to_date === "NaN-NaN-NaN"
                                  ? "Invalid date format"
                                  : FunctionCheckDateLength(
                                      value?.to_date,
                                      "to_date"
                                    ).error
                                  ? FunctionCheckDateLength(
                                      value?.to_date,
                                      "to_date"
                                    ).message
                                  : checkFromToDate(
                                      value?.from_date,
                                      value?.to_date,
                                      null,
                                      "to_date"
                                    )
                              }
                            />
                          );
                        }}
                      />
                    </div>
                  );
                },
              },
              {
                classNameCore: "col-span-8 grid",
                name: "tran_conditions_prepair",
                initalValue: [],
                fieldCustoms: (props) => {
                  return (
                    <RenderTableService
                      value={props.value}
                      setValue={props.setValue}
                      props={props}
                      isEdit={true}
                    />
                  );
                },
              },
              {
                classNameCore: "col-span-2",
                name: "pricing_method",
                initalValue: "TRAN_VOLUME",
                fieldCustoms: ({ value, setValue, thisValue }) => {
                  return (
                    <DropdownObject
                      value={value}
                      label={`Pricing Method`}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      options={[
                        {
                          value: "TRAN_VOLUME",
                          label: "Transaction Volume",
                        },
                      ]}
                      error={thisValue?.error}
                      helperText={thisValue?.error ? thisValue?.message : ""}
                    />
                  );
                },
              },
              {
                initalValue: "MONTHLY",
                classNameCore: "col-span-2",
                name: "pricing_frequency",
                fieldCustoms: ({ value, setValue, thisValue }) => {
                  return (
                    <DropdownObject
                      value={value}
                      label={`Pricing Frequency`}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      options={[
                        {
                          label: "Monthly",
                          value: "MONTHLY",
                        },
                      ]}
                      error={thisValue?.error}
                      helperText={thisValue?.error ? thisValue?.message : ""}
                    />
                  );
                },
              },
              {
                initalValue: "MONTHLY",
                classNameCore: "col-span-2",
                name: "transaction_consolidation",
                fieldCustoms: ({ value, setValue, thisValue }) => {
                  return (
                    <DropdownObject
                      value={value}
                      label={`Transaction Consolidation`}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      // options={["MONTHLY", "TRANSACTION", "NO"]}
                      options={[
                        {
                          label: "Monthly",
                          value: "MONTHLY",
                        },
                        {
                          label: "Quarterly",
                          value: "QUARTERLY",
                        },
                        {
                          label: "Yearly",
                          value: "YEARLY",
                        },
                      ]}
                      error={thisValue?.value === "" ? false : thisValue?.error}
                      helperText={
                        thisValue?.value === ""
                          ? ""
                          : thisValue?.error
                          ? thisValue?.message
                          : ""
                      }
                    />
                  );
                },
                required: true,
              },
              {
                classNameCore: "col-span-8",
                name: "fee_value",
                initalValue: {
                  tier: "NO",
                  value: [
                    {
                      fixed_amount: "",
                      rate: "",
                      min: "",
                      max: "",
                    },
                  ],
                },
                fieldCustoms: ({ value, setValue }) => {
                  return (
                    <RenderTable
                      value={value}
                      setValue={setValue}
                      isEdit={true}
                    />
                  );
                },
              },
              {
                classNameCore: "col-span-8",
                name: "remark",
                fieldCustoms: ({ value, setValue, name, thisValue }) => {
                  return (
                    <FieldText
                      lable={"Remark"}
                      name={name}
                      value={value}
                      setValue={setValue}
                      limit={300}
                      thisValue={thisValue}
                      fullWidth={true}
                    />
                  );
                },
              },
            ]}
            onSubmit={async (thisNewData) => {
              let checkEveryualifier = [];
              let FormatData = {
                from_date: formatDateApi(
                  thisNewData?.from_date_to_date?.from_date
                ),
                to_date: formatDateApi(thisNewData?.from_date_to_date?.to_date),
                offer_code: thisNewData?.offer_code,
                offer_name: thisNewData?.offer_name,
                pricing_frequency: thisNewData?.pricing_frequency,
                pricing_method: thisNewData?.pricing_method,
                remark: thisNewData?.remark,
                transaction_consolidation:
                  thisNewData?.transaction_consolidation,
                tran_conditions: thisNewData?.tran_conditions_prepair.map(
                  (i) => {
                    // console.log("i?.qualifiers_string", i?.qualifiers_string);
                    FunctionCheckFormatStringBeforeArray(
                      i?.qualifiers_string,
                      checkEveryualifier
                    );
                    let qualifiers_string = i?.qualifiers_string
                      ?.replace(/"/g, "")
                      .replace(/\//g, "");

                    return {
                      service_code: i.service_code,
                      charge_code: i.charge_code,
                      qualifiers: {
                        channel: qualifiers_string.split(","),
                      },
                    };
                  }
                ),
                fee_value: thisNewData?.fee_value,
                from_date_to_date: thisNewData?.from_date_to_date,
              };

              const isErrorFeeValue =
                FormatData?.fee_value?.value
                  ?.map((row) => {
                    // console.log("row", {
                    //   row,
                    //   fee_value: FormatData.fee_value,
                    //   fix: row?.fix_amount,
                    //   rate: row?.rate,
                    // });
                    return (
                      (row?.fix_amount === "" ||
                        row?.fix_amount === null ||
                        row?.fix_amount === undefined) &&
                      (row?.rate === "" ||
                        row?.rate === null ||
                        row?.rate === undefined)
                      //   &&
                      // FormatData?.fee_value?.tier === "NO"
                    );
                  })
                  ?.filter((i) => i === true)?.length > 0;

              if (isCreate) {
                FormatData = {
                  ...FormatData,
                  raw_data: FormatData,
                };
              }

              if (!isCreate) {
                FormatData = {
                  ...FormatData,
                  raw_data: data,
                };
              }

              const errorState = FunctionGetObjectIsError(FormatData);

              let isSameData = false;

              if (isErrorFeeValue) {
                errorState.push({
                  error: true,
                  field_name: "fee_value",
                  message: "fee value is required",
                  value: "",
                });
              }

              if (!isCreate) {
                isSameData = FunctionCheckDataChangeBundleOffer(
                  data,
                  FormatData
                );
              }
              if (isSameData) {
                errorState.push({
                  error: true,
                  field_name: "No data change",
                  message: "Nothing to Update",
                  value: "",
                });
              }

              if (checkEveryualifier?.length > 0) {
                errorState.push({
                  error: true,
                  field_name: "qualifiers",
                  message: "Qualifiers is not valid",
                  value: "",
                });
              }

              if (FormatData?.tran_conditions?.length === 0) {
                errorState.push({
                  error: true,
                  field_name: "service",
                  message: "Must be at least one service .",
                  value: "",
                });
              }
              FunctionRenameKeyError(errorState, [
                {
                  from: "offer_code",
                  to: "bundled_offer_code",
                },
                {
                  from: "offer_name",
                  to: "bundled_offer_name",
                },
              ]);

              FunctionGetErrorFromDomByRay(errorState);

              let mappingData = cloneDeep({
                ...FormatData,
                monthly_pricing_day: 1,
              });

              delete mappingData?.from_date_to_date;

              let newFeeValue = {
                tier: mappingData?.fee_value?.tier,
                value: mappingData?.fee_value?.value?.map((item) => {
                  let thisValue = {};

                  if (FunctionCheckBooleanNumber(item?.rate)) {
                    thisValue = { ...thisValue, rate: Number(item?.rate) };
                  }
                  if (FunctionCheckBooleanNumber(item?.from)) {
                    if (item?.from === "MIN") {
                      thisValue = { ...thisValue, from: 0 };
                    }
                    if (item?.from !== "MIN") {
                      thisValue = { ...thisValue, from: Number(item?.from) };
                    }
                  }
                  if (FunctionCheckBooleanNumber(item?.to)) {
                    if (item?.to === "MAX") {
                      thisValue = { ...thisValue, to: 999999999.99 };
                    }
                    if (item?.to !== "MAX") {
                      thisValue = { ...thisValue, to: Number(item?.to) };
                    }
                  }
                  if (FunctionCheckBooleanNumber(item?.fix_amount)) {
                    thisValue = {
                      ...thisValue,
                      fix_amount: Number(item?.fix_amount),
                    };
                  }
                  if (item?.rate_type) {
                    thisValue = { ...thisValue, rate_type: item?.rate_type };
                  }
                  if (item?.rate_type === "%") {
                    thisValue = { ...thisValue, rate_type: "PERCENT" };
                  }

                  if (item?.rate_type === "AMT") {
                    thisValue = { ...thisValue, rate_type: "THB" };
                  }

                  if (FunctionCheckBooleanNumber(item?.min_tier)) {
                    thisValue = {
                      ...thisValue,
                      min_tier: Number(item?.min_tier),
                    };
                  }
                  if (FunctionCheckBooleanNumber(item?.max_tier)) {
                    thisValue = {
                      ...thisValue,
                      max_tier: Number(item?.max_tier),
                    };
                  }
                  return thisValue;
                }),
              };

              if (FunctionCheckBooleanNumber(mappingData?.fee_value?.max)) {
                newFeeValue = {
                  ...newFeeValue,
                  max: Number(mappingData?.fee_value?.max),
                };
              }
              if (FunctionCheckBooleanNumber(mappingData?.fee_value?.min)) {
                newFeeValue = {
                  ...newFeeValue,
                  min: Number(mappingData?.fee_value?.min),
                };
              }

              mappingData = {
                ...mappingData,
                fee_value: newFeeValue,
                to_date:
                  mappingData?.to_date === "" || mappingData?.to_date === null
                    ? "9999-12-31T00:00:00"
                    : mappingData?.to_date,
              };

              if (mappingData?.tran_conditions?.length > 0) {
                mappingData = {
                  ...mappingData,
                  tran_conditions: mappingData?.tran_conditions?.map((item) => {
                    let newQualifiers = {
                      channel: [],
                    };

                    item?.qualifiers?.channel?.forEach((i) => {
                      if (
                        i !== null &&
                        i !== undefined &&
                        i !== "" &&
                        i !== "undefined"
                      ) {
                        newQualifiers.channel.push(i);
                      }
                    });

                    // if (item?.qualifiers?.channel?.length > 0) {
                    // }

                    return {
                      ...item,
                      qualifiers: newQualifiers,
                    };
                  }),
                };
              }
              console.log("FormatData : ", {
                FormatData,
                errorState,
                mappingData,
              });

              if (errorState?.length > 0) {
                setError(FunctionDeleteErrorSameName(errorState));
                setErrorOpen(true);
                return;
              }

              setSubmitData(mappingData);
              setShowConfirm(true);
              setErrorOpen(false);
            }}
            classNameSubmit="hidden"
          />
        </LocalizationProvider>
      </div>
    );
  }
);

export const RenderEditMode = memo(({ data, isCreate, params }) => {
  const [error, setError] = React.useState([]);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [loadingAction, setLoadingAction] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [submitData, setSubmitData] = React.useState(null);
  const [serverError, setServerError] = React.useState(null);

  const navigate = useNavigate();

  const submited = async () => {
    try {
      setLoadingAction(true);
      let ret;
      if (isCreate) {
        ret = await offersBundleService.postOfferBundle(submitData);
      } else {
        ret = await offersBundleService.updateOfferBundle(params, submitData);
      }
      if (ret.data.status.description === "Success") {
        setIsSuccess(true);
        setShowConfirm(false);
        setServerError(null);
        navigate("/bundle");
      }
    } catch (e) {
      setServerError(e.response.data.status.description);
    } finally {
      setLoadingAction(false);
    }
  };

  return (
    <div className="w-full pt-xxl">
      {error && (
        <TopperError
          isShow={errorOpen}
          setOpen={setErrorOpen}
          open={errorOpen}
          errorArray={error}
        />
      )}
      <ConfirmModal
        confirmText="OK"
        onClick={submited}
        loading={loadingAction}
        isSuccess={isSuccess}
        isError={serverError}
        message={serverError}
        open={showConfirm}
        onClose={() => {
          setShowConfirm(false);
          setIsSuccess(false);
          setServerError(null);
        }}
        text={
          "Do you want to " + (isCreate ? "create" : "edit") + " bundle-offer ?"
        }
      />
      <RenderControllerMemo
        isCreate={isCreate}
        data={data}
        setError={setError}
        setErrorOpen={setErrorOpen}
        setSubmitData={setSubmitData}
        setShowConfirm={setShowConfirm}
      />
    </div>
  );
});

export const RenderDetail = ({ data }) => {
  return (
    <React.Fragment>
      <RenderTableService
        value={data?.tran_conditions?.map((item) => {
          return {
            ...item,
            // qualifiers_string: item?.qualifiers?.channel?.join(","),
            qualifiers_string:
              item?.qualifiers?.channel?.length > 0 &&
              item?.qualifiers?.channel[0] !== ""
                ? JSON.stringify(item?.qualifiers?.channel)
                    .replace("[", "")
                    .replace("]", "")
                : "-",
          };
        })}
        isEdit={false}
      />

      <div className="px-xl">
        <PBFERenderHeaderChargeFromService
          charge={{ fee_charge: { ...data } }}
          isBundle={true}
        />
        <PBFEFeeTable data={data} />
      </div>
      <div className="h-10px bg-gray-100"></div>
    </React.Fragment>
  );
};

export const OfferBundleDetail = memo(
  ({ setRouteName, setActionAppbar, action = false }) => {
    const params = useParams();
    const [isEdit, setIsEdit] = React.useState(false);
    const isCreate = params?.id === "create";

    const { data, loading, error } = useQuery(
      offersBundleService.getOfferBundlesInfo,
      {
        offer_code: params.id,
      },
      {},
      [],
      {
        prePareData: null,
        checkFirstLoad: isCreate ? false : true,
      }
    );

    useEffect(() => {
      if (isCreate) {
        setRouteName([
          { title: "BUNDLED OFFER", path: "/bundle" },
          { title: "CREATE", path: `/bundle/create` },
        ]);
      } else {
        setRouteName([
          { title: "BUNDLED OFFER", path: "/bundle" },
          { title: data?.offer_code, path: `/offer/${data?.offer_code}` },
        ]);
        setActionAppbar("");
      }
    }, [data, setRouteName]);

    useEffect(() => {
      let mounted = true;
      if (action === "EDIT") {
        setIsEdit(true);
        setRouteName([
          { title: "BUNDLED OFFER", path: `/bundle` },
          {
            title: `${data.offer_code}`,
            path: `/bundle/${data.offer_code}`,
            setSelected: setIsEdit,
            check: true,
          },
          {
            title: "EDIT BUNDLED OFFER",
          },
        ]);
      }
      return () => {
        mounted = false;
      };
    }, [action]);

    if (loading) {
      return (
        <div className="h-screen m-auto">
          <PBFELoading />
        </div>
      );
    }
    if (error && !isCreate) return <PBFEError error={error} />;

    if (isEdit || isCreate) {
      return <RenderEditMode data={data} isCreate={isCreate} params={params} />;
    } else {
      return (
        <div className="w-full pt-xxl">
          <MetaData data={data} />
          <div className="h-10px bg-gray-100"></div>
          <RenderDetail data={data} />
          <div className="h-10px bg-gray-100"></div>
        </div>
      );
    }
  }
);
