import { useParams } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import offersBundleService from "../../api/offersBundleService";
import React from "react";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import PBFEError from "../../components/Error/alertError";
import { RenderDetail } from ".";
import { formateDateWithTimeHourMintues } from "../../utils/util";
import { PBFEContent } from "../../components/Elements/Text/content";
import { MetaData } from "../OfferDetail";

const OfferBundleHistory = ({ setRouteName }) => {
  const params = useParams();
  const [dataHistory, setDataHistory] = React.useState({});
  const { data, loading, error } = useQuery(
    offersBundleService.getHistoryOfferBundle,
    {},
    {
      offer_code: encodeURI(params.offer_code),
      offset: 0,
      limit: 100,
    },
    []
  );

  console.log("data", { data, params, dataHistory });

  React.useEffect(() => {
    if (data?.length > 0 && !dataHistory?.offer_code) {
      const findSelf = data?.find((item) => {
        return Number(item.bndl_offer_history_id) === Number(params.history_id);
      });

      if (findSelf) {
        setRouteName([
          { title: "BUNDLED OFFER", path: "/bundle" },
          {
            title: findSelf?.offer_code,
            path: `/bundle/${findSelf?.offer_code}`,
          },
          {
            title: `HISTORY`,
          },
        ]);
        setDataHistory(findSelf);
      }
    }
  }, [data, params]);

  if (loading) return <PBFELoading />;
  if (error) return <PBFEError error={error} />;
  return (
    <div className="w-full pt-xxl">
      <div>
        <div className="grid grid-cols-4 gap-4 px-xl py-fourteen ">
          <PBFEContent
            label="Update Date"
            value={formateDateWithTimeHourMintues(dataHistory?.create_datetime)}
          />
          <div className="col-span-2">
            <PBFEContent label="Update User" value={dataHistory?.create_user} />
          </div>
          <PBFEContent label="Type" value={dataHistory?.action} />
          <div className="col-span-4">
            <PBFEContent label="Remark" value={dataHistory?.remark} />
          </div>
        </div>
        <div className="h-10px bg-gray-100"></div>
      </div>

      <MetaData data={dataHistory.raw_data} />

      <div className="h-10px bg-gray-100"></div>
      <RenderDetail data={dataHistory.raw_data} />
      <div className="h-10px bg-gray-100"></div>
    </div>
  );
};

export default OfferBundleHistory;
