import { FormControl, TextField } from "@mui/material";
import { useState, useEffect, memo } from "react";
import { PBFELoading } from "../../../components/Elements/Loading/loading";
import { PBFEContent } from "../../../components/Elements/Text/content";
import PBFEError from "../../../components/Error/alertError";
import { PBFECommonDropdown } from "../../../components/Input/commonDropdown";
import { PBFEAccordionMain } from "../../../components/Panel/Accordion/accordionMain";
import { PBFECommonTable } from "../../../components/Table/commonTable";
import PBFESideTable from "../../../components/Table/sideTable";
import {
  GROUP_PATH_NAME,
  GROUP_ROUTE,
  HISTORY_HEADER,
  MINIMUM_SEARCH_ACTIVE,
  ModalType,
  NEW_PROFILE_PATH_NAME,
  PROFILE_PATH_NAME,
  PROFILE_ROUTE,
  USER_ROLE,
  USER_ROLE_MAPPING,
  USER_ROLE_OPTIONS,
} from "../../../constants/constant";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import EditIcon from "../../../assets/icon/edit.svg";
import { Button } from "@mui/material";
import { UserRoleModal } from "../../../components/Panel/Modal/userRoleModal";
import useQuery from "../../../hooks/useQuery";
import userProfileService from "../../../api/userProfile";
import userGroupService from "../../../api/userGroupService";
import PBFEFieldTextInput from "../../../components/Input/fieldTextInput";
import _ from "lodash";
import classNames from "classnames";
import {
  convertErrorApi,
  formatAction,
  formateDateWithTimeHourMintues,
  isValidErrorApi,
} from "../../../utils/util";
import PBFEInvalid from "../../../components/Error/invalidData";
import { useLocation } from "react-router-dom";
import { ERROR_MESSAGE_REQUIRED } from "../../../utils/errorMessage";
import api from "../../../api";

const SectionHeaderEdit = ({ isEdit = false, data = {}, setData }) => {
  const onChangeStatus = (e) => {
    setData({ ...data, status: e.target.value });
  };

  return (
    <div className="w-full px-xl my-20px">
      <div className="flex">
        <div className="w-full">
          <PBFEContent
            label="Staff Name"
            value={data?.user_name}
            // disable={isEdit}
          />
        </div>
        <div className="w-content">
          <PBFEContent
            label="Staff ID"
            value={data?.user_id}
            // disable={isEdit}
          />
        </div>
      </div>
      <div className="flex mt-25px">
        <div className="w-full">
          <PBFEContent
            label="Staff Email"
            value={data?.email || "-"}
            // disable={isEdit}
          />
        </div>
        <div className="w-content">
          {isEdit ? (
            <PBFECommonDropdown
              label={"Status"}
              options={["Active", "Inactive"]}
              value={_.capitalize(data?.status)}
              onChange={onChangeStatus}
            />
          ) : (
            <PBFEContent
              label="Status"
              value={
                <div className="flex">
                  <div
                    className={classNames("rounded-full h-3 w-3 mr-2 my-auto", {
                      "bg-green-500": _.lowerCase(data?.status) === "active",
                      "bg-gray-400": _.lowerCase(data?.status) === "inactive",
                    })}
                  />
                  {_.capitalize(data?.status)}
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

const SectionHeaderCreate = ({
  isCreate = false,
  data,
  setData,
  errorDesc = false,
}) => {
  const [searchId, setSearchId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onClickSearchCreate = async () => {
    try {
      setLoading(true);

      const { data } = await userProfileService.getUserIdap(searchId);

      setData((prev) => {
        return {
          ...prev,
          user_id: data?.data?.user_id,
          user_name: data?.data?.user_name,
          email: data?.data?.email,
        };
      });
      setError(false);
    } catch (error) {
      if (isValidErrorApi(error)) {
        setError(convertErrorApi(error.response.data.status.description));
        setData((prev) => {
          return {
            ...prev,
            user_id: "",
            user_name: "",
            email: "",
          };
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const onChangeValue = (value) => {
    setSearchId(value);
  };

  const getHelperText = () => {
    if (loading) {
      return "Searching...";
    } else if (!searchId.length) {
      return ERROR_MESSAGE_REQUIRED;
    }

    return error;
  };

  return (
    <div className="w-full px-xl my-20px">
      <div className="flex">
        <PBFEFieldTextInput
          label="Staff ID"
          onClick={(e) => onClickSearchCreate(e)}
          value={searchId}
          setValue={onChangeValue}
          error={(errorDesc && !searchId?.length) || error || loading}
          helperText={getHelperText()}
        />
      </div>
      <div className="flex mt-25px">
        <div className="w-full">
          <PBFEContent
            label="Staff Name"
            value={data?.user_name || "-"}
            // disable={isCreate}
          />
        </div>
        <div className="w-full">
          <PBFEContent
            label="Staff Email"
            value={data?.email || "-"}
            // disable={isCreate}
          />
        </div>
      </div>
    </div>
  );
};

const mappingUserGroup = async (userGroup = [], groupArr = []) => {
  let group = userGroup.reduce((prev, curr) => {
    if (
      curr?.user_role !== "SECURITYADMIN" &&
      curr?.user_role !== "SYSTEMADMIN"
    ) {
      const findPrevGroupIdIndex = prev.findIndex(
        (item) => item?.user_group_id === curr?.user_group_id
      );
      if (findPrevGroupIdIndex > -1) {
        prev[findPrevGroupIdIndex].action.push({
          label: USER_ROLE_MAPPING[curr?.user_role],
          value: curr?.user_role,
        });
      } else {
        prev = [
          ...prev,
          {
            user_group_id: curr?.user_group_id,
            name: curr?.user_group_name,
            action: [
              {
                label: USER_ROLE_MAPPING[curr?.user_role],
                value: curr?.user_role,
              },
            ],
          },
        ];
      }

      return [...prev];
    }

    return [...prev];
  }, []);

  group = group?.map((gp) => {
    const newAction = USER_ROLE_OPTIONS.map((option) => {
      const findPrevIndex = gp?.action.findIndex(
        (item) => item?.value === option.value
      );
      if (findPrevIndex > -1) {
        return {
          ...option,
          checked: true,
        };
      }

      return { ...option, checked: false };
    });

    return {
      ...gp,
      checked: true,
      action: newAction,
    };
  });

  // if (groupArr) {
  //   let userGroup = groupArr;
  //   group = userGroup?.map((usGroup) => {
  //     const findIndexUsGroup = group?.findIndex(
  //       (gp) => gp.user_group_id === usGroup?.user_group_id
  //     );
  //     if (findIndexUsGroup > -1) {
  //       return {
  //         ...group[findIndexUsGroup],
  //         checked: true,
  //       };
  //     }

  //     return {
  //       user_group_id: usGroup?.user_group_id,
  //       name: usGroup?.user_group_name,
  //       checked: false,
  //       action: USER_ROLE_OPTIONS.map((option) => {
  //         return {
  //           checked: false,
  //           value: option.value,
  //           label: option.label,
  //         };
  //       }),
  //     };
  //   });
  // }

  return group;
};

const Info = memo(
  ({
    isEdit = false,
    isCreate = false,
    selected,
    setDataUserProfile,
    errorDesc,
    setErrorDesc,
    isOpenError,
    setIsOpenError,
    showError,
  }) => {
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState(ModalType.Group);
    const [roleValue, setRoleValue] = useState(USER_ROLE.SecurityAdmin);
    const [editGroupIndex, setEditGroupIndex] = useState(-1);
    const [currentOptions, setCurrentOptions] = useState([]);
    const [group, setGroup] = useState([]);
    const [reason, setReason] = useState("");
    const [checkedSelectAll, setCheckedSelectAll] = useState(false);
    const [checkGroup, setCheckGroup] = useState(false);
    const [page, setPage] = useState(0);

    const prePareData = async (data) => {
      let roles = {};

      // const { data: groupArr } = await userGroupService.getUserGroup();

      await Promise.all(
        data?.user_role?.map(async (role) => {
          if (["SECURITYADMIN", "SYSTEMADMIN"].includes(role?.user_role)) {
            roles = {
              name: USER_ROLE_MAPPING[role?.user_role],
              value: [],
            };
            return;
          }

          roles = {
            name: "User Group",
            value: await mappingUserGroup(data?.user_role),
          };
          return;
        })
      );

      setRoleValue(roles?.name);

      return {
        ...data,
        roles,
      };
    };

    const { data, loading, error, setData } = useQuery(
      userProfileService.getAllUserProfileById,
      {
        user_id: selected?.user_id,
      },
      {},
      [selected],
      { prePareData, checkFirstLoad: isCreate ? false : true }
    );

    useEffect(() => {
      setErrorDesc(false);
    }, [selected]);

    useEffect(() => {
      if (isCreate) {
        setData({ ...data, roles: { name: "", value: [] } });
      }
    }, [isCreate]);

    useEffect(() => {
      if (data) {
        setDataUserProfile({
          ...data,
          reason,
          roles: { ...data.roles, name: roleValue },
        });
      }
    }, [data, reason, roleValue]);

    useEffect(() => {
      if (errorDesc) {
        let checkError = [];

        if (roleValue === "User Group") {
          data.roles.value
            ?.filter((ev) => ev.checked)
            .forEach((ev) => {
              const checkAction = ev.action.every((action) => {
                return !action.checked;
              });

              checkError = [...checkError, checkAction];
            });
        }

        setErrorDesc(
          checkError.some((ev) => ev === true) || !data?.user_id || !reason
        );
      }
    }, [data, reason, roleValue]);

    const onClickConfirm = () => {
      setData((prev) => {
        if (modalType === ModalType.Group) {
          if (!prev?.roles?.value?.length) {
            prev.roles.value = group.map((gp) => {
              return {
                user_group_id: gp?.user_group_id,
                name: gp?.user_group_name,
                checked: gp.checked,
                action: USER_ROLE_OPTIONS.map((option) => {
                  return {
                    checked: false,
                    value: option.value,
                    label: option.label,
                  };
                }),
              };
            });
          } else {
            prev.roles.value = prev.roles.value.map((item) => {
              const findUserGroup = group.find(
                (gp) => gp.user_group_id === item.user_group_id
              );

              return {
                ...item,
                checked: findUserGroup.checked,
                action: findUserGroup?.action.map((action) => {
                  return {
                    ...action,
                    checked: findUserGroup.checked ? action.checked : false,
                  };
                }),
              };
            });
          }
        } else {
          prev.roles.value[editGroupIndex].action = currentOptions;
        }
        return { ...prev };
      });
      setCurrentOptions([]);
      setOpenModal(false);
      setEditGroupIndex(-1);
      setCheckedSelectAll(false);
    };

    const getNewDataUserGroup = (userGroup = []) => {
      const group = data?.roles?.value;

      const newGroup = userGroup?.map((usGroup) => {
        const findIndexUsGroup = group?.findIndex(
          (gp) => gp.user_group_id === usGroup?.user_group_id
        );
        if (findIndexUsGroup > -1) {
          return {
            ...group[findIndexUsGroup],
          };
        }

        return {
          user_group_id: usGroup?.user_group_id,
          name: usGroup?.user_group_name,
          checked: false,
          action: USER_ROLE_OPTIONS?.map((option) => {
            return {
              checked: false,
              value: option.value,
              label: option.label,
            };
          }),
        };
      });

      return newGroup;
    };

    const edit = async (type, val, index) => {
      if (type === ModalType.Group) {
        setGroup(await getGroupOptions());
      } else {
        const findIndexGroup = data?.roles?.value?.findIndex(
          (gp) => gp?.user_group_id === val?.user_group_id
        );
        const newCurrentOptions = getRoleOptions(findIndexGroup);
        setEditGroupIndex(findIndexGroup);
        setCurrentOptions(newCurrentOptions);
        setCheckedSelectAll(
          newCurrentOptions.every((option) => option.checked)
        );
      }
      setModalType(type);
      setOpenModal(true);
    };

    const onClose = () => {
      setOpenModal(false);
      setEditGroupIndex(-1);
      setCheckedSelectAll(false);
    };

    const getGroupOptions = async () => {
      const { data: groupArr } = await userGroupService.getUserGroup();
      const newDataUserGroup = getNewDataUserGroup(groupArr.data);
      if (!checkGroup) {
        setData({ ...data, roles: { value: newDataUserGroup } });
        setCheckGroup(true);
      }
      if (data?.roles?.value?.length > 0) {
        const newGroupOption = newDataUserGroup.map((item) => {
          return { ...item, label: item.name, value: item.user_group_id };
        });
        return newGroupOption;
      } else {
        const newGroup = newDataUserGroup?.map((group) => {
          return {
            ...group,
            label: group.name,
            value: group.user_group_id,
            checked: false,
          };
        });
        return newGroup;
      }
    };

    if (loading) return <PBFELoading />;

    if (error) return <PBFEError error={error} />;

    const RoleDisplay = () => {
      return (
        <div>
          {data?.roles?.value
            ?.filter((item) => item?.checked)
            .map((val, index) => {
              return (
                <div className="pl-large pt-4px">
                  <div className="text-14px ">{val?.name || "-"}</div>
                  <div className="flex space-x-2 mt-2 my-14px">
                    {val?.action
                      .filter((action) => action.checked)
                      .map((act) => (
                        <div className="bg-gray-200 p-2 px-3 rounded-3xl text-14px font-semibold">
                          {act.label}
                        </div>
                      ))}
                    {(isEdit || isCreate) && (
                      <div className="flex space-x-2 items-center">
                        <Button
                          variant="text"
                          onClick={() => edit(ModalType?.Role, val, index)}
                          sx={{ fontSize: "14px", marginTop: "0px" }}
                          startIcon={<img src={EditIcon}></img>}
                        >
                          EDIT
                        </Button>
                        {val?.action.every((action) => !action.checked) && (
                          <div className=" text-xs text-red-400 ">
                            Please select at least 1 role
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          {(isEdit || isCreate) && (
            <div className="w-full flex justify-end space-x-2 items-center">
              {data?.roles?.value?.every((value) => !value.checked) && (
                <div className=" text-xs text-red-400 ">
                  Please select at least 1 group
                </div>
              )}

              <Button
                variant="text"
                onClick={() => edit(ModalType?.Group)}
                sx={{ fontSize: "14px", marginTop: "0px" }}
                startIcon={<img src={EditIcon}></img>}
              >
                EDIT USER GROUP
              </Button>
            </div>
          )}
        </div>
      );
    };

    const RoleEdit = () => {
      return (
        <div className="pl-large">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={USER_ROLE.SecurityAdmin}
            name="radio-buttons-group"
            value={roleValue}
            onChange={(val) => setRoleValue(val.target.value)}
            sx={{ width: "190px" }}
          >
            <FormControlLabel
              value={USER_ROLE.SecurityAdmin}
              control={<Radio />}
              label={USER_ROLE.SecurityAdmin}
            />
            <FormControlLabel
              value={USER_ROLE.SystemAdmin}
              control={<Radio />}
              label={USER_ROLE.SystemAdmin}
            />
            <FormControlLabel
              value={USER_ROLE.UserGroup}
              control={<Radio />}
              label={USER_ROLE.UserGroup}
            />
          </RadioGroup>
          {roleValue === USER_ROLE.UserGroup && <RoleDisplay />}
        </div>
      );
    };

    const getRoleOptions = (index) => {
      if (index > -1) {
        const newDataValue = _.cloneDeep(data?.roles?.value);
        const currentRole = newDataValue[index];
        return _.cloneDeep(currentRole?.action);
      }
      return [];
    };

    const callbackSortGroup = (group_a, group_b) => {
      return group_a.name < group_b.name ? -1 : 1;
    };

    // console.log("SAJDFSAHDK: ", {
    //   isOpenError,
    //   showError,
    // });

    return (
      <div className="w-full overflow-auto">
        <PBFEInvalid
          isShow={errorDesc || isOpenError}
          message={isOpenError ? showError : ""}
          setOpen={setErrorDesc || setIsOpenError}
          open={errorDesc || isOpenError}
          errorState={[]}
          pt="0"
          mt="0"
        />
        <UserRoleModal
          editGroupIndex={editGroupIndex}
          options={
            modalType === ModalType.Group
              ? group.sort(callbackSortGroup)
              : currentOptions
          }
          setOptions={
            modalType === ModalType.Group ? setGroup : setCurrentOptions
          }
          title={modalType === ModalType.Group ? "User Group" : "Roles"}
          type={modalType}
          open={openModal}
          action={onClickConfirm}
          onClose={onClose}
          checkedSelectAll={checkedSelectAll}
          setCheckedSelectAll={setCheckedSelectAll}
        />
        {selected ? (
          <SectionHeaderEdit data={data} isEdit={isEdit} setData={setData} />
        ) : (
          <SectionHeaderCreate
            data={data}
            isCreate={isCreate}
            setData={setData}
            errorDesc={errorDesc}
          />
        )}
        <div className="h-10px bg-gray-100"></div>
        <div className="w-full px-xl my-20px bg-white">
          <PBFEContent
            label="Roles"
            value={isCreate ? "" : data?.roles?.name || "-"}
          />
          {isEdit || isCreate ? <RoleEdit /> : <RoleDisplay />}
        </div>
        <div className="h-10px bg-gray-100"></div>
        {isEdit || isCreate ? (
          <div className="pl-xl pr-xl">
            <TextField
              sx={{ marginTop: "20px", marginBottom: "20px", width: "100%" }}
              variant="standard"
              label="Reason"
              onChange={(e) => setReason(e.target.value)}
              error={!reason?.length || reason?.length > 250}
              helperText={
                (errorDesc && !reason?.length && ERROR_MESSAGE_REQUIRED) ||
                (errorDesc && reason?.length > 250 && "Maximum 250 characters.")
              }
            />
          </div>
        ) : (
          <PBFEAccordionMain
            label="History"
            detail={
              <PBFECommonTable
                disabledCursor
                orderByDefault="action_date"
                orderDefault="desc"
                headCells={HISTORY_HEADER}
                rows={data?.history}
                formatControl={[
                  formateDateWithTimeHourMintues,
                  null,
                  null,
                  null,
                ]}
                page={page}
                setPage={setPage}
                showFields={["action_date", "action", "action_by", "reason"]}
                // orderByDefault="service_code"
                // headCells={SERVICE_HEAD_CELL}
                // formatControl={[null, deleteService]}
                // rows={FormatFilterActionDuplicate(servicesData)}
                // page={pageService}
                // setPage={setPageService}
                // showFields={["service_name", "service_code"]}
              />
            }
          />
        )}
      </div>
    );
  }
);

const UserProfile = ({
  setRouteName,
  setPrimaryDisplay,
  setActionAppbar,
  isEdit = false,
  setIsEdit = () => {},
  isCreate = false,
  setIsCreate = () => {},
  actionAppbar = "",
  setDataUserProfile,
  dataUserProfile,
  routeName,
  errorDesc,
  setErrorDesc,
}) => {
  const location = useLocation();

  const [selected, setSelected] = useState("");
  const [searched, setSearched] = useState("");
  const [showError, setShowError] = useState("");
  const [isOpenError, setIsOpenError] = useState(false);

  const [page, setPage] = useState(0);

  const {
    data: rows,
    setData: setRows,
    loading,
    originalData,
    error,
    refetch,
  } = useQuery(userProfileService.getAllUserProfile);

  console.log("ASD : ", {
    rows,
    actionAppbar,
    selected,
    searched,
  });

  const setInfo = (row) => {
    setRouteName([
      {
        title: PROFILE_PATH_NAME,
        path: PROFILE_ROUTE,
        setSelected: setSelected,
        check: true,
        action: () => {
          setSearched("");
          setRows(originalData);
        },
      },
      {
        title: row.user_name,
      },
    ]);
    setSelected(row);
    setIsEdit(false);
    setIsCreate(false);
  };

  useEffect(() => {
    if (isCreate && !selected) {
      setRouteName([
        {
          title: PROFILE_PATH_NAME,
          path: PROFILE_ROUTE,
          // setSelected: setSelected,
          setSelected: () => {
            window.location.reload();
          },
          check: true,
          // action: () => {
          //   setSearched("");
          //   setRows(originalData);
          //   setIsCreate(false);
          // },
        },
        { title: NEW_PROFILE_PATH_NAME },
      ]);
    } else {
      if (isEdit) {
        FunctionInserDataEdit();
        setRouteName([
          {
            title: PROFILE_PATH_NAME,
            path: PROFILE_ROUTE,
            check: true,
            setSelected: () => {
              window.location.reload();
            },
          },
          {
            title: `${selected.user_name}`,
            path: `/userprofile/${selected.user_name}`,
            setSelected: setIsEdit,
            check: true,
          },
          {
            title: "EDIT USER PROFILE",
          },
        ]);
      }
      if (!selected) {
        setRouteName([{ title: PROFILE_PATH_NAME, path: PROFILE_ROUTE }]);
      }
    }
    setPrimaryDisplay(false);
  }, [setRouteName, isCreate, isEdit]);

  const FunctionInserDataEdit = async () => {
    try {
      const { data } = await api.instanceCorp.put(
        `/v1/user-ldap/${selected?.user_id}`
      );
      if (data) {
        setSelected({
          ...selected,
          ...data,
        });
      }
    } catch (error) {
      // const thisError = error?.response?.data?.status;
      // setShowError(`${thisError?.code} - ${thisError?.description}`);
      // setIsOpenError(true);
      // console.error("ERROR TO LOAD DATA INSET", error);
    }
  };

  useEffect(() => {
    if (selected) {
      setActionAppbar("USERPROFILE_EDIT");
    } else {
      setActionAppbar("USERPROFILE_CREATE");
    }
  }, [selected]);

  useEffect(() => {
    setIsEdit(false);
    setIsCreate(false);
  }, [location.pathname]);

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal.target.value);
    setPage(0);
    if (searchedVal?.target?.value?.length < MINIMUM_SEARCH_ACTIVE) {
      setRows(originalData);
      return;
    }

    const filteredRows = originalData?.filter((row) => {
      return (
        row?.user_name
          .toLowerCase()
          .includes(searchedVal.target.value.toLowerCase()) ||
        row?.user_id
          .toLowerCase()
          .includes(searchedVal.target.value.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  const rowsDataSorted =
    rows?.length > 0 &&
    rows?.sort((a, b) => {
      if (a.user_name) {
        return a.user_name.toString().localeCompare(b.user_name);
      } else {
        return 0;
      }
    });

  if (error) return <PBFEError error={error} />;

  return (
    <div className="h-screen w-full">
      <div className=" h-full flex">
        <div className=" pt-xxl min-w-max  h-screen overflow-y-scroll">
          <PBFESideTable
            id="PBFESideTable"
            labelKey={"user_name"}
            valueKey={"user_id"}
            searched={searched}
            setInfo={setInfo}
            requestSearch={requestSearch}
            loading={loading}
            rows={rowsDataSorted || []}
            page={page}
            setPage={setPage}
            searchPlacholder="Search Staff Name / ID"
          />
        </div>
        <div className="border-r"></div>
        <div className="pt-xxl w-full overflow-y-scroll h-screen">
          {(selected || isCreate) && (
            <Info
              actionAppbar={actionAppbar}
              isCreate={isCreate}
              isEdit={isEdit}
              selected={selected}
              setDataUserProfile={setDataUserProfile}
              errorDesc={errorDesc}
              setErrorDesc={setErrorDesc}
              showError={showError}
              isOpenError={isOpenError}
              setIsOpenError={setIsOpenError}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
