import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const PBFEFieldTextInput = ({
  value,
  setValue = () => {},
  disabled = false,
  label,
  onClick = () => {},
  error,
  helperText,
}) => {
  return (
    <div className="relative inline-block">
      <div
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
        className="absolute right-4 top-5 cursor-pointer z-20"
      >
        <SearchIcon />
      </div>
      <div className="z-10">
        <TextField
          disabled={disabled}
          id="standard-basic"
          label={label}
          variant="standard"
          value={value}
          onChange={(e) => setValue(e?.target?.value)}
          error={error}
          helperText={error ? helperText : ""}
        />
      </div>
    </div>
  );
};
export default PBFEFieldTextInput;
