import api from "./index";
import { apiConfig } from "../config";

const getOffers = async (params, query) => {
  return api.instanceCorp.get(apiConfig.getOfferAPI);
};

const getOffersInfo = async (params, query) => {
  return api.instanceCorp.get(`${apiConfig.getOfferAPI}/${params.offer_code}`);
};

const getHistoryOffer = async (params, query) => {
  return api.instanceCorp.get(`${apiConfig.getOfferAPI}/histories${query}`);
};

const postOffer = async (body) => {
  return api.instanceCorp.post(apiConfig.getOfferAPI, {
    ...body,
  });
};

const updateOffer = async (params, body) => {
  return api.instanceCorp.put(`${apiConfig.getOfferAPI}/${params.offer_code}`, {
    ...body,
  });
};

const exportedObject = {
  getOffers,
  getOffersInfo,
  getHistoryOffer,
  postOffer,
  updateOffer,
};

export default exportedObject;
