import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "../../components/Input/dateRangePicker";

import { format } from "date-fns";

import { PBFEEditableList } from "../../components/Display/pbfeList";
import GenerateReportFilterModal from "../../components/Panel/Modal/generateReportFilterModal";
import { validateFormatDate } from "../../utils/util";
import { PBFEDropdown } from "../../components/Input/dropdown";
import { PBFECommonDropdown } from "../../components/Input/commonDropdown";
import { MenuItem, Select } from "@mui/material";
import { FilterDropdown } from "../../components/Input/filterDropdown";
import {
  OPTIONS_ACCOUNT_STATUS,
  OPTIONS_FEE_PROFILE,
  OPTIONS_REPORT_STATUS,
} from "../../constants/constant";

const GenerateReport = ({
  setRouteName,
  setActionAppbar,
  path,
  route,
  setDataGenerateReport,
  dataGenerateReport,
  reset,
  showCharge = true,
  showAccount = true,
  showCustomer = true,
  showFeeAccount = false,
  showFeeAccountDropdown = false,
  showFeeDebitDropdown = false,
  showFeeProfileStatusDropdown = false,
  showFeeProfileEffectiveDateRange = false, 
  showStatus = false,
  showMakerName = false,
  showCheckerName = false,
}) => {
  const MODE = {
    CUSTOMER: "customer",
    ACCOUNT: "account",
    FEE_ACCOUNT: "fee_account",
    SERVICE: "service",
    CHARGE: "charge",
    MAKER: "maker",
    CHECKER: "checker",
  };

  const initData = {
    customer: [],
    account: [],
    fee_account: [],
    service: [],
    charge: [],
    maker: [],
    checker: [],
    feeAccount: "All Status",
    feeDebit: "All Status",
    reportStatus: "All Status",
  };

  const today = `${format(new Date(), "yyyy-MM-dd")}T00:00:00`;

  const initCalendarValue = [new Date(today), new Date(today)];

  const refCalendar = useRef();
  const [calendarValue, setCalendarValue] = useState(initCalendarValue);
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState("");
  const [data, setData] = useState(initData);
  const [cacheData, setCacheData] = useState(initData);
  const [isErrorDate, setIsErrorDate] = useState([false, false]);
  const [feeAccount, setFeeAccount] = useState("All Status");
  const [feeDebit, setFeeDebit] = useState("All Status");
  const [reportStatus, setReportStatus] = useState("All Status");
  const [optionsFeeAccount, setOptionsFeeAccount] = useState(OPTIONS_ACCOUNT_STATUS);
  // const [maker, setMaker] = useState("All Maker");
  // const [checker, setChecker] = useState("All Checker");

  //const reportType = path === "FEE DEBIT" ? "FEE_DEBIT" : path === "PRICED TRANSACTION" ? "PRICED_TRANSACTION" : "PRICED_TXN_SUMMARY";

  useEffect(() => {
    setCalendarValue(initCalendarValue);
    setData(initData);
    setCacheData(initData);
    setFeeAccount("All Status");
    setFeeDebit("All Status");
    setReportStatus("All Status");
    // setMaker("All Maker");
    // setChecker("All Checker");
  }, [reset]);

  useEffect(() => {
    if (data && setDataGenerateReport) {
      setDataGenerateReport({ ...data, calendarValue });
    }
  }, [data, calendarValue]);

  useEffect(() => {
    if (showFeeProfileStatusDropdown) {
      setOptionsFeeAccount(OPTIONS_FEE_PROFILE)
    }
    setData({ ...data, feeAccount, feeDebit, reportStatus });
  }, [feeAccount, feeDebit, reportStatus, showFeeProfileStatusDropdown]);

  const toggleCalendar = (e) => {
    console.log("toggleCalendar", e);
    setOpenCalendar(!isOpenCalendar);
    e?.stopPropagation();
  };
  useEffect(() => {
    setActionAppbar("GENERATE");
    setRouteName([
      {
        title: path,
        path: route,
      },
      {
        title: "GENERATE REPORT",
        path: "",
      },
    ]);
    document.addEventListener("mousedown", handleClickOutsideCalendar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideCalendar);
    };
  }, [setRouteName, refCalendar, path, route, calendarValue, isErrorDate]);

  const handleClickOutsideCalendar = async (event) => {
    console.log("handleClickOutsideCalendar", { event, isErrorDate });
    if (refCalendar.current && !refCalendar.current.contains(event.target)) {
      if (isErrorDate.some((item) => item === true)) {
        return;
        // newCalendarValue = [new Date(), new Date()];
        // setCalendarValue(newCalendarValue);
      }
      setOpenCalendar(false);
    }
  };

  const onChangeFilterCalendar = (value) => {
    setCalendarValue(value);
  };

  const adjustFilter = (newData) => {
    switch (mode) {
      case MODE.ACCOUNT:
        setData({ ...data, account: newData });
        break;
      case MODE.FEE_ACCOUNT:
        setData({ ...data, fee_account: newData });
        break;
      case MODE.CUSTOMER:
        setData({ ...data, customer: newData });
        break;
      case MODE.SERVICE:
        setData({ ...data, service: newData });
        break;
      case MODE.CHARGE:
        setData({ ...data, charge: newData });
        break;
      case MODE.MAKER:
        setData({ ...data, maker: newData });
        break;
      case MODE.CHECKER:
        setData({ ...data, checker: newData });
        break;
      default:
      // code block
    }
    setOpenModal(false);
  };

  const openModalMode = (mode) => {
    setMode(mode);
    setOpenModal(true);
  };

  const removeItem = (mode, item) => {
    let temp = [...data[mode]];
    let index = temp.findIndex((element) => element.id === item.id);
    if (index > -1) temp.splice(index, 1); // 2nd parameter means remove one item only
    setData({ ...data, [mode]: temp });
  };

  const onClose = () => {
    setOpenModal(false);
  };

  return (
    <div className="grid grid-cols-2 gap-8 w-full mt-24 max-w-3xl pl-xl">
      <GenerateReportFilterModal
        selected={mode && data[mode]}
        open={openModal}
        onSubmit={adjustFilter}
        onClose={onClose}
        placeholder={""}
        mode={mode}
        cacheData={cacheData}
        setCacheData={setCacheData}
      />
      <div>
        {showFeeProfileEffectiveDateRange ? (
          <div className="text-textlabel text-12px mb-10px">Fee Effective Date Range</div> 
        ) : (
          <div className="text-textlabel text-12px mb-10px">Date Range</div>
        )}
        <DateRangePicker
          marginX="0"
          isErrorDate={isErrorDate}
          setIsErrorDate={setIsErrorDate}
          refCalendar={refCalendar}
          isOpenCalendar={isOpenCalendar}
          calendarValue={calendarValue}
          setCalendarValue={setCalendarValue}
          toggleCalendar={toggleCalendar}
          onChange={onChangeFilterCalendar}
        />
        {/* <div className="text-textlabel text-12px mt-10px">
          Specific Date Description
        </div> */}
      </div>
      {showCustomer && (
        <PBFEEditableList
          closeAction={removeItem}
          action={() => openModalMode(MODE.CUSTOMER)}
          mode={MODE.CUSTOMER}
          label="Customer"
          arr={data.customer}
        />
      )}

      {showStatus && (
        <div>
          <div className="text-textlabel text-12px mb-10px">Status</div>
          <FilterDropdown
            label=""
            value={reportStatus}
            onChange={(e) => setReportStatus(e.target.value)}
            options={OPTIONS_REPORT_STATUS}
            fontSize={16}
            //   isErrorDate={isErrorDate}
            py={"6px"}
          />
        </div>
      )}
      {showAccount && (
        <PBFEEditableList
          closeAction={removeItem}
          action={() => openModalMode(MODE.ACCOUNT)}
          mode={MODE.ACCOUNT}
          label="Account"
          arr={data.account}
        />
      )}
      {showMakerName && (
        <PBFEEditableList
          closeAction={removeItem}
          action={() => openModalMode(MODE.MAKER)}
          mode={MODE.MAKER}
          label="Maker Name"
          arr={data.maker}
        />
      )}
      <PBFEEditableList
        closeAction={removeItem}
        action={() => openModalMode(MODE.SERVICE)}
        mode={MODE.SERVICE}
        label="Service"
        arr={data.service}
      />
      {showCheckerName && (
        <PBFEEditableList
          closeAction={removeItem}
          action={() => openModalMode(MODE.CHECKER)}
          mode={MODE.CHECKER}
          label="Checker Name"
          arr={data.checker}
        />
      )}
      {showCharge && (
        <PBFEEditableList
          closeAction={removeItem}
          action={() => openModalMode(MODE.CHARGE)}
          mode={MODE.CHARGE}
          label="Charge"
          arr={data.charge}
        />
      )}
      {showFeeAccount && (
          <PBFEEditableList
            closeAction={removeItem}
            action={() => openModalMode(MODE.FEE_ACCOUNT)}
            mode={MODE.FEE_ACCOUNT}
            showFeeAccount
            label="Fee Account No."
            arr={data.fee_account}
          />
      )}
      {showFeeAccountDropdown && (
        <div>
          <div className="text-textlabel text-12px mb-10px">
            Fee Account Status
          </div>
          <FilterDropdown
            label=""
            value={feeAccount}
            onChange={(e) => setFeeAccount(e.target.value)}
            options={optionsFeeAccount}
            fontSize={16}
            isErrorDate={isErrorDate}
            py={"6px"}
          />
        </div>
      )}
      {showFeeDebitDropdown && (
        <div>
          <div className="text-textlabel text-12px mb-10px">
            Debit Account Status
          </div>
          <FilterDropdown
            label=""
            value={feeDebit}
            onChange={(e) => setFeeDebit(e.target.value)}
            options={OPTIONS_ACCOUNT_STATUS}
            fontSize={16}
            isErrorDate={isErrorDate}
            py={"6px"}
          />
        </div>
      )}
    </div>
  );
};

export default GenerateReport;
