export const mappingServiceInfo = (
  data,
  before,
  after,
  transaction_id = null
) => {
  let afterDiffFlag = "N";
  if (
    before.reg_addr_line1 !== after.contact_addr_line1 ||
    before.reg_addr_line2 !== after.contact_addr_line2 ||
    before.reg_addr_line3 !== after.contact_addr_line3 ||
    before.reg_zip_code !== after.contact_zip_code
  )
    afterDiffFlag = "Y";

  return {
    refer_transaction_id: transaction_id,
    transaction_type: "UPDATESERVICEADDRESS",
    customer_service_info_id: 1,
    customer_id: data.customer_id,
    customer_name: data.customer_name,
    customer_tax_id: "3444553444345",
    account_id: after.account_id ? after.account_id : null,
    service_code: after.service_code,
    remark: after?.remark || "",
    reference1: after.service_code,
    data: {
      display_obj: {
        before: {
          customer_service_info_id: before.customer_service_info_id,
          customer_branch_no: before.customer_branch_no,
          reg_addr_line1: before.reg_addr_line1,
          reg_addr_line2: before.reg_addr_line2,
          reg_addr_line3: before.reg_addr_line3,
          reg_zip_code: before.reg_zip_code,
          contact_addr_line1: before?.contact_addr_line1 || "",
          contact_addr_line2: before?.contact_addr_line2 || "",
          contact_addr_line3: before?.contact_addr_line3 || "",
          contact_zip_code: before?.contact_zip_code || "",
          different_contact_addr_flag: before.different_contact_addr_flag,
          email: before.email,
          customer_full_name_thai: before?.customer_full_name_thai,
          email_alert_flag: before.email_alert_flag,
        },
        after: {
          customer_service_info_id: after.customer_service_info_id,
          customer_branch_no: after.customer_branch_no,
          reg_addr_line1: after.reg_addr_line1,
          reg_addr_line2: after.reg_addr_line2,
          reg_addr_line3: after.reg_addr_line3,
          reg_zip_code: after.reg_zip_code,
          contact_addr_line1: after.contact_addr_line1,
          contact_addr_line2: after.contact_addr_line2,
          contact_addr_line3: after.contact_addr_line3,
          contact_zip_code: after.contact_zip_code,
          different_contact_addr_flag: afterDiffFlag,
          email: after.email,
          email_alert_flag: after.email_alert_flag,
          customer_full_name_thai: after?.customer_full_name_thai,
        },
      },
      update_obj: {
        customer_service_info_id: after.customer_service_info_id,
        customer_branch_no: after.customer_branch_no,
        reg_addr_line1: after.reg_addr_line1,
        reg_addr_line2: after.reg_addr_line2,
        reg_addr_line3: after.reg_addr_line3,
        reg_zip_code: after.reg_zip_code,
        contact_addr_line1: after.contact_addr_line1,
        contact_addr_line2: after.contact_addr_line2,
        contact_addr_line3: after.contact_addr_line3,
        contact_zip_code: after.contact_zip_code,
        email: after.email,
        different_contact_addr_flag: afterDiffFlag,
        email_alert_flag: after.email_alert_flag,
        service_code: after.service_code,
        customer_full_name_thai: after?.customer_full_name_thai,
      },
    },
  };
};
