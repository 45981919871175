import MainRoute from "./routes";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import React from "react";

// config
import { sessionExpire, modalConfig, keyCloakRedirect } from "./config";

// mui
import { ThemeProvider, createTheme } from "@mui/material/styles";

// components
import { PBFETimerRefreshToken } from "./components/Time/timerRefreshToken";
import { AlertModal } from "./components/Panel/Modal/alertModal";
import { setModal } from "./redux/modalSlice";
import { checkUserLogin, getTokenFromLocalStorage } from "./utils/util";

import authService from "./api/authService";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7A58BF",
    },
    secondary: {
      main: "#462279",
    },
  },
});

function App() {
  const dispatch = useDispatch();
  const modalSelector = useSelector((state) => state.modal);
  const [stopRefreshToken, setStopRefreshToken] = React.useState(false);
  const [checkInitialApp, setCheckIntialApp] = React.useState(false);
  const [checkInterval, setCheckInterval] = React.useState(false);

  // console.log("CHECK : ", {
  //   stopRefreshToken,
  //   checkInitialApp,
  //   checkInterval,
  //   modalSelector,
  //   user: checkUserLogin(),
  // });

  console.warn = function filterWarnings() {};
  console.error = function filterErrors() {};

  useEffect(() => {
    setCheckIntialApp(true);

    const interval = setInterval(() => {
      setCheckInterval(!checkInterval);
      onClickCapture(false);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (checkInitialApp && !checkUserLogin()) {
      return window.open(keyCloakRedirect, "_self");
    }
  }, [checkInitialApp]);

  const onClickCapture = async (updateActive = true) => {
    const lastActive = sessionStorage.getItem("lastActive");
    const today = new Date().getTime();
    if (lastActive !== null && today - lastActive >= +sessionExpire * 1000) {
      setStopRefreshToken(true);
      const token = getTokenFromLocalStorage();
      if (token.refresh_token !== undefined && token.refresh_token !== null) {
        await authService.logout({ refresh_token: token.refresh_token });
      }
      sessionStorage.clear();
      return dispatch(
        setModal({
          open: true,
          ...modalConfig.sessionExpiredModal,
        })
      );
    }
    if (updateActive) return sessionStorage.setItem("lastActive", today);
  };

  return (
    <ThemeProvider theme={theme}>
      <div onClickCapture={onClickCapture}>
        <PBFETimerRefreshToken stopRefreshToken={stopRefreshToken} />
        <MainRoute />
        <AlertModal
          className="z-index-9999"
          open={modalSelector.open}
          title={modalSelector.title}
          description={modalSelector.description}
          action={modalSelector.actionModal}
          hasBlur={true}
        />
      </div>
    </ThemeProvider>
  );
}

export default App;
