import * as React from "react";

export const TEXT_AUTHOR = "radium-omnibus";

export const ERROR_MESSAGE_REQUIRED = {
  TH: "จำเป็นต้องกรอกข้อมูลนี้",
  EN: "This field is required.",
};

export const ERROR_MESSAGE_LIMIT_STRING = {
  TH: "กรอกได้ไม่เกิน {value} ตัวอักษร",
  EN: "This field limit {value} characters.",
};

export const ERROR_MESSAGE_MINIMUM_STRING = {
  TH: "ต้องกรอกมากกว่า {value} ตัวอักษร",
  EN: "This field minimum {value} characters.",
};

export const ERROR_MESSAGE_LIMIT_NUMBER = {
  TH: "เลขต้องอยู่ระหว่าง 0 ถึง {value}",
  EN: "This field limit 0 to {value}.",
};

export const ERROR_MESSAGE_MINIMUM_NUMBER = {
  TH: "เลขต้องมากกว่า {value}",
  EN: "This field minimum {value}.",
};

export const ERROR_MESSAGE_NUMBER = {
  TH: "กรอกได้เฉพาะตัวเลข",
  EN: "This field must be a number.",
};
export const ERROR_MESSAGE_MINUS = {
  TH: "ตัวเลขห้ามติดลบ",
  EN: "This field must be a positive number.",
};
export const ERROR_MESSAGE_EMAIL = {
  TH: "โปรดกรอกอีเมลที่ถูกต้อง",
  EN: "Field Email Address must have a valid form",
};

export const ERROR_MESSAGE_NO_SPACE = {
  TH: "ห้ามมีช่องว่าง",
  EN: "This field must not have a space.",
};

export const ERROR_MESSAGE_LABEL_REQUIRED = {
  TH: "โปรดใส่ Label",
  EN: "Please enter a label.",
};

export const SIGN_REQUIRED = "*";

export const TEXT_UPLOAD_FILE = {
  TH: "อัพโหลดไฟล์",
  EN: "Upload File",
};

export const TEXT_DELETE_ARRAY = {
  TH: "ลบ",
  EN: "Delete",
};

export const TEXT_ADD_ARRAY = {
  TH: "เพิ่ม",
  EN: "Add",
};

export const TEXT_NO_DATA = {
  TH: "ไม่พบข้อมูล",
  EN: "No Record Found.",
};

export const TEXT_SUBMIT = {
  TH: "ยืนยัน",
  EN: "Submit",
};
