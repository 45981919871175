import React, { useMemo } from "react";
import { CSVLink } from "react-csv";

const useExportCsv = ({ fileName, headers, data }) => {
  const uuid = useMemo(() => Math.random().toString(36).substring(7), []);

  const onClickExport = () => {
    const elementExport = document.getElementById(uuid);
    elementExport.click();
  };

  const CsvFilePanel = () => (
    <CSVLink
      id={uuid}
      type="button"
      hidden
      filename={fileName}
      headers={headers}
      data={data}
    >
      Download me
    </CSVLink>
  );

  return {
    onClickExport,
    CsvFilePanel,
  };
};

export default useExportCsv;
