import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Fragment, useEffect, memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import serviceService from "../../api/serviceService";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import { PBFEContent } from "../../components/Elements/Text/content";
import PBFEError from "../../components/Error/alertError";
import { PBFEAccordionMain } from "../../components/Panel/Accordion/accordionMain";
import { PBFETaxInfo } from "../../components/Panel/TaxInfo/taxInfo";
import { PBFEFeeTable } from "../../components/Table/feeTable";
import {
  EDIT_COLUMN_WITHOUT_QUALIFIERS,
  MAPPING_FCY,
  MAX_VALUE,
  PRICING_WITHOUT_DEBIT,
  QUALIFIER_TYPE,
  RATE_TYPE,
  RATE_TYPE_NEW_OPTION,
} from "../../constants/constant";
import {
  GRAY_100,
  GRAY_BORDER_INSIDE,
  TABLE_STYLE,
  UNDERLINE,
  WHITE_BORDER_INSIDE,
  WHITE_BORDER_NOPADDING,
  WHITE_BORDER_NOPADDING_W_35,
} from "../../constants/style";
import useQuery from "../../hooks/useQuery";
import {
  calculateRowSpan,
  formateDate,
  formateDateWithTimeHourMintues,
  getClearValueObject,
  getWordSpaceUppercase,
  mappingUT10012,
} from "../../utils/util";
import {
  PBFERenderHeaderChargeFromService,
  PBFERenderHeaderChargeFromServicePosting,
} from "../WaitingForApproval/approvalDetail";
import Plus from "../../assets/icon/plus.svg";
import _, { cloneDeep } from "lodash";
import { FormatRateType } from "../../functions";
import { InputForTable } from "../../components/radium-omnibus/src/fields/inputForTable";
import { TableDropdown } from "../../components/radium-omnibus/src/fields/tableDropdown";
import DeleteIcon from "../../assets/icon/delete.svg";
import { TopperError } from "../../components/radium-omnibus/src/utils/Field.CheckError";
import { ConfirmModal } from "../../components/Panel/Modal/confirmModal";
import RenderControllerMemo from "./render/SD.RenderControllerMemo";
import exportedObject from "../../api";
import { FunctionChangeSnakeCaseToNormalCase } from "../../components/radium-omnibus/src/utils/Field.Function";
import Emitter from "../../utils/useEmitter";

const Line = () => {
  return <div className="h-10px bg-gray-100" />;
};

export const TableCellRemove = ({
  info,
  hasTier,
  isEdit = false,
  feeValueIndex,
  hasQualifer,
  conditionIndex,
  onClick,
}) => {
  const checkCondition =
    (hasQualifer && conditionIndex === 0) ||
    (!hasQualifer && feeValueIndex) === 0;

  return (
    <TableCell
      disabled={checkCondition}
      onClick={onClick}
      sx={WHITE_BORDER_NOPADDING_W_35}
      rowSpan={calculateRowSpan(
        (info?.length === undefined
          ? info.value.length - 1
          : info?.length - 1) + (isEdit && hasTier ? 1 : 0)
      )}
      align="left"
    >
      <img
        alt=""
        src={DeleteIcon}
        className={
          isEdit && conditionIndex > 0
            ? "text-textscb h-15px w-15px  cursor-pointer mr-16px"
            : "hidden"
        }
      />
    </TableCell>
  );
};

const onChangeFieldDropDownSingleTier = (setValue, name, value, index) => {
  setValue((prev) => {
    if (index === -1) {
      let temp = { ...prev };
      temp[name] = value;

      if (value === "NO") {
        const intialValue = {
          from: "",
          to: "",
          min_tier: "",
          max_tier: "",
        };
        temp.value[0] = {
          ...temp.value[0],
          ...intialValue,
        };
      }
      return { ...temp };
    }

    if (index >= 0) {
      let temp = [...prev];
      temp[index].fee_value[name] = value;

      if (value === "NO") {
        const intialValue = {
          from: "",
          to: "",
          min_tier: "",
          max_tier: "",
        };
        temp[index].fee_value.value[0] = {
          ...temp[index].fee_value.value[0],
          ...intialValue,
        };
      }
      return [...temp];
    }
  });
};

const onChangeFieldSingle = (setValue, name, value, index) => {
  setValue((prev) => {
    if (index === -1) {
      let temp = { ...prev };
      temp[name] = value;
      return { ...temp };
    }

    if (index >= 0) {
      let temp = [...prev];
      temp[index].fee_value[name] = value;
      return [...temp];
    }
  });
};

const onChangeFieldDropDownCondition = (
  setValue,
  name,
  value,
  index = 0,
  isQualifer,
  feeValueIndex
) => {
  if (feeValueIndex >= 0) {
    setValue((prev) => {
      let temp = [...prev];
      temp[feeValueIndex].fee_value.conditions[index][name] = value;
      return [...temp];
    });
  } else {
    setValue((prev) => {
      if (isQualifer) {
        prev.conditions[index][name] = value;
        return { ...prev };
      } else {
        prev[index][name] = value;
        return [...prev];
      }
    });
  }
};

const onChangeFieldCondition = (
  setValue,
  name,
  value,
  index,
  indexInfo,
  isQualifer,
  feeValueIndex
) => {
  const thisValue = value;

  if (feeValueIndex >= 0) {
    setValue((prev) => {
      if (isQualifer) {
        prev[feeValueIndex].fee_value.conditions[indexInfo].value[index] = {
          ...prev[feeValueIndex].fee_value.conditions[indexInfo].value[index],
          [name]: thisValue,
        };
        return [...prev];
      } else {
        prev[feeValueIndex].fee_value.value[index][name] = thisValue;
        return [...prev];
      }
    });
  } else {
    setValue((prev) => {
      if (isQualifer) {
        prev.conditions[indexInfo].value[index] = {
          ...prev.conditions[indexInfo]["value"][index],
          [name]: thisValue,
        };
        return { ...prev };
      } else {
        prev["value"][index] = {
          ...prev["value"][index],
          [name]: thisValue,
        };
        return { ...prev };
      }
    });
  }
};

const onClickAddTierQualifiers = (setValue, infoIndex, feeValueIndex) => {
  if (feeValueIndex >= 0) {
    setValue((prev) => {
      const cloneDeep = _.cloneDeep([...prev]);
      cloneDeep[feeValueIndex].fee_value.conditions[infoIndex].value.push({
        fix_amount: "",
        from: "",
        to: "",
        rate_type: "",
        rate: "",
      });
      return cloneDeep;
    });
  } else {
    setValue((prev) => {
      const cloneDeep = _.cloneDeep({ ...prev });

      cloneDeep.conditions[infoIndex].value.push({
        fix_amount: "",
        from: "",
        to: "",
        rate_type: "",
        rate: "",
      });
      return cloneDeep;
    });
  }
};

const onClickAddTierNonQualifiers = (setValue, index) => {
  setValue((prev) => {
    if (index === -1) {
      const cloneDeep = _.cloneDeep({ ...prev });

      cloneDeep.value.push({
        fix_amount: "",
        from: "",
        to: "",
        rate_type: "",
        rate: "",
      });
      return cloneDeep;
    } else {
      const cloneDeep = _.cloneDeep([...prev]);

      cloneDeep[index].fee_value.value.push({
        fix_amount: "",
        from: "",
        to: "",
        rate_type: "",
        rate: "",
      });
      return cloneDeep;
    }
  });
};

const onClickRemoveTier = (
  setValue,
  index,
  infoIndex,
  isQualifer,
  feeValueIndex
) => {
  if (feeValueIndex >= 0) {
    setValue((prev) => {
      const cloneDeep = _.cloneDeep([...prev]);
      if (isQualifer) {
        cloneDeep[feeValueIndex].fee_value.conditions[infoIndex].value.splice(
          index,
          1
        );
      } else {
        cloneDeep[feeValueIndex].fee_value.value.splice(index, 1);
      }
      return cloneDeep;
    });
  } else {
    setValue((prev) => {
      let cloneDeep = _.cloneDeep({ ...prev });
      if (isQualifer) {
        cloneDeep.conditions[infoIndex].value.splice(index, 1);
        return { ...cloneDeep };
      } else {
        cloneDeep.value.splice(index, 1);
        return { ...cloneDeep };
      }
    });
  }
};

const onClickRemoveQulifiers = (
  setValue,
  isQualifer,
  infoIndex,
  info,
  index,
  feeValueIndex
) => {
  if (isQualifer) {
    setValue((prev) => {
      const isArray = Array.isArray(prev);

      console.log("cloneDeep", {
        info,
        index,
        prev,
        infoIndex,
        feeValueIndex,
        isArray,
      });

      if (isArray) {
        let cloneDeep = _.cloneDeep([...prev]);
        cloneDeep[feeValueIndex].fee_value.conditions.splice(infoIndex, 1);
        return cloneDeep;
      } else if (prev?.conditions?.length > 0) {
        let cloneDeep = _.cloneDeep({ ...prev });
        cloneDeep.conditions.splice(infoIndex, 1);
        return { ...cloneDeep };
      }

      return prev;
    });
  } else {
    return;
  }
};

const onClickAddQualifier = (setValue, isQualifer, feeValueIndex) => {
  if (isQualifer) {
    if (feeValueIndex >= 0) {
      setValue((prev) => {
        const cloneDeep = _.cloneDeep([...prev]);
        const lastCondition =
          cloneDeep[feeValueIndex].fee_value.conditions[
            cloneDeep[feeValueIndex].fee_value.conditions.length - 1
          ];
        cloneDeep[feeValueIndex].fee_value.conditions.push({
          ...getClearValueObject(lastCondition),
          tier: "NO",
        });

        return cloneDeep;
      });
    } else {
      setValue((prev) => {
        let cloneDeep = _.cloneDeep({ ...prev });

        const lastCondition =
          cloneDeep.conditions[cloneDeep.conditions.length - 1];

        cloneDeep.conditions.push({
          ...getClearValueObject(lastCondition),
          tier: "NO",
        });

        return cloneDeep;
      });
    }
  } else {
    return;
  }
};

const EditRowData = ({
  info,
  infoIndex,
  hasTier,
  isEdit,
  value,
  step,
  index,
  underline,
  setValue,
  isQualifer,
  feeValueIndex,
  disableCharge,
}) => {
  // const [errorLocal, setErrorLocal] = useState([]);
  // const infoCtx = useContext(ServiceContext);

  const isTierNo = !hasTier;

  return (
    <Fragment>
      <TableRow
        sx={{
          backgroundColor: GRAY_100,
        }}
      >
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
            height: "1px",
            padding: 1,
            minWidth: "90px",
            //    width: "90px",
            verticalAlign: isEdit && "top",
          }}
        >
          <InputForTable
            index={index}
            name="from"
            disabled={!isEdit || (!hasTier && isEdit) || disableCharge}
            onChange={(e) => {
              onChangeFieldCondition(
                setValue,
                e.target.name,
                e.target.value,
                index,
                infoIndex,
                isQualifer,
                feeValueIndex
              );
            }}
            value={
              isTierNo
                ? ""
                : hasTier && isEdit
                ? step?.from === 0
                  ? "MIN"
                  : step?.from
                : step?.from === 0
                ? "MIN"
                : step?.from
            }
            // setErrorState={setErrorState}
            sync_data={
              step.to === 999999999.99
                ? step.to
                : step.to === 0
                ? 0
                : step.to === "0"
                ? 0
                : step.to
                ? step.to
                : ""
            }
            sync_name="to"
            group={
              value?.conditions?.length > 0
                ? value.conditions[infoIndex].value
                : value?.value?.length > 0
                ? value?.value
                : {}
            }
          />
        </TableCell>
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
            height: "1px",
            padding: 1,
            minWidth: "90px",
            verticalAlign: isEdit && "top",
            //     width: "90px",
            // verticalAlign: errorLocal?.length > 0 ? "top" : "",
          }}
        >
          <InputForTable
            index={index}
            name="to"
            disabled={!isEdit || (!hasTier && isEdit) || disableCharge}
            onChange={(e) => {
              onChangeFieldCondition(
                setValue,
                e.target.name,
                e.target.value,
                index,
                infoIndex,
                isQualifer,
                feeValueIndex
              );
            }}
            value={
              isTierNo
                ? "-"
                : isEdit
                ? step?.to === 999999999.99
                  ? "MAX"
                  : step?.to
                : step?.to === 999999999.99
                ? "MAX"
                : step?.to
            }
            tier={value?.tier}
          />
        </TableCell>
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
            height: "1px",
            padding: 1,
            verticalAlign: isEdit && "top",
            // verticalAlign: errorLocal?.length > 0 ? "top" : "",
          }}
        >
          <InputForTable
            index={index}
            isNumber={true}
            name="fix_amount"
            disabled={!isEdit}
            onChange={(e) => {
              onChangeFieldCondition(
                setValue,
                e.target.name,
                e.target.value,
                index,
                infoIndex,
                isQualifer,
                feeValueIndex
              );
            }}
            value={step.fix_amount === 0 ? "0" : step.fix_amount}
          />
        </TableCell>
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
            height: "1px",
            padding: 1,
            verticalAlign: isEdit && "top",
            // verticalAlign: errorLocal?.length > 0 ? "top" : "",
          }}
        >
          <InputForTable
            index={index}
            isNumber={true}
            name="rate"
            disabled={!isEdit || disableCharge}
            onChange={(e) => {
              onChangeFieldCondition(
                setValue,
                e.target.name,
                e.target.value,
                index,
                infoIndex,
                isQualifer,
                feeValueIndex
              );
            }}
            value={step.rate === 0 ? "0" : step.rate}
            sync_data={step.rate_type}
            sync_name="rate_type"
          />
        </TableCell>
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
            height: "1px",
            padding: 1,
            verticalAlign: isEdit && "top",
            // verticalAlign: errorLocal?.length > 0 ? "top" : "",
          }}
        >
          <TableDropdown
            index={index}
            error={
              (step.rate && step.rate_type === "-") ||
              (step.rate && !step.rate_type)
                ? true
                : false
            }
            disabled={!isEdit || disableCharge}
            options={RATE_TYPE_NEW_OPTION}
            value={
              step.rate_type === "PERCENT"
                ? "%"
                : step.rate_type === "THB"
                ? "AMT"
                : step.rate_type
            }
            name="rate_type"
            onChange={(e) => {
              onChangeFieldCondition(
                setValue,
                e.target.name,
                e.target.value,
                index,
                infoIndex,
                isQualifer,
                feeValueIndex
              );
            }}
          />
        </TableCell>

        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
            height: "1px",
            padding: 1,
            verticalAlign: isEdit && "top",
            // verticalAlign: errorLocal?.length > 0 ? "top" : "",
          }}
        >
          <InputForTable
            index={index}
            isNumber={true}
            name="min_tier"
            disabled={!isEdit || (!hasTier && isEdit) || disableCharge}
            onChange={(e) => {
              onChangeFieldCondition(
                setValue,
                e.target.name,
                e.target.value,
                index,
                infoIndex,
                isQualifer,
                feeValueIndex
              );
            }}
            value={step.min_tier === 0 ? "0" : step.min_tier}
            sync_data={step.max_tier}
            sync_name="max_tier"
          />
        </TableCell>
        <TableCell
          sx={{
            borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
            borderBottom: underline ? UNDERLINE : "0px",
            height: "1px",
            padding: 1,
            verticalAlign: isEdit && "top",
            // verticalAlign: errorLocal?.length > 0 ? "top" : "",
          }}
        >
          <InputForTable
            index={index}
            isNumber={true}
            name="max_tier"
            disabled={!isEdit || (!hasTier && isEdit) || disableCharge}
            onChange={(e) => {
              onChangeFieldCondition(
                setValue,
                e.target.name,
                e.target.value,
                index,
                infoIndex,
                isQualifer,
                feeValueIndex
              );
            }}
            sync_data={step.min_tier}
            value={step.max_tier === 0 ? "0" : step.max_tier}
          />
        </TableCell>

        {isEdit && (
          <TableCell
            sx={{
              borderTop: index > 0 ? "" : WHITE_BORDER_INSIDE,
              borderBottom: underline ? UNDERLINE : "0px",
              height: "1px",
              padding: 0,
              minWidth: "25px",
              width: "25px",
            }}
          >
            <img
              alt=""
              onClick={(e) => {
                e.preventDefault();
                onClickRemoveTier(
                  setValue,
                  index,
                  infoIndex,
                  isQualifer,
                  feeValueIndex
                );
              }}
              src={DeleteIcon}
              className={
                hasTier && index > 0
                  ? "text-textscb  h-15px w-15px cursor-pointer"
                  : "hidden"
              }
            />
          </TableCell>
        )}

        {index === 0 && (
          <TableCell
            sx={{
              ...WHITE_BORDER_NOPADDING,
            }}
            rowSpan={calculateRowSpan(
              (info?.length === undefined
                ? info.value.length - 1
                : info?.length - 1) + (isEdit && hasTier ? 1 : 0)
            )}
          >
            <InputForTable
              index={index}
              isNumber={true}
              name="min"
              disabled={!isEdit || disableCharge}
              onChange={(e) => {
                isQualifer
                  ? onChangeFieldDropDownCondition(
                      setValue,
                      e.target.name,
                      e.target.value,
                      infoIndex,
                      isQualifer,
                      feeValueIndex
                    )
                  : onChangeFieldSingle(
                      setValue,
                      "min",
                      e.target.value,
                      feeValueIndex
                    );
              }}
              value={
                isQualifer
                  ? info.min === 0
                    ? "0"
                    : info.min
                  : value?.min === 0
                  ? "0"
                  : value?.min
              }
              sync_data={isQualifer ? info.max : value?.max}
              sync_name="max"
            />
          </TableCell>
        )}
        {index === 0 && (
          <TableCell
            sx={{
              ...WHITE_BORDER_NOPADDING,
              // verticalAlign: errorLocal?.length > 0 ? "top" : "",
            }}
            rowSpan={calculateRowSpan(
              (info?.length === undefined
                ? info.value.length - 1
                : info?.length - 1) + (isEdit && hasTier ? 1 : 0)
            )}
          >
            <InputForTable
              index={index}
              isNumber={true}
              name="max"
              disabled={!isEdit || disableCharge}
              onChange={(e) => {
                isQualifer
                  ? onChangeFieldDropDownCondition(
                      setValue,
                      e.target.name,
                      e.target.value,
                      infoIndex,
                      isQualifer,
                      feeValueIndex
                    )
                  : onChangeFieldSingle(
                      setValue,
                      "max",
                      e.target.value,
                      feeValueIndex
                    );
              }}
              value={
                isQualifer
                  ? info.max === 0
                    ? "0"
                    : info.max
                  : value?.max === 0
                  ? "0"
                  : value?.max
              }
            />
          </TableCell>
        )}

        {isEdit && index === 0 && (
          <TableCellRemove
            info={info}
            index={index}
            hasTier={hasTier}
            isEdit={isEdit}
            onClick={(e) => {
              onClickRemoveQulifiers(
                setValue,
                isQualifer,
                infoIndex,
                info,
                index,
                feeValueIndex
              );
            }}
            feeValueIndex={index}
            hasQualifer={isQualifer}
            conditionIndex={infoIndex}
          />
        )}
      </TableRow>
    </Fragment>
  );
};

const RenderCondtion = ({
  info,
  index,
  hasTier,
  setValue,
  isQualifer,
  value,
  feeValueIndex,
  posibleValueOfQualifires,
  fieldIndex,
  disableCharge,
}) => {
  // const [errorLocal, setErrorLocal] = useState([]);

  const isEdit = true;

  return (
    <Fragment key={index}>
      <TableRow sx={{ backgroundColor: GRAY_100 }}>
        <TableCell
          sx={{
            width: "60px",
            height: "1px",
            padding: 1,
            borderBottom: GRAY_BORDER_INSIDE,
            borderTop: WHITE_BORDER_INSIDE,
          }}
          rowSpan={calculateRowSpan(
            info?.value?.length + (isEdit && hasTier ? 1 : 0)
          )}
        >
          <InputForTable
            isNumber={true}
            formatNumber={false}
            name="priority"
            disabled={
              !isEdit ||
              !(
                posibleValueOfQualifires &&
                Object.keys(posibleValueOfQualifires).length > 0
              )
            }
            onChange={(e) => {
              onChangeFieldDropDownCondition(
                setValue,
                e.target.name,
                e.target.value,
                index,
                isQualifer,
                feeValueIndex
              );
            }}
            value={info?.priority}
            // setErrorState={setErrorState}
          />
        </TableCell>
        {Object.keys(posibleValueOfQualifires)?.length > 0 &&
          Object.keys(posibleValueOfQualifires)?.map(
            (qualifierKey, qualifireIndex) => {
              let originalKey = {};

              Object?.keys(posibleValueOfQualifires)?.map((key) => {
                originalKey = {
                  ...originalKey,
                  [_.upperCase(key)]: posibleValueOfQualifires[key],
                };
              });

              const upperCaseQualifierKey = _.upperCase(qualifierKey);

              const optionsQualifiers = cloneDeep(
                originalKey[upperCaseQualifierKey]
              );

              return (
                <TableCell
                  key={`${qualifireIndex}_cell_table_qualifires`}
                  sx={{
                    width: "60px",
                    height: "54px",
                    padding: 1,
                    borderBottom: GRAY_BORDER_INSIDE,
                    borderTop: WHITE_BORDER_INSIDE,
                  }}
                  rowSpan={
                    calculateRowSpan(info?.value?.length) +
                    (isEdit && hasTier ? 1 : 0)
                  }
                >
                  <TableDropdown
                    options={optionsQualifiers}
                    value={
                      info?.qualifiers[qualifierKey]
                        ? info?.qualifiers[qualifierKey]
                        : []
                    }
                    name={qualifierKey}
                    isMulti={true}
                    conditionIndex={index}
                    onChange={(e) => {
                      const { value } = e.target;

                      setValue((prev) => {
                        if (prev?.conditions) {
                          // console.log("IN 1", { prev, clone });
                          const clone = cloneDeep(prev);
                          clone.conditions[index].qualifiers[qualifierKey] =
                            value;

                          return clone;
                        } else {
                          const clone = cloneDeep([...prev]);

                          // console.log("IN 2", {
                          //   prev,
                          //   clone,
                          //   index,
                          //   feeValueIndex,
                          //   qualifierKey,
                          //   fieldIndex,
                          // });

                          clone[feeValueIndex].fee_value.conditions[
                            index
                          ].qualifiers[qualifierKey] = value;

                          return clone;
                        }
                      });
                    }}
                  />
                </TableCell>
              );
            }
          )}
        <TableCell
          sx={{
            width: "60px",
            height: "54px",
            padding: 1,
            borderBottom: GRAY_BORDER_INSIDE,
            borderTop: WHITE_BORDER_INSIDE,
          }}
          rowSpan={calculateRowSpan(
            info?.value?.length + (isEdit && hasTier ? 1 : 0)
          )}
        >
          {isEdit ? (
            <TableDropdown
              options={
                disableCharge
                  ? ["NO"]
                  : info?.value?.length > 1
                  ? ["STEP", "SLAB"]
                  : ["NO", "STEP", "SLAB"]
              }
              value={info?.tier}
              name="tier"
              onChange={(e) => {
                onChangeFieldDropDownCondition(
                  setValue,
                  e.target.name,
                  e.target.value,
                  index,
                  isQualifer,
                  feeValueIndex
                );
              }}
            />
          ) : (
            <div>{info?.tier}</div>
          )}
        </TableCell>
      </TableRow>

      {info?.value?.map((step, self_index) => {
        return (
          <EditRowData
            info={info}
            infoIndex={index}
            hasTier={hasTier}
            isEdit={isEdit}
            // data={data}
            step={step}
            index={self_index}
            underline={self_index < info?.value?.length - 1 || self_index > 0}
            setValue={setValue}
            isQualifer={isQualifer}
            feeValueIndex={feeValueIndex}
            value={value}
            disableCharge={disableCharge}
          />
        );
      })}
      {hasTier && isEdit && (
        <TableRow
          sx={{
            backgroundColor: GRAY_100,
            padding: 0,
          }}
        >
          <TableCell
            colSpan={EDIT_COLUMN_WITHOUT_QUALIFIERS}
            sx={{
              borderBottom: "0px",
              height: "1px",
              padding: 0,
            }}
          >
            <div className="w-full flex justify-end  text-textscb text-10px px-0 py-fourteen">
              <div>TIER</div>
              <img
                alt=""
                onClick={(e) => {
                  e.stopPropagation();
                  onClickAddTierQualifiers(setValue, index, feeValueIndex);
                }}
                className="ml-10px h-15px w-15px cursor-pointer"
                src={Plus}
              ></img>
            </div>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

const TableEditForQualifiers = ({
  value,
  setValue,
  isQualifer,
  feeValueIndex,
  posibleValueOfQualifires = {},
  fieldIndex,
  disableCharge = false,
}) => {
  return (
    <Fragment>
      {value?.conditions?.map((info, index) => {
        const hasTier = info?.tier !== "NO";
        return (
          <React.Fragment>
            <RenderCondtion
              key={index}
              info={info}
              index={index}
              hasTier={hasTier}
              value={value}
              setValue={setValue}
              isQualifer={isQualifer}
              feeValueIndex={feeValueIndex}
              posibleValueOfQualifires={posibleValueOfQualifires}
              fieldIndex={fieldIndex}
              disableCharge={disableCharge}
            />
          </React.Fragment>
        );
      })}
    </Fragment>
  );
};

const TableEditForNonQualifiers = ({
  value,
  setValue,
  isQualifer,
  feeValueIndex,
  disableCharge = false,
}) => {
  // const [errorLocal, setErrorLocal] = useState([]);

  const isEdit = true;

  const hasTier = value?.tier !== "NO";

  return (
    <Fragment>
      {value?.tier && (
        <TableRow sx={{ backgroundColor: GRAY_100 }}>
          <TableCell
            sx={{
              width: "60px",
              height: "54px",
              padding: 0,
              borderBottom: GRAY_BORDER_INSIDE,
              borderTop: WHITE_BORDER_INSIDE,
            }}
            rowSpan={calculateRowSpan(
              value?.value?.length + (isEdit && hasTier ? 1 : 0)
            )}
          >
            <TableDropdown
              name="tier"
              options={
                disableCharge
                  ? ["NO"]
                  : value?.value?.length > 1
                  ? ["STEP", "SLAB"]
                  : ["NO", "STEP", "SLAB"]
              }
              value={value?.tier}
              onChange={(e) => {
                onChangeFieldDropDownSingleTier(
                  setValue,
                  "tier",
                  e.target.value,
                  feeValueIndex
                );
              }}
            />
          </TableCell>
        </TableRow>
      )}
      {value?.value?.map((step, self_index) => {
        return (
          <EditRowData
            disableCharge={disableCharge}
            infoIndex={0}
            isQualifer={false}
            info={value?.value}
            hasTier={hasTier}
            isEdit={isEdit}
            step={step}
            index={self_index}
            underline={self_index < value?.value?.length - 1 || self_index > 0}
            setValue={setValue}
            feeValueIndex={feeValueIndex}
            value={value}
          />
        );
      })}
      {hasTier && isEdit && (
        <TableRow
          sx={{
            borderBottom: "0px",
            backgroundColor: GRAY_100,
            height: "1px",
            padding: 0,
          }}
        >
          <TableCell
            colSpan={EDIT_COLUMN_WITHOUT_QUALIFIERS}
            sx={{
              borderBottom: "0px",
              height: "1px",
              padding: 0,
            }}
          >
            <div className="w-full flex justify-end  text-textscb text-10px px-0 py-fourteen">
              <div>TIER</div>
              <img
                alt=""
                onClick={() => {
                  onClickAddTierNonQualifiers(setValue, feeValueIndex);
                }}
                className="ml-10px h-15px w-15px cursor-pointer"
                src={Plus}
              ></img>
            </div>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

const TableHeadQualifiers = ({ value }) => {
  if (value) {
    let qualiferObjectKey = {};

    // Object.keys(value)?.length > 0 &&
    //   Object.keys(value)?.map((qualifierKey, qualifireIndex) => {
    //     console.log("qualifierKey", { qualifierKey, qualifireIndex });
    //     let originalKey = {};

    //     Object?.keys(value)?.map((key) => {
    //       originalKey = {
    //         ...originalKey,
    //         [_.upperCase(key)]: value[key],
    //       };

    //       console.log("originalKey", originalKey);
    //     });
    //   });

    // value?.conditions?.forEach((condition) => {
    //   console.log("condition", condition);
    const listQualifierKeys = Object.keys(value);
    listQualifierKeys.forEach((qualiferKey) => {
      qualiferObjectKey = {
        ...qualiferObjectKey,
        [qualiferKey]: "",
      };
    });
    // });

    // const listQualifierKeys = Object.keys(qualiferObjectKey);
    // console.log("listQualifierKeys", listQualifierKeys);

    if (listQualifierKeys?.length > 0) {
      return listQualifierKeys.map((qualifier, index) => {
        return (
          <TableCell key={index}>
            {QUALIFIER_TYPE[qualifier]
              ? QUALIFIER_TYPE[qualifier]
              : FunctionChangeSnakeCaseToNormalCase(qualifier)}
            {/* {getWordSpaceUppercase(qualifier?.replace("_", " "))} */}
          </TableCell>
        );
      });
    }
    return null;
  }

  return null;
};

export const RenderTable = ({
  value,
  setValue,
  index = -1,
  posibleValueOfQualifires = {},
  fieldIndex = -1,
  isDisabledCharge = null,
}) => {
  // const isQualifer = value?.conditions?.length > 0;
  const isQualifer = value?.conditions?.length > 0;
  const [disableCharge, setDisableCharge] = React.useState(false);

  React.useEffect(() => {
    if (isDisabledCharge === true || isDisabledCharge === false) {
      setDisableCharge(isDisabledCharge);
    }
    return () => {};
  }, [isDisabledCharge]);

  // React.useEffect(() => {
  //   if
  // }, []);

  React.useEffect(() => {
    Emitter.on(`pricing_method_${fieldIndex}`, (data) => {
      if (data?.value === "SUBSCRIPTION" || data?.value === "ONE_TIME") {
        setValue({
          tier: "NO",
          min: "",
          max: "",
          fixed_amount: "",
          value: [
            {
              fixed_amount: "",
              fix_amount: "",
              rate: "",
              min: "",
              max: "",
            },
          ],
        });
        setDisableCharge(true);
      } else {
        setDisableCharge(false);
      }
    });
    return () => {
      Emitter.off(`pricing_method_${fieldIndex}`);
    };
  }, []);

  return (
    <div className="border-t border-b pb-fourteen">
      <TableContainer>
        <Table sx={TABLE_STYLE}>
          <TableHead>
            <TableRow>
              {isQualifer && <TableCell>Priority</TableCell>}
              {isQualifer && (
                <TableHeadQualifiers value={posibleValueOfQualifires} />
              )}
              <TableCell>Tier</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Fixed Amount</TableCell>
              <TableCell>Rate</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Tier Min</TableCell>
              <TableCell>Tier Max</TableCell>
              {<TableCell></TableCell>}
              <TableCell>Min Charge</TableCell>
              <TableCell>Max Charge</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ marginTop: "16px" }}>
            {value?.conditions ? (
              <TableEditForQualifiers
                value={value}
                setValue={setValue}
                isQualifer={isQualifer}
                feeValueIndex={index}
                posibleValueOfQualifires={posibleValueOfQualifires}
                fieldIndex={fieldIndex}
                disableCharge={disableCharge}
              />
            ) : (
              <TableEditForNonQualifiers
                value={value}
                setValue={setValue}
                isQualifer={isQualifer}
                feeValueIndex={index}
                disableCharge={disableCharge}
              />
            )}
            {value?.conditions && (
              <TableRow sx={{ backgroundColor: GRAY_100 }}>
                <TableCell
                  sx={{
                    height: "1px",
                    padding: 0,
                    borderBottom: GRAY_BORDER_INSIDE,
                    borderTop: WHITE_BORDER_INSIDE,
                  }}
                  colSpan={MAX_VALUE}
                >
                  <div className="w-full flex justify-end px-10px text-textscb text-10px py-10px">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickAddQualifier(setValue, isQualifer, index);
                      }}
                      className="flex"
                    >
                      <div>QUALIFIER</div>
                      <img
                        className="ml-10px h-15px w-15px cursor-pointer"
                        src={Plus}
                      ></img>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export const RenderServiceDetail = ({
  data,
  isHistory = false,
  dataHistory,
}) => {
  return (
    <div className="w-full pt-xxl">
      {isHistory && (
        <div>
          <div className="grid grid-cols-4 gap-4 px-xl py-fourteen ">
            <PBFEContent
              label="Update Date"
              value={formateDateWithTimeHourMintues(
                dataHistory?.create_datetime
              )}
            />
            <div className="col-span-2">
              <PBFEContent
                label="Update User"
                value={dataHistory?.create_user}
              />
            </div>
            <PBFEContent label="Type" value={dataHistory?.action} />
            <div className="col-span-4">
              <PBFEContent label="Remark" value={dataHistory?.remark} />
            </div>
          </div>
          <Line />
        </div>
      )}
      <div className="px-xl py-fourteen ">
        <div className="grid grid-cols-4">
          <div className="col-span-3">
            <PBFEContent label="Name" value={data?.service_name} />
          </div>
          <PBFEContent label="Code" value={data?.service_code} />
        </div>
        <div className="grid grid-cols-4 mt-25px">
          <PBFEContent label="From" value={formateDate(data?.effective_from)} />
          <PBFEContent label="To" value={formateDate(data?.effective_to)} />
          <div className="col-span-2">
            <PBFEContent
              label="Transaction Code"
              isArray={true}
              value={data?.transaction_mapping}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 mt-25px">
          <div className="col-span-2">
            <PBFEContent
              label="Statement Description"
              value={data?.statement_description}
            />
          </div>
          <PBFEContent
            label="Monthly Posting Day"
            value={data?.monthly_posting_day}
          />
          <PBFEContent
            label="FCY Service"
            value={data?.fcy_flag ? MAPPING_FCY[data?.fcy_flag] : "-"}
          />
        </div>
        <div className="mt-25px">
          <PBFEContent
            label="Debit Advice Description"
            value={data?.debit_advice_name ? data?.debit_advice_name : "-"}
          />
        </div>
        <div className="grid grid-cols-4 mt-25px">
          <div className="col-span-2">
            <PBFEContent
              label="Thai Description in Email Content"
              value={data?.alert_name_th ? data?.alert_name_th : "-"}
            />
          </div>

          <PBFEContent
            label="English Description in Email Content"
            value={data?.alert_name_eng ? data?.alert_name_eng : "-"}
          />

          {data?.fcy_flag === "Y" && (
            <PBFEContent
              label="PMH Instruction Type"
              value={data?.pmh_instr_type ? data?.pmh_instr_type : "-"}
            />
          )}
        </div>
      </div>
      <Line />
      <PBFEAccordionMain
        label="Revenue GL"
        detail={<PBFETaxInfo data={data} type={"Revenue"} container="py-0" />}
      />
      <Line />
      <PBFEAccordionMain
        label="Alternative Revenue GL"
        detail={
          <PBFETaxInfo
            hasStatement={true}
            data={{
              alt_statement_desc: data?.alt_statement_desc,
              gl_description: data?.alt_gl_description,
              gl_product_code: data?.alt_gl_product_code,
              gl_account_code: data?.alt_gl_account_code,
              gl_tax_code: data?.alt_gl_tax_code,
              gl_rc_code: data?.alt_gl_rc_code,
              gl_oc_code: data?.alt_gl_oc_code,
            }}
            type={"Revenue"}
            container="py-0"
          />
        }
      />
      <Line />
      <PBFEAccordionMain
        label="Vat GL"
        detail={
          <PBFETaxInfo data={data?.vat_gl} type={"VAT"} container="py-0" />
        }
      />
      <Line />
      <PBFEAccordionMain
        label="Withholding Tax GL"
        detail={
          <PBFETaxInfo data={data?.wht_gl} type={"WHT"} container="py-0" />
        }
      />
      <Line />
      {/* <PBFEAccordionMain
        label="Charges"
        detail={<PBFEChargesTable type="Service" data={data?.fee_charge} />}
      /> */}
      {data?.fee_charge.map((fee, index) => {
        // console.log("fee", fee);
        return (
          <React.Fragment key={index}>
            <PBFEAccordionMain
              headerDetail={
                <div className="px-6 mt-25px">
                  <PBFERenderHeaderChargeFromService
                    charge={{ fee_charge: { ...fee } }}
                  />
                  <PBFERenderHeaderChargeFromServicePosting
                    charge={{ fee_charge: { ...fee } }}
                  />
                </div>
              }
              label={`${fee.charge_code} - ${fee.charge_name}`}
              detail={<PBFEFeeTable data={fee} type={``} />}
              //  hasToogle ={fee.price_without_post === "N"}
              fullScreen={fee.price_without_post === "Y"}
              hasToogle={fee.price_without_post === "Y"}
              showLabel={fee.price_without_post === "Y"}
              wfull={""}
              toogleLabel={PRICING_WITHOUT_DEBIT}
              footer={
                <React.Fragment>
                  <div className="grid grid-cols-5 pl-xl pb-20px">
                    <div className="text-textlabel text-label">
                      Debit Advice Item Description
                    </div>
                    <div className="col-span-2 text-textlabel text-label">
                      {fee?.dr_adv_item_desc}
                    </div>
                    <PBFEContent
                      mode="flex w-24"
                      label={`Display Volume`}
                      value={fee?.show_volume_flag === "Y" ? "YES" : "NO"}
                    />
                    <PBFEContent
                      mode="flex w-24"
                      label={`Display Value`}
                      value={fee?.show_value_flag === "Y" ? "YES" : "NO"}
                    />
                  </div>
                </React.Fragment>
              }
            />
            {/* <Line /> */}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export const RenderServiceEditMode = memo(
  ({ data, isCreate, params, before }) => {
    const navigate = useNavigate();
    const [error, setError] = React.useState([]);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [loadingAction, setLoadingAction] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [submitData, setSubmitData] = React.useState(null);
    const [serverError, setServerError] = React.useState(null);
    const [taxscheme, setTaxscheme] = React.useState([]);

    React.useEffect(() => {
      LoadTaxScheme();
    }, []);

    const LoadTaxScheme = async () => {
      try {
        const {
          data: { data },
        } = await exportedObject.instanceCorp.get("/v1/tax-schemes");
        setTaxscheme(data);
      } catch (error) {
        console.eroor(error);
      }
    };

    const submited = async () => {
      try {
        setLoadingAction(true);
        let ret;
        if (isCreate) {
          ret = await serviceService.postService(submitData);
        } else {
          ret = await serviceService.updateService(params, submitData);
        }
        if (ret.data.status.description === "Success") {
          setIsSuccess(true);
          setShowConfirm(false);
          setServerError(null);
          navigate("/service");
        }
      } catch (e) {
        setServerError(e.response.data.status.description);
      } finally {
        setLoadingAction(false);
      }
    };

    return (
      <div className="w-full pt-xxl">
        {error && (
          <TopperError
            isShow={errorOpen}
            setOpen={setErrorOpen}
            open={errorOpen}
            errorArray={error}
          />
        )}
        <ConfirmModal
          confirmText="OK"
          onClick={submited}
          loading={loadingAction}
          isSuccess={isSuccess}
          isError={serverError}
          message={serverError}
          open={showConfirm}
          onClose={() => {
            setShowConfirm(false);
            setIsSuccess(false);
            setServerError(null);
          }}
          text={
            "Do you want to " + (isCreate ? "create" : "edit") + " service ?"
          }
        />
        <RenderControllerMemo
          isCreate={isCreate}
          data={data}
          setError={setError}
          setErrorOpen={setErrorOpen}
          setSubmitData={setSubmitData}
          setShowConfirm={setShowConfirm}
          taxscheme={taxscheme}
        />
      </div>
    );
  }
);

const ServiceDetail = ({ setRouteName, setActionAppbar, action = false }) => {
  const params = useParams();

  const [isEdit, setIsEdit] = React.useState(false);

  const isCreate = params?.service_code === "create";

  const prePareData = (data) => {
    for (let index = 0; index < data.fee_charge.length; index++) {
      if (data.fee_charge[index].charge_code === "UT10012") {
        data.fee_charge[index] = mappingUT10012([data?.fee_charge[index]])[0];
      }
    }
    return data;
  };

  const { data, loading, error } = useQuery(
    serviceService.getServiceInfo,
    {
      service_code: params.service_code,
    },
    {},
    [params.service_code],
    { prePareData, checkFirstLoad: isCreate ? false : true }
  );

  useEffect(() => {
    if (isCreate) {
      setRouteName([
        { title: "SERVICE", path: "/service" },
        { title: "CREATE", path: `/service/create` },
      ]);
    }
    if (data !== undefined && !isCreate) {
      setRouteName([
        { title: "SERVICE", path: "/service" },
        { title: data?.service_code, path: `/service/${data?.service_code}` },
      ]);
    }
    setActionAppbar("");
  }, [data, setRouteName]);

  useEffect(() => {
    let mounted = true;
    if (action === "EDIT") {
      setIsEdit(true);
      setRouteName([
        { title: "SERVICE", path: `/service` },
        {
          title: `${data?.service_code}`,
          path: `/service/${data?.service_code}`,
          setSelected: setIsEdit,
          check: true,
        },
        {
          title: "EDIT SERVICE",
        },
      ]);
    }
    return () => {
      mounted = false;
    };
  }, [action]);

  if (loading) {
    return (
      <div className="h-screen m-auto">
        <PBFELoading />
      </div>
    );
  }

  if (error && !isCreate) return <PBFEError error={error} />;

  if (isEdit || isCreate) {
    return (
      <RenderServiceEditMode
        data={data}
        isCreate={isCreate}
        params={params}
        before={data}
      />
    );
  } else {
    return <RenderServiceDetail data={data} />;
  }
};

export default ServiceDetail;
