import React from "react";
import { Alert, AlertTitle, Collapse } from "@mui/material";

function PBFEInvalid({
  open,
  setOpen,
  message,
  errorState = [],
  id,
  isShow,
  requireShowField = true,
  pt = "pt-xxl",
}) {

  return (
    <div className={`w-full ${pt}`} id="invalid">
      {isShow && (
        <Collapse in={open}>
          <Alert
            onClose={() => {
              setOpen(false);
            }}
            severity="error"
          >
            <AlertTitle>ValidationError</AlertTitle>
            {message}
            <ul className="px-4 list-decimal">
              {errorState.length > 0 &&
                errorState.map((item, index) => {
                  if (!item.show && requireShowField) return null;
                  return <li>{item.message}</li>;
                })}
            </ul>
          </Alert>
        </Collapse>
      )}
    </div>
  );
}

export default PBFEInvalid;
