import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavLink } from "react-router-dom";
import { Checkbox, FormControlLabel } from "@mui/material";
import { PBFECommonTable } from "../../components/Table/commonTable";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
} from "../../components/Panel/Accordion/accordionMain";
import { PBFETaxInfo } from "../../components/Panel/TaxInfo/taxInfo";
import useQuery from "../../hooks/useQuery";
import { TAX_HEAD_CELLS, TAX_SCHEME_TITLE } from "../../constants/constant";
import {
  formateDate,
  formateDateWithTimeHourMintues,
  formatPercent,
  isValidDate,
} from "../../utils/util";
import PBFEError from "../../components/Error/alertError";
import { TaxCreate } from "./taxCreate";
import { HistoryModal } from "../../components/Panel/Modal/historyModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import historyService from "../../api/historyService";
import taxSchemeService from "../../api/taxSchemeService";
import { PBFEContent } from "../../components/Elements/Text/content";

export const TaxInfoHistory = ({ setRouteName }) => {
  const params = useParams();
  const [selectedHistory, setSelectedHistory] = useState({});
  const { data, loading, error } = useQuery(
    historyService.getTaxSchemeHistory,
    {},
    {
      tax_scheme_id: params.tax_scheme_id,
      offset: 0,
      limit: 100,
    },

    []
  );

  useEffect(() => {
    setRouteName([
      {
        title: TAX_SCHEME_TITLE,
        path: "/taxScheme",
      },
      {
        title: params.tax_scheme_id,
        path: "/taxScheme/" + params.tax_scheme_id,
      },
      {
        title: "HISTORY",
      },
    ]);
    for (let i = 0; i < data?.length; i++) {
      if (data[i].tax_scheme_history_id == params.tax_scheme_history_id) {
        setSelectedHistory(data[i]);
      }
    }
  }, [data]);

  if (loading) return <PBFELoading />;
  if (error) return <PBFEError error={error} />;

  console.log("history", selectedHistory?.history_data);

  return (
    <div className="w-full overflow-auto pt-xxl ">
      <div className="grid grid-cols-4 gap-4 p-large">
        <PBFEContent
          label="Update Date"
          value={formateDateWithTimeHourMintues(
            selectedHistory?.create_datetime
          )}
        />
        <div className="col-span-2">
          <PBFEContent
            label="Update User"
            value={selectedHistory?.create_user}
          />
        </div>
        <PBFEContent label="Type" value={selectedHistory?.action} />
        <div className="col-span-4">
          <PBFEContent label="Remark" value={selectedHistory?.remark} />
        </div>
      </div>
      <div className="h-4px bg-gray-100"></div>
      <div className="grid grid-cols-4 gap-4  p-large">
        <div className="col-span-2">
          <PBFEContent
            label="Name"
            value={selectedHistory?.history_data?.taxSchemeName}
          />
        </div>
        <div className="col-span-2">
          <PBFEContent
            label="Tax Type"
            value={selectedHistory?.history_data?.taxType}
          />
        </div>
        <PBFEContent
          label="GL Description"
          value={selectedHistory?.history_data?.glDescription}
        />
        <PBFEContent
          label="Product Code"
          value={selectedHistory?.history_data?.glProductCode}
        />
        <PBFEContent
          label="Account Code"
          value={selectedHistory?.history_data?.glAccountCode}
        />
        <PBFEContent
          label="Tax Code"
          value={selectedHistory?.history_data?.glTaxCode}
        />
        <PBFEContent
          label="GL RC"
          value={selectedHistory?.history_data?.glRcCode}
        />
        <PBFEContent
          label="GL OC"
          value={selectedHistory?.history_data?.glOcCode}
        />
      </div>
    </div>
  );
};
