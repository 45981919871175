import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PBFEError from "../../components/Error/alertError";
import { PBFECommonTableAsync } from "../../components/Table/commonTableAsync";
import { MY_REQUEST_TABLE_HEADER } from "../../constants/constant";

import React from "react";

import useQuery from "../../hooks/useQuery";
import { ColorStatus, formateDateWithTimeHourMintues, TwoLineColumn } from "../../utils/util";
import Skeleton from "react-loading-skeleton";
import myRequestService from "../../api/myRequestSerive";

const MyRequest = ({ setRouteName, mt, disabledRouteName = false }) => {
  const limit = 15;

  const {
    data: rows,
    setData: setRows,
    loading,
    error,
    totalRecords,
    refetch,
  } = useQuery(
    myRequestService.getMyRequest,
    {},
    {
      offset: 0,
      limit,
    },
    []
  );

  const [searched, setSearched] = useState("");
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setRouteName([
      {
        title: "MY REQUEST",
        path: "/myrequest",
      },
    ]);
  }, [setRouteName, disabledRouteName]);

  if (error) return <PBFEError error={error} />;

  return (
    <div className={"w-full  pt-md mt-" + mt}>
      {loading ? (
        <div className="p-fourteen">
          <Skeleton
            containerTestId="react-loading-skeleton"
            height={30}
            width={`100%`}
            count={9}
          />
        </div>
      ) : (
        <PBFECommonTableAsync
          // hasUtilIndex={true}
          orderDefault="desc"
          disabledSort
          // orderByDefault="request_date"
          // sort_name_by="request_date"
          limit={limit}
          refetch={refetch}
          total_records={totalRecords}
          searched={searched}
          page={page}
          setPage={setPage}
          headCells={MY_REQUEST_TABLE_HEADER}
          rows={rows}
          showFooter={true}
          onClickRow={(row) => {
            navigate(`/myrequest/${row.request_id}`);
          }}
          selectable={true}
          formatControl={[
            ColorStatus,
            null,
            formateDateWithTimeHourMintues,
            TwoLineColumn,
            null,
            null,
            null,
            null,
          ]}
          showFields={[
            "status",
            "transaction_reference",
            "request_date",
            "request_type,customer_name,account_id",
            "code",
            "maker",
            "status",
            "reject_reason",
          ]}
        />
      )}
    </div>
  );
};

const Waitingforapprove = ({
  setRouteName,
  primaryDisplay,
  mt = "50px",
  disabledRouteName = false,
  setActionAppbar,
}) => {
  useEffect(() => {
    if (setActionAppbar) {
      setActionAppbar("MY_REQUEST");
    }
  }, []);

  return (
    <MyRequest
      mt={mt}
      primaryDisplay={primaryDisplay}
      setRouteName={setRouteName}
      disabledRouteName={disabledRouteName}
    />
  );
};

export default Waitingforapprove;
