import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
// import { pbfeVersion } from "../../../config";

// context
import { DrawerContext } from "../../../context/drawerContext";

// components
import PBFEListbar from "../../Layout/Listbar/listbar";

//icon//
import IconButton from "@mui/material/IconButton";
// import LogoutIcon from "../../assets/icon/logout.svg";
import MenuIcon from "@mui/icons-material/Menu";

import LogoutIcon from "@mui/icons-material/Logout";

import ScbLogo from "../../../assets/images/SCB-logo.svg";
import ScbUserIcon from "../../../assets/icon/user.svg";
import ScbUserGroupIcon from "../../../assets/icon/usergroup.svg";
import ScbServiceIcon from "../../../assets/icon/service.svg";
import ScbOfferIcon from "../../../assets/icon/offer.svg";
import ScbYourrequest from "../../../assets/icon/yourrequest.svg";
import ScbWaiting from "../../../assets/icon/waiting.svg";
import Scbtaxshceme from "../../../assets/icon/taxscheme.svg";
import ScbCustomer from "../../../assets/icon/customer.svg";
import ScbAccount from "../../../assets/icon/account.svg";
import ScbReport from "../../../assets/icon/report.svg";
import UserIcon from "../../../assets/icon/userSolo.svg";
import BankIcon from "../../../assets/icon/bank.svg";
import UploadIcon from "../../../assets/icon/upload.svg";
import PBFEListbarCollapse from "../../Layout/ListbarCollapse/listbarCollapse";
import PBFESecondaryButton from "../../Elements/Button/secondaryButton";
import { DRAWER } from "../../../constants/style";
import {
  ACCOUNT_PATH_NAME,
  ACCOUNT_ROUTE,
  ADMIN,
  CUSTOMER_INFO,
  CUSTOMER_PATH_NAME,
  CUSTOMER_ROUTE,
  DATA_UPLOAD,
  DESIGN,
  GROUP_PATH_NAME,
  GROUP_ROUTE,
  MAKER_CHECKER_REQUEST_PATH_NAME,
  MAKER_CHECKER_REQUEST_ROUTE,
  MAKER_CHECKER_WAITING_PATH_NAME,
  MAKER_CHECKER_WAITING_ROUTE,
  OFFER_PATH_NAME,
  OFFER_ROUTE,
  PROFILE_PATH_NAME,
  PROFILE_ROUTE,
  REPORT,
  REPORT_FEE_PATH_NAME,
  REPORT_FEE_ROUTE,
  REPORT_PRICE_PATH_NAME,
  REPORT_PRICE_TXN_SUMMARY_PATH_NAME,
  REPORT_PRICE_TXN_SUMMARY_ROUTE,
  REPORT_PRICE_ROUTE,
  SERVICE_PATH_NAME,
  SERVICE_ROUTE,
  SETTINGS,
  SETTINGS_PATH_NAME,
  SETTINGS_ROUTE,
  TAX_PATH_NAME,
  TAX_ROUTE,
  UPLOAD_PATH_NAME,
  UPLOAD_ROUTE,
  BANK_BRANCH_PATH_NAME,
  BANK_BRANCH_ROUTE,
  EMAIL_ALERT_REPORT_PATH_NAME,
  OVERDUE_REPORT_PATH_NAME,
  REPORT_OVERDUE,
  CUSTOMER_FEE_PROFILE_REPORT,
  REPORT_CUSTOMER_FEE_PROFILE,
  REPORT_EMAIL_ALERT,
  OFFER_BUNDLE_PATH_NAME,
  OFFER_BUNDLE_ROUTE,
  REPORT_OPERATION_ROUTE,
  REPORT_OPERATION,
  REPORT_FEE_PROFILE_PATH_NAME,
  REPORT_FEE_PROFILE_ROUTE
} from "../../../constants/constant";
import { currentTime } from "../../../functions";
import authService from "../../../api/authService";
import { getTokenFromLocalStorage } from "../../../utils/util";
import { keyCloakRedirect } from "../../../config";
import { useNavigate } from "react-router-dom";

const openedMixin = (theme) => ({
  width: DRAWER,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `42px`,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(2, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER,
  flexShrink: 0,
  whiteSpace: "nowrap",
  // boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function PBFEDrawer({ version }) {
  const { openDrawer, setOpenDrawer } = useContext(DrawerContext);
  const { user } = useSelector((state) => state.auth);
  const [now, setNow] = useState(currentTime());

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    let updateTimer = setInterval(() => {
      setNow(currentTime());
    }, 10000);
    return () => clearInterval(updateTimer);
  }, []);

  const onClickLogout = async () => {
    try {
      const token = getTokenFromLocalStorage();
      await authService.logout({ refresh_token: token.refresh_token });
      sessionStorage.clear();
      window.open(keyCloakRedirect, "_self");
    } catch (error) {
      console.log("logout error", error);
    }
  };

  const checkMakerChecker = () => {
    const permission = user?.menu;

    let temp_perrmission = [];

    const my_request = {
      text: MAKER_CHECKER_REQUEST_PATH_NAME,
      imgIcon: ScbYourrequest,
      path: MAKER_CHECKER_REQUEST_ROUTE,
    };
    const waiting_for_approve = {
      text: MAKER_CHECKER_WAITING_PATH_NAME,
      imgIcon: ScbWaiting,
      path: MAKER_CHECKER_WAITING_ROUTE,
    };

    if (permission?.my_request === "E" || permission?.my_requesr === "V") {
      temp_perrmission.push(my_request);
    }
    if (
      permission?.waiting_for_approval === "E" ||
      permission?.waiting_for_approval === "V"
    ) {
      temp_perrmission.push(waiting_for_approve);
    }

    return temp_perrmission;
  };

  const maker_checker_manu = checkMakerChecker();

  return (
    <Drawer variant="permanent" open={openDrawer}>
      {openDrawer ? (
        <div></div>
      ) : (
        <div className="p-md w-42px">
          <IconButton
            color="default"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge={false}
            sx={{
              width: "100%",
              ...(openDrawer && { display: "none" }),
            }}
          >
            <MenuIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </div>
      )}
      {openDrawer && (
        <div className="m-10px">
          <DrawerHeader>
            <div className="w-full flex flex-col items-center justify-center space-y-2 text-textgray text-10px">
              <div className="flex justify-between w-full items-center">
                <div
                  onClick={() => navigate("/")}
                  className="flex justify-center items-center cursor-pointer"
                >
                  <img alt="" className="" src={ScbLogo} />
                </div>
                <IconButton onClick={handleDrawerClose}>
                  <MenuIcon sx={{ fontSize: 18 }} />
                </IconButton>
              </div>
              <div className="bg-gray-100 w-full rounded-md  flex">
                <div>
                  <img alt="user_icon" className="py-4 px-2" src={UserIcon} />
                </div>
                <div className="grid grid-cols-1 py-2 my-auto">
                  <div
                    className="uppercase text-10px truncate"
                    title={user?.profile?.user_name}
                  >
                    {user?.profile?.user_name}
                  </div>
                  <div className="uppercase text-8px">
                    {user?.profile?.user_id}
                  </div>
                </div>
              </div>

              <PBFESecondaryButton
                onClick={onClickLogout}
                startIcon={<LogoutIcon />}
                text="Logout"
              />
            </div>
          </DrawerHeader>
          {user?.menu && (
            <React.Fragment>
              <div className="text-textgray">
                <Divider />
                {maker_checker_manu?.length > 0 && (
                  <React.Fragment>
                    <PBFEListbar items={maker_checker_manu} />
                    <Divider />
                  </React.Fragment>
                )}
                {user?.menu?.admin === "E" || user?.menu?.admin === "V" ? (
                  <React.Fragment>
                    <PBFEListbarCollapse
                      items={[
                        {
                          text: PROFILE_PATH_NAME,
                          imgIcon: ScbUserIcon,
                          path: PROFILE_ROUTE,
                        },
                        {
                          text: GROUP_PATH_NAME,
                          imgIcon: ScbUserGroupIcon,
                          path: GROUP_ROUTE,
                        },
                      ]}
                      textHeader={ADMIN}
                    />
                    <Divider />
                  </React.Fragment>
                ) : (
                  ""
                )}
                {user?.menu?.design === "E" || user?.menu?.design === "V" ? (
                  <React.Fragment>
                    <PBFEListbarCollapse
                      items={[
                        {
                          text: TAX_PATH_NAME,
                          imgIcon: Scbtaxshceme,
                          path: TAX_ROUTE,
                        },
                        {
                          text: SERVICE_PATH_NAME,
                          imgIcon: ScbServiceIcon,
                          path: SERVICE_ROUTE,
                        },
                        {
                          text: OFFER_PATH_NAME,
                          imgIcon: ScbOfferIcon,
                          path: OFFER_ROUTE,
                        },
                        {
                          text: OFFER_BUNDLE_PATH_NAME,
                          imgIcon: ScbOfferIcon,
                          path: OFFER_BUNDLE_ROUTE,
                        },
                        {
                          text: BANK_BRANCH_PATH_NAME,
                          imgIcon: BankIcon,
                          path: BANK_BRANCH_ROUTE,
                        },
                      ]}
                      textHeader={DESIGN}
                    />
                    <Divider />
                  </React.Fragment>
                ) : (
                  ""
                )}

                {user?.menu?.customer === "E" ||
                user?.menu?.customer === "V" ? (
                  <React.Fragment>
                    <PBFEListbarCollapse
                      items={[
                        {
                          text: CUSTOMER_PATH_NAME,
                          imgIcon: ScbCustomer,
                          path: CUSTOMER_ROUTE,
                        },
                        {
                          text: ACCOUNT_PATH_NAME,
                          imgIcon: ScbAccount,
                          path: ACCOUNT_ROUTE,
                        },
                      ]}
                      textHeader={CUSTOMER_INFO}
                    />
                    <Divider />
                  </React.Fragment>
                ) : (
                  ""
                )}
                {user?.menu?.data_upload === "E" ||
                user?.menu?.data_upload === "V" ? (
                  <React.Fragment>
                    <PBFEListbarCollapse
                      textHeader={DATA_UPLOAD}
                      items={[
                        {
                          text: UPLOAD_PATH_NAME,
                          imgIcon: UploadIcon,
                          path: UPLOAD_ROUTE,
                        },
                      ]}
                    />
                    <Divider />
                  </React.Fragment>
                ) : (
                  ""
                )}
                {user?.menu?.report === "E" || user?.menu?.report === "V" ? (
                  <React.Fragment>
                    <PBFEListbarCollapse
                      items={[
                        {
                          text: REPORT_PRICE_PATH_NAME,
                          imgIcon: ScbReport,
                          path: REPORT_PRICE_ROUTE,
                        },
                        {
                          text: REPORT_FEE_PATH_NAME,
                          imgIcon: ScbReport,
                          path: REPORT_FEE_ROUTE,
                        },
                        {
                          text: REPORT_PRICE_TXN_SUMMARY_PATH_NAME,
                          imgIcon: ScbReport,
                          path: REPORT_PRICE_TXN_SUMMARY_ROUTE,
                        },
                        {
                          text: EMAIL_ALERT_REPORT_PATH_NAME,
                          imgIcon: ScbReport,
                          path: REPORT_EMAIL_ALERT,
                        },
                        {
                          text: OVERDUE_REPORT_PATH_NAME,
                          imgIcon: ScbReport,
                          path: REPORT_OVERDUE,
                        },
                        {
                          text: CUSTOMER_FEE_PROFILE_REPORT,
                          imgIcon: ScbReport,
                          path: REPORT_CUSTOMER_FEE_PROFILE,
                        },
                        {
                          text: REPORT_OPERATION,
                          imgIcon: ScbReport,
                          path: REPORT_OPERATION_ROUTE,
                        },
                        {
                          text: REPORT_FEE_PROFILE_PATH_NAME,
                          imgIcon: ScbReport,
                          path: REPORT_FEE_PROFILE_ROUTE,
                        }
                      ]}
                      textHeader={REPORT}
                    />
                    <Divider />
                  </React.Fragment>
                ) : (
                  ""
                )}
                {user?.menu?.settings === "E" ||
                user?.menu?.settings === "V" ? (
                  <React.Fragment>
                    <PBFEListbarCollapse
                      textHeader={SETTINGS}
                      items={[
                        {
                          text: SETTINGS_PATH_NAME,
                          imgIcon: ScbReport,
                          path: SETTINGS_ROUTE,
                        },
                      ]}
                    />
                    <Divider />
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      )}
      {openDrawer && (
        <div className="fixed w-200px bottom-0 mt-auto text-10px flex flex-row justify-between bg-gray-100 p-xs">
          <div className="text-textsecoundary">{now}</div>
          <div className="text-textsecoundary">{version}</div>
        </div>
      )}
    </Drawer>
  );
}

export default PBFEDrawer;
