import { TextField } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import NoFound from "../../../components/Error/noFound";
import { Information } from "../../../components/Panel/Information/information";
import { modalConfig } from "../../../config";
import {
  postSendApproverCustomerService,
  setConfirmText,
  setStateComponent,
} from "../../../redux/actionAppbarSlice";
import { mappingOfferApprover } from "../../../utils/mappingData";
import { getValidateOfferError, onScrollToTop } from "../../../utils/util";

const AccountRenderOffer = ({
  account,
  customer,
  data,
  dataRequest,
  accountTable = [],
  hideButton = false,
  setIsEdit,
  isEdit = false,
  isNew = false,
  setIsEditWhereData,
  debitProfile,
  setDebitProfile,
  originalData,
  setErrorState,
  is_maker,
  setRouteName,
  checkConfirmError,
  setCheckConfirmError,
  errorState,
  refetch,
  setDataRequest = () => {},
  remark,
}) => {
  const [dataOffer, setDataOffer] = React.useState([]);
  // const [remark, setRemark] = React.useState("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    setDataOffer(data?.offer);
    // setRemark(data?.remark || "")
  }, []);

  React.useEffect(() => {
    const dontDoThis = !dataOffer.length || !isNew || !isEdit;
    if (!dontDoThis) {
      dispatch(
        setStateComponent({
          stateComponent: "customerService",
          onClickAction: onClickSendToApprover,
          onClickBeforeAction,
        })
      );
      dispatch(setConfirmText(modalConfig.sendApproverConfirm.description));
    }
  }, [
    dataOffer,
    debitProfile,
    dataRequest,
    errorState,
    checkConfirmError,
    setCheckConfirmError,
    originalData,
    remark,
  ]);

  const onClickBeforeAction = (setShowConfirm) => {
    let checkError = false;

    const listError = getValidateOfferError(_.cloneDeep(dataOffer[0]));
    console.log("listError", listError);

    if (listError.length > 0) {
      checkError = true;
      setErrorState((prev) => {
        return [...prev, { field_name: "validate_error", show: false }];
      });
    } else {
      setErrorState((prev) =>
        prev.filter((item) => item.field_name !== "validate_error")
      );
    }

    if (!debitProfile.length) {
      setErrorState((prev) => {
        const find_exists = prev.find(
          (item) => item.field_name === "posting_account"
        );
        if (!find_exists) {
          prev.push({
            error: true,
            message: "Please select debit profile",
            field_name: "posting_account",
            field_value: [],
            show: true,
          });
        }
        return [...prev];
      });
      checkError = true;
    }

    if (
      errorState?.filter(
        (item) => !["validate_error"].includes(item.field_name)
      ).length > 0
    ) {
      checkError = true;
    }

    if (checkError) {
      setCheckConfirmError(true);
      onScrollToTop("invalid");
    } else {
      setShowConfirm(true);
    }
  };

  const onClickSendToApprover = () => {
    dispatch(
      postSendApproverCustomerService({
        data: mappingOfferApprover(
          {
            ...data,
            ...account,
            ...dataOffer[0],
            posting_account: debitProfile,
          },
          originalData,
          dataRequest,
          remark
        ),
      })
    );
  };

  if (!data?.offer?.length) return <NoFound label={"No Offer Found"} />;
  else
    return (
      <div>
        {dataOffer?.map((offer, index) => {
          return (
            <Information
              setDataRequest={setDataRequest}
              refetch={refetch}
              setCheckConfirmError={setCheckConfirmError}
              setErrorState={setErrorState}
              customer={customer}
              account={account}
              is_maker={is_maker}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              key={index}
              disabledDebitProfile={true}
              origin={data}
              isOffer={true}
              account_table={offer?.posting_account}
              label={offer?.offer_name + " - " + offer?.offer_code}
              data={offer}
              setData={setDataOffer}
              index={index}
              accountTable={accountTable}
              setIsEditWhereData={setIsEditWhereData}
              hideButton={hideButton}
              isNew={isNew}
              type="offer"
              setDebitProfile={setDebitProfile}
              debitProfile={debitProfile}
              setRouteName={setRouteName}
            />
          );
        })}
        {/* {(isEdit || isNew) && (
          <div className="ml-40px mr-25px">
            <TextField
              id="remark"
              onChange={(e) => setRemark(e.target.value)}
              value={remark}
              label="REMARK"
              helperText={"Limited " + remark?.length + "/300"}
              variant="filled"
              fullWidth
              inputProps={{ maxLength: 300 }}
            />
          </div>
        )} */}
      </div>
    );
};

export default AccountRenderOffer;
