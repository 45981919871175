import {
  Checkbox,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ERROR_MESSAGE_REQUIRED } from "../../../../utils/errorMessage";

export const TableDropdown = ({
  options = [],
  value,
  onChange,
  isMulti,
  name,
  error = false,
  disabled = false,
}) => {
  const [focus, setFocus] = useState("");

  const errorBorder = "border border-red-500 h-10 w-full mb-10 ";

  return (
    <div className={error ? errorBorder : focus}>
      <Select
        id={`dropdown-${name}`}
        disabled={disabled}
        name={name}
        value={value}
        IconComponent={() => <div></div>}
        onChange={onChange}
        variant="standard"
        sx={{
          color: "#7A58BF",
          fontSize: 16,
          paddingY: "6px",
          width: "100%",
        }}
        disableUnderline
        onFocus={() => setFocus("border-2 border-textscb")}
        onBlur={() => setFocus("")}
        multiple={isMulti}
        renderValue={
          isMulti
            ? (selected) => {
                if (!selected?.length) {
                  return <div>-</div>;
                }

                return selected.join(",\n");
              }
            : null
        }
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        {options?.map((item, index) => {
          return (
            <MenuItem key={index} value={item}>
              {isMulti ? (
                <React.Fragment>
                  <Checkbox checked={value?.indexOf(item) > -1} />
                  <ListItemText primary={item} />
                </React.Fragment>
              ) : (
                item
              )}
            </MenuItem>
          );
        })}
      </Select>
      {error && (
        <div className="w-24">
          <FormHelperText id={`error-${name}`} className="Mui-error">
            {ERROR_MESSAGE_REQUIRED}
          </FormHelperText>
        </div>
      )}
    </div>
  );
};
