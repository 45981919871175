import { cloneDeep } from "lodash";
import * as React from "react";

const FunctionRemoveObjectFromError = (data) => {
  const createNewData = data?.map((row) => {
    const keys = Object.keys(row);
    const newObject = {};
    keys.forEach((key) => {
      if (typeof row[key] !== "object") {
        newObject[key] = row[key];
      }
      if (typeof row[key] === "object" && row[key]?.error) {
        newObject[key] = row[key]?.value;
      }
      if (typeof row[key] === "object" && !row[key]?.error) {
        newObject[key] = row[key];
      }
    });

    // console.log("newObject", newObject);

    return newObject;
  });

  // console.log("DATA : ", { data, createNewData });

  return createNewData;
};

export const FieldDeleteStep = (
  props,
  index,
  setThisStep,
  setKeepData,
  setDeleteindex
) => {
  if (props?.triggerDelete) {
    props?.triggerDelete(props, index);
  }

  setThisStep((prev) => {
    let temp = [...prev];
    temp.splice(index, 1);
    return temp;
  });

  if (props.grandParentKey === props.parentKey) {
    if (
      props.FieldReference.current[props.parentIndex][props.parentKey]?.length
    ) {
      let cloneData = cloneDeep(
        props.FieldReference.current[props.parentIndex][props.parentKey]
      );

      cloneData.splice(index, 1);

      setKeepData({
        [props.parentKey]: FunctionRemoveObjectFromError(cloneData),
      });
      setDeleteindex(index);
    }
  } else {
    if (
      props.FieldReference.current[props.grandParentIndex][
        props.grandParentKey
      ][props.parentIndex][props.parentKey].length
    ) {
      let cloneData = cloneDeep(
        props.FieldReference.current[props.grandParentIndex][
          props.grandParentKey
        ][props.parentIndex][props.parentKey]
      );

      cloneData.splice(index, 1);

      // console.log("IN 2");

      const newData = {
        ...props.FieldReference.current[props.grandParentIndex],
        [props.grandParentKey]: {
          ...props.FieldReference.current[props.grandParentIndex][
            props.grandParentKey
          ],
          [props.parentIndex]: {
            ...props.FieldReference.current[props.grandParentIndex][
              props.grandParentKey
            ][props.parentIndex],
            [props.parentKey]: FunctionRemoveObjectFromError(cloneData),
          },
        },
      };

      setKeepData(newData);
      setDeleteindex(index);
    }
  }
};

export const FunctionDeleteReferenceAfterDelete = (
  props,
  index,
  setDeleteindex
) => {
  if (props.grandParentKey === props.parentKey) {
    if (
      props.FieldReference.current[props.parentIndex][props.parentKey]?.length
    ) {
      props.FieldReference.current[props.parentIndex][props.parentKey].splice(
        index,
        1
      );
      setDeleteindex(-1);
    }
  } else {
    if (
      props.FieldReference.current[props.grandParentIndex][
        props.grandParentKey
      ][props.parentIndex][props.parentKey].length
    ) {
      props.FieldReference.current[props.grandParentIndex][
        props.grandParentKey
      ][props.parentIndex][props.parentKey].splice(index, 1);
      setDeleteindex(-1);
    }
  }
};
