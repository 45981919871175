import { cloneDeep } from "lodash";
import { TYPE_SELECT } from "../type";

export const FunctionLoadDataTypeSelect = (props, value) => {
  const self = props.options.find((i) => i.value === value);
  if (self) {
    props.setValue(self);
  } else {
    props.setValue(value);
  }
};

export const FunctionCoreLoadDataFirstStep = (props) => {
  try {
    const value = cloneDeep(props.loadData[props.name]);
    // console.log("PROPS 1 : ", { props, value });

    if (props.type === TYPE_SELECT) {
      FunctionLoadDataTypeSelect(props, value);
    } else {
      props.setValue(value);
    }
  } catch (error) {}
};

export const FunctionCoreLoadDataSecondStep = (props) => {
  try {
    // console.log("PROPS 2 : ", props);
    const value = cloneDeep(
      props.loadData[props.grandParentKey][props.parentIndex][props.parentKey][
        props.fieldIndex
      ][props.name]
    );

    if (props.type === TYPE_SELECT) {
      FunctionLoadDataTypeSelect(props, value);
    } else {
      props.setValue(value);
    }
  } catch (error) {}
};

export const FunctionCoreLoadDataThirdStep = (props) => {
  try {
    // console.log("PROPS 3 : ", props);
    const value = cloneDeep(
      props.loadData[props.parentKey][props.fieldIndex][props.name]
    );

    if (props.type === TYPE_SELECT) {
      FunctionLoadDataTypeSelect(props, value);
    } else {
      props.setValue(value);
    }
  } catch (error) {}
};

export const FunctionCoreinitalValueFirstStep = (props) => {
  try {
    const value = cloneDeep(props.initalValue[props.name]);
    // console.log("PROPS 1 : ", { props, value });

    if (props.type === TYPE_SELECT) {
      FunctionLoadDataTypeSelect(props, value);
    } else {
      props.setValue(value);
    }
  } catch (error) {}
};

export const FunctionCoreinitalValueSecondStep = (props) => {
  try {
    // console.log("PROPS 2 : ", props);
    const value = cloneDeep(
      props.initalValue[props.grandParentKey][props.parentIndex][
        props.parentKey
      ][props.fieldIndex][props.name]
    );

    if (props.type === TYPE_SELECT) {
      FunctionLoadDataTypeSelect(props, value);
    } else {
      props.setValue(value);
    }
  } catch (error) {}
};

export const FunctionCoreinitalValueThirdStep = (props) => {
  try {
    // console.log("PROPS 3 : ", props);
    const value = cloneDeep(
      props.initalValue[props.parentKey][props.fieldIndex][props.name]
    );

    if (props.type === TYPE_SELECT) {
      FunctionLoadDataTypeSelect(props, value);
    } else {
      props.setValue(value);
    }
  } catch (error) {}
};

export const FunctionLoadData = (props) => {
  // setTimeout(() => {
  if (props.loadData && props.name) {
    try {
      if (
        props.parentKey &&
        props.fieldIndex >= 0 &&
        props.loadData[props.parentKey]?.length - 1 >= props.fieldIndex &&
        props.grandParentKey === props.parentKey &&
        props.grandParentIndex >= 0
      ) {
        FunctionCoreLoadDataThirdStep(props);
      } else if (
        props.grandParentIndex >= 0 &&
        props.parentKey &&
        props.fieldIndex >= 0 &&
        props.grandParentKey !== props.parentKey &&
        props.loadData[props.grandParentKey][props.parentIndex][props.parentKey]
          ?.length -
          1 >=
          props.fieldIndex
      ) {
        FunctionCoreLoadDataSecondStep(props);
      } else if (
        props.loadData[props.name] ||
        props.loadData[props.name] === 0
      ) {
        FunctionCoreLoadDataFirstStep(props);
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  } else {
    return;
  }
  return;
  // }, 200);
};

export const FunctionInitalData = (props) => {
  if ((props.loadData && props.name) || (props.initalValue && props.name)) {
    try {
      if (
        props.parentKey &&
        props.fieldIndex >= 0 &&
        props.initalValue[props.parentKey]?.length - 1 >= props.fieldIndex &&
        props.grandParentKey === props.parentKey &&
        props.grandParentIndex >= 0
      ) {
        FunctionCoreinitalValueThirdStep(props);
      } else if (
        props.grandParentIndex >= 0 &&
        props.parentKey &&
        props.fieldIndex >= 0 &&
        props.grandParentKey !== props.parentKey &&
        props.initalValue[props.grandParentKey][props.parentIndex][
          props.parentKey
        ]?.length -
          1 >=
          props.fieldIndex
      ) {
        FunctionCoreinitalValueSecondStep(props);
      } else if (props.initalValue[props.name]) {
        FunctionCoreinitalValueFirstStep(props);
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  } else {
    return;
  }
  return;
};

export const FunctionLoadOriginalData = (props) => {
  if (props.loadData || props.initalValue) {
    try {
      if (
        props.parentKey &&
        props.fieldIndex >= 0 &&
        props.loadData[props.parentKey]?.length - 1 >= props.fieldIndex &&
        props.grandParentKey === props.parentKey &&
        props.grandParentIndex >= 0
      ) {
        return props.loadData[props.parentKey][props.fieldIndex];
      } else if (
        props.grandParentIndex >= 0 &&
        props.parentKey &&
        props.fieldIndex >= 0 &&
        props.grandParentKey !== props.parentKey &&
        props.loadData[props.grandParentKey][props.parentIndex][props.parentKey]
          ?.length -
          1 >=
          props.fieldIndex
      ) {
        return props.loadData[props.grandParentKey][props.parentIndex][
          props.parentKey
        ][props.fieldIndex];
      } else if (
        props.loadData[props.name] ||
        props.loadData[props.name] === 0
      ) {
        return props.loadData;
      } else if (
        props.parentKey &&
        props.fieldIndex >= 0 &&
        props.initalValue[props.parentKey]?.length - 1 >= props.fieldIndex &&
        props.grandParentKey === props.parentKey &&
        props.grandParentIndex >= 0
      ) {
        return props.initalValue[props.parentKey][props.fieldIndex];
      } else if (
        props.grandParentIndex >= 0 &&
        props.parentKey &&
        props.fieldIndex >= 0 &&
        props.grandParentKey !== props.parentKey &&
        props.initalValue[props.grandParentKey][props.parentIndex][
          props.parentKey
        ]?.length -
          1 >=
          props.fieldIndex
      ) {
        return props.initalValue[props.grandParentKey][props.parentIndex][
          props.parentKey
        ][props.fieldIndex];
      } else if (props.initalValue[props.name]) {
        return props.initalValue;
      } else {
        return "No Data";
      }
    } catch (error) {
      return;
    }
  } else {
    return;
  }
};
