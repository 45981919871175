import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import NumberFormat from "react-number-format";
import {
  checkFormToValue,
  FormatNumber,
  getValidateError,
  renderDropdownValue2,
} from "../../../../functions";

export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={name === "rate" ? 99 : 2}
      className={`${other.className} inputForNumber`}
    />
  );
});

export const FormatCustomForFromTo = React.forwardRef(
  function FormatCustomForFromTo(props, ref) {
    const { onChange, name, ...other } = props;

    return (
      <input
        {...other}
        getInputRef={ref}
        onChange={(e) => {
          onChange({
            target: {
              name: name,
              value: e.target.value,
            },
          });
        }}
        className={`${other.className} inputForNumber`}
      />
    );
  }
);

const useStyles = makeStyles(() => ({
  noBorder: {
    // border: "none",
    borderColor: "rgb(243 244 246)",
    borderRadius: 0,
    textTransform: "uppercase",
  },
  input: {
    color: "#7A58BF",
    textTransform: "uppercase",
  },
  isNumber: {
    minWidth: "100px",
  },
}));

export const InputForTable = ({
  errorText = "",
  value,
  onChange,
  disabled,
  isNumber = false,
  name,
  isEdit,
  formatNumber = true,
  tier = "",
  sync_data = "",
  sync_name = "",
  group,
  index,
  className = "",
  fullWidth = false,
}) => {
  const classes = useStyles();

  const getError = (errorText === "" &&
    name &&
    getValidateError(name, value, tier, sync_name, sync_data)) || {
    error: false,
    message: "",
    tier: "",
  };

  const getPrvError =
    group?.length > 1 &&
    index > 0 &&
    checkFormToValue(
      value,
      group[index - 1][sync_name] ? group[index - 1][sync_name] : 0
    );

  if (disabled && !isEdit) {
    if (typeof renderDropdownValue2(name, value) === "number" && formatNumber) {
      return (
        <div id={`input-${name}`} className="text-14px py-10px">
          {FormatNumber(renderDropdownValue2(name, value))}
        </div>
      );
    } else {
      return (
        <div id={`input-${name}`} className="text-14px py-10px">
          {renderDropdownValue2(name, value)}
        </div>
      );
    }
  }
  return (
    <TextField
      id={`input-${name}`}
      className={className}
      disabled={disabled}
      placeholder={"-"}
      size="small"
      margin="none"
      value={value}
      name={name}
      fullWidth={fullWidth}
      error={
        getPrvError?.length > 0
          ? true
          : errorText !== ""
          ? errorText
          : getError?.error && getError?.message !== ""
      }
      onChange={onChange}
      variant="outlined"
      helperText={
        getPrvError
          ? getPrvError
          : errorText !== ""
          ? errorText
          : getError?.message
      }
      classes={{ notchedOutline: classes.input }}
      InputProps={{
        classes: { notchedOutline: classes.noBorder },
        className: !disabled && classes.input,
        inputComponent:
          (isNumber && NumberFormatCustom) ||
          (["from", "to"].includes(name) && FormatCustomForFromTo),
      }}
    />
  );
};
