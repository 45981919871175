import { useParams } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import serviceService from "../../api/serviceService";
import React from "react";
import { PBFELoading } from "../../components/Elements/Loading/loading";
import PBFEError from "../../components/Error/alertError";
import { RenderServiceDetail } from ".";

const ServiceHistoryMain = ({ setRouteName }) => {
  const params = useParams();
  const [dataHistory, setDataHistory] = React.useState({});
  const { data, loading, error } = useQuery(
    serviceService.getHistoryService,
    {},
    {
      service_code: encodeURI(params.service_code),
      offset: 0,
      limit: 100,
    },
    []
  );

  console.log("data", { data, params });

  React.useEffect(() => {
    if (data?.length > 0 && !dataHistory?.service_code) {
      const findSelf = data?.find((item) => {
        return (
          Number(item.fee_service_history_id) === Number(params.history_id)
        );
      });

      if (findSelf) {
        setRouteName([
          { title: "SERVICE", path: "/service" },
          {
            title: findSelf?.service_code,
            path: `/service/${findSelf?.service_code}`,
          },
          {
            title: `HISTORY`,
          },
        ]);
        setDataHistory(findSelf);
      }
    }
  }, [data, params]);

  if (loading) return <PBFELoading />;
  if (error) return <PBFEError error={error} />;

  return (
    <RenderServiceDetail
      data={dataHistory?.raw_data}
      isHistory={true}
      dataHistory={dataHistory}
    />
  );
};

export default ServiceHistoryMain;
