import api from "./index";
import { apiConfig } from "../config";

const getAllRequest = async (params, query) => {
  return api.instanceCorp.get(`${apiConfig.getAllRequestAPI}${query}`);
};

const getMakerChecker = async (params, query) => {
  return api.instanceCorp.get(`${apiConfig.getMakerCheckerAPI}${query}`);
};

const getCheckerAllRequest = async (params, query) => {
  return api.instanceCorp.get(
    `${apiConfig.getMakerCheckerAllRequestAPI}${query}`
  );
};

const getMakerAllRequest = async (params, query) => {
  return api.instanceCorp.get(
    `${apiConfig.getMakerMakerAllRequestAPI}${query}`
  );
};

const postMakerChecker = async (body) => {
  return api.instanceCorp.post(`${apiConfig.postMakerCheckerAPI}`, {
    ...body,
  });
};

const getMakerCheckerInfoById = async (params, query) => {
  return api.instanceCorp.get(
    `${apiConfig.postMakerCheckerAPI}/${params.request_id}`
  );
};

const updateMakerCheckerApprove = async (params, query, body) => {
  return api.instanceCorp.put(
    `${apiConfig.postMakerCheckerAPI}/${params.request_id}/approve`
  );
};

const updateMakerCheckerReject = async (params, query, body) => {
  return api.instanceCorp.put(
    `${apiConfig.postMakerCheckerAPI}/${params.request_id}/status-update`,
    {
      ...body,
    }
  );
};

const getMakerCheckerValidatePending = async (params, query) => {
  return api.instanceCorp.get(
    `${apiConfig.getMakerValidatePendingAPI}${query}`
  );
};

const exportedObject = {
  getAllRequest,
  getMakerChecker,
  getMakerAllRequest,
  getCheckerAllRequest,
  postMakerChecker,
  getMakerCheckerInfoById,
  updateMakerCheckerApprove,
  updateMakerCheckerReject,
  getMakerCheckerValidatePending,
};

export default exportedObject;
