import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import TablePagination from "@mui/material/TablePagination";
import { ROW_PER_PAGE } from "../../constants/constant";
import { getComparator, stableSort } from "../../functions";
import {
  TABLE_STYLE_WITH_HOVER_STANDARD_WITDH,
  TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER,
} from "../../constants/style";
import NoRecordFound from "../Error/noRecordFound";

export const PBFESettingTable = ({
  headCells,
  rows,
  showFooter = false,
  selectable = false,
  onClickRow,
  page = 0,
  setPage,
  orderByDefault = "",
  hasUtilIndex = false,
  formatControl,
  showFields = [],
  searched,
  overwriteStyle = null,
  disabledSort = false,
  orderDefault = "asc",
  showNoData = false,
  rowsPerPage = ROW_PER_PAGE,
  disabledSortList = [],
}) => {
  const [order, setOrder] = React.useState(orderDefault);
  // set default order by here
  const [orderBy, setOrderBy] = React.useState(orderByDefault);
  const [sortBy, setSortBy] = React.useState(true);

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const getDisabledSort = (headCell) => {
      if (disabledSort) {
        return disabledSort;
      } else if (disabledSortList.length > 0) {
        return disabledSortList.some((item) => item === headCell.id);
      }

      return false;
    };

    return (
      <TableHead>
        <TableRow hover={false}>
          {headCells.map((headCell) => {
            return (
              <TableCell
                // rowSpan={2}
                // colSpan={}
                key={headCell.id}
                align={"left"}
                padding={"normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  // aria-rowspan={2}
                  disabled={
                    disabledSort ? disabledSort : getDisabledSort(headCell)
                  }
                  // disabled={disabledSort}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortBy(!sortBy);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const DataArrayTable = disabledSort
    ? rows
    : stableSort(rows, getComparator(order, orderBy), orderBy, sortBy);
  const convertShowFieldsObject = (row) => {
    let temp = [];
    if (showFields.length === 0) {
      /// Non Show Field case
      Object.keys(row).map((key) => temp.push(row[key]));
    } else {
      for (let i = 0; i < showFields.length; i++) {
        const key = showFields[i];
        if (row[key]) {
          temp.push(row[key]);
        } else {
          temp.push("");
        }
      }
    }
    return temp;
  };

  if (showNoData && DataArrayTable.length <= 0) {
    return (
      <TableContainer>
        <NoRecordFound />
      </TableContainer>
    );
  }

  return (
    <div>
      <TableContainer>
        {searched?.length > 2 && rows?.length <= 0 ? (
          <NoRecordFound />
        ) : (
          <Table
            sx={TABLE_STYLE_WITH_HOVER_STANDARD_WITDH}
            aria-label="simple table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              rowCount={rows?.length}
              onRequestSort={handleRequestSort}
              disabledSortList={disabledSortList}
            />
            <TableBody>
              {DataArrayTable?.map((row, index) => {
                return (
                  <TableRow
                    id="table-row"
                    key={index}
                    hover={selectable}
                    onClick={() => onClickRow && onClickRow(row)}
                    sx={TABLE_STYLE_WITH_HOVER_WITHOUT_BOTTOM_BORDER}
                  >
                    {convertShowFieldsObject(row).map((item, index) => {
                      if (overwriteStyle !== null) {
                        return (
                          <TableCell
                            id="table-cell"
                            align="left"
                            key={index}
                            sx={overwriteStyle[index]}
                          >
                            {item}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell
                          id="table-cell"
                          align="left"
                          key={index}
                          sx={
                            index === 0 && hasUtilIndex ? { width: "35px" } : {}
                          }
                        >
                          {formatControl === undefined ||
                          formatControl[index] == null
                            ? item
                            : formatControl[index](item)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {rows?.length > 0 && showFooter && (
        <div className="border-t">
          <TablePagination
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[]}
            showFirstButton={true}
            showLastButton={true}
          />
        </div>
      )}
    </div>
  );
};
