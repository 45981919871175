import * as React from "react";

import {
  ERROR_MESSAGE_EMAIL,
  ERROR_MESSAGE_LIMIT_NUMBER,
  ERROR_MESSAGE_LIMIT_STRING,
  ERROR_MESSAGE_MINIMUM_NUMBER,
  ERROR_MESSAGE_MINIMUM_STRING,
  ERROR_MESSAGE_MINUS,
  ERROR_MESSAGE_NO_SPACE,
  ERROR_MESSAGE_NUMBER,
  ERROR_MESSAGE_REQUIRED,
} from "./Field.Constant";
import {
  FunctionCheckEmail,
  FunctionCheckIsNumber,
  FunctionCheckIsRequired,
  FunctionCheckLimit,
  FunctionCheckMinimum,
  FunctionCheckNoSpace,
  // FunctionCheckLimitString,
} from "../utils/Field.Function";
import {
  TYPE_CALCULATOR,
  TYPE_MODEL_ERROR_EMAIL,
  TYPE_MODEL_ERROR_LIMIT,
  TYPE_MODEL_ERROR_MINIMUM,
  TYPE_MODEL_ERROR_NO_SPACE,
  TYPE_MODEL_ERROR_NUMBER,
  TYPE_MODEL_ERROR_REQURIED,
  TYPE_MODE_NORMAL,
  TYPE_NUMBER,
} from "../type";

export const FunctionCheckValidation = (
  mode,
  language,
  modelError = [],
  name = "",
  value = "",
  type = "",
  errorCustoms
) => {
  if (name) {
    let temp = [];

    modelError.forEach((key) => {
      if (key === TYPE_MODEL_ERROR_REQURIED) {
        temp.push(
          FunctionCheckIsRequired(value, ERROR_MESSAGE_REQUIRED[language])
        );
      } else if (key === TYPE_MODEL_ERROR_NUMBER) {
        temp.push(
          FunctionCheckIsNumber(
            value,
            ERROR_MESSAGE_NUMBER[language],
            ERROR_MESSAGE_MINUS[language]
          )
        );
      } else if (key === TYPE_MODEL_ERROR_EMAIL) {
        temp.push(FunctionCheckEmail(value, ERROR_MESSAGE_EMAIL[language]));
      } else if (key?.split(":")[0] === TYPE_MODEL_ERROR_LIMIT) {
        const limit = Number(key?.split(":")[1]);
        temp.push(
          FunctionCheckLimit(
            type === TYPE_NUMBER ? Number(value) : value,
            limit,
            type === TYPE_NUMBER
              ? ERROR_MESSAGE_LIMIT_NUMBER[language].replace("{value}", limit)
              : ERROR_MESSAGE_LIMIT_STRING[language].replace("{value}", limit)
          )
        );
      } else if (key?.split(":")[0] === TYPE_MODEL_ERROR_MINIMUM) {
        const minimum = Number(key?.split(":")[1]);
        temp.push(
          FunctionCheckMinimum(
            type === TYPE_NUMBER ? Number(value) : value,
            minimum,
            type === TYPE_NUMBER
              ? ERROR_MESSAGE_MINIMUM_NUMBER[language].replace(
                  "{value}",
                  minimum
                )
              : ERROR_MESSAGE_MINIMUM_STRING[language].replace(
                  "{value}",
                  minimum
                )
          )
        );
      } else if (key === TYPE_MODEL_ERROR_NO_SPACE) {
        temp.push(
          FunctionCheckNoSpace(value, ERROR_MESSAGE_NO_SPACE[language])
        );
      }
    });

    if (errorCustoms) {
      temp.push(errorCustoms({ value, name }));
    }

    const error_message = temp.filter((err) => {
      return err !== "";
    });

    if (error_message[0] === ERROR_MESSAGE_REQUIRED[language]) {
      return {
        error: true,
        message: ERROR_MESSAGE_REQUIRED[language],
        value: value,
        field_name: name,
      };
    } else if (error_message.length === 1) {
      return {
        error: true,
        message: String(error_message),
        value: value,
        field_name: name,
      };
    } else if (error_message.length > 1) {
      return {
        error: true,
        message: error_message.join(","),
        value: value,
        field_name: name,
      };
    } else {
      const formatValue =
        type === TYPE_NUMBER || type === TYPE_CALCULATOR
          ? Number(value) || 0
          : value || "";

      return mode === TYPE_MODE_NORMAL
        ? formatValue
        : {
            error: false,
            message: "",
            field_name: name,
            value: formatValue,
          };
    }
  } else {
    return { error: false, message: "" };
  }
};
