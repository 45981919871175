import axios from "axios";
import store from "../redux/store";
import { setModal } from "../redux/modalSlice";
import { pbfeApiBaseCorpURL, modalConfig } from "../config";
import { StatusCodes } from "http-status-codes";
import { getTokenFromLocalStorage } from "../utils/util";
import authService from "../api/authService";

const instanceCorp = axios.create({
  baseURL: pbfeApiBaseCorpURL,
});

const requestInterceptors = (configAxios) => {
  const access_token = sessionStorage.getItem("access_token");

  if (access_token) {
    configAxios.headers = {
      ...configAxios.headers,
      Authorization: `Bearer ${access_token}`,
    };
  }
  return configAxios;
};

const responseInterceptors = (response) => {
  return response;
};

const responseErrorInterceptors = async (error) => {
  const errorResponse = error?.response || { response: "Network Error" };

  if (errorResponse?.status === StatusCodes.UNAUTHORIZED) {
    const token = getTokenFromLocalStorage();
    if (token.refresh_token !== undefined && token.refresh_token !== null) {
      await authService.logout({ refresh_token: token.refresh_token });
    }
    sessionStorage.clear();
    if (errorResponse?.data?.status?.code !== undefined) {
      store.dispatch(
        setModal({
          open: true,
          actionModal: modalConfig.sessionExpiredModal.actionModal,
          description: errorResponse?.data?.status?.description,
        })
      );
    } else {
      store.dispatch(
        setModal({ open: true, ...modalConfig.sessionExpiredModal })
      );
    }
  } else if (errorResponse?.status === StatusCodes.NOT_FOUND) {
    store.dispatch(setModal({ open: false, ...modalConfig.networkErrorModal }));
  }

  return Promise.reject(error);
};

instanceCorp.interceptors.request.use(requestInterceptors);

instanceCorp.interceptors.response.use(
  responseInterceptors,
  responseErrorInterceptors
);

const exportedObject = { instanceCorp };

export default exportedObject;
