export const ERROR_MESSAGE_REQUIRED = "This field is required.";
export const ERROR_MESSAGE_MINUS = "Value cannot be less than 0.";
export const ERROR_MESSAGE_GREATER_THAN_VALUE =
  "Value cannot be greater than [value]";
export const ERROR_MESSAGE_DECIMAL =
  "Value decimal must not be more than [value] digits.";
export const ERROR_MESSAGE_NUMBER = "Value must be a number.";
export const ERROR_MESSAGE_VALUE_ONE_GREATER_THAN_VALUE_TWO =
  "[value1] value must be greater than [value2].";
export const ERROR_MESSAGE_DATA_NOT_FOUND = "Data not found.";
export const ERROR_MESSAGE_DATE_INVALID = "Date is invalid.";
export const ERROR_MESSAGE_DATA_DUPLICATE = "Data cannot be duplicated.";
export const ERROR_MESSAGE_NAME_ALREADY_EXIST = "Name already exist.";
