import * as React from "react";
import { TYPE_NUMBER } from "../type";

export const FunctionStroageReference = ({ props }) => {
  const isNumber = typeof props.FunctionGetThisValue === TYPE_NUMBER;
  try {
    if (
      props.parentIndex >= 0 &&
      props.parentKey &&
      props.fieldIndex >= 0 &&
      props.name &&
      props.grandParentIndex >= 0 &&
      props.grandParentKey &&
      props.parentKey !== props.grandParentKey
    ) {
      props.FieldReference.current[props.grandParentIndex][
        props.grandParentKey
      ][props.parentIndex][props.parentKey][props.fieldIndex] = {
        ...props.FieldReference.current[props.grandParentIndex][
          props.grandParentKey
        ][props.parentIndex][props.parentKey][props.fieldIndex],
        [props.name]: props.FunctionGetThisValue,
      };
    } else if (
      props.parentIndex >= 0 &&
      props.parentKey &&
      props.fieldIndex >= 0 &&
      props.name &&
      props.parentKey === props.grandParentKey
    ) {
      props.FieldReference.current[props.parentIndex][props.parentKey][
        props.fieldIndex
      ] = {
        ...props.FieldReference.current[props.parentIndex][props.parentKey][
          props.fieldIndex
        ],
        [props.name]: props.FunctionGetThisValue,
      };
    } else if (props.name) {
      if (isNumber && props.FunctionGetThisValue >= 0) {
        props.FieldReference.current[props.index] = props.FunctionGetThisValue;
      } else {
        props.FieldReference.current[props.index] = props.FunctionGetThisValue;
      }
    } else {
      return;
    }
  } catch (error) {
    console.error("ERROR IN FUNCTION ", error);
  }
};

export const FieldStorage = (props) => {
  return (
    <div
      ref={() => {
        FunctionStroageReference({
          props,
        });
      }}
    ></div>
  );
};
