import api from "./index";

// config
import { apiConfig } from "../config";

const getToken = async (params) => {
  return api.instanceCorp.get(`${apiConfig.getTokenAPI}${params}`);
};

const refreshToken = async (refresh_token) => {
  return api.instanceCorp.get(
    `${apiConfig.refreshTokenAPI}?refreshToken=${refresh_token}`
  );
};

const logout = async (body) => {
  return api.instanceCorp.post(apiConfig.logoutAPI, { ...body });
};

const exportedObject = {
  getToken,
  refreshToken,
  logout,
};

export default exportedObject;
