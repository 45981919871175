import { cloneDeep } from "lodash";
import exportedObject from "../../../api/taxSchemeService";
import {
  FunctionDeleteErrorSameName,
  FunctionGetObjectIsError,
} from "../../../components/radium-omnibus/src/utils/Field.CheckError";
import {
  FunctionCheckDataChangeService,
  FunctionCheckFormatStringBeforeArray,
  FunctionCheckNonValue,
  hasValue,
} from "../../../functions";
import { formatDateApi } from "../../../utils/util";

export const FunctionCheckBooleanNumber = (value) => {
  if (value || value === 0 || value === "0") {
    return true;
  } else {
    return false;
  }
};

export const FunctionMappingDataFeeCharge = (fee_charge) => {
  let newFeeCharge = cloneDeep(fee_charge);

  return newFeeCharge?.map((row) => {
    if (row?.fee_value?.conditions?.length > 0) {
      let newFeeValue = {
        conditions: row?.fee_value?.conditions?.map((item) => {
          let newCondition = {
            tier: item?.tier,
            qualifiers: item?.qualifiers || null,
            value: item?.value?.map((value) => {
              let thisValue = {};
              if (FunctionCheckBooleanNumber(value?.rate)) {
                thisValue = { ...thisValue, rate: Number(value?.rate) };
              }
              if (FunctionCheckBooleanNumber(value?.from)) {
                if (value?.from === "MIN") {
                  thisValue = { ...thisValue, from: 0 };
                }
                if (value?.from !== "MIN") {
                  thisValue = { ...thisValue, from: Number(value?.from) };
                }
              }
              if (FunctionCheckBooleanNumber(value?.to)) {
                if (value?.to === "MAX") {
                  thisValue = { ...thisValue, to: 999999999.99 };
                }
                if (value?.to !== "MAX") {
                  thisValue = { ...thisValue, to: Number(value?.to) };
                }
              }
              if (FunctionCheckBooleanNumber(value?.fix_amount)) {
                thisValue = {
                  ...thisValue,
                  fix_amount: Number(value?.fix_amount),
                };
              }
              if (value?.rate_type) {
                thisValue = { ...thisValue, rate_type: value?.rate_type };
              }

              if (value?.rate_type === "%") {
                thisValue = { ...thisValue, rate_type: "PERCENT" };
              }

              if (value?.rate_type === "AMT") {
                thisValue = { ...thisValue, rate_type: "THB" };
              }

              if (FunctionCheckBooleanNumber(value?.min_tier)) {
                thisValue = {
                  ...thisValue,
                  min_tier: Number(value?.min_tier),
                };
              }
              if (FunctionCheckBooleanNumber(value?.max_tier)) {
                thisValue = {
                  ...thisValue,
                  max_tier: Number(value?.max_tier),
                };
              }
              return thisValue;
            }),
          };

          if (FunctionCheckBooleanNumber(item?.priority)) {
            newCondition = {
              ...newCondition,
              priority: Number(item?.priority),
            };
          }

          if (FunctionCheckBooleanNumber(item?.max)) {
            newCondition = {
              ...newCondition,
              max: Number(item?.max),
            };
          }

          if (FunctionCheckBooleanNumber(item?.min)) {
            newCondition = {
              ...newCondition,
              min: Number(item?.min),
            };
          }

          return newCondition;
        }),
      };

      return {
        ...row,
        fee_value: newFeeValue,
      };
    } else {
      let newFeeValue = {
        tier: row?.fee_value?.tier,
        value: row?.fee_value?.value?.map((item) => {
          let thisValue = {};
          if (item?.rate) {
            thisValue = { ...thisValue, rate: Number(item?.rate) };
          }
          if (FunctionCheckBooleanNumber(item?.from)) {
            if (item?.from === "MIN") {
              thisValue = { ...thisValue, from: 0 };
            }
            if (item?.from !== "MIN") {
              thisValue = { ...thisValue, from: Number(item?.from) };
            }
          }
          if (FunctionCheckBooleanNumber(item?.to)) {
            if (item?.to === "MAX") {
              thisValue = { ...thisValue, to: 999999999.99 };
            }
            if (item?.to !== "MAX") {
              thisValue = { ...thisValue, to: Number(item?.to) };
            }
          }
          if (FunctionCheckBooleanNumber(item?.fix_amount)) {
            thisValue = {
              ...thisValue,
              fix_amount: Number(item?.fix_amount),
            };
          }
          if (item?.rate_type) {
            thisValue = { ...thisValue, rate_type: item?.rate_type };
          }
          if (item?.rate_type === "%") {
            thisValue = { ...thisValue, rate_type: "PERCENT" };
          }
          if (item?.rate_type === "AMT") {
            thisValue = { ...thisValue, rate_type: "THB" };
          }
          if (FunctionCheckBooleanNumber(item?.min_tier)) {
            thisValue = {
              ...thisValue,
              min_tier: Number(item?.min_tier),
            };
          }
          if (FunctionCheckBooleanNumber(item?.max_tier)) {
            thisValue = {
              ...thisValue,
              max_tier: Number(item?.max_tier),
            };
          }
          return thisValue;
        }),
      };

      if (FunctionCheckBooleanNumber(row?.fee_value?.max)) {
        newFeeValue = { ...newFeeValue, max: Number(row?.fee_value?.max) };
      }
      if (FunctionCheckBooleanNumber(row?.fee_value?.min)) {
        newFeeValue = { ...newFeeValue, min: Number(row?.fee_value?.min) };
      }

      return {
        ...row,
        fee_value: newFeeValue,
      };
    }
  });
};

export const FunctionGetErrorFromDomByRay = (errorState) => {
  const findErrorInDom = document.querySelectorAll(
    ".MuiFormHelperText-contained"
  );

  const findErrorInDomDropDown = document.querySelectorAll(
    ".MuiFormHelperText-root.Mui-error"
  );

  if (findErrorInDom.length > 0) {
    findErrorInDom.forEach((item) => {
      const name = String(item.id).split("-")[1];
      const errorMessage = item.innerText;
      errorState.push({
        error: true,
        field_name: name,
        message: errorMessage,
        value: "",
      });
    });
  }

  if (findErrorInDomDropDown.length > 0) {
    findErrorInDomDropDown.forEach((item) => {
      const name = String(item?.id).split("-")[1];
      const errorMessage = item.innerText;
      errorState.push({
        error: true,
        field_name: name,
        message: errorMessage,
        value: "",
      });
    });
  }
};

const SDOnSubmit = async ({
  thisNewData,
  setError,
  setErrorOpen,
  setSubmitData,
  setShowConfirm,
  data,
  taxscheme,
  isCreate,
}) => {
  let submitTransactionMapping = [];
  let checkEveryQualifier = [];

  const roleBackFeeCharge = thisNewData?.fee_charge?.map((row) => {
    let objectQulifier = {};

    if (Object?.keys(row?.qualifiers || {})?.length > 0) {
      row?.qualifiers?.forEach((qualifier) => {
        let qualifiers_string = qualifier?.string
          ?.replace(/"/g, "")
          .replace(/\//g, "");

        FunctionCheckFormatStringBeforeArray(
          qualifier?.string,
          checkEveryQualifier
        );

        const qualifierValue = qualifiers_string?.split(",");

        objectQulifier[qualifier.key] = qualifierValue;
      });
    }

    let clonetemp = cloneDeep(row?.pricing_frequency_transaction_consolidation);

    // if (row?.pricing_frequency_transaction_consolidation) {
    //   delete row?.pricing_frequency_transaction_consolidation;
    // }

    return {
      ...row,
      transaction_consolidation: clonetemp?.transaction_consolidation,
      pricing_frequency: clonetemp?.pricing_frequency,
      qualifiers:
        Object.keys(objectQulifier)?.length > 0 ? objectQulifier : null,
      posting_frequency:
        clonetemp?.pricing_method === "ONE_TIME"
          ? "DAILY"
          : clonetemp?.pricing_method === "TRAN_VOLUME" ||
            clonetemp?.pricing_method === "TRAN_VALUE"
          ? "MONTHLY"
          : clonetemp?.pricing_frequency,
      pricing_method: clonetemp?.pricing_method,
    };
  });

  if (thisNewData?.transaction_mapping?.length > 0) {
    if (Array.isArray(thisNewData?.transaction_mapping)) {
      submitTransactionMapping = [...thisNewData?.transaction_mapping];
    } else {
      let temp = [];
      if (thisNewData.transaction_mapping) {
        temp = thisNewData?.transaction_mapping?.split(",");
      }
      for (let i = 0; i < temp?.length; i++) {
        submitTransactionMapping.push({
          transaction_code: temp[i],
        });
      }
    }
  }

  let FormatData = cloneDeep({
    ...thisNewData,
    raw_data: data,
    transaction_mapping:
      submitTransactionMapping?.length > 0
        ? submitTransactionMapping
        : // : {
          //     error: true,
          //     field_name: "transaction_code",
          //     message: "This field is required.",
          //     value: "",
          //   },
          null,
    effective_from: formatDateApi(thisNewData?.from_date_to_date?.from_date),
    effective_to: formatDateApi(thisNewData?.from_date_to_date?.to_date),
    fee_charge: roleBackFeeCharge,

    debit_advice_name: thisNewData?.debit_advice_name?.error
      ? {
          error: true,
          field_name: "debit_advice_description",
          message: "This field is required.",
          value: "",
        }
      : thisNewData?.debit_advice_name,

    vat_tax_scheme_id:
      thisNewData?.vat_gl === "NO"
        ? null
        : thisNewData?.vat_gl
        ? taxscheme?.find((d) => {
            return d?.tax_scheme_name === thisNewData?.vat_gl;
          })?.tax_scheme_id || null
        : null,

    wht_tax_scheme_id:
      thisNewData?.wht_gl === "NO"
        ? null
        : thisNewData?.wht_gl
        ? taxscheme?.find((d) => {
            return d?.tax_scheme_name === thisNewData?.wht_gl;
          })?.tax_scheme_id || null
        : null,
    vat_gl: thisNewData?.vat_gl === "NO" ? null : thisNewData?.vat_gl,
    wht_gl: thisNewData?.wht_gl === "NO" ? null : thisNewData?.wht_gl,
  });

  if (isCreate) {
    let findVat = null;
    let findWht = null;

    if (
      thisNewData?.vat_gl !== "NO" &&
      thisNewData?.vat_gl !== null &&
      thisNewData?.vat_gl !== undefined &&
      thisNewData?.vat_gl !== "" &&
      thisNewData?.vat_gl !== "undefined" &&
      !thisNewData?.vat_gl?.error
    ) {
      const {
        data: { data: fd },
      } = await exportedObject.getTaxSchemeInfo({
        tax_scheme_id: taxscheme?.find((d) => {
          return d?.tax_scheme_name === thisNewData?.vat_gl;
        })?.tax_scheme_id,
      });

      findVat = {
        gl_rate: fd?.current_tax_rate,
        gl_account_code: fd?.gl_account_code,
        gl_description: fd?.gl_description,
        gl_oc_code: fd?.gl_oc_code,
        gl_product_code: fd?.gl_product_code,
        gl_rc_code: fd?.gl_rc_code,
        gl_tax_code: fd?.gl_tax_code,
        tax_scheme_id: fd?.tax_scheme_id,
        gl_name: fd?.tax_scheme_name,
        gl_type: fd?.tax_type,
      };
    }
    if (
      thisNewData?.wht_gl !== "NO" &&
      thisNewData?.wht_gl !== null &&
      thisNewData?.wht_gl !== undefined &&
      thisNewData?.wht_gl !== "" &&
      thisNewData?.wht_gl !== "undefined"
    ) {
      const {
        data: { data: fd },
      } = await exportedObject.getTaxSchemeInfo({
        tax_scheme_id: taxscheme?.find((d) => {
          return d?.tax_scheme_name === thisNewData?.wht_gl;
        })?.tax_scheme_id,
      });

      findWht = {
        gl_rate: fd?.current_tax_rate,
        gl_account_code: fd?.gl_account_code,
        gl_description: fd?.gl_description,
        gl_oc_code: fd?.gl_oc_code,
        gl_product_code: fd?.gl_product_code,
        gl_rc_code: fd?.gl_rc_code,
        gl_tax_code: fd?.gl_tax_code,
        tax_scheme_id: fd?.tax_scheme_id,
        gl_name: fd?.tax_scheme_name,
        gl_type: fd?.tax_type,
      };
    }
    FormatData = {
      ...FormatData,
      raw_data: { ...FormatData, vat_gl: findVat, wht_gl: findWht },
    };
  }

  if (!isCreate) {
    FormatData = {
      ...FormatData,
      raw_data: data,
    };
  }

  const errorState = FunctionGetObjectIsError(FormatData);

  let isSameData = false;

  let isErrorFeeValue = false;

  FormatData?.fee_charge?.forEach((d) => {
    if (d?.fee_value?.conditions?.length > 0) {
      d?.fee_value?.conditions?.forEach((third) => {
        if (third?.value?.length > 0) {
          third?.value?.forEach((row) => {
            const thisError =
              FunctionCheckNonValue(row?.fix_amount) &&
              FunctionCheckNonValue(row?.rate);
            if (thisError) {
              isErrorFeeValue = true;
            }
          });
        }
      });
    }
    if (d?.fee_value?.value) {
      d?.fee_value?.value?.forEach((row) => {
        if (
          FunctionCheckNonValue(row?.fix_amount) &&
          FunctionCheckNonValue(row?.rate)
        ) {
          isErrorFeeValue = true;
        }
      });
    }
  });

  if (isErrorFeeValue) {
    errorState.push({
      error: true,
      field_name: "fee_value",
      message: "fee value is required",
      value: "",
    });
  }

  if (!isCreate) {
    isSameData = FunctionCheckDataChangeService(data, FormatData);
  }

  if (isSameData) {
    errorState.push({
      error: true,
      field_name: "No data change",
      message: "Nothing to Update",
      value: "",
    });
  }

  if (FormatData?.fee_charge?.length === 0) {
    errorState.push({
      error: true,
      field_name: "fee_charge",
      message: "Must be at least one fee charge .",
      value: "",
    });
  }

  if (
    FormatData?.fee_charge?.length > 0 &&
    FormatData?.fee_charge[0]?.charge_code
  ) {
    FormatData?.fee_charge?.forEach((row) => {
      if (
        row?.pricing_method === "" ||
        row?.pricing_method === null ||
        row?.pricing_method === "undefined" ||
        row?.pricing_method === undefined
      ) {
        errorState.push({
          error: true,
          field_name: "pricing_method",
          message: "This field is required.",
          value: "",
        });
      }

      if (
        row?.posting_frequency === "" ||
        row?.posting_frequency === null ||
        row?.posting_frequency === "undefined" ||
        row?.posting_frequency === undefined
      ) {
        errorState.push({
          error: true,
          field_name: "posting_frequency",
          message: "This field is required.",
          value: "",
        });
      }

      if (
        row?.transaction_consolidation === "" ||
        row?.transaction_consolidation === null ||
        row?.transaction_consolidation === "undefined" ||
        row?.transaction_consolidation === undefined
      ) {
        errorState.push({
          error: true,
          field_name: "transaction_consolidation",
          message: "This field is required.",
          value: "",
        });
      }
    });
  }

  FunctionGetErrorFromDomByRay(errorState); // Error ใน DOM ของ Fee charge

  // const findChargeName = document.querySelectorAll("#charge_name");
  const findChargeCode = document.querySelectorAll("#charge_code");

  // let allChargeName = [];
  let allChargeCode = [];

  // if (findChargeName?.length > 0) {
  //   findChargeName.forEach((item) => {
  //     if (item?.defaultValue) {
  //       allChargeName.push(item?.defaultValue);
  //     }
  //   });
  // }

  if (findChargeCode?.length > 0) {
    findChargeCode.forEach((item) => {
      if (item?.defaultValue) {
        allChargeCode.push(item?.defaultValue);
      }
    });
  }

  //หา charge name ซ้ำ

  // if (allChargeName?.length > 0) {
  //   const findDuplicate = allChargeName.filter(
  //     (item, index) => allChargeName.indexOf(item) !== index
  //   );

  //   if (findDuplicate?.length > 0) {
  //     errorState.push({
  //       error: true,
  //       field_name: "fee_charge",
  //       message: "Charge name must be unique",
  //       value: "",
  //     });
  //   }
  // }

  //หา charge code ซ้ำ

  if (allChargeCode?.length > 0) {
    const findDuplicate = allChargeCode.filter(
      (item, index) => allChargeCode.indexOf(item) !== index
    );

    if (findDuplicate?.length > 0) {
      errorState.push({
        error: true,
        field_name: "fee_charge",
        message: "Charge code must be unique",
        value: "",
      });
    }
  }

  //Charge มากกว่า 1

  if (thisNewData?.fee_charge?.length > 0) {
    const zero = thisNewData?.fee_charge[0]?.charge_code;

    if (!zero) {
      errorState.push({
        error: true,
        field_name: "fee_charge",
        message: "Must be at least one fee charge .",
        value: "",
      });
    }
  }
  // Check ทุก Qualifire ว่าถูกต้องหรือไม่
  if (checkEveryQualifier?.length > 0) {
    errorState.push({
      error: true,
      field_name: "qualifiers",
      message: "Qualifiers is not valid",
      value: "",
    });
  }

  let mappingData = cloneDeep(FormatData);

  mappingData = {
    ...mappingData,
    fee_charge: FunctionMappingDataFeeCharge(mappingData?.fee_charge),
    effective_to:
      mappingData?.effective_to === "" || mappingData?.effective_to === null
        ? "9999-12-31T00:00:00"
        : mappingData?.effective_to,
  };

  console.log("FormatData : ", {
    FormatData,
    errorState,
    data,
    checkEveryQualifier,
    mappingData,
  });

  if (errorState?.length > 0) {
    setError(FunctionDeleteErrorSameName(errorState));
    setErrorOpen(true);
    return;
  }
  setSubmitData(mappingData);
  setShowConfirm(true);
  setErrorOpen(false);
};

export default SDOnSubmit;
// ("input-to-helper-text");

// MuiOutlinedInput-root MuiInputBase-root MuiInputBase-colorPrimary Mui-error MuiInputBase-formControl MuiInputBase-sizeSmall makeStyles-input-2 css-wye6gr-MuiInputBase-root-MuiOutlinedInput-root
//MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root
