import { useEffect, useState } from "react";
import PBFESearchbar from "../../components/Elements/Searchbar/searchbar";
import { FilterDropdown } from "../../components/Input/filterDropdown";
import {
  BANK_BRACH_HEADER,
  BANK_BRANCH_PATH_NAME,
  BANK_BRANCH_ROUTE,
  MORE_THAN_SEARCH_ACTIVE,
  ROW_PER_PAGE,
} from "../../constants/constant";
import Skeleton from "react-loading-skeleton";
import bankBranch from "../../api/bankBranch";
import useQuery from "../../hooks/useQuery";
import { PBFECommonTableAsync } from "../../components/Table/commonTableAsync";
import PBFEError from "../../components/Error/alertError";

const PBFEFormatDataBankBranch = (data) => {
  const res = data?.map((row) => {
    return {
      bank_code: row?.bank_code,
      branch_code: row?.branch_code,
      branch_name: row?.branch_name,
      region_code: row?.region_code,
      source_system: row?.source_system,
    };
  });
  return res;
};

const BankBranch = ({ setRouteName, setActionAppbar }) => {
  let limit = 15;

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("bank_code");
  const [sortBy, setSortBy] = useState("asc");
  const [searched, setSearched] = useState("");
  const [valueId, setValueId] = useState("014");
  const [sourceSystem, setSourceSystem] = useState("All");

  const { data, refetch, loading, error, totalRecords } = useQuery(
    bankBranch.getBankBranch,
    {},
    {
      limit: 15,
      sort_by: sortBy,
      offset: 0,
      order_by: orderBy,
      bank_code: valueId,
      sourceSystem: sourceSystem,
    }
  );

  const { data: BankId } = useQuery(bankBranch.getBankId);

  useEffect(() => {
    setActionAppbar("");
    setRouteName([{ title: BANK_BRANCH_PATH_NAME, path: BANK_BRANCH_ROUTE }]);
  }, [setRouteName]);

  const onChangeFilter = async (event) => {
    setPage(0);
    if (event.target.name === "source_system") {
      /// case source system
      setSourceSystem(event.target.value);
      const param = event.target.value === "All" ? {
        offset: 0,
        limit,
        search_criteria: searched,
        sort_by: order,
        order_by: orderBy,
        source_system: "",
        bank_code:valueId,
      } : {
        offset: 0,
        limit,
        search_criteria: searched,
        sort_by: order,
        order_by: orderBy,
        bank_code:valueId,
        source_system: event.target.value,
      }

      await refetch(
        {},
        param
      );
    }
    else {
      // case bank code
      setValueId(event.target.value);
      if (event.target.value !== "") {
        await refetch(
          {},
          {
            offset: 0,
            limit,
            search_criteria: searched,
            sort_by: order,
            order_by: orderBy,
            bank_code: event.target.value,
            source_system: sourceSystem === "All" ? "" : sourceSystem,
          }
        );
      } else {
        await refetch(
          {},
          {
            offset: 0,
            limit,
            search_criteria: searched,
            sort_by: order,
            order_by: orderBy,
            source_system: sourceSystem === "All" ? "" : sourceSystem,
          }
        );
      }
    }


  };

  const onChangeSearch = async (searchedVal) => {
    const value = searchedVal.target.value;
    setSearched(value);
    setPage(0);
    if (value?.length > MORE_THAN_SEARCH_ACTIVE) {
      await refetch(
        {},
        {
          offset: 0,
          limit,
          search_criteria: value,
          sort_by: order,
          order_by: orderBy,
          ...getFilter(),
          // ...getFilter(bankCode),
        }
      );
      return;
    }
    if (value?.length === 0) {
      await refetch(
        {},
        {
          offset: 0,
          limit,
          search_criteria: "",
          sort_by: order,
          order_by: orderBy,
          ...getFilter(),
          // ...getFilter(bankCode),
        }
      );
      return;
    }
  };

  const mappingBankId = () => {
    return BankId.map((id) => {
      return { label: id, value: id };
    });
  };

  const getFilter = () => {
    if (valueId !== "") {
      return {
        bank_code: valueId,
        source_system: sourceSystem === "All" ? "" : sourceSystem,
      };
    }
    if(sourceSystem !== "All" && sourceSystem !== ""){
      return {
        // bank_code: "014",
        source_system: sourceSystem,
      };
    }
  };

  if (error) return <PBFEError error={error} />;

  return (
    <div className=" w-full">
      <div className="flex justify-between mt-50px w-full pt-md">
        <div className="m-15px flex space-x-4">
          <FilterDropdown
            label="Bank Code"
            value={valueId}
            onChange={onChangeFilter}
            options={
              BankId?.length > 0
                ? [{ label: "All Bank Code", value: "" }].concat(
                  mappingBankId(BankId)
                )
                : [{ value: "", label: "Loading..." }]
            }
            width="110px"
          />
          <FilterDropdown
            label="Source System"
            value={sourceSystem}
            name="source_system"
            onChange={onChangeFilter}
            options={
              [{ value: "All", label: "All" },
              { value: "BC", label: "BC" },
              { value: "BCMS", label: "BCMS" },
              ]

            }
            width="110px"
          />
        </div>
        <PBFESearchbar
          placeholder={"Branch Code / Branch Name"}
          onChange={onChangeSearch}
          value={searched}
        />
      </div>
      {loading ? (
        <div className="p-fourteen">
          <Skeleton
            containerTestId="react-loading-skeleton"
            height={30}
            width={`100%`}
            count={9}
          />
        </div>
      ) : (
        <PBFECommonTableAsync
          searched={searched}
          filter={{ ...getFilter() }}
          page={page}
          setPage={setPage}
          headCells={BANK_BRACH_HEADER}
          rows={PBFEFormatDataBankBranch(data)}
          showFooter={true}
          selectable={false}
          formatControl={[null, null, null, null, null]}
          refetch={refetch}
          total_records={totalRecords}
          limit={ROW_PER_PAGE}
          orderByDefault="request_date"
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          sortBy={sortBy}
          setSortBy={setSortBy}
          disabledSortList={["source_system"]}

        />
      )}
    </div>
  );
};

export default BankBranch;
