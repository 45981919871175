import api from "./index";

// config
import { apiConfig } from "../config";

const getAccount = async (params, queryString) => {
  return api.instanceCorp.get(`${apiConfig.getAccountAPI}${queryString}`);
};

const getAccountById = async (params, queryString) => {
  return api.instanceCorp.get(
    `${apiConfig.getAccountAPI}/${params.account_id}/info`
  );
};

const getAccountByIdSearch = async (params, queryString) => {
  return api.instanceCorp.get(
    `${apiConfig.getAccountAPI}/${params.account_id}`
  );
};

const getServiceAccountById = async (params, queryString) => {
  return api.instanceCorp.get(
    `${apiConfig.getAccountAPI}/${params.account_id}/unassigned-services`
  );
};

const getOfferAccountById = async (params, queryString) => {
  return api.instanceCorp.get(
    `${apiConfig.getAccountAPI}/${params.account_id}/unassigned-offers`
  );
};

const getBundleOfferAccountById = async (params, queryString) => {
  return api.instanceCorp.get(
    `${apiConfig.getAccountAPI}/${params.account_id}/unassigned-bundle-offers`
  );
};

export default {
  getAccount,
  getAccountById,
  getServiceAccountById,
  getOfferAccountById,
  getAccountByIdSearch,
  getBundleOfferAccountById,
};
