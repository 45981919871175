import React, { useEffect } from "react";

import {
  MAKER_CHECKER_REQUEST_PATH_NAME,
  MAKER_CHECKER_WAITING_PATH_NAME,
} from "../../constants/constant";
import MyRequest from "../MyRequest";
import { useSelector } from "react-redux";
import Waitingforapprove from "../WaitingForApproval";

function Landing({ setRouteName, setActionAppbar }) {
  const { user } = useSelector((state) => state.auth);

  const permission = user?.menu;

  useEffect(() => {
    setActionAppbar("");
    setRouteName([]);
  }, []);

  return (
    <div className="bg-bgLight w-screen  min-h-screen pb-xl">
      <div className="font-bold text-36px m-40px pt-xl">
        Welcome, {user?.user_name}
      </div>

      {permission?.my_request === "E" || permission?.my_request === "V" ? (
        <div className="bg-white mr-30px ml-40px border boder-gray-300 rounded-lg mb-30px">
          <div className="font-bold text-header m-20px">
            {MAKER_CHECKER_REQUEST_PATH_NAME.toUpperCase()}
          </div>
          <div className="m-20px mt-0">
            <MyRequest
              disabledRouteName={true}
              primaryDisplay={false}
              mt="0"
              setRouteName={setRouteName}
            />
          </div>
        </div>
      ) : null}
      {permission?.waiting_for_approval === "E" ||
      permission?.waiting_for_approval === "V" ? (
        <div className="bg-white mr-30px ml-40px border boder-gray-300 rounded-lg">
          <div className="font-bold text-header m-20px">
            {MAKER_CHECKER_WAITING_PATH_NAME.toUpperCase()}
          </div>
          <div className="m-20px mt-0">
            <Waitingforapprove
              disabledRouteName={true}
              primaryDisplay={false}
              mt="0"
              setRouteName={setRouteName}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Landing;
