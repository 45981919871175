import api from "./index";

// config
import { apiConfig } from "../config";

const getUpload = async (params, queryString) => {
  return api.instanceCorp.get(`${apiConfig.getUpload}${queryString}`, {
    headers: { "content-type": "multipart/form-data" },
  });
};

const postUpload = async (formData) => {
  return api.instanceCorp.post(`${apiConfig.getUpload}`, formData, {
    headers: { "content-type": "multipart/form-data" },
  });
};

const getUploadDownload = async (id, queryString) => {
  return api.instanceCorp.get(
    `${apiConfig.getUpload}/${id}/download${queryString}`,
    {
      headers: { "content-type": "multipart/form-data" },
      responseType: "blob",
    }
  );
};

export default {
  getUpload,
  postUpload,
  getUploadDownload,
};
