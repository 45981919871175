import * as React from "react";

import { TEXT_AUTHOR } from "./Field.Constant";

export const FunctionBody = (props) => {
  let thisProps = {
    disabled: props.disabled,
    isEdit: props.isEdit,
    name: props.name,
    reference: props.reference,
    setValue: props.setValue,
    value: props.value,
    received: props.received,
    props: props,
    originalData: props.originalData,
    isLast: props.isLastStep,
    isFirst: props.isFirstStep,
    initalValue: props.initalValue,
    thisValue: props.FunctionGetThisValue || "",
    fieldIndex: props.fieldIndex,
    grandParentIndex: props.grandParentIndex,
    grandParentKey: props.grandParentKey,
    parentIndex: props.parentIndex,
    parentKey: props.parentKey,
    loadData: props.loadData,
    currentData: props.currentData,
    listener: props.listener,
    setListener: props.setListener,
  };

  if (props.grandParentKey || props.parentKey) {
    thisProps = {
      ...thisProps,
      add: props.FunctionAddStep,
      remove: props.FunctionRemoveStep,
    };
  }

  return (
    <div className={`${TEXT_AUTHOR}-customs`}>
      {props?.fieldCustoms ? (
        <React.Fragment>{props?.fieldCustoms(thisProps)}</React.Fragment>
      ) : (
        <React.Fragment>Error Please fill fieldCustoms</React.Fragment>
      )}
    </div>
  );
};
