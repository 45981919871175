import * as React from "react";
import { ICONS_X } from "../utils/Field.Icons.js";
import { STYLE_FIELD_ERROR } from "../utils/Field.Style.js";

export const FieldError = ({ message }) => {
  return (
    <div style={STYLE_FIELD_ERROR}>
      <ICONS_X />
      {message}
    </div>
  );
};
