import { PBFEApprovalDetail } from "../WaitingForApproval/approvalDetail";

export const PBFEMyRequestDetail = ({ setRouteName, setDataRequest }) => {
  return (
    <PBFEApprovalDetail
      setRouteName={setRouteName}
      setDataRequest={setDataRequest}
    />
  );
};
