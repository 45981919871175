import * as React from "react";
import { TEXT_AUTHOR } from "./Field.Constant";

export const FieldMultipleSubmitController = ({
  classNameSubmit = "",
  labelSubmit = "Submit",
  data = "",
  trigger = () => {},
  render = false,
}) => {
  const [click, setClick] = React.useState(0);

  React.useEffect(() => {
    try {
      if (click === 1) {
        const x = document.querySelectorAll(`.${TEXT_AUTHOR}-submit`);
        if (x.length > 0) {
          x.forEach((_, i) => {
            x[i].click();
          });
          setClick(0);
        } else {
          return;
        }
      }
    } catch (error) {
      console.error("FieldMultipleSubmitController Effect Count", error);
    }
  }, [click]);

  React.useEffect(() => {
    if (click > 0 && trigger) {
      try {
        const checkType = typeof data;
        if (data !== null && data !== undefined) {
          if (checkType === "object") {
            const key = Object?.keys(data);
            if (key.length > 0) {
              trigger(data);
              setClick(0);
            } else {
              return;
            }
          } else {
            trigger(data);
            setClick(0);
          }
        } else {
          return;
        }
      } catch (error) {
        console.error("FieldMultipleSubmitController Effect Trigger", error);
      }
    } else {
      return;
    }
  }, [data, trigger]);

  if (render) {
    return (
      <React.Fragment>
        {render({
          click: () => {
            if (click === 0) {
              setClick(1);
            } else {
              return;
            }
          },
        })}
      </React.Fragment>
    );
  } else {
    return (
      <button
        id={`${TEXT_AUTHOR}-button-submit`}
        className={`${TEXT_AUTHOR}-multi-submit ${classNameSubmit}`}
        onClick={() => {
          if (click === 0) {
            setClick(1);
          } else {
            return;
          }
        }}
      >
        {labelSubmit}
      </button>
    );
  }
};
