import * as React from "react";
import { TYPE_FUNCTION } from "../type";
import { TEXT_AUTHOR } from "../utils/Field.Constant";

export const FieldRenderNoneEdit = ({
  classNameLabel = "",
  classNameValue = "",
  label = "",
  value = "",
  subLabel = "",
  classNameSubLabel = "",
  classNameWrapper = "",
}) => {
  return (
    <div
      className={`${TEXT_AUTHOR}-wrapper ${
        typeof classNameWrapper === TYPE_FUNCTION
          ? classNameWrapper(value)
          : classNameWrapper
      }`}
    >
      {label && (
        <div
          className={`${TEXT_AUTHOR}-label ${
            typeof classNameLabel === TYPE_FUNCTION
              ? classNameLabel(value)
              : classNameLabel
          }`}
        >
          {typeof label === TYPE_FUNCTION ? label(value) : label}
        </div>
      )}
      {subLabel && (
        <div
          className={`${TEXT_AUTHOR}-sub-label ${
            typeof classNameSubLabel === TYPE_FUNCTION
              ? classNameSubLabel(value)
              : classNameSubLabel
          }`}
        >
          {typeof subLabel === TYPE_FUNCTION ? subLabel(value) : subLabel}
        </div>
      )}
      {(value || value >= 0) && (
        <div
          className={`${TEXT_AUTHOR}-value ${
            typeof classNameValue === TYPE_FUNCTION
              ? classNameValue(value)
              : classNameValue
          }`}
        >
          {value}
        </div>
      )}
    </div>
  );
};
