import React from "react";
import { PBFEContent } from "../../../components/Elements/Text/content";
import PBFEInvalid from "../../../components/Error/invalidData";
import { PBFEAccordionMain } from "../../../components/Panel/Accordion/accordionMain";
import { ACCOUNT_TITLE } from "../../../constants/constant";
import AccountRenderOffer from "./Account.RenderOffer";
import { TextField } from "@mui/material";

export const AccountAddNewOfferDuplicate = ({
  dataRequest,
  data,
  customer,
  accountTable,
  debitProfile,
  setDebitProfile,
  originalData,
  setErrorState,
  is_maker,
  errorState,
  setCheckConfirmError,
  checkConfirmError,
  setRouteName,
  setIsNewService,
}) => {
  const [remark, setRemark] = React.useState("");

  React.useEffect(() => {
    setRouteName([
      { title: ACCOUNT_TITLE, path: "/account" },
      {
        title: `${dataRequest.account_name}`,
        path: `/account/${dataRequest.account_id}`,
        setSelected: setIsNewService,
        check: true,
      },
      {
        title: "ADD OFFER",
      },
    ]);
  }, [dataRequest]);

  return (
    <div className="w-full">
      <PBFEInvalid
        isShow={checkConfirmError && errorState.length > 0}
        message=""
        setOpen={setCheckConfirmError}
        open={checkConfirmError}
        errorState={errorState}
      />
      <div className="w-full overflow-auto">
        <div className="grid grid-cols-2 w-full px-xl my-20px">
          <PBFEContent label="Account Name" value={dataRequest?.account_name} />
          <PBFEContent label="Account No." value={dataRequest?.account_id} />
          <PBFEContent
            label="Customer Name"
            value={dataRequest?.customer_name}
          />
          <PBFEContent label="Customer ID" value={dataRequest?.customer_id} />
        </div>
        <div className="h-10px bg-gray-100"></div>
        <PBFEAccordionMain
          label="Offer"
          detail={
            <AccountRenderOffer
              dataRequest={dataRequest}
              checkConfirmError={checkConfirmError}
              setCheckConfirmError={setCheckConfirmError}
              is_maker={is_maker}
              customer={customer}
              originalData={originalData}
              debitProfile={debitProfile}
              setDebitProfile={setDebitProfile}
              remark={remark}
              setErrorState={setErrorState}
              data={{
                offer: [
                  {
                    ...data,
                    service: data?.offer_fee_service,
                  },
                ],
              }}
              isEdit={true}
              isNew={true}
              accountTable={accountTable}
            />
          }
        />
        <div className="ml-40px mr-25px">
          <TextField
            id="remark"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            label="REMARK"
            variant="filled"
            helperText={"Limited " + remark?.length + "/300"}
            fullWidth
            inputProps={{ maxLength: 300 }}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountAddNewOfferDuplicate;
