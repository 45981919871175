import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import React from "react";
import { PBFEContent } from "../../../components/Elements/Text/content";
import PBFEInvalid from "../../../components/Error/invalidData";
import { CUSTOMER_TITLE } from "../../../constants/constant";
import { formatEmpty, isValidEmail } from "../../../utils/util";

const CustomerEditServiceAddress = ({
  setRouteName,
  setDataRequest,
  duplicateData,
  data,
  summitEditServiceInfo,
  setSubmitForm,
  indexServiceAddress,
  setServiceInfoError,
}) => {
  const [form, setForm] = React.useState({});
  const [sameAsRegisAddress, setSameAsRegisAddress] = React.useState(true);
  const [checkConfirmError, setCheckConfirmError] = React.useState(false);
  const [isShowError, setIsShowError] = React.useState(true);
  const [errorList, setErrorList] = React.useState([]);

  React.useEffect(() => {
    if (duplicateData) {
      setRouteName([
        { title: CUSTOMER_TITLE, path: "/customer" },
        {
          title: duplicateData?.customer_name,
          path: `/customer/${duplicateData.customer_id}`,
        },
        {
          title: "EDIT ADDRESS",
        },
      ]);
      setForm(duplicateData);
      setSameAsRegisAddress(duplicateData?.different_contact_addr_flag === "N");
    } else {
      if (
        data.service_info[indexServiceAddress].different_contact_addr_flag ===
        "Y"
      ) {
        setForm({
          ...data.service_info[indexServiceAddress],
          contact_addr_line1:
            data.service_info[indexServiceAddress].contact_addr_line1,
          contact_addr_line2:
            data.service_info[indexServiceAddress].contact_addr_line2,
          contact_addr_line3:
            data.service_info[indexServiceAddress].contact_addr_line3,
          contact_zip_code:
            data.service_info[indexServiceAddress].contact_zip_code,
          service_code: data.service_info[indexServiceAddress].service_code,
        });
      } else {
        setForm({
          ...data.service_info[indexServiceAddress],
          contact_addr_line1:
            data.service_info[indexServiceAddress].reg_addr_line1,
          contact_addr_line2:
            data.service_info[indexServiceAddress].reg_addr_line2,
          contact_addr_line3:
            data.service_info[indexServiceAddress].reg_addr_line3,
          contact_zip_code: data.service_info[indexServiceAddress].reg_zip_code,
          service_code: data.service_info[indexServiceAddress].service_code,
        });
      }
      setSameAsRegisAddress(
        data.service_info[indexServiceAddress].different_contact_addr_flag ===
          "N"
      );
    }
  }, [indexServiceAddress]);

  React.useEffect(() => {
    if (form !== {}) {
      setSubmitForm(form);
    }
  }, [summitEditServiceInfo]);

  React.useEffect(() => {
    /// validate
    var tempError = [];
    var isValdiateError = false;
    if (!isValidEmail(form.email)) {
      isValdiateError = true;
      tempError.push({
        message:
          "Please enter valid email addresses. For multiple email addresses, please use comma without space between each email such as email1@gmail.com,email2@gmail.com",
      });
    }
    if (!form.customer_branch_no || form.customer_branch_no.length !== 5) {
      isValdiateError = true;
      tempError.push({
        message: "Invalid customer branch no. please make sure to use 5 digit",
      });
    }
    if (!form.reg_zip_code || form.reg_zip_code.length !== 5) {
      isValdiateError = true;
      tempError.push({
        message: "Invalid registered postcode. please make sure to use 5 digit",
      });
    }
    if (!sameAsRegisAddress) {
      if (!form.contact_zip_code || form.contact_zip_code.length !== 5) {
        isValdiateError = true;
        tempError.push({
          message: "Invalid contact postcode. please make sure to use 5 digit",
        });
      }
      if (form?.contact_addr_line1?.length < 1) {
        isValdiateError = true;
        tempError.push({
          message: "Invalid contact address. please make sure it not empty",
        });
      }
    }
    if (form?.reg_addr_line1?.length < 1) {
      isValdiateError = true;
      tempError.push({
        message: "Invalid registered address. please make sure it not empty",
      });
    }

    if (isValdiateError) {
      setCheckConfirmError(true);
      setServiceInfoError(true);
      setIsShowError(true);
    } else {
      setCheckConfirmError(false);
      setServiceInfoError(false);
    }
    setErrorList(tempError);
  }, [
    form.email,
    form.customer_branch_no,
    form.reg_zip_code,
    form?.contact_zip_code,
    form.reg_addr_line1,
    form.contact_addr_line1,
    sameAsRegisAddress,
  ]);

  const toogleAddress = (e) => {
    setSameAsRegisAddress(e.target.checked);
    if (e.target.checked) {
      setForm({
        ...form,
        contact_addr_line1: form.reg_addr_line1,
        contact_addr_line2: form.reg_addr_line2,
        contact_addr_line3: form.reg_addr_line3,
        contact_zip_code: form.reg_zip_code,
      });
    }
  };

  return (
    <div id="editServiceAddress" className="w-full">
      <PBFEInvalid
        isShow={isShowError}
        // message=""
        setOpen={setCheckConfirmError}
        open={checkConfirmError}
        errorState={errorList}
        requireShowField={false}
      />

      <div className="w-full overflow-auto">
        <div className="grid grid-cols-2 w-full px-xl my-20px gap-20px">
          <PBFEContent
            label="Customer Name"
            value={data?.customer_name || duplicateData?.customer_id}
          />
          <PBFEContent
            label="Customer ID"
            value={data?.customer_id || duplicateData?.customer_id}
          />
          <PBFEContent
            label="Account No."
            value={formatEmpty(form?.account_id || duplicateData?.account_id)}
          />
          <PBFEContent label="Service" value={form.service_code} />
        </div>
        <div className="h-10px bg-gray-100"></div>
        <div className="grid grid-cols-2 gap-20px p-xl py-large">
          <TextField
            label="Tax Branch No."
            fullWidth
            variant="standard"
            onChange={(e) =>
              setForm({ ...form, customer_branch_no: e.target.value })
            }
            value={form.customer_branch_no || ""}
            inputProps={{
              maxLength: 5,
              style: { color: "#7A58BF", fontSize: 16 },
            }}
            helperText={
              "Must be Number Limited " +
              form?.customer_branch_no?.length +
              "/5"
            }
          />
          <TextField
            label="Customer Name"
            fullWidth
            variant="standard"
            onChange={(e) =>
              setForm({ ...form, customer_full_name_thai: e.target.value })
            }
            value={form?.customer_full_name_thai || ""}
            inputProps={{
              maxLength: 256,
              style: { color: "#7A58BF", fontSize: 16 },
            }}
            helperText={form?.customer_full_name_thai?.length + "/256"}
          />
          <div className="text-textscbdark text-value col-span-2 font-bold">
            Registered Address
          </div>
          <TextField
            label=""
            fullWidth
            variant="standard"
            onChange={(e) =>
              setForm({ ...form, reg_addr_line1: e.target.value })
            }
            value={form?.reg_addr_line1}
            inputProps={{
              maxLength: 256,
              style: { color: "#7A58BF", fontSize: 16 },
            }}
            helperText={"Limited " + form?.reg_addr_line1?.length + "/256"}
          />
          <TextField
            fullWidth
            variant="standard"
            onChange={(e) =>
              setForm({ ...form, reg_addr_line2: e.target.value })
            }
            value={form?.reg_addr_line2}
            inputProps={{
              maxLength: 256,
              style: { color: "#7A58BF", fontSize: 16 },
            }}
            helperText={"Limited " + form?.reg_addr_line2?.length + "/256"}
          />
          <TextField
            variant="standard"
            onChange={(e) =>
              setForm({ ...form, reg_addr_line3: e.target.value })
            }
            value={form?.reg_addr_line3}
            inputProps={{
              maxLength: 70,
              style: { color: "#7A58BF", fontSize: 16 },
            }}
            helperText={"Limited " + form?.reg_addr_line3?.length + "/70"}
          />
          <TextField
            variant="standard"
            onChange={(e) => setForm({ ...form, reg_zip_code: e.target.value })}
            value={form?.reg_zip_code}
            inputProps={{
              maxLength: 5,
              style: { color: "#7A58BF", fontSize: 16 },
            }}
            helperText={"Postcode Limited " + form?.reg_zip_code?.length + "/5"}
          />
          <div className="text-textscbdark  text-value font-bold col-span-2 flex flex-row">
            <div>Contact Address</div>
            <div className="-mt-10px ml-10px">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sameAsRegisAddress}
                    onChange={toogleAddress}
                  />
                }
                label={"Same As Registered Address"}
              />
            </div>
          </div>
          {!sameAsRegisAddress && (
            <>
              <TextField
                label=""
                fullWidth
                variant="standard"
                onChange={(e) =>
                  setForm({ ...form, contact_addr_line1: e.target.value })
                }
                value={form?.contact_addr_line1}
                inputProps={{
                  maxLength: 256,
                  style: { color: "#7A58BF", fontSize: 16 },
                }}
                helperText={
                  "Limited " + form?.contact_addr_line1?.length + "/256"
                }
              />
              <TextField
                fullWidth
                variant="standard"
                onChange={(e) =>
                  setForm({ ...form, contact_addr_line2: e.target.value })
                }
                value={form?.contact_addr_line2}
                inputProps={{
                  maxLength: 256,
                  style: { color: "#7A58BF", fontSize: 16 },
                }}
                helperText={
                  "Limited " + form?.contact_addr_line2?.length + "/256"
                }
              />
              <TextField
                variant="standard"
                onChange={(e) =>
                  setForm({ ...form, contact_addr_line3: e.target.value })
                }
                value={form?.contact_addr_line3}
                inputProps={{
                  maxLength: 70,
                  style: { color: "#7A58BF", fontSize: 16 },
                }}
                helperText={
                  "Limited " + form?.contact_addr_line3?.length + "/70"
                }
              />
              <TextField
                variant="standard"
                onChange={(e) =>
                  setForm({ ...form, contact_zip_code: e.target.value })
                }
                value={form?.contact_zip_code}
                inputProps={{
                  maxLength: 5,
                  style: { color: "#7A58BF", fontSize: 16 },
                }}
                helperText={
                  "Postcode Limited " + form?.contact_zip_code?.length + "/5"
                }
              />
            </>
          )}
          <div className="col-span-2 grid grid-cols-5">
            <div className="col-span-4">
              <TextField
                label="Email"
                variant="standard"
                fullWidth
                onChange={(e) => setForm({ ...form, email: e.target.value })}
                value={form?.email || ""}
                inputProps={{
                  maxLength: 254,
                  style: { color: "#7A58BF", fontSize: 16 },
                }}
                // helperText={
                //   "Seperate Email by , Limited " + form?.email?.length + "/254"
                // }
                helperText={
                  <div className="grid">
                    <div>
                      Seperate Email by , Limited {form?.email?.length}/254
                    </div>
                    <div className="text-red-500 mr-3">
                      กรณีมีหลาย email address ให้ใช้เครื่องหมาย comma (,) คั่น
                      โดยไม่ต้องมี space เช่น email1@gmail.com,email2@gmail.com
                    </div>
                  </div>
                }
              />
            </div>
            <div className="ml-20px mt-15px">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.email_alert_flag === "Y"}
                    onClick={(e) => {
                      e.stopPropagation();
                      setForm({
                        ...form,
                        email_alert_flag:
                          form?.email_alert_flag === "Y" ? "N" : "Y",
                      });
                    }}
                  />
                }
                label={"Email Alert"}
              />
            </div>
          </div>
          <div className="col-span-2">
            <TextField
              label="Remark"
              variant="standard"
              fullWidth
              onChange={(e) => setForm({ ...form, remark: e.target.value })}
              value={form?.remark}
              inputProps={{
                maxLength: 300,
                style: { color: "#7A58BF", fontSize: 16 },
              }}
              helperText={"Limited " + (form?.remark?.length || 0) + "/300"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerEditServiceAddress;
