import api from "./index";
import { apiConfig } from "../config";

const getService = async (params, query) => {
  return api.instanceCorp.get(apiConfig.getServiceAPI);
};

const getServiceInfo = async (params, query) => {
  return api.instanceCorp.get(
    `${apiConfig.getServiceAPI}/${params.service_code}`
  );
};

const postService = async (body) => {
  return api.instanceCorp.post(apiConfig.postService, {
    ...body,
  });
};
const emailToStopService = async(customer_fee_service_id,customer_offer_id)=>{
  return api.instanceCorp.post(apiConfig.emailStopAlert, {
    "customer_fee_service_id": customer_fee_service_id,
    "customer_offer_id": customer_offer_id
  })
}

const updateService = async (params, body) => {
  return api.instanceCorp.put(
    `${apiConfig.postService}/${params.service_code}`,
    {
      ...body,
    }
  );
};

const getHistoryService = async (params, query) => {
  return api.instanceCorp.get(`${apiConfig.getServiceAPI}/histories${query}`);
};

const exportedObject = {
  getService,
  getServiceInfo,
  postService,
  updateService,
  getHistoryService,
  emailToStopService
};

export default exportedObject;
